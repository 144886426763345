import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import {Badge, Table} from 'reactstrap';
import * as db from '../../../config/dbStructure';
import config from '../../../config/app';
import {isMotorCalibrationOk} from '../../../lib/util';
import moment from "moment/moment";

export default class MotorTestBatchDeviceTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Pos.</th>
                        <th>Device</th>
                        <th><i className="fa fa-arrows-h"></i></th>
                        <th>Motor speed</th>
                        <th>Motor position</th>
                        <th>Motor calibration</th>
                        <th>Motor engine</th>
                        <th>Motor moved correctly</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.testBatchDevices.map((testBatchDevice, i) => {
                            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                            let motorSpeed = device.get(db.Device.MOTOR_SPEED);
                            let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
                            let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);
                            let motorPosition = device.get(db.Device.MOTOR_POSITION);
                            let {
                                calibrationOk,
                                fsdcNonOK,
                                clNonOk,
                                speedNonOk
                            } = isMotorCalibrationOk(device);
                            let motorCurrentsAreOk = calibrationOk;

                            let row = (i % 4) + 1;
                            let column = Math.trunc(i / 4);
                            let columnMap = ['A', 'B', 'C', 'D', 'E'];

                            let lastConnectionDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

                            lastConnectionDate = lastConnectionDate != null ?  moment(lastConnectionDate).fromNow() : null;

                            let calibrateMotorCommandInQueue = this.props.batchInfo?.[testBatchDevice.id]?.calibrateMotorCommandInQueue;
                            let changeMotorSpeedCommandInQueue = this.props.batchInfo?.[testBatchDevice.id]?.changeMotorSpeedCommandInQueue;

                            return <tr key={testBatchDevice.id}>
                                <td scope="row">
                                    <a
                                        href={'https://cleveron-assets.s3.amazonaws.com/lean-management/batch-map-explaination.png'}
                                        target={'_blank'}
                                    >
                                        {columnMap[column]}{row}
                                    </a>
                                </td>
                                <td>
                                    <a
                                        href={`${config.adminDashboard.host}/devices/${testBatchDevice.get(db.TestBatchDevice.DEVICE).id}`}
                                        target={'_blank'}>
                                        {testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.SERIAL_NUMBER)}
                                    </a>
                                </td>
                                <td>
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) == null &&
                                    <i className="fa fa-times" style={{color: 'red'}}></i> }
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'connected' &&
                                    <i className="fa fa-check" style={{color: 'green'}}></i> }
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'No debug' &&
                                    <i className="fa fa-times" style={{color: 'orange'}}></i>}
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'Not connected' &&
                                    <i className="fa fa-times" style={{color: 'red'}}></i>} ({lastConnectionDate || ''})
                                </td>
                                <td>
                                    {
                                        changeMotorSpeedCommandInQueue && <Badge>Change motor speed command in queue</Badge>
                                    }<br/>
                                    {!speedNonOk && <span style={{color: 'green'}}>{motorSpeed}</span>}
                                    {speedNonOk && <span style={{color: 'red'}}>{motorSpeed}</span>}

                                </td>
                                <td>
                                    {motorPosition}
                                </td>
                                <td>
                                    {
                                        calibrateMotorCommandInQueue && <Badge>Motor calibrate command in queue</Badge>
                                    }<br/>
                                    {!motorCurrentsAreOk && <i className="fa fa-times" style={{color: 'red'}}></i>}
                                    {motorCurrentsAreOk && <i className="fa fa-check" style={{color: 'green'}}></i>}
                                    <div>
                                        <pre>{JSON.stringify(motorCurrentLimits, null, 2)}</pre>
                                    </div>
                                </td>
                                <td>{mountedEngine}</td>
                                <td>
                                    <Toggle defaultChecked={false}
                                        checked={testBatchDevice.get(this.props.testCheckField) === 'true'}
                                        onChange={
                                            (e) => this.props.onMotorTestCheck(testBatchDevice, e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

MotorTestBatchDeviceTable.propTypes = {
    testBatchDevices: PropTypes.array.isRequired,
    onMotorTestCheck: PropTypes.func.isRequired,
    testCheckField: PropTypes.string.isRequired
};