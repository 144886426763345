import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';

export default class PageAssemblyCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        console.log('scemo2')
    }

    render() {
        return <div>
            <h1>Page assembly check</h1>
            <Button onClick={() => {}}>Connect device</Button>
            <Button onClick={() => {}}>Execute hardware check</Button>
            <br/>

            Hardware checks: Successfull / Fails
        </div>;

    }
}

PageAssemblyCheck.propTypes = {
    match: PropTypes.any
};