import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import MotorTestBatchDeviceTable from './motor-test-batch-device-table';
import Loader from '../loader';
import Toggle from 'react-toggle';
import {checkConnectivityDevices, getBatchDevices, renderTestButtons} from '../../utils';
import {getMotorSpeedBasedOnDeviceData, isMotorCalibrationOk} from '../../../lib/util';

export default class PageMotorTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testBatchDevices: [],
            testBatch: null,
            loading: {},
            batchInfo: {}
        };

        this.getBatchDevices = this.getBatchDevices.bind(this);
        this.onConnectivityCheck = this.onConnectivityCheck.bind(this);
        this.getTestBatch = this.getTestBatch.bind(this);
        this.onMotorUpTestCheck = this.onMotorUpTestCheck.bind(this);
        this.onMotorDownTestCheck = this.onMotorDownTestCheck.bind(this);
        this.onMotorUpTestActiveChange = this.onMotorUpTestActiveChange.bind(this);
        this.onMotorDownTestActiveChange = this.onMotorDownTestActiveChange.bind(this);
        this.setAllMotorUpOk = this.setAllMotorUpOk.bind(this);
        this.setAllMotorDownOk = this.setAllMotorDownOk.bind(this);
        this.sendAMotorCalibrationForUncalibratedDevices = this.sendAMotorCalibrationForUncalibratedDevices.bind(this);
        this.sendAForceMotorCalibrationForUncalibratedDevices = this.sendAForceMotorCalibrationForUncalibratedDevices.bind(this);
    }

    async componentDidMount() {
        await this.getBatchDevices();
        await this.getTestBatch();
        await this.refreshCommandQueue();
    }

    async getTestBatch(){
        try{
            let testBatch = await (new Parse.Query(db.classes.TestBatch)).get(this.props.match.params.id);

            this.setState({testBatch});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onConnectivityCheck(){
        this.setState({loading: {connectivityCheck: true}});

        try{
            let testBatchDevices = await this.getBatchDevices();
            await checkConnectivityDevices(testBatchDevices);
            await this.getBatchDevices();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        } finally {
            this.setState({loading: {connectivityCheck: false}});
        }
    }

    async getBatchDevices(){
        try{
            let testBatchDevices = await getBatchDevices(this.props.match.params.id);

            this.setState({testBatchDevices});

            return testBatchDevices;
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onMotorUpTestCheck(testBatchDevice, checked){
        try {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let motorPosition = device.get(db.Device.MOTOR_POSITION);

            if(motorPosition !== 0) throw Error('Motor position reported is not 0');

            testBatchDevice.set(db.TestBatchDevice.MOTOR_UP_TEST, checked.toString());
            testBatchDevice.set(db.TestBatchDevice.MOTOR_UP_TEST_MODIFIED_DATE, new Date());

            await testBatchDevice.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.getBatchDevices();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onMotorDownTestCheck(testBatchDevice, checked){
        try {

            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let motorPosition = device.get(db.Device.MOTOR_POSITION);

            if(motorPosition !== 100) throw Error('Motor position reported is not 100');

            testBatchDevice.set(db.TestBatchDevice.MOTOR_DOWN_TEST, checked.toString());
            testBatchDevice.set(db.TestBatchDevice.MOTOR_DOWN_TEST_MODIFIED_DATE, new Date());

            await testBatchDevice.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.getBatchDevices();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onMotorUpTestActiveChange(checked){
        try {
            this.state.testBatch.set(db.TestBatch.MOTOR_UP_TEST_ACTIVE, checked);
            if(this.state.testBatch.get(db.TestBatch.MOTOR_DOWN_TEST_ACTIVE))
                this.state.testBatch.set(db.TestBatch.MOTOR_DOWN_TEST_ACTIVE, !checked);

            await this.state.testBatch.save();
            
            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.getTestBatch();
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async onMotorDownTestActiveChange(checked){
        try {
            this.state.testBatch.set(db.TestBatch.MOTOR_DOWN_TEST_ACTIVE, checked);
            if(this.state.testBatch.get(db.TestBatch.MOTOR_UP_TEST_ACTIVE))
                this.state.testBatch.set(db.TestBatch.MOTOR_UP_TEST_ACTIVE, !checked);

            await this.state.testBatch.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.getTestBatch();
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async setAllMotorUpOk(){
        try {
            let testBatchDevices = [];
            for (let testBatchDevice of this.state.testBatchDevices) {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let motorPosition = device.get(db.Device.MOTOR_POSITION);

                if(motorPosition !== 0) continue;

                testBatchDevice.set(db.TestBatchDevice.MOTOR_UP_TEST, 'true');
                testBatchDevice.set(db.TestBatchDevice.MOTOR_UP_TEST_MODIFIED_DATE, new Date());
                testBatchDevices.push(testBatchDevice);
            }

            await Parse.Object.saveAll(testBatchDevices);

            this.setState({testBatchDevices: await getBatchDevices(this.props.match.params.id)});
            
            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async setAllMotorDownOk(){
        try {
            let testBatchDevices = [];
            for (let testBatchDevice of this.state.testBatchDevices) {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let motorPosition = device.get(db.Device.MOTOR_POSITION);

                if(motorPosition !== 100) continue;

                testBatchDevice.set(db.TestBatchDevice.MOTOR_DOWN_TEST, 'true');
                testBatchDevice.set(db.TestBatchDevice.MOTOR_DOWN_TEST_MODIFIED_DATE, new Date());
                testBatchDevices.push(testBatchDevice);
            }

            await Parse.Object.saveAll(testBatchDevices);

            this.setState({testBatchDevices: await getBatchDevices(this.props.match.params.id)});
            
            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async sendAMotorCalibrationForUncalibratedDevices(e){
        e.preventDefault();
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            for(let testBatchDevice of testBatchDevices){
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);
                const {
                    cL,
                    fSpDC,
                    maxC,
                    minC,
                    mCal,
                    mT,
                    maxCFs
                } = motorCurrentLimits;

                let fsdcNonOK = fSpDC < 21  || fSpDC > 50;
                let clNonOk = cL > 210 || cL < 130;

                if(fsdcNonOK || clNonOk) {
                    let query = new Parse.Query('CommandQueue');
                    query.equalTo(db.CommandQueue.COMMAND_NAME, db.commands.CALIBRATE_MOTOR);
                    query.equalTo(db.CommandQueue.DEVICE, device);
                    query.notEqualTo(db.CommandQueue.DELETED, true);

                    let commands = await query.find();

                    if (commands.length > 0){
                        console.log('There is already a command on device ' + device.id);
                        continue;
                    }

                    let command = new Parse.Object(db.classes.CommandQueue);
                    command.set(db.CommandQueue.COMMAND_NAME, db.commands.CALIBRATE_MOTOR);
                    command.set(db.CommandQueue.DATA, {});
                    command.set(db.CommandQueue.DEVICE, device);
                    command.set(db.CommandQueue.USER, currentUser);
                    command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))
                    commandsToBeSaved.push(command);

                    command = new Parse.Object(db.classes.CommandQueue);
                    command.set(db.CommandQueue.COMMAND_NAME, db.commands.START_DEBUG);
                    command.set(db.CommandQueue.DATA, {});
                    command.set(db.CommandQueue.DEVICE, device);
                    command.set(db.CommandQueue.USER, currentUser);
                    command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))
                    commandsToBeSaved.push(command);
                }
            }

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.refreshCommandQueue(e);
        } catch (err) {
            await swal('Error', err.message, 'error') && console.error(err);
        }
    }
    async sendAForceMotorCalibrationForUncalibratedDevices(e){
        e.preventDefault();
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            for(let testBatchDevice of testBatchDevices){
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);

                let query = new Parse.Query('CommandQueue');
                query.equalTo(db.CommandQueue.COMMAND_NAME, db.commands.CALIBRATE_MOTOR);
                query.equalTo(db.CommandQueue.DEVICE, device);
                query.notEqualTo(db.CommandQueue.DELETED, true);

                let commands = await query.find();

                if (commands.length > 0){
                    console.log('There is already a command on device ' + device.id);
                    continue;
                }

                let command = new Parse.Object(db.classes.CommandQueue);
                command.set(db.CommandQueue.COMMAND_NAME, db.commands.CALIBRATE_MOTOR);
                command.set(db.CommandQueue.DATA, {});
                command.set(db.CommandQueue.DEVICE, device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))
                commandsToBeSaved.push(command);
            }

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (err) {
            await swal('Error', err.message, 'error') && console.error(err);
        }
    }

    async sendChangeMotorSpeed(e){
        e.preventDefault();
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            for(let testBatchDevice of testBatchDevices){
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let motorSpeed = device.get(db.Device.MOTOR_SPEED);

                let {
                    speedNonOk
                } = isMotorCalibrationOk(device);
                let correctMotorSpeed = getMotorSpeedBasedOnDeviceData(device);

                console.log(motorSpeed, speedNonOk, correctMotorSpeed);

                if(correctMotorSpeed == null) continue;

                let query = new Parse.Query('CommandQueue');
                query.equalTo(db.CommandQueue.COMMAND_NAME, db.commands.CHANGE_MOTOR_SPEED);
                query.equalTo(db.CommandQueue.DEVICE, device);
                query.notEqualTo(db.CommandQueue.DELETED, true);

                let commands = await query.find();

                if (commands.length > 0){
                    console.log('There is already a command on device ' + device.id);
                    continue;
                }

                if(speedNonOk){
                    let command = new Parse.Object(db.classes.CommandQueue);
                    command.set(db.CommandQueue.COMMAND_NAME, db.commands.CHANGE_MOTOR_SPEED);
                    command.set(db.CommandQueue.DATA, {
                        value: correctMotorSpeed,
                        unit: 'speed'
                    });
                    command.set(db.CommandQueue.DEVICE, device);
                    command.set(db.CommandQueue.USER, currentUser);
                    command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))
                    commandsToBeSaved.push(command);
                }
            }

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.refreshCommandQueue(e);
        } catch (err) {
            await swal('Error', err.message, 'error') && console.error(err);
        }
    }

    async refreshCommandQueue(e){
        if(e)
            e.preventDefault();

        try {
            let testBatchDevices = this.state.testBatchDevices;
            let batchInfo = {

            };

            let commandsToBeSaved = [];
            for(let testBatchDevice of testBatchDevices){
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let motorSpeed = device.get(db.Device.MOTOR_SPEED);

                let {
                    speedNonOk
                } = isMotorCalibrationOk(device);
                let correctMotorSpeed = getMotorSpeedBasedOnDeviceData(device);

                console.log(motorSpeed, speedNonOk, correctMotorSpeed);

                if(correctMotorSpeed == null) continue;

                let query = new Parse.Query('CommandQueue');
                query.equalTo(db.CommandQueue.DEVICE, device);
                query.notEqualTo(db.CommandQueue.DELETED, true);

                let commands = await query.find();

                if (commands.length > 0){
                    if(batchInfo[testBatchDevice.id] == null){
                        batchInfo[testBatchDevice.id] = {};
                    }

                    let commandNames = commands.map(command => command.get(db.CommandQueue.COMMAND_NAME));

                    batchInfo[testBatchDevice.id].calibrateMotorCommandInQueue = commandNames.indexOf(db.commands.CALIBRATE_MOTOR) >= 0;
                    batchInfo[testBatchDevice.id].changeMotorSpeedCommandInQueue = commandNames.indexOf(db.commands.CHANGE_MOTOR_SPEED) >= 0;

                    continue;
                }
            }

            this.setState({batchInfo});

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (err) {
            await swal('Error', err.message, 'error') && console.error(err);
        }
    }
    render() {

        let motorCalibrationOk = this.state.testBatchDevices.filter(testBatchDevice => {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

           let {calibrationOk} =  isMotorCalibrationOk(device);

           return !calibrationOk;
        }).length === 0;

        let motorSpeedOk = this.state.testBatchDevices.filter(testBatchDevice => {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

            let {speedNonOk} = isMotorCalibrationOk(device);

            return speedNonOk;
        }).length === 0;


        console.table({
            motorCalibrationOk,
            motorSpeedOk
        });

        let batchInfo = this.state.batchInfo;

        return (
            <div>
                <h1 className="text-center">Motor UP test (Batch #{this.state.testBatch?.get(db.TestBatch.NAME)})</h1>

                {renderTestButtons(this.state.testBatch)}
                <Row style={{marginTop: 20}}>

                    <Col md={4}></Col>
                    {
                        motorCalibrationOk && <>
                            <Col sm={6} md={4}>
                                <p style={{display: 'inline-block', marginRight: 10}}>Motor UP test active</p>
                                <Toggle
                                    defaultChecked={false}
                                    checked={
                                        this.state.testBatch &&
                                        this.state.testBatch.get(db.TestBatch.MOTOR_UP_TEST_ACTIVE)
                                    }
                                    onChange={
                                        (e) => this.onMotorUpTestActiveChange(e.target.checked)
                                    }
                                />
                            </Col>

                            <Col sm={6} md={3}>
                                <Button outline color={'primary'} onClick={this.setAllMotorUpOk}>
                                    Set all motor up ok
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8} style={{textAlign: 'center'}}>
                        {
                            motorSpeedOk === false && <p style={{color: 'red'}}>Motor speed in wrong on some devices. Please click "Change motor speed" and wait the next device connection.</p>
                        }
                        {
                            motorSpeedOk === true &&
                            motorCalibrationOk === false &&
                            <p style={{color: 'red'}}>Some devices are not calibrated. Click the "Calibrate motor" button and wait the next device connection.</p>
                        }
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button outline color={'primary'} onClick={this.onConnectivityCheck}>
                            Check for connectivity {this.state.loading.connectivityCheck && <Loader/>}
                        </Button>
                        <Button outline color={'primary'}
                                onClick={async (e) => await this.refreshCommandQueue(e)}>
                            Refresh command queue
                        </Button>
                        {
                            motorSpeedOk && <Button outline color={'warning'}
                                onClick={async (e) => await this.sendAMotorCalibrationForUncalibratedDevices(e)}>
                                Calibrate motor
                            </Button>
                        }
                        
                        {/*
                            <Button
                                outline color={'danger'}
                                onClick={async (e) => await this.sendAForceMotorCalibrationForUncalibratedDevices(e)}>
                                Force a motor calibration
                            </Button>
                        */}

                        <Button outline color={'warning'}
                            onClick={async (e) => await this.sendChangeMotorSpeed(e)}>
                            Change motor speed
                        </Button>
                    </Col>
                </Row>

                <MotorTestBatchDeviceTable
                    testBatchDevices={this.state.testBatchDevices}
                    onMotorTestCheck={this.onMotorUpTestCheck}
                    testCheckField={db.TestBatchDevice.MOTOR_UP_TEST}
                    batchInfo={this.state.batchInfo}
                />

                <h1 className="text-center">Motor DOWN test(Batch # {this.props.match.params.id})</h1>
                <Row>
                    <Col sm={6} md={3}>
                        <Button outline color={'primary'} onClick={this.onConnectivityCheck}>
                            Check for connectivity {this.state.loading.connectivityCheck && <Loader/>}
                        </Button>
                    </Col>
                    {
                        motorCalibrationOk && <>
                            <Col sm={6} md={4}>
                                <p style={{display: 'inline-block', marginRight: 10}}>Motor DOWN test active</p>
                                <Toggle defaultChecked={false}
                                    checked={
                                        this.state.testBatch &&
                                        this.state.testBatch.get(db.TestBatch.MOTOR_DOWN_TEST_ACTIVE)
                                    }
                                    onChange={
                                        (e) => this.onMotorDownTestActiveChange(e.target.checked)
                                    }
                                />
                            </Col>
                            <Col sm={6} md={3}>
                                <Button outline color={'primary'} onClick={this.setAllMotorDownOk}>
                                    Set all motor down ok
                                </Button>
                            </Col>
                        </>
                    }
                    {
                        motorCalibrationOk === false && <p style={{color: 'red'}}>There is a problem with the motor calibration please wait 5 minutes (after the devices are connected). If the problem persists contact support@cleveron.ch</p>
                    }
                </Row>

                <MotorTestBatchDeviceTable
                    testBatchDevices={this.state.testBatchDevices}
                    onMotorTestCheck={this.onMotorDownTestCheck}
                    testCheckField={db.TestBatchDevice.MOTOR_DOWN_TEST}
                />
            </div>
        );
    }
}

PageMotorTest.propTypes = {
    match: PropTypes.any
};