import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import TemperatureTable from './temperature-table';
import {checkConnectivityDevices, getBatchDevices, renderTestButtons} from '../../utils';
import Parse from '../../../lib/parse';
import Loader from '../loader';
import config from '../../../config/app';
import moment from 'moment';

let mean = (array) => {
    array = array
        .filter(number => !isNaN(number) && number != null);

    if(array.length > 0)
        return array.reduce((previous, current) => current += previous) / array.length;

    return null;
};

export default class PageTemperatureCalibration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testBatchDevices: [],
            loading: {},
            referenceTemp: -1
        };

        this.onCheckConnectionTest = this.onCheckConnectionTest.bind(this);
        this.onTemperatureCheck = this.onTemperatureCheck.bind(this);
        this.onApplyTemperatureCorrection = this.onApplyTemperatureCorrection.bind(this);
        this.onCo2Check = this.onCo2Check.bind(this);
        this.resetTemperatureCalibration = this.resetTemperatureCalibration.bind(this);
    }

    async componentDidMount() {
        let testBatch = await new Parse.Query(db.classes.TestBatch).get(this.props.match.params.id);
        let testBatchDevices = await getBatchDevices(this.props.match.params.id);
        const referenceTemp = await this.getReferenceTemperature() || -1;

        this.setState({testBatch, testBatchDevices, referenceTemp});
    }

    async getReferenceTemperature(){
        //2000496 Cleveron Laboratory
        let testBatchDevices = await getBatchDevices(this.props.match.params.id);

        let referenceTemperature = mean(testBatchDevices.map(testBatchDevice => {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let temp = device.get(db.Device.TEMPERATURE);

            return temp;
        }));

        return referenceTemperature;
    }

    async onCheckConnectionTest(){
        this.setState({loading: {connectivityCheck: true}});

        try {
            let testBatchDevices = await getBatchDevices(this.props.match.params.id);
            await checkConnectivityDevices(testBatchDevices);
            testBatchDevices = await getBatchDevices(this.props.match.params.id);
            this.setState({testBatchDevices});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message || '', 'error');
        } finally {
            this.setState({loading: {connectivityCheck: false}});
        }
    }

    async onTemperatureCheck(){
        this.setState({loading: {temperatureCheck: true}});

        try {
            await this.onCheckConnectionTest();

            let testBatchDevices = await getBatchDevices(this.props.match.params.id);

            let referenceTemperature = await this.getReferenceTemperature();

            if(!referenceTemperature) throw new Error('Reference temeprature is not defined.');

            let testBatcheDevicesTosave = testBatchDevices.map(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let serialNumber = device.get(db.Device.SERIAL_NUMBER);
                let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
                let temperature = device.get(db.Device.TEMPERATURE);
                let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

                let differenceMinutes = moment().diff(moment(lastMeasurementDate), 'minutes');

                let difference = temperature - referenceTemperature;

                let tempOk = Math.abs(difference) < config.leanManagment.MAXIMAL_TEMPERATURE_ERROR_GAP;
                let timingOk = differenceMinutes < 6;

                if(!tempOk || !timingOk) {
                    testBatchDevice.set(db.TestBatchDevice.TEMPERATURE_TEST, false);

                    return testBatchDevice;
                } else {
                    testBatchDevice.set(db.TestBatchDevice.TEMPERATURE_TEST, true);

                    return testBatchDevice;
                }

                return null;
            }).filter(item => !!item);

            await Parse.Object.saveAll(testBatcheDevicesTosave);

            testBatchDevices = await getBatchDevices(this.props.match.params.id);

            this.setState(testBatchDevices);

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message || '', 'error');
        } finally {
            this.setState({loading: {temperatureCheck: false}});
        }
    }

    async onCo2Check(){
        try {
            this.setState({loading: {co2Check: true}});
            let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
                .include(db.TestBatchDevice.DEVICE)
                .equalTo(db.TestBatchDevice.BATCH, toPointerFromId(this.props.match.params.id, db.classes.TestBatch))
                .find();

            let avgCo2 = 0;
            let count = 0;
            for (let testBatchDevice of testBatchDevices) {
                const device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let currentCo2 = device.get(db.Device.CO2);

                if(!currentCo2) continue;

                avgCo2 += currentCo2;
                count++;
            }
            avgCo2 = avgCo2 / count;


            let objectsToSave = [];
            let currentCo2Map = {};

            for (let testBatchDevice of testBatchDevices) {
                const device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let currentCo2 = device.get(db.Device.CO2);

                if (currentCo2 == null) {

                    continue;
                }

                currentCo2Map[testBatchDevice.id] = currentCo2;

                const currentTemperatureErrorGap = avgCo2 - currentCo2;

                const isCo2Ok =  currentCo2 > 50;

                if (isCo2Ok) {
                    testBatchDevice.set(db.TestBatchDevice.CO2_TEST, true);
                    testBatchDevice.set(db.TestBatchDevice.CURRENT_CO2, currentCo2);
                    testBatchDevice.set(db.TestBatchDevice.REFERENCE_CO2, avgCo2);
                    objectsToSave.push(testBatchDevice);
                } else {
                    testBatchDevice.set(db.TestBatchDevice.CO2_TEST, false);
                    testBatchDevice.set(db.TestBatchDevice.CURRENT_CO2, currentCo2);
                    testBatchDevice.set(db.TestBatchDevice.REFERENCE_CO2, avgCo2);
                    objectsToSave.push(testBatchDevice);
                }
            }

            await Parse.Object.saveAll(objectsToSave);

            this.setState({loading: {co2Check: false}, currentCo2Map, avgCo2});

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message || '', 'error');
            this.setState({loading: {co2Check: false}});
        }
    }

    async onApplyTemperatureCorrection(){
        const ok = await swal(`This action will calibrate current sensors based on reference temperature ${this.state.referenceTemp}°. Confirm?`, {
            buttons: ['Cancel', 'Ok']
        });

        if (!ok || ok === '') {
            return swal({title: 'Action aborted by user', text: ' ', icon: 'warning', button: [''], timer: 500});
        }

        return;

        this.setState({loading: {applyTemperatureCorrection: true}});

        let referenceTemperature = await this.getReferenceTemperature();

        try {
            let devicesToSave = [];
            let testBatchDevices = await getBatchDevices(this.props.match.params.id);

            testBatchDevices.forEach(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let lastMeasuredTemperature = device.get(db.Device.MEASURED_TEMP);
                let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
                let serialNumber = device.get(db.Device.SERIAL_NUMBER);

                if(versionFirmware !== config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2)
                    throw new Error(`Firmware version on device ${serialNumber} is out of date. Please update it or contact CLEVERON.`)

                let correctionTemperature = referenceTemperature - lastMeasuredTemperature;
                device.set(db.Device.CORRECTION_TEMP, correctionTemperature)
                devicesToSave.push(device);
            });

            await Parse.Object.saveAll(devicesToSave);

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message || '', 'error');
        } finally {
            this.setState({loading: {applyTemperatureCorrection: false}});
        }
    }

    async resetTemperatureCalibration(){
        try {
            const ok = await swal(`This action will calibrate current sensors based on reference temperature ${this.state.referenceTemp}°. Confirm?`, {
                buttons: ['Cancel', 'Ok']
            });

            if (!ok || ok === '') {
                return swal({title: 'Action aborted by user', text: ' ', icon: 'warning', button: [''], timer: 500});
            }

            let devicesToSave = [];
            this.state.testBatchDevices.forEach(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                device.set(db.Device.CORRECTION_TEMP, 0)
                devicesToSave.push(device);
            });

            await Parse.Object.saveAll(devicesToSave);

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e) {
            console.error(e.message);
            swal('Error', e.message || '', 'error');
        }
    }

    render() {

        return (
            <div>
                <h1 className="text-center">Temperature Calibration {this.state.testBatch?.get(db.TestBatch.NAME)}</h1>
                {renderTestButtons()}
                <h2>
                    Reference temperature: <strong>{this.state.referenceTemp.toFixed(2)}(°C)</strong>
                </h2>
                <br/>
                <Button outline color={'primary'} onClick={this.onCheckConnectionTest}>
                    Check for connectivity {this.state.loading.connectivityCheck && <Loader/>}
                </Button>

                <Button outline color={'primary'} onClick={this.onTemperatureCheck}>
                    Check temperature {this.state.loading.temperatureCheck && <Loader/>}
                </Button>

                {
                    this.state.testBatch?.get(db.TestBatch.TYPE) === db.TestBatch.TYPE$CLEVER_SENSE_CO2 &&
                    <Button outline color={'primary'} onClick={this.onCo2Check}>
                        Check co2 {this.state.loading.co2Check && <Loader/>}
                    </Button>
                }

                {
                    this.state.testBatch?.get(db.TestBatch.TYPE) === db.TestBatch.TYPE$CLEVER_SENSE_CO2 &&
                    <Button outline color={'warning'} onClick={this.resetTemperatureCalibration}>
                        Reset temperature calibration
                    </Button>
                }

                <TemperatureTable testBatchDevices={this.state.testBatchDevices}
                    referenceTemperature={this.state.referenceTemp}
                    currentCo2Map={this.state.currentCo2Map}
                    avgCo2={this.state.avgCo2}
                    testBatch={this.state.testBatch}
                />
            </div>
        );
    }
}

PageTemperatureCalibration.propTypes = {
    match: PropTypes.any
};