import React from 'react';
import PropTypes from 'prop-types';
import paths from '../../paths';
import Parse from '../../../lib/parse';
import swal from 'sweetalert';
import db from '../../../config/dbStructure.js';
import logo from '../../../assets/images/logo.png';

import {
    Button,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarText,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';
import {isRolesInRoles} from '../../../lib/util';

export default class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navBarOpen: true
        }

        this.toggle = this.toggle.bind(this);
    }

    async logout() {
        try {
            await Parse.User.logOut();

            window.location = paths.loginPage;
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    toggle(){
        this.setState({navBarOpen: !this.state.navBarOpen});
    }

    render() {

        return (
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/"><img src={logo} height={20}></img></NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.navBarOpen} navbar>
                    <Nav className="mr-auto" navbar>

                        {
                            isRolesInRoles(this.props.roles, ['Supplier', 'Admin']) &&
                                <>
                                    <NavItem
                                        style={{cursor: 'pointer'}}>
                                        <NavLink href={paths.batch}
                                            active={this.props.history.location.pathname === paths.batch}>
                                            <i className="fa fa-check-square"></i> Batches</NavLink>
                                    </NavItem>
                                    <NavItem
                                        style={{cursor: 'pointer'}}>
                                        <NavLink href={paths.supplierOrders}
                                                 active={this.props.history.location.pathname === paths.supplierOrders}>
                                            <i className="fa fa-wrench"></i> Orders
                                        </NavLink>
                                    </NavItem>
                                </>

                        }
                        {
                            isRolesInRoles(this.props.roles, ['Installer', 'Admin']) && <NavItem
                                style={{cursor: 'pointer'}}>
                                <NavLink href={paths.maintenance}
                                    active={this.props.history.location.pathname === paths.maintenance}>
                                    <i className="fa fa-wrench"></i> Maintenance Tasks</NavLink>
                            </NavItem>
                        }
                        {
                            isRolesInRoles(this.props.roles, ['Admin']) && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Operations
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem href={paths.operationDashboard}>
                                        Operation dashboard
                                    </DropdownItem>
                                    <DropdownItem href={paths.linkRoomQr}>
                                        Link Room - QR
                                    </DropdownItem>
                                    <DropdownItem href={paths.productionReport}>
                                        Production report
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                        {
                            isRolesInRoles(this.props.roles, ['Admin', 'Installer']) &&
                            <NavItem style={{cursor: 'pointer'}}>
                                <NavLink href={paths.listBuildings}
                                    active={this.props.history.location.pathname === paths.listBuildings}>
                                    <i className="fa fa-building-o"></i> Buildings</NavLink>
                            </NavItem>
                        }
                    </Nav>
                    {
                        Parse.User.current() && <>
                            <NavbarText>{Parse.User.current().get(db._User.USERNAME)}</NavbarText>
                            <NavbarText>
                                <Button outline style={{margin: 10}} onClick={this.logout}>
                                    Logout
                                </Button>
                            </NavbarText>
                        </>
                    }
                </Collapse>
            </Navbar>
        );
    }
}

NavBar.propTypes = {
    history: PropTypes.any,
    onChange: PropTypes.func,
    selected: PropTypes.any,
    roles: PropTypes.array
};