import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Alert, Badge, Button, Col, Row} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import * as assert from 'assert';
import {
    createBasicACL,
    extractDeviceFields,
    extractTestBatchField,
    isSupportedSerieNumber,
    moveDeviceToRoom, toPointerFromId
} from '../../../lib/util';
import TestBatchDeviceTable from './test-batch-device-table';
import axios from 'axios';
import {
    checkConnectivityDevices,
    getBatchDevices,
    getTag,
    inseertAtIndex,
    insertElementAndEliminateIfExsisting,
    moveDownItemArray,
    moveUpItemArray,
    renderTestButtons
} from '../../utils';
import LinkRoomModal from './link-room-modal';
import SelectTagsModal from './select-tags-modal';
import _ from 'lodash';
import moment from 'moment';
import config from '../../../config/app';
import AddCustomCommandModal from './add-custom-command-modal';
import batchStateConfig from '../../../lib/batch-state-config';
import StateMachine from '../../../lib/StateMachine';
import {validate} from 'uuid';
import Select from 'react-select';

export default class PageSingleBatch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testBatchDevices: [],
            deviceFirmwares: [],
            supplierOrders: [],
            testBatchDeviceHistories: [],
            loading: {}
        };

        this.getBatchDevices = this.getBatchDevices.bind(this);
        this.createTestBatchDevice = this.createTestBatchDevice.bind(this);
        this.onCompleteTestBatch = this.onCompleteTestBatch.bind(this);
        this.setInMountFlag = this.setInMountFlag.bind(this);
        this.linkToRoom = this.linkToRoom.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.checkIfTestBatchCanBeCompleted = this.checkIfTestBatchCanBeCompleted.bind(this);
        this.completeTestBatch = this.completeTestBatch.bind(this);
        this.checkConnectivityDevices = this.checkConnectivityDevices.bind(this);
        this.sendStopDebug = this.sendStopDebug.bind(this);
        this.sendSetToOnlineMode = this.sendSetToOnlineMode.bind(this);
        this.refreshTestBatchDevices = this.refreshTestBatchDevices.bind(this);
        this.toggleAvailableOnBrack = this.toggleAvailableOnBrack.bind(this);
        this.addCustomCommand = this.addCustomCommand.bind(this);
        this.setToConfigured = this.setToConfigured.bind(this);
        this.addQrCodeToTestBatch = this.addQrCodeToTestBatch.bind(this);
        this.addFirmwareUpdateCommand = this.addFirmwareUpdateCommand.bind(this);
        this.getAllAssociatedOrders = this.getAllAssociatedOrders.bind(this);
        this.sendCommandStartTestNb = this.sendCommandStartTestNb.bind(this);
        this.changeTestBatchTag = this.changeTestBatchTag.bind(this);
        this.changeDescription = this.changeDescription.bind(this);
        this.getTestBatchDeviceHistory = this.getTestBatchDeviceHistory.bind(this);
        this.moveUpTestBatchDevice = this.moveUpTestBatchDevice.bind(this);
        this.moveDownTestBatchDevice = this.moveDownTestBatchDevice.bind(this);
        this.moveToCoordinate = this.moveToCoordinate.bind(this);
        this.setOnlineFlag = this.setOnlineFlag.bind(this);

        this.toggleLinkRoomModal = null;
        this.toggleAddCustomCommandModal = null;
        this.toggleSelectTagsModal = null;
    }

    async componentDidMount(){
        await this.getBatchDevices();
        let testBatch = await this.getTestBatch();
        await this.getAllAssociatedOrders();
        await this.getTestBatchDeviceHistory(testBatch);
        const deviceFirmwares = await this.getFirmwares(db.Device.DEVICE_TYP$THERM);

        this.setState({deviceFirmwares});
    }

    static getInfoFromTestBatchDevice(testBatchDevice){
        let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
        let room = device.get(db.Device.ROOM_ID);
        let home = room && room.get(db.Room.HOME);
        let homeName = home && home.get(db.Home.HOME_NAME);
        let lastMeasurement = device && device.get(db.Device.LAST_MEASUREMENT);
        let wifiName = device?.get(db.Device.CONFIG_WIFI_SSID);
        let actualConnectionWifi = device?.get(db.Device.CONNECTION_WIFI_SSID);
        let wifiPassword = device?.get(db.Device.CONFIG_WIFI_PASSWORD);
        let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);
        let commercialLabel = device.get(db.Device.COMMERCIAL_LABEL);
        let iccid = device.get(db.Device.ICCID);
        let request = lastMeasurement?.get(db.Measurement.REQUEST);
        let installationInfo = device.get(db.Device.INSTALLATION_INFO);
        let hwVersionA = installationInfo.hwVersionA;
        
        let ledInternalConfig;

        let states = _.get(request, 'params.deviceState.data', []);

        states.forEach(state => {
            if(!state) return;

            if(_.isEqual(state.type, ['Hardware', 'Threshold'])) {
                ledInternalConfig = _.get(state, 'value.thresholds');
            }
        });

        return {
            device,
            room,
            home,
            homeName,
            wifiName,
            wifiPassword,
            firmwareVersion,
            actualConnectionWifi,
            commercialLabel,
            lastMeasurement,
            iccid,
            ledInternalConfig,
            hwVersionA
        }
    }

    onDownload(){
        let csvContent = `Serial, Mac address, Type, Created at, Last update, tested, date tested\n`;
        let formatDate = date => moment(date).format('DD/MM/YYYY HH:mm');

        this.state.testBatchDevices.map(testBatchDevice => {
            let {
                device,
                room,
                home,
                homeName,
                wifiName,
                wifiPassword,
                firmwareVersion
            } = PageSingleBatch.getInfoFromTestBatchDevice(testBatchDevice);

            csvContent += `${device.get(db.Device.SERIAL_NUMBER)}, ${device.get(db.Device.MAC_ADDRESS)}, ${device.get(db.Device.DEVICE_TYP)}, ${formatDate(testBatchDevice.get(db.TestBatchDevice.CREATED_AT))}, ${formatDate(testBatchDevice.get(db.TestBatchDevice.UPDATED_AT))}, ${testBatchDevice.get(db.TestBatchDevice.TESTED)}, ${formatDate(testBatchDevice.get(db.TestBatchDevice.DATE_TESTED))}\n`;
        });

        //var encodedUri = encodeURI(csvContent);
        //window.open(encodedUri);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        hiddenElement.target = '_blank';
        hiddenElement.download = `batch_${this.state.testBatch.get(db.TestBatch.NAME) || this.state.testBatch.id}_devices${moment().format('DDMMYYYYHHmm')}.csv`;
        hiddenElement.click();
    }

    async getTestBatch(){
        try{
            let testBatch = await (new Parse.Query(db.classes.TestBatch)).get(this.props.match.params.id);

            this.setState({testBatch});

            return testBatch;
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }
    async getBatchDevices(){
        try{
            let testBatchDevices = await getBatchDevices(this.props.match.params.id);
            this.setState({testBatchDevices});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async createTestBatchDevice(ids){

        let createTestBatchDeviceSingle = async (id) => {
            let isMac = id.split(':').length === 6;
            let isRouter = id.toString().length === 10;
            let getTestBatchDeviceFromDevice = device => (new Parse.Query(db.classes.TestBatchDevice))
                .equalTo(db.TestBatchDevice.DEVICE, device)
                .include(db.TestBatchDevice.BATCH)
                .first();
            let getDeviceFromId = id => {
                if(!isMac) {
                    assert(id.toString().length === 7 || id.toString().length === 10, 'Serial is not valid');
                    assert(isSupportedSerieNumber(id), 'This serie is not supported');
                }
    
                let query = new Parse.Query('Device');
    
                if(isMac)
                    query.equalTo(db.Device.MAC_ADDRESS, id);
                else
                    query.equalTo(db.Device.SERIAL_NUMBER, parseInt(id));
    
                return query.first();
            };
            let checkIfDeviceCanBeAddedToThisTestBatch = (device, testBatch) => {
                let testBatchType = testBatch.get(db.TestBatch.TYPE);
                let deviceType = device.get(db.Device.DEVICE_TYP);
                let testBatchTag = testBatch.get(db.TestBatch.TAG);
                let tags = testBatch.get(db.TestBatch.TAGS);
    
                if(
                    testBatchType !== db.TestBatch.TYPE$TESTKIT &&
                    testBatchType === db.TestBatch.TYPE$CLEVER_THERMO &&
                    deviceType === db.Device.DEVICE_TYP$SENSE
                ){
                    throw Error(`You cannot add a device with type ${deviceType} to a TestBatch of type ${testBatchType}`);
                }
    
                if(
                    testBatchType !== db.TestBatch.TYPE$TESTKIT &&
                    testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2 &&
                    deviceType === db.Device.DEVICE_TYP$THERM
                ){
                    throw Error(`You cannot add a device with type ${deviceType} to a TestBatch of type ${testBatchType}`);
                }
    
                if(
                    testBatchType !== db.TestBatch.TYPE$TESTKIT &&
                    deviceType === db.Device.DEVICE_TYP$ROUTER_RUT240
                ){
                    throw Error(`You cannot add a device with type ${deviceType} to a TestBatch of type ${testBatchType}`);
                }


    
                if(
                    (
                        testBatchTag !== db.TestBatch.TAG$REFURBISHED &&
                        tags.indexOf(db.TestBatch.TAGS$REFURBISHED) < 0
                    ) &&
                    device.get(db.Device.REFURBISHED_DATE) != null
                )
                    throw Error('It is not possible to add a refurbished thermo in a a batch without the tag "refurbished".');
            }
            let confirmThatDeviceIsrefurbishedAndResetTests = async (testBatch, device, testBatchDevice) => {
                let testBatchCurrentlyOnTheDevice = testBatchDevice.get(db.TestBatchDevice.BATCH);

                if(testBatchCurrentlyOnTheDevice == null) return;

                let tagBatchCurrentlyOnTheDevice = testBatchCurrentlyOnTheDevice.get(db.TestBatch.TAG);
                let completedBatchCurrentlyOnTheDevice = testBatchCurrentlyOnTheDevice.get(db.TestBatch.COMPLETED);

                let batchIsRefurbishedAndCompleted =
                    tagBatchCurrentlyOnTheDevice === db.TestBatch.TAG$REFURBISHED &&
                    completedBatchCurrentlyOnTheDevice === true;

                if(
                    testBatch.get(db.TestBatch.TAG) === db.TestBatch.TAG$REFURBISHED &&
                   !batchIsRefurbishedAndCompleted
                ){
                    let result = await swal({
                        title: 'Are you sure?',
                        text: `This is a refurbished batch, all old tests will be resetted and it will not possible to remove this device from the batch. Confirm to continue.`,
                        buttons: ['Abort', 'Confirm'],
                    });
    
                    if(!result) throw new Error('Aborted by user');
                    
                    testBatchDevice.set(db.TestBatchDevice.TEMPERATURE_TEST, null);
                    testBatchDevice.set(db.TestBatchDevice.MOTOR_UP_TEST, null);
                    testBatchDevice.set(db.TestBatchDevice.MOTOR_DOWN_TEST, null);
                    testBatchDevice.set(db.TestBatchDevice.BATTERY_TEST, null);
                    testBatchDevice.set(db.TestBatchDevice.VISUAL_TEST, null);
                    testBatchDevice.set(db.TestBatchDevice.DATE_TESTED, null);
                    testBatchDevice.set(db.TestBatchDevice.TESTED, null);
                    testBatchDevice.set(db.TestBatchDevice.TESTED_BY, null);
                    testBatchDevice.set(db.TestBatchDevice.CONNECTED, null);
                    testBatchDevice.set(db.TestBatchDevice.REFURBISHED_DATE, moment().utc().toDate());
    
                    device.set(db.Device.MOUNTED_ENGINE, null);
                    device.set(db.Device.REFURBISHED_DATE, moment().utc().toDate());
    
                    await Parse.Object.saveAll([testBatchDevice, device]);
                }
            }
    
            let addNewTestBatchDevice = async (device, testBatch) => {
                let newTestBatchDevice = new Parse.Object('TestBatchDevice');
                newTestBatchDevice.set(db.TestBatchDevice.BATCH, testBatch);
                newTestBatchDevice.set(db.TestBatchDevice.DEVICE, device);

                newTestBatchDevice = await newTestBatchDevice.save();

                let testBatchDeviceOrder = testBatch.get(db.TestBatch.TEST_BATCH_DEVICE_ORDER);

                if(testBatchDeviceOrder == null) testBatchDeviceOrder = [];

                testBatchDeviceOrder =
                    insertElementAndEliminateIfExsisting(testBatchDeviceOrder, newTestBatchDevice.id);

                testBatch.set(db.TestBatch.TEST_BATCH_DEVICE_ORDER, testBatchDeviceOrder);

                await testBatch.save();

                let toTestRoom = await new Parse.Query(db.classes.Room).get('47Vk3ljLmF');

                moveDeviceToRoom(device, toTestRoom);

                await device.save();
            }
            let checkThatIsATestedTestBatchDevice = testBatchDevice => {
                let testMonthsAgo = moment(testBatchDevice.get(db.TestBatchDevice.DATE_TESTED)).diff(moment(), 'months');
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let deviceType = device.get(db.Device.DEVICE_TYP);
    
                if(deviceType === db.Device.DEVICE_TYP$ROUTER_RUT240) return true;
    
                if(
                    testBatchDevice.get(db.TestBatchDevice.MOTOR_UP_TEST) === true &&
                    testBatchDevice.get(db.TestBatchDevice.MOTOR_DOWN_TEST) === true &&
                    testBatchDevice.get(db.TestBatchDevice.VISUAL_TEST) === true &&
                    testBatchDevice.get(db.TestBatchDevice.TEMPERATURE_TEST) === true &&
                    testBatchDevice.get(db.TestBatchDevice.DATE_TESTED) != null &&
                    testMonthsAgo < 6
                )
                    throw Error(`This devices has not been tested or is has been tested more than 6 months ago.`)
            };
            let linkDeviceToThisTestBatchEvenIfAlreadyExsisting =
                async (device, existingTestBatchDevice, currentTestBatch) => {
                    let testBatchDeviceOrder = currentTestBatch.get(db.TestBatch.TEST_BATCH_DEVICE_ORDER);

                    if(testBatchDeviceOrder == null) testBatchDeviceOrder = [];

                    if(
                        existingTestBatchDevice.get(db.TestBatchDevice.BATCH) != null &&
                        existingTestBatchDevice.get(db.TestBatchDevice.BATCH).id === currentTestBatch.id
                    ) {
                        throw new Error('Duplicate device in this batch');
                    }
    
                    let testBatchType = currentTestBatch.get(db.TestBatch.TYPE);

                    let exsistingTestBatch = existingTestBatchDevice.get(db.TestBatchDevice.BATCH);

                    if(exsistingTestBatch){
                        let exsistingTestBatchName = exsistingTestBatch.get(db.TestBatch.NAME);

                        let changeBatch = await swal({
                            title: `Device already linked to the batch ${exsistingTestBatchName}.`,
                            text: `Would you like to change the device batch with this one?`,
                            buttons: ['No', 'Yes']
                        });

                        if(changeBatch){
                            if(testBatchType === db.TestBatch.TYPE$TESTKIT)
                                checkThatIsATestedTestBatchDevice(existingTestBatchDevice);

                            existingTestBatchDevice.set(db.TestBatchDevice.BATCH, currentTestBatch);
                            await existingTestBatchDevice.save();

                            let resetRoom = await swal({
                                title: `Reset room?`,
                                text: `Reset room to default To-test in Lean management(Moser-Baer)?`,
                                buttons: ['No', 'Yes']
                            });


                            if(resetRoom){
                                let toTestRoom = await new Parse.Query(db.classes.Room).get('47Vk3ljLmF');

                                let device = existingTestBatchDevice.get(db.TestBatchDevice.DEVICE);
                                moveDeviceToRoom(device, toTestRoom)

                                device.save();
                            }

                            console.log('createTestBatchDevice', testBatchDeviceOrder);

                            testBatchDeviceOrder =
                                insertElementAndEliminateIfExsisting(testBatchDeviceOrder, exsistingTestBatch.id);
                            exsistingTestBatch.set(db.TestBatch.TEST_BATCH_DEVICE_ORDER, testBatchDeviceOrder);
                            await exsistingTestBatch.save();

                            await this.getBatchDevices();
                            await this.getTestBatch();
                        }
                    }


                }
            try {
                let testBatchTag = this.state.testBatch.get(db.TestBatch.TAG);
                let testBatchType = this.state.testBatch.get(db.TestBatch.TYPE);
                let device = await getDeviceFromId(id);
                if(!device && !isRouter) throw new Error(`Device with serial ${id} is not found`);

                if(
                    (testBatchTag === db.TestBatch.TAG$WNB ||
                    testBatchTag === db.TestBatch.TAG$WNBP) &&
                    !device.get(db.Device.SERIAL_NUMBER).toString().startsWith('330') &&
                    !device.get(db.Device.SERIAL_NUMBER).toString().startsWith('340')
                ) {
                    throw new Error('Only device with serie 330 or 340 are supported for this type of batch.')
                }
    
                if(!device && isRouter){
                    let routerSerial = await swal('Router is not yet registered on the cloud. Enter the 10 digit serial number:', {content: 'input', buttons: true});
                    if(!routerSerial) throw new Error('No serial number found. Aborting.');
                    if(routerSerial.length !== 10) throw new Error('Wrong serial, not 10 digit.');
    
                    let routerMac = await swal('Enter mac address:', {content: 'input', buttons: true});
                    if(!routerMac) throw new Error('No serial number found. Aborting.');
                    if(routerMac.split(':').length !== 6) throw new Error('Wrong mac.');
    
                    let routerDevice = new Parse.Object(db.classes.Device);
                    routerDevice.set(db.Device.SERIAL_NUMBER, parseInt(routerSerial));
                    routerDevice.set(db.Device.MAC_ADDRESS, routerMac);
                    routerDevice.set(db.Device.DEVICE_TYP, db.Device.DEVICE_TYP$ROUTER_RUT240);
    
                    let deviceACL = createBasicACL([], []);
    
                    routerDevice.setACL(deviceACL);
    
                    device = await routerDevice.save();
                }
    
                let deviceType = device.get(db.Device.DEVICE_TYP);
    
                checkIfDeviceCanBeAddedToThisTestBatch(device, this.state.testBatch);
    
                let testBatchDevice = await getTestBatchDeviceFromDevice(device);

                if(!testBatchDevice){
                    // New device as seems like there was never a testBatchDevice for it until now so
                    // all tests should be to false
                    if(testBatchType === db.TestBatch.TYPE$TESTKIT && deviceType !== db.Device.DEVICE_TYP$ROUTER_RUT240)
                        throw Error('It\'s not possible to add an untested device to a testkit.');
    
                    await addNewTestBatchDevice(device, this.state.testBatch);
                } else {
                    await confirmThatDeviceIsrefurbishedAndResetTests(this.state.testBatch, device, testBatchDevice);
                    await linkDeviceToThisTestBatchEvenIfAlreadyExsisting(device, testBatchDevice, this.state.testBatch);
                }
    
                await swal({title: 'Device added', text: ' ', icon: 'success', button: [''], timer: 500});
    
                await this.getBatchDevices();
            }catch (e) {
                console.error(e);
                await swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 2000});
            }
        }

        if(ids.includes(',')){
            ids = ids.split(',');
            if(ids.length > 1){
                for(let singleId of ids){
                    try {
                        await createTestBatchDeviceSingle(singleId);
                    } catch (e){
                        console.log(e);
                    }
                }
            }
        }

        await createTestBatchDeviceSingle(ids);
    }

    async onCompleteTestBatch(){
        try {
            let response = await axios.post(
                // eslint-disable-next-line max-len
                `${config.leanManagment.host}/api/device-testing/complete-test-batch`, {
                    testBatchId: this.props.match.params.id,
                    sessionToken: Parse.User.current().getSessionToken()
                });



            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            if (e.response.data) {
                console.error(e.response.data.message);
                swal('Error', e.response.data.message, 'error');
            } else {
                console.error(e.response.message);
                swal('Error', e.message, 'error');
            }
        }
    }

    async setInMountFlag(){

        let devices = [];
        for(let testBatchDevice of this.state.testBatchDevices){
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

            device.set(db.Device.DEVICE_STATE_FLAG, db.Device.DEVICE_STATE_FLAG$IN_MOUNT);

            devices.push(device);
        }

        await Parse.Object.saveAll(devices);

        swal({title: 'Flag updated', text: ' ', icon: 'success', button: [''], timer: 1000});

        await this.getBatchDevices();
    }
    async setOnlineFlag(){

        let devices = [];
        for(let testBatchDevice of this.state.testBatchDevices){
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

            device.set(db.Device.DEVICE_STATE_FLAG, db.Device.DEVICE_STATE_FLAG$ONLINE);

            devices.push(device);
        }

        await Parse.Object.saveAll(devices);

        swal({title: 'Flag updated', text: ' ', icon: 'success', button: [''], timer: 1000});

        await this.getBatchDevices();
    }

    async toggleAvailableOnBrack(e){
        e.preventDefault();

        let devices = [];
        for(let testBatchDevice of this.state.testBatchDevices){
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let commercialLabel = device.get(db.Device.COMMERCIAL_LABEL);

            if (commercialLabel !== db.Device.COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH) {
                device.set(db.Device.COMMERCIAL_LABEL, db.Device.COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH);
            } else {
                device.unset(db.Device.COMMERCIAL_LABEL);
            }

            devices.push(device);
        }

        await Parse.Object.saveAll(devices);

        swal({title: 'Commercial flag updated', text: ' ', icon: 'success', button: [''], timer: 1000});

        await this.getBatchDevices();
    }

    async linkToRoom(home, room){
        try {
            let devices = [];
            for (let testBatchDevice of this.state.testBatchDevices) {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

                devices.push(moveDeviceToRoom(device, room));
            }

            await Parse.Object.saveAll(devices);

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    checkIfTestBatchCanBeCompleted(){
        let testBatchDevices = this.state.testBatchDevices;
        let testBatch = this.state.testBatch;
        let testBatchType = testBatch.get(db.TestBatch.TYPE);
        const tag = testBatch.get(db.TestBatch.TAG);

        let testBatchCanBeCompleted = true;

        for (let testBatchDevice of testBatchDevices) {
            let visualTest = testBatchDevice.get(db.TestBatchDevice.VISUAL_TEST) === 'true';
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);
            let installationInfo = device.get(db.Device.INSTALLATION_INFO);
            let hwVersionA = installationInfo.hwVersionA;
            let batteryTest = true;
            let motorTest = true;
            let co2Check = true;
            let temperatureTest = true;
            let firmwareCheck = true;
            let connectionTest = true;

            if(testBatchType === db.TestBatch.TYPE$CLEVER_THERMO){
                //batteryTest = testBatchDevice.get(db.TestBatchDevice.BATTERY_TEST) === 'true';
                motorTest = testBatchDevice.get(db.TestBatchDevice.MOTOR_UP_TEST) === 'true' &&
                    testBatchDevice.get(db.TestBatchDevice.MOTOR_DOWN_TEST) === 'true';
                temperatureTest = testBatchDevice.get(db.TestBatchDevice.TEMPERATURE_TEST) === true;

                if(tag === db.TestBatch.TAG$WNBP){
                    connectionTest = testBatchDevice.get(db.TestBatchDevice.NB_IOT_CONNECTION_TEST) === true;
                }
            }
            if(testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2){
                co2Check = testBatchDevice.get(db.TestBatchDevice.CO2_TEST) === true;
                temperatureTest = testBatchDevice.get(db.TestBatchDevice.TEMPERATURE_TEST) === true;
            }

            if(testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2 && firmwareVersion === config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2){
                firmwareCheck = true;
            } else if (testBatchType === db.TestBatch.TYPE$CLEVER_THERMO){
                if(hwVersionA === '1.4' && firmwareVersion === config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO_14){
                    firmwareCheck = true;
                }
                if(hwVersionA === '2.0' && firmwareVersion === config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO){
                    firmwareCheck = true;
                }
            }

            if(!visualTest || !motorTest || !co2Check || !temperatureTest || !firmwareCheck || !connectionTest){
                testBatchCanBeCompleted = false;
            }
        }

        if(testBatchDevices.length === 0){
            testBatchCanBeCompleted = false;
        }

        return testBatchCanBeCompleted;
    }

    async sendStopDebug(){
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            testBatchDevices.forEach(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let command = new Parse.Object('CommandQueue');
                command.set('commandName', 'stopDebug');
                command.set('device', device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command);
            });

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async sendSetToOnlineMode(){
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            testBatchDevices.forEach(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);


                let command1 = new Parse.Object('CommandQueue');
                command1.set('commandName', 'extendedConfig');
                command1.set('device', device);
                command1.set(db.CommandQueue.DATA, {
                    'offlineMode':	0,
                    'ringBrgt': 255,
                    'statusBrgt':255,
                    'threshold':[
                        {
                            'value': 100,
                            'red': 0,
                            'green':255,
                            'blue': 0,
                            'redF': 0,
                            'greenF': 255,
                            'blueF':0
                        },
                        {
                            'value': 150,
                            'red': 240,
                            'green':100,
                            'blue': 0,
                            'redF': 240,
                            'greenF':100,
                            'blueF':0
                        },
                        {
                            'value': 200,
                            'red': 255,
                            'green':0,
                            'blue': 0,
                            'redF': 255,
                            'greenF': 0,
                            'blueF':0
                        }
                    ]
                });
                command1.set(db.CommandQueue.USER, currentUser);
                command1.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command1);
            });

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async completeTestBatch(){
        try {
            let testBatch = this.state.testBatch;
            let tag = testBatch.get(db.TestBatch.TAG);
            let type = testBatch.get(db.TestBatch.TYPE);

            let allowedTags = [
                db.TestBatch.TAG$WIFI,
                db.TestBatch.TAG$WNBP,
                db.TestBatch.TAG$WNB
            ]
            if(
                type === db.TestBatch.TYPE$CLEVER_THERMO &&
                tag !== db.TestBatch.TAG$WIFI &&
                tag !== db.TestBatch.TAG$WNBP &&
                tag !== db.TestBatch.TAG$WNB
            ) {
                throw new Error(`Tag should be one of the following: ${allowedTags.join(',')}`);
            }


            let testBatchDevices = this.state.testBatchDevices;
            let batchCanBeCompleted = this.checkIfTestBatchCanBeCompleted();

            let text = null;

            if (batchCanBeCompleted === false) {
                // eslint-disable-next-line max-len
                /*text = await swal('Tests are not successfull yet. Would you like to complete it anyway? Enter a comment below.', {
                    content: 'input',
                    buttons: ['Cancel', 'Ok']
                });

                if (!text) {
                    return;
                }*/

                throw new Error('Test batch cannot be completed because tests are not done yet.');
            }

            let testBatchDevicesModified = [];
            for (let testBatchDevice of testBatchDevices) {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                testBatchDevice.set(db.TestBatchDevice.TESTED, true);
                testBatchDevice.set(db.TestBatchDevice.DATE_TESTED, moment().utc().toDate());
                testBatchDevice.set(db.TestBatchDevice.TESTED_BY, Parse.User.current());

                if(testBatch.get(db.TestBatch.TAG) === db.TestBatch.TAG$REFURBISHED){
                    testBatchDevice.set(db.TestBatchDevice.REFURBISHED_DATE, moment().utc().toDate());
                    device.set(db.Device.REFURBISHED_DATE, moment().utc().toDate());
                    testBatchDevicesModified.push(device);
                }

                testBatchDevicesModified.push(testBatchDevice);
            }

            await Parse.Object.saveAll(testBatchDevicesModified);

            await testBatch.set(db.TestBatch.COMPLETED, true);
            await testBatch.set(db.TestBatch.DATE_COMPLETED, moment().utc().toDate());
            await testBatch.set(db.TestBatch.COMPLETED_BY, Parse.User.current());
            await testBatch.set(db.TestBatch.COMPLETION_COMMENT, text);
            await testBatch.set(db.TestBatch.STATUS, db.TestBatch.STATUS$TESTED);
            await testBatch.set(db.TestBatch.TEST_MODE_ACTIVE, false);

            await testBatch.save();

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            swal('Error', e.message, 'error');
            console.error(e);
        }
    }

    async checkConnectivityDevices() {
        try {
            await checkConnectivityDevices(this.state.testBatchDevices);

            this.state.testBatch.set(db.TestBatch.LAST_CONNECTION_CHECK, moment().toDate());

            await this.state.testBatch.save();

            this.getBatchDevices();
        } catch (e) {
            console.error('Error:', e.message);
            swal('Error', e.message, 'error');
        }
    }

    async refreshTestBatchDevices(){
        await this.getBatchDevices();
        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
    }

    async addCustomCommand(name, data){
        if(!name || !data)
            return Promise.reject(Error('No name defined'));

        try {
            data = JSON.parse(data);
        } catch (e) {
            return Promise.reject(Error('Json not valid'));
        }

        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            testBatchDevices.forEach(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let command = new Parse.Object('CommandQueue');
                command.set('commandName', name);
                command.set('data', data);
                command.set('device', device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command);
            });

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async setToConfigured(e, force){
        e.preventDefault();

        let setToConfigured = async (testBatch, force) => {
            let status = testBatch.get(db.TestBatch.STATUS);
            let config = _.merge({}, batchStateConfig, {init: status, roles: this.props.roles, object: testBatch, props: {supplierOrder: {}}});
            let sm = new StateMachine(config);
            await sm.goTo(db.TestBatch.STATUS$CONFIGURED, force);

            testBatch.set(db.TestBatch.STATUS, db.TestBatch.STATUS$CONFIGURED);
            await testBatch.save();

            swal({title: 'Status updated!', text: ' ', icon: 'success', button: [''], timer: 1000});
        }

        try {
            try {
                await setToConfigured(this.state.testBatch);
            } catch (e){
                if(force === true){
                    let result = await swal({
                        title: 'Warning!',
                        text: `You are forcing a status change on this batch / order without any check. Are you sure to do so? This can lead to misconfiguration and quality assurance issues. Ask the IT responsible if you are unsure.` + e.message,
                        buttons: ['Cancel', 'Yes, I know what I\'m doing.'],
                        dangerMode: true
                    });

                    if (!result) throw new Error('Action aborted by user');

                    await setToConfigured(this.state.testBatch, true);
                } else {
                    swal('Error', e.message, 'error');
                }
            }
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    async addQrCodeToTestBatch(roomUuid){
        try {
            let currentTestBatch = this.state.testBatch;
            let type = currentTestBatch.get(db.TestBatch.TYPE);

            if(type !== db.TestBatch.TYPE$TESTKIT) throw new Error('Test batch should of type ' + type);

            if(!validate(roomUuid)) throw new Error('Invalid format.');

            let rid = await (new Parse.Query(db.classes.RoomInstallationId))
                .equalTo(db.RoomInstallationId.UUID, roomUuid)
                .first();

            if(!rid) throw Error('No room installation id found.');

            let room = await (new Parse.Query(db.classes.Room))
                .contains(db.Room.UNIQUE_ID, roomUuid)
                .include(db.Room.HOME)
                .first();

            //if(room) throw Error(`Room id has already be used on room "${room.get(db.Room.ROOM_NAME)}" on building "${room?.get(db.Room.HOME)?.get(db.Home.HOME_NAME)}". Use another code.`)

            let currentListOfQrCodes = currentTestBatch.get(db.TestBatch.ROOM_QR_CODES);

            if(currentListOfQrCodes && currentListOfQrCodes.indexOf(roomUuid) >= 0) throw Error('Room id already attached to this batch.');

            currentTestBatch.add(db.TestBatch.ROOM_QR_CODES, roomUuid);

            await currentTestBatch.save();

            await swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e) {
            console.error(e);
            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000});
            throw e;
        }
    }


    async addFirmwareUpdateCommand(firmware){
        function ucFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        let firmwareVersion = firmware.get(db.DeviceFirmware.VERSION);

        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            for(let testBatchDevice of testBatchDevices){

                const device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                const deviceType = device.get(db.Device.DEVICE_TYP);
                const deviceVersionFirmware = device.get(db.Device.VERSION_FIRMWARE);

                if(deviceVersionFirmware === firmwareVersion) continue;

                let query = new Parse.Query('CommandQueue');
                query.equalTo(db.CommandQueue.COMMAND_NAME, 'updateFirmware');
                query.equalTo(db.CommandQueue.DEVICE, toPointerFromId(this.props.match.params.id, 'Device'));

                let commands = await query.find();

                if (commands.length > 0){
                    console.log('There is already a command ChangeMotorPositionCommand in the queue');
                    continue;
                }

                const url = `http://simplyhome-assets.s3.amazonaws.com/Simply${ucFirstLetter(deviceType)}_${firmwareVersion}.bin`;

                let command = new Parse.Object(db.classes.CommandQueue);
                command.set(db.CommandQueue.COMMAND_NAME, db.commands.UPDATE_FIRMWARE);
                command.set(db.CommandQueue.DATA, {
                    url,
                    hash: "abracadabra",
                    size: 500,
                    unit: "bytes"
                });
                command.set(db.CommandQueue.DEVICE, device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command);

                command = new Parse.Object(db.classes.CommandQueue);
                command.set(db.CommandQueue.COMMAND_NAME, db.commands.START_DEBUG);
                command.set(db.CommandQueue.DATA, {});
                command.set(db.CommandQueue.DEVICE, device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command);
            }

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (err) {
            await swal('Error', err.message, 'error') && console.error(err);
        }
    }

    async getFirmwares(){
        const testBatch = this.state.testBatch;
        const testBatchType = testBatch.get(db.TestBatch.TYPE);
        let deviceType = '';

        let query = new Parse.Query('DeviceFirmware');

        if(testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2)
            deviceType = db.Device.DEVICE_TYP$SENSP;
        else if(testBatchType === db.TestBatch.TYPE$CLEVER_THERMO)
            deviceType = db.Device.DEVICE_TYP$THERM;

        query.equalTo(db.DeviceFirmware.DEVICE_TYPE, deviceType);
        query.equalTo(db.DeviceFirmware.STATUS, db.DeviceFirmware.STATUS$STABLE);

        return await query.find();
    }

    async getAllAssociatedOrders(){
        let supplierOrders = await new Parse.Query(db.classes.SupplierOrder)
            .equalTo(db.SupplierOrder.TEST_BATCHES, toPointerFromId(this.props.match.params.id, db.classes.TestBatch))
            .find();

        this.setState({supplierOrders});
    }


    async getTestBatchDeviceHistory(testBatch){
        let testBatchDeviceHistories = await new Parse.Query(db.classes.TestBatchDeviceHistory)
            .equalTo(db.TestBatchDeviceHistory.NEW_BATCH, testBatch)
            .include([
                db.TestBatchDeviceHistory.DEVICE,
                db.TestBatchDeviceHistory.NEW_BATCH,
                db.TestBatchDeviceHistory.OLD_BATCH,
            ])
            .find();

        this.setState({testBatchDeviceHistories});

        return testBatchDeviceHistories;
    }

    async sendCommandStartTestNb(){
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            testBatchDevices.forEach(testBatchDevice => {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let command = new Parse.Object('CommandQueue');
                command.set('commandName', db.commands.START_TEST_NB);
                command.set('data', {});
                command.set('device', device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command);
            });

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async changeTestBatchTag(){
        try {

            let testBatch = this.state.testBatch;
            let newTag = await getTag();
            testBatch.set(db.TestBatch.TAG, newTag);

            await testBatch.save();

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async changeTextBatchTags(){

    }

    async changeDescription(){
        try {
            let testBatch = this.state.testBatch;

            let text = await swal({
                heightAuto: false,
                title: 'Enter description',
                content: {
                    element: 'input',
                    attributes: {
                        placeholder: 'Enter description',
                        type: 'text',
                        value: testBatch.get(db.TestBatch.DESCRIPTION)
                    },
                }
            });

            if(!text) return;

            testBatch.set(db.TestBatch.DESCRIPTION, text);

            await testBatch.save();

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async moveUpTestBatchDevice(testBatchDevice){
        console.log('moveDownTestBatchDevice')
        let testBatch = this.state.testBatch;

        let testBatchDeviceOrder = testBatch?.get(db.TestBatch.TEST_BATCH_DEVICE_ORDER);

        if(testBatchDeviceOrder == null)
            testBatchDeviceOrder = this.state.testBatchDevices.map(testBatchDevice => testBatchDevice.id);

        testBatchDeviceOrder = moveUpItemArray(testBatchDeviceOrder, testBatchDevice.id);

        testBatch.set(db.TestBatch.TEST_BATCH_DEVICE_ORDER, testBatchDeviceOrder);

        await testBatch.save();

        await this.getTestBatch();
    }

    async moveDownTestBatchDevice(testBatchDevice){
        console.log('moveUpTestBatchDevice')
        let testBatch = this.state.testBatch;

        let testBatchDeviceOrder = testBatch?.get(db.TestBatch.TEST_BATCH_DEVICE_ORDER);

        if(testBatchDeviceOrder == null)
            testBatchDeviceOrder = this.state.testBatchDevices.map(testBatchDevice => testBatchDevice.id);

        testBatchDeviceOrder = moveDownItemArray(testBatchDeviceOrder, testBatchDevice.id);

        testBatch.set(db.TestBatch.TEST_BATCH_DEVICE_ORDER, testBatchDeviceOrder);

        await testBatch.save();

        await this.getTestBatch();
    }

    async moveToCoordinate(testBatchDevice){
        try {
            let columns = ['A', 'B', 'C', 'D'];
            let lines = ["1", "2", "3", "4"];

            let column = await swal({
                text: `Column (one of ${columns.join(',')})`,
                content: "input",
                button: {
                    text: "Column"
                },
            })

            let line = await swal({
                text: `Line (one of ${lines.join(',')})`,
                content: "input",
                button: {
                    text: "Line"
                },
            });
            let columnIndex = columns.indexOf(column);
            let lineIndex = lines.indexOf(line);

            if(columnIndex < 0) throw new Error(`Invalid column "${column}" is should be one of ${columns.join(',')}.`);
            if(lineIndex < 0) throw new Error(`Invalid column "${line}" is should be one of ${lines.join(',')}.`);

            let index = (columnIndex * 4) + lineIndex;

            let testBatch = this.state.testBatch;
            let testBatchDeviceOrder = testBatch?.get(db.TestBatch.TEST_BATCH_DEVICE_ORDER);

            if(testBatchDeviceOrder == null)
                testBatchDeviceOrder= this.state.testBatchDevices.map(testBatchDevice => testBatchDevice.id);

            testBatchDeviceOrder = inseertAtIndex(testBatchDeviceOrder, testBatchDevice.id, index);

            testBatch.set(db.TestBatch.TEST_BATCH_DEVICE_ORDER, testBatchDeviceOrder);

            await testBatch.save();

            await this.getTestBatch();

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }

    }

    linkSerialNumberToDevice(value){

    }

    render() {
        let user = Parse.User.current();
        let testBatch = this.state.testBatch;
        let tag = testBatch?.get(db.TestBatch.TAG);
        let tags = testBatch?.get(db.TestBatch.TAGS);
        let description = testBatch?.get(db.TestBatch.DESCRIPTION);
        let username = user.get(db._User.USERNAME);
        let testBatchName = this.state.testBatch && this.state.testBatch.get(db.TestBatch.NAME);
        let type = this.state.testBatch?.get(db.TestBatch.TYPE);

        let testBatchDeviceOrder = testBatch?.get(db.TestBatch.TEST_BATCH_DEVICE_ORDER);


        console.log(testBatchDeviceOrder);
        let renderAssociatedSupplierOrders = () => {
            let supplierOrders = this.state.supplierOrders;

            return <>
                <h5>Associated orders</h5>
                <ul>
                    {supplierOrders.map(supplierOrder => {
                        let referenceNumber = supplierOrder.get(db.SupplierOrder.REFERENCE_NUMBER);
                        let deleted = supplierOrder.get(db.SupplierOrder.DELETED);
                        let createdAt = supplierOrder.get(db.SupplierOrder.CREATED_AT);
                        let shippedAt = supplierOrder.get(db.SupplierOrder.SHIPPING_DATE);
                        let shippmentCompanyName = supplierOrder.get(db.SupplierOrder.SHIPPING_COMPANY_NAME);
                        let shippmentCountry = supplierOrder.get(db.SupplierOrder.SHIPPING_COUNTRY);
                        let shippmentCity = supplierOrder.get(db.SupplierOrder.SHIPPING_CITY);
                        let shippmentStreet = supplierOrder.get(db.SupplierOrder.SHIPPING_STREET);
                        let shippmentStreetNumber = supplierOrder.get(db.SupplierOrder.SHIPPING_STREET_NUMBER);
                        let salesOrderId = supplierOrder.get(db.SupplierOrder.SALES_ORDER_ID);

                        let addressString = [shippmentCompanyName, shippmentStreet, shippmentStreetNumber, shippmentCity, shippmentCountry].join(', ');
                
                        return <li key={supplierOrder.id}>
                            <a href={`https://crm.zoho.eu/crm/org20065170472/tab/SalesOrders/${salesOrderId}`} target={'_blank'} rel={'noreferrer'}>{supplierOrder.get(db.SupplierOrder.REFERENCE_NUMBER)}</a>&nbsp;
                            ({addressString && addressString})&nbsp;
                            c.d. {createdAt && moment(createdAt).format('DD/MM/YYYY')}&nbsp;
                            s.d. {shippedAt &&  moment(shippedAt).format('DD/MM/YYYY')}&nbsp;
                            {deleted && <Badge color="danger">deleted</Badge>}
                        </li>
                    })}
                </ul>
            </>
        };


        let renderTestBatchDevicesHistories = (testBatchDeviceHistories) => {
            return <Row>
                <Col md={12}>
                    <h4>Test batch devices history</h4>
                    <ul>
                        {
                            testBatchDeviceHistories.map(testBatchDeviceHistory => {
                                let device = testBatchDeviceHistory.get(db.TestBatchDeviceHistory.DEVICE);
                                let oldBatch = testBatchDeviceHistory.get(db.TestBatchDeviceHistory.OLD_BATCH);
                                let newBatch = testBatchDeviceHistory.get(db.TestBatchDeviceHistory.NEW_BATCH);
                                let dateTime = testBatchDeviceHistory.get(db.TestBatchDeviceHistory.CREATED_AT);


                                dateTime = dateTime == null ? '-' : moment(dateTime).format('DD/MM/YYYY HH:mm');

                                const {
                                    serialNumber
                                } = extractDeviceFields(device);

                                const {
                                    name: newName,
                                } = extractTestBatchField(newBatch);

                                const {
                                    name: oldName,
                                } = extractTestBatchField(oldBatch);


                                return <li>
                                    {serialNumber} {dateTime} {oldName || '-'} -> {newName}
                                </li>
                            })
                        }
                    </ul>
                </Col>
            </Row>
        }

        return (
            <div className={'page-single-batch'}>
                <LinkRoomModal
                    save={this.linkToRoom}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleLinkRoomModal = toggleModal}
                />
                <AddCustomCommandModal
                    save={this.addCustomCommand}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleAddCustomCommandModal = toggleModal}
                />
                {
                    this.state.testBatch && <SelectTagsModal
                        testBatch={this.state.testBatch}
                        setToggleModal={toggleModal => this.toggleSelectTagsModal = toggleModal}
                    />
                }
                <Row>
                    <Col md={12}>
                        <h1 className="text-center">
                            Batch {this.state.testBatch?.get(db.TestBatch.NAME)} ({this.state.testBatchDevices?.length}) [{this.state.testBatch?.get(db.TestBatch.TYPE)}]
                            {
                                tag != null &&  <Badge>
                                    {tag}
                                </Badge>
                            }
                        </h1>
                    </Col>
                    <Col md={12}>
                        <h4>
                            {
                                tags != null &&  tags.map((tag, i) => {
                                    return <>
                                        <Badge color={'success'} key={i}>
                                            <i className="fa fa-tag" aria-hidden="true"></i>&nbsp;{tag}
                                        </Badge>&nbsp;
                                    </>
                                })
                            }
                        </h4>
                    </Col>
                </Row>

                {renderTestButtons(this.state.testBatch)}

                {
                    description && <Row style={{marginTop: 20}}>
                        <Col md={4}></Col>
                        <Col md={4}>
                            <Alert color="info">
                                <p>{description}</p>
                            </Alert>

                        </Col>
                        <Col md={4}></Col>
                    </Row>
                }
                <Row style={{marginTop: 20}} className={'action-buttons'}>
                    <Col md={12}>
                        <Button outline color={'primary'} onClick={this.checkConnectivityDevices}>
                            <i className="fa fa-arrows-h"></i> Check for connectivity
                        </Button>
                        {
                            !this.props.edit &&
                            this.state.testBatch &&
                            this.state.testBatch.get(db.TestBatch.COMPLETED) && <a href={`mailto:it@cleveron.ch?subject=[Lean management] User ${username} has requested modification of Batch ${testBatchName}&body=User ${username} has requested modification of Batch ${testBatchName}.</br>Reason: </br>`}>
                                <Button outline color={'warning'}>Request batch modification</Button>
                            </a>
                        }
                        {this.props.edit && <Fragment>
                            {
                                this.state.testBatch &&
                                (!this.state.testBatch.get(db.TestBatch.COMPLETED) || this.props.roles.indexOf('Admin') >= 0) &&
                                    <Fragment>
                                        <Button outline color={'primary'} onClick={async () => {
                                            while (true){
                                                let value = await swal('Add device serial number of 7 digits', {content: 'input', buttons: true});
                                                if(!value) return;

                                                if(value.startsWith('https://devices.cleveron.ch/device/')){
                                                    value = value.replace('https://devices.cleveron.ch/device/', '');
                                                    value = value.toUpperCase();
                                                }

                                                await this.createTestBatchDevice(value);
                                            }
                                        }}><i className="fa fa-plus" aria-hidden="true"></i> Add device</Button>

                                        <Button outline color={'primary'} onClick={async () => {
                                            while (true){
                                                let value = await swal('Add device serial number of 7 digits', {content: 'input', buttons: true});
                                                if(!value) return;

                                                await this.linkSerialNumberToDevice(value);
                                            }
                                        }}><i className="fa fa-plus" aria-hidden="true"></i> Add and link serial to device</Button>

                                        {
                                            type === db.TestBatch.TYPE$TESTKIT && <Button outline color={'primary'} onClick={async () => {
                                                while (true){
                                                    let value = await swal('Add QR Code ', {content: 'input', buttons: true});
                                                    if(!value) return;

                                                    if(value.startsWith('https://room.cleveron.ch/room/')){
                                                        value = value.replace('https://room.cleveron.ch/room/', '');
                                                    }

                                                    if(value.startsWith('https://app.cleveron.ch/room/')){
                                                        value = value.replace('https://app.cleveron.ch/room/', '');
                                                        value = value.replace('/report', '');
                                                    }

                                                    await this.addQrCodeToTestBatch(value);
                                                }
                                            }}><i className="fa fa-plus" aria-hidden="true"></i> Add Qr Code</Button>
                                        }

                                        <Button outline color={'primary'} onClick={this.setInMountFlag}>
                                            <i className="fa fa-flag"></i> Set in-mount flag
                                        </Button> <Button outline color={'primary'} onClick={this.setOnlineFlag}>
                                            <i className="fa fa-flag"></i> Set online flag
                                        </Button>
                                        <Button outline color={'warning'} onClick={this.completeTestBatch}>
                                            <i className="fa fa-check"></i> Complete TestBatch
                                        </Button>
                                        <Button outline color={'warning'} onClick={this.sendStopDebug}>
                                             Send all stop debug
                                        </Button>
                                        <Button outline color={'warning'} onClick={this.sendSetToOnlineMode}>
                                            sendSetToOnlineMode
                                        </Button>
                                        <Button outline color={'warning'} onClick={this.toggleAvailableOnBrack}>
                                            toggleAvailabilityOnBrack.ch
                                        </Button>
                                        <Button outline color={'warning'} onClick={this.toggleSelectTagsModal}>
                                            Change tags
                                        </Button>
                                        <Button outline color={'warning'} onClick={async () => {
                                            this.state.testBatch.unset(db.TestBatch.TEST_BATCH_DEVICE_ORDER);
                                            await this.state.testBatch.save();
                                            await swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                                            await this.getTestBatch();
                                        }}>Reset order</Button>
                                    </Fragment>
                            }
                            {
                                this.props.roles.indexOf('Admin') >= 0 &&<Fragment>
                                    <Button outline color="secondary" size="sm" onClick={() => {this.toggleAddCustomCommandModal()}}>
                                        Add custom command
                                    </Button>
                                    <Button outline color="warning" onClick={() => this.toggleLinkRoomModal()}>
                                        Link to room
                                    </Button>
                                    <Button outline color={'warning'} onClick={async () => {
                                        let testBatch = this.state.testBatch;

                                        testBatch.set(db.TestBatch.COMPLETED, false);
                                        testBatch.set(db.TestBatch.STATE, db.TestBatch.STATUS$IN_PREPARATION_FOR_CONFIG);

                                        await testBatch.save();
                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    }}>
                                        <i className="fa fa-times"></i> Un-complete test batch
                                    </Button>
                                    <Button outline color={'warning'} onClick={this.setToConfigured}>
                                        Set configured
                                    </Button><br/>
                                    <Button outline color={'warning'} onClick={(e) => this.setToConfigured(e, true)}>
                                        Force set configured
                                    </Button>

                                    <Button outline color={'primary'} onClick={async (e) => await this.changeDescription()}>
                                        Change description
                                    </Button>

                                </Fragment>
                            }
                            <Button outline color={'warning'} onClick={async (e) => await this.changeTestBatchTag()}>
                                Change test batch tag
                            </Button>
                            <div style={{minWidth: 200, maxWidth: 300, display: 'inline-block'}}>
                                <Select
                                    options={this.state.deviceFirmwares.map(deviceFirmware => ({value: deviceFirmware, label: deviceFirmware.get(db.DeviceFirmware.VERSION)}))}
                                    isClearable={true}
                                    value={this.state.selectedDeviceFirmware}
                                    onChange={selectedDeviceFirmware => {
                                        this.setState({selectedDeviceFirmware});
                                    }}
                                />
                            </div>
                            <Button outline color={'warning'} onClick={() => this.addFirmwareUpdateCommand(this.state.selectedDeviceFirmware.value)}>
                                Add firmware update command
                            </Button>
                            <Button outline color={'warning'} onClick={(e) => this.sendCommandStartTestNb(e)}>
                                Force nb-iot connection
                            </Button>
                            <Button outline color={'warning'} onClick={this.refreshTestBatchDevices}>
                                <i className="fa fa-refresh" aria-hidden="true"></i> Refresh
                            </Button>
                        </Fragment> }
                        <Button outline color="primary" onClick={this.onDownload}>
                            <i className="fa fa-download" aria-hidden="true"></i> Download csv
                        </Button>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col md={12}>
                        <TestBatchDeviceTable
                            testBatchDevices={this.state.testBatchDevices}
                            getBatchDevices={this.getBatchDevices}
                            testBatch={this.state.testBatch}
                            edit={this.props.edit}
                            roles={this.props.roles}
                            testBatchDeviceOrder={testBatchDeviceOrder}
                            moveUpTestBatchDevice={this.moveUpTestBatchDevice}
                            moveDownTestBatchDevice={this.moveDownTestBatchDevice}
                            moveToCoordinate={this.moveToCoordinate}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h5>Qr Codes attached</h5>
                        {
                            (this.state.testBatch?.get(db.TestBatch.ROOM_QR_CODES) || [])
                                .map(uuid =>
                                    <p key={uuid}><span><i className="fa fa-qrcode"></i> {uuid}</span></p>
                                )
                        }
                    </Col>
                </Row>
                {
                    this.props.roles.indexOf('Admin') >= 0 && <Row style={{marginTop: 20, marginBottom: 80}}>
                        <Col md={12}>
                            <h5>List serials</h5>
                            {
                                this.state.testBatchDevices
                                    .map(testBatchDevice => testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.SERIAL_NUMBER))
                                    .map(serial => <span key={serial} style={{display: 'inline-block'}}>{serial + ','}</span>)

                            }
                        </Col>
                        <Col md={12}>
                            <h5>List mac address</h5>
                            {
                                this.state.testBatchDevices
                                    .map(testBatchDevice => testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.MAC_ADDRESS))
                                    .map(macAddress => <div key={macAddress}><span style={{display: 'inline-block'}}>{macAddress}</span> <br/> </div>)

                            }
                        </Col>
                        <Col md={12}>
                            <h5>Logs</h5>
                            {
                                this.state.testBatch &&
                                this.state.testBatch.get(db.TestBatch.CONFIGURATION_LOGS) &&
                                this.state.testBatch.get(db.TestBatch.CONFIGURATION_LOGS)
                                    .reverse()
                                    .map((log, i) => {
                                        return <div key={i} style={{marginTop: 20, marginBottom: 20}}>
                                            {moment(log.timestamp).format('DD/MM/YYYY HH:mm')}
                                            <div dangerouslySetInnerHTML={{__html: log.text.replace('\n', '<br/>')}}></div>
                                        </div>
                                    })
                            }
                        </Col>
                        <Col>
                            {renderAssociatedSupplierOrders()}
                        </Col>
                    </Row>
                }

                {renderTestBatchDevicesHistories(this.state.testBatchDeviceHistories)}
            </div>
        )
    }
}

PageSingleBatch.propTypes = {
    match: PropTypes.any,
    edit: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
};