'use strict';

import * as db from '../config/dbStructure';
import config from '../config/app';
import Parse from './parse';
import _ from 'lodash';

export function isSupportedSerieNumber(serieNumber){
    let stringSerieNumber = serieNumber.toString();

    if(stringSerieNumber.startsWith('170')){
        return true;
    } else if(stringSerieNumber.startsWith('180')){
        return true;
    } else if(stringSerieNumber.startsWith('190')){
        return true;
    } else if (stringSerieNumber.startsWith('200')){
        return true;
    } else if (stringSerieNumber.startsWith('210')){
        return true;
    } else if (stringSerieNumber.startsWith('220')){
        return true;
    } else if (stringSerieNumber.startsWith('230')){
        return true;
    } else if (stringSerieNumber.startsWith('240')) {
        return true;
    } else if (stringSerieNumber.startsWith('330')){
        return true;
    } else if (stringSerieNumber.startsWith('340')){
        return true;
    } else if (
        stringSerieNumber.startsWith('111') ||
        stringSerieNumber.startsWith('112')
    ){ //Teltonika router rut240 should change to return true;
        return false;
    }

    return false;
}


/**
 *
 * @param acl
 * @returns {{writeUsers: string[], readUsers: string[]}}
 */
export function getReadWriteUsers(acl) {
    let homeACLObject = acl.toJSON();
    let keys = Object.keys(homeACLObject);

    let readUsers = keys.filter(key => !key.startsWith('role:')).filter(key => homeACLObject[key].read);
    let writeUsers = keys.filter(key => !key.startsWith('role:')).filter(key => homeACLObject[key].write);

    return {
        readUsers,
        writeUsers
    }
}

/**
 *
 * @param readUsers
 * @param writeUsers
 * @returns {Parse.ACL}
 */
export function createBasicACL(readUsers, writeUsers){
    let acl = new Parse.ACL();
    for(let readUser of readUsers){
        acl.setReadAccess(readUser, true);
    }

    for(let writeUser of writeUsers){
        acl.setWriteAccess(writeUser, true);
    }

    acl.setRoleReadAccess(db.roles.ADMIN, true);
    acl.setRoleWriteAccess(db.roles.ADMIN, true);
    acl.setRoleReadAccess(db.roles.LEAN_MANAGEMENT, true);
    acl.setRoleWriteAccess(db.roles.LEAN_MANAGEMENT, true);
    acl.setPublicReadAccess(false);
    acl.setPublicWriteAccess(false);

    return acl;
}

/**
 *
 * @param object1
 * @param object2
 * @returns {Parse.Object} object2
 */
export function copyACLToObject(object1, object2){
    if(!object1) throw Error('object1 is not defined');
    if(!object2) throw Error('Room is not defined');
    let object1ACL = object1.getACL();
    if(!object1ACL) throw Error('object1ACL ACL is not defined');

    let {readUsers, writeUsers} = getReadWriteUsers(object1ACL);
    let object2ACL = createBasicACL(readUsers, writeUsers);

    object2.setACL(object2ACL);
}

export function moveDeviceToRoom(device, newRoom) {
    if(!device) throw Error('Device to be moved is not defined');
    if(!newRoom) throw Error('Target room is not defined');
    let home = newRoom.get(db.Room.HOME);
    if(!home) throw Error('Room should be linked to a building.');

    device.set(db.Device.ROOM_ID, newRoom);
    device.set(db.Device.HOME, home);

    copyACLToObject(newRoom, device);

    return device;
}


export function isRolesInRoles(arrayRoles, roles){
    if(!arrayRoles || !roles) return false;

    let found = false;

    roles.forEach(role => {
        if(arrayRoles.indexOf(role) >= 0) {
            found = true;
            return;
        }
    });

    return found;
}

export function removeCharacter$FromObjectKeys(object){
    Object.keys(object).forEach(function(key){
        if(key.includes('$')){
            let oldKey = key;
            key = key.replace('$', '');
            object[key] = object[oldKey];
            delete object[oldKey];
        }

        if(key.includes('.')){
            let oldKey = key;
            key = key.replace('.', '__');
            object[key] = object[oldKey];
            delete object[oldKey];
        }

        if(_.isArray(object[key])){
            object[key] = object[key].map(removeCharacter$FromObjectKeys);
        }
    });

    return object;
}

export function showAddressString(address){
    if(!address) return 'Not defined';

    let firstName = address.get(db.LMAddress.FIRST_NAME);
    let lastName = address.get(db.LMAddress.LAST_NAME);
    let street = address.get(db.LMAddress.STREET);
    let streetNumber = address.get(db.LMAddress.STREET_NUMBER);
    let city = address.get(db.LMAddress.CITY);
    let companyName = address.get(db.LMAddress.COMPANY_NAME);
    let country = address.get(db.LMAddress.COUNTRY);
    let zip = address.get(db.LMAddress.ZIP);

    return `${companyName || ''}, ${firstName || ''} ${lastName || ''}, ${street || ''} ${streetNumber || ''}, ${zip || ''} ${city || ''}, ${country || ''}`
}

export function getAddressStringFromSupplierOrder(supplierOrder){
    let shippingFirstName = supplierOrder.get(db.SupplierOrder.SHIPPING_FIRST_NAME);
    let shippingLastName = supplierOrder.get(db.SupplierOrder.SHIPPING_LAST_NAME);
    let shippingCity = supplierOrder.get(db.SupplierOrder.SHIPPING_CITY);
    let shippingCompanyName = supplierOrder.get(db.SupplierOrder.SHIPPING_COMPANY_NAME);
    let shippingCountry = supplierOrder.get(db.SupplierOrder.SHIPPING_COUNTRY);
    let shippingStreet = supplierOrder.get(db.SupplierOrder.SHIPPING_STREET);
    let shippingStreetNumber = supplierOrder.get(db.SupplierOrder.SHIPPING_STREET_NUMBER);
    let shippingDepartment = supplierOrder.get(db.SupplierOrder.SHIPPING_DEPARTMENT);
    let shippingZip = supplierOrder.get(db.SupplierOrder.SHIPPING_ZIP);

    let addressString = '';
    addressString += (shippingFirstName || '' ) + ', ';
    addressString += (shippingLastName || '' ) + ', ';
    addressString += (shippingCity || '' ) + ', ';
    addressString += (shippingCompanyName || '' ) + ', ';
    addressString += (shippingCountry || '' ) + ', ';
    addressString += (shippingStreet || '' ) + ', ';
    addressString += (shippingStreetNumber || '' ) + ', ';
    addressString += (shippingDepartment || '' ) + ', ';
    addressString += (shippingZip || '' ) + ', ';

    return addressString;
}

export function extractSupplierOrderInformation(supplierOrder){
    let home = supplierOrder.get(db.SupplierOrder.HOME);
    let status = supplierOrder.get(db.SupplierOrder.STATUS);
    let swissPostTrackingNumber = supplierOrder.get(db.SupplierOrder.SWISS_POST_TRACKING_NUMBER);
    let shippingDate = supplierOrder.get(db.SupplierOrder.SHIPPING_DATE);
    let dueDate = supplierOrder.get(db.SupplierOrder.PRODUCTION_DUE_DATE);
    let actualTherm = supplierOrder.get(db.SupplierOrder.ACTUAL_THERM);
    let actualSense = supplierOrder.get(db.SupplierOrder.ACTUAL_SENSE);
    let requiredTherm = supplierOrder.get(db.SupplierOrder.REQUIRED_THERM);
    let requiredThermWNB = supplierOrder.get(db.SupplierOrder.REQUIRED_THERM_WNB);
    let requiredThermWNBP = supplierOrder.get(db.SupplierOrder.REQUIRED_THERM_WNBP);
    let requiredSense = supplierOrder.get(db.SupplierOrder.REQUIRED_SENSE);
    let requiredSenseW = supplierOrder.get(db.SupplierOrder.REQUIRED_SENSP_W);
    let requiredSenseWNB = supplierOrder.get(db.SupplierOrder.REQUIRED_SENSP_WNB);

    let salesOrderId = supplierOrder.get(db.SupplierOrder.SALES_ORDER_ID);
    let operationId = supplierOrder.get(db.SupplierOrder.OPERATION_ID);

    let roomQrCode = supplierOrder.get(db.SupplierOrder.ROOM_QR_CODE);
    let adapters = supplierOrder.get(db.SupplierOrder.ADAPTERS);
    let referenceNumber = supplierOrder.get(db.SupplierOrder.REFERENCE_NUMBER);
    let testBatches = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);
    let orderFile = supplierOrder.get(db.SupplierOrder.ORDER_FILE);
    let priority = supplierOrder.get(db.SupplierOrder.PRIORITY);
    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);
    let type = supplierOrder.get(db.SupplierOrder.TYPE);
    let notes = supplierOrder.get(db.SupplierOrder.NOTES);
    let subject = supplierOrder.get(db.SupplierOrder.SUBJECT);
    let connectionGuideInsert = supplierOrder.get(db.SupplierOrder.CONNECTION_GUIDE_INSERT);

    let shippingFirstName = supplierOrder.get(db.SupplierOrder.SHIPPING_FIRST_NAME);
    let shippingLastName = supplierOrder.get(db.SupplierOrder.SHIPPING_LAST_NAME);
    let shippingCity = supplierOrder.get(db.SupplierOrder.SHIPPING_CITY);
    let shippingCompanyName = supplierOrder.get(db.SupplierOrder.SHIPPING_COMPANY_NAME);
    let shippingCountry = supplierOrder.get(db.SupplierOrder.SHIPPING_COUNTRY);
    let shippingStreet = supplierOrder.get(db.SupplierOrder.SHIPPING_STREET);
    let shippingStreetNumber = supplierOrder.get(db.SupplierOrder.SHIPPING_STREET_NUMBER);
    let shippingDepartment = supplierOrder.get(db.SupplierOrder.SHIPPING_DEPARTMENT);
    let shippingZip = supplierOrder.get(db.SupplierOrder.SHIPPING_ZIP);
    let responsibleCompany = supplierOrder.get(db.SupplierOrder.RESPONSIBLE_COMPANY);

    let sentFromSupplierDate = supplierOrder.get(db.SupplierOrder.SENT_FROM_SUPPLIER_DATE);

    let addressString = getAddressStringFromSupplierOrder(supplierOrder);


    let adapterA = supplierOrder.get(db.SupplierOrder.ADAPTER_A);
    let adapterB = supplierOrder.get(db.SupplierOrder.ADAPTER_B);
    let adapterC = supplierOrder.get(db.SupplierOrder.ADAPTER_C);
    let adapterD = supplierOrder.get(db.SupplierOrder.ADAPTER_D);

    let insertA = supplierOrder.get(db.SupplierOrder.INSERT_A);
    let insertB = supplierOrder.get(db.SupplierOrder.INSERT_B);

    let reinforcementRing = supplierOrder.get(db.SupplierOrder.REINFORCEMENT_RING);
    let routerRut240 = supplierOrder.get(db.SupplierOrder.ROUTER_RUT_240);
    let powerbank = supplierOrder.get(db.SupplierOrder.POWERBANK);
    let internalNotes = supplierOrder.get(db.SupplierOrder.INTERNAL_NOTES);

    return {
        home,
        status,
        swissPostTrackingNumber,
        shippingDate,
        dueDate,
        actualTherm,
        actualSense,
        requiredTherm,
        requiredThermWNB,
        requiredThermWNBP,
        requiredSense,
        requiredSenseW,
        requiredSenseWNB,
        connectionGuideInsert,

        salesOrderId,
        operationId,

        roomQrCode,
        adapters,
        referenceNumber,
        testBatches,
        orderFile,
        priority,
        configuration,
        type,
        notes,
        internalNotes,
        subject,
        adapterA,
        adapterB,
        adapterC,
        adapterD,
        insertA,
        insertB,
        reinforcementRing,
        routerRut240,
        powerbank,

        //Shipping information
        shippingFirstName,
        shippingLastName,
        shippingCity,
        shippingCompanyName,
        shippingCountry,
        shippingStreet,
        shippingStreetNumber,
        shippingDepartment,
        shippingZip,
        sentFromSupplierDate,
        addressString,
        responsibleCompany
    }
}

export function extractUserFields(user){
    let username,
        email,
        typeOfCustomer,
        password,
        language,
        city;

    if(user){
        username =          user.get(db._User.USERNAME);
        email =             user.get(db._User.EMAIL);
        typeOfCustomer =    user.get(db._User.TYPE_OF_CUSTOMER);
        password =          user.get(db._User.PASSWORD);
        language =          user.get(db._User.LANGUAGE);
        city =              user.get(db._User.CITY);
    }

    return {
        username,
        email,
        typeOfCustomer,
        password,
        language,
        city
    }
}

/**
 *
 * @param operationTask
 * @return {{crmOperationId: *, actualSense: *, subject: *, requiredSense: *, operationName: *, completed: *, type: *, actualTherm: *, home: *, installer: *, referenceNumber: *, operationId: *, requiredTherm: *, operationRefNumber: *, installationPeriod: *, status: *}}
 */
export function extractFieldFromOperationTask(operationTask){
    let home = operationTask.get(db.OperationTask.HOME);
    let status = operationTask.get(db.OperationTask.STATUS);
    let requiredTherm = operationTask.get(db.OperationTask.REQUIRED_THERM);
    let requiredSense = operationTask.get(db.OperationTask.REQUIRED_SENSE);
    let operationId = operationTask.get(db.OperationTask.OPERATION_ID);
    let referenceNumber = operationTask.get(db.OperationTask.REFERENCE_NUMBER);
    let type = operationTask.get(db.OperationTask.TYPE);
    let subject = operationTask.get(db.OperationTask.SUBJECT);
    let completed = operationTask.get(db.OperationTask.COMPLETED);
    let installer = operationTask.get(db.OperationTask.INSTALLER);
    let installationPeriod = operationTask.get(db.OperationTask.INSTALLATION_PERIOD);
    let actualTherm = operationTask.get(db.OperationTask.ACTUAL_THERM);
    let actualSense = operationTask.get(db.OperationTask.ACTUAL_SENSE);
    let crmOperationId = operationTask.get(db.OperationTask.OPERATION_ID);
    let operationName = operationTask.get(db.OperationTask.SUBJECT);
    let operationRefNumber = operationTask.get(db.OperationTask.REFERENCE_NUMBER);

    return {
        home,
        operationId,
        referenceNumber,
        subject,
        type,
        status,
        completed,
        requiredTherm,
        requiredSense,
        installer,
        installationPeriod,
        actualTherm,
        actualSense,
        crmOperationId,
        operationName,
        operationRefNumber
    }
}

/**
 *
 * @param roomTicket
 * @return {{note: *, affectedDevices: *, crmOperationId: *, issueCategory: *, invoiced: *, photo: *, ACL: *, billable: *, confirmed: *, crmOperationReferenceNumber: *, createdAt: *, draft: *, closed: *, user: *, updatedAt: *, status: *}}
 */
export function extractFieldFromRoomTicket(roomTicket){
    let createdAt = roomTicket.get(db.RoomTicket.CREATED_AT);
    let updatedAt = roomTicket.get(db.RoomTicket.UPDATED_AT);
    let ACL = roomTicket.get(db.RoomTicket.ACL);
    let issueCategory = roomTicket.get(db.RoomTicket.ISSUE_CATEGORY);
    let affectedDevices = roomTicket.get(db.RoomTicket.AFFECTED_DEVICES);
    let billable = roomTicket.get(db.RoomTicket.BILLABLE);
    let status = roomTicket.get(db.RoomTicket.STATUS);
    let note = roomTicket.get(db.RoomTicket.NOTE);
    let photo = roomTicket.get(db.RoomTicket.PHOTO);
    let crmOperationReferenceNumber = roomTicket.get(db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER);
    let crmOperationId = roomTicket.get(db.RoomTicket.CRM_OPERATION_ID);
    let user = roomTicket.get(db.RoomTicket.USER);
    let room = roomTicket.get(db.RoomTicket.ROOM);
    let numberOfPieces = roomTicket.get(db.RoomTicket.NUMBER_PIECES);

    return {
        createdAt,
        updatedAt,
        ACL,
        issueCategory,
        affectedDevices,
        billable,
        status,
        note,
        photo,
        crmOperationReferenceNumber,
        crmOperationId,
        user,
        room,
        numberOfPieces
    }
}

export function extractFieldFromIssueCategory(issueCategory){
    if(!issueCategory) return {};
    let createdAt = issueCategory.get(db.IssueCategory.CREATED_AT);
    let updatedAt = issueCategory.get(db.IssueCategory.UPDATED_AT);
    let ACL = issueCategory.get(db.IssueCategory.ACL);
    let categoryKey = issueCategory.get(db.IssueCategory.CATEGORY_KEY);
    let categoryLabelEN = issueCategory.get(db.IssueCategory.CATEGORY_LABEL_EN);
    let subCategoryKey = issueCategory.get(db.IssueCategory.SUB_CATEGORY_KEY);
    let subCategoryLabelEN = issueCategory.get(db.IssueCategory.SUB_CATEGORY_LABEL_EN);

    return {
        createdAt,
        updatedAt,
        ACL,
        categoryKey,
        categoryLabelEN,
        subCategoryKey,
        subCategoryLabelEN
    }
}

export function extractHomeFields(home){
    let owner =     null;
    let homeName =  null;
    let area =      null;
    let city =      null;
    let address =   null;
    let year =      null;
    let language =  null;

    let canton =        null;
    let zip =           null;
    let country =       null;
    let heatingSystem = null;

    let numTherm = null;
    let numSensp = null;

    let typeOfCustomer = null;

    if(home){
        owner =     home.get(db.Home.OWNER);
        homeName =  home.get(db.Home.HOME_NAME);
        area =      home.get(db.Home.AREA);
        city =      home.get(db.Home.CITY);
        address =   home.get(db.Home.ADDRESS);
        year =      home.get(db.Home.YEAR);
        language =  home.get(db.Home.LANGUAGE);

        canton =        home.get(db.Home.CANTON);
        zip =           home.get(db.Home.ZIP)
        country =       home.get(db.Home.COUNTRY);
        heatingSystem = home.get(db.Home.HEATING_SYSTEM_TYPE);

        numTherm = home.get(db.Home.NUMBER_RADIATORS);
        numSensp = home.get(db.Home.NUMBER_SENSP);
        typeOfCustomer = home.get(db.Home.TYPE_OF_CUSTOMER);
    }

    return {
        owner,
        homeName,
        area,
        city,
        address,
        year,
        language,
        canton,
        zip,
        country,
        heatingSystem,
        numTherm,
        numSensp,
        typeOfCustomer
    }
}

/**
 *
 * @param room
 * @return {{installationCompleted: *, data: *, installationComment: *, oldTempMax: *, energySavingPercentage: *, mainPhotoUrl: *, tempMax: *, createdAt: *, presenceForecastEnabled: *, lastUpdateAvgTemp: *, roomType: *, updatedAt: *, height: *, area: *, deviceConfig: *, avgTempLastHour: *, roomProgram: *, thermSleepTimeMinutes: *, motorOnValue: *, presenceDataConfirmationDate: *, roomName: *, installationCompletedDate: *, numberSense: *, minValueWhenMaxTempPreset: *, numberSenco: *, monitoringActivated: *, stateFlag: *, numberSensp: *, tempMin: *, pidActive: *, uniqueId: *, hidden: *, co2: *, presenceDataConfirmationUser: *, mainPhoto: *, weeklyPresenceForecast: *, heatingSlopeActive: *, avgHumLastHour: *, roomSize: *, sunRadiated: *, correctionTemp: *, floor: *, numberRadiators: *, temperaturePredictionFormula: *, ACL: *, lastMonthlyPresenceForecastUpdate: *, windows: *, home: *, oldTempMin: *, hide: *, deleted: *, emergencyTemperature: *}}
 */
export function extractRoomFields(room){
    let createdAt = room.get(db.Room.CREATED_AT);
    let updatedAt = room.get(db.Room.UPDATED_AT);
    let ACL = room.get(db.Room.ACL);
    let roomName = room.get(db.Room.ROOM_NAME);
    let roomCode = room.get(db.Room.ROOM_CODE);
    let tempMin = room.get(db.Room.TEMP_MIN);
    let tempMax = room.get(db.Room.TEMP_MAX);
    let area = room.get(db.Room.AREA);
    let height = room.get(db.Room.HEIGHT);
    let windows = room.get(db.Room.WINDOWS);
    let home = room.get(db.Room.HOME);
    let data = room.get(db.Room.DATA);
    let stateFlag = room.get(db.Room.STATE_FLAG);
    let correctionTemp = room.get(db.Room.CORRECTION_TEMP);
    let floor = room.get(db.Room.FLOOR);
    let mainPhoto = room.get(db.Room.MAIN_PHOTO);
    let numberRadiators = room.get(db.Room.NUMBER_RADIATORS);
    let numberSense = room.get(db.Room.NUMBER_SENSE);
    let numberSensp = room.get(db.Room.NUMBER_SENSP);
    let numberSenco = room.get(db.Room.NUMBER_SENCO);
    let motorOnValue = room.get(db.Room.MOTOR_ON_VALUE);
    let pidActive = room.get(db.Room.PID_ACTIVE);
    let minValueWhenMaxTempPreset = room.get(db.Room.MIN_VALUE_WHEN_MAX_TEMP_PRESET);
    let oldTempMax = room.get(db.Room.OLD_TEMP_MAX);
    let oldTempMin = room.get(db.Room.OLD_TEMP_MIN);
    let presenceForecastEnabled = room.get(db.Room.PRESENCE_FORECAST_ENABLED);
    let deviceConfig = room.get(db.Room.DEVICE_CONFIG);
    let hide = room.get(db.Room.HIDE);
    let emergencyTemperature = room.get(db.Room.EMERGENCY_TEMPERATURE);
    let monitoringActivated = room.get(db.Room.MONITORING_ACTIVATED);
    let sunRadiated = room.get(db.Room.SUN_RADIATED);
    let temperaturePredictionFormula = room.get(db.Room.TEMPERATURE_PREDICTION_FORMULA);
    let heatingSlopeActive = room.get(db.Room.HEATING_SLOPE_ACTIVE);
    let energySavingPercentage = room.get(db.Room.ENERGY_SAVING_PERCENTAGE);
    let roomProgram = room.get(db.Room.ROOM_PROGRAM);
    let presenceDataConfirmationDate = room.get(db.Room.PRESENCE_DATA_CONFIRMATION_DATE);
    let presenceDataConfirmationUser = room.get(db.Room.PRESENCE_DATA_CONFIRMATION_USER);
    let uniqueId = room.get(db.Room.UNIQUE_ID);
    let roomSize = room.get(db.Room.ROOM_SIZE);
    let roomType = room.get(db.Room.ROOM_TYPE);
    let mainPhotoUrl = room.get(db.Room.MAIN_PHOTO_URL);
    let thermSleepTimeMinutes = room.get(db.Room.THERMO_SLEEP_TIME_MINUTES);
    let hidden = room.get(db.Room.HIDDEN);
    let deleted = room.get(db.Room.DELETED);
    let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
    let avgHumLastHour = room.get(db.Room.AVG_HUM_LAST_HOUR);
    let co2 = room.get(db.Room.CO2);
    let lastUpdateAvgTemp = room.get(db.Room.LAST_UPDATE_AVG_TEMP);
    let installationCompleted = room.get(db.Room.INSTALLATION_COMPLETED);
    let installationComment = room.get(db.Room.INSTALLATION_COMMENT);
    let installationCompletedDate = room.get(db.Room.INSTALLATION_COMPLETED_DATE);
    let weeklyPresenceForecast = room.get(db.Room.WEEKLY_PRESENCE_FORECAST);
    let lastMonthlyPresenceForecastUpdate = room.get(db.Room.LAST_MONTHLY_PRESENCE_FORECAST_UPDATE);
    let technicalComments = room.get(db.Room.TECHNICAL_COMMENTS);
    let thermoSleepMinutes = room.get(db.Room.THERMO_SLEEP_TIME_MINUTES);

    return {
        createdAt,
        updatedAt,
        ACL,
        roomName,
        roomCode,
        tempMin,
        tempMax,
        area,
        height,
        windows,
        home,
        data,
        stateFlag,
        correctionTemp,
        floor,
        mainPhoto,
        numberRadiators,
        numberSense,
        numberSensp,
        numberSenco,
        motorOnValue,
        pidActive,
        minValueWhenMaxTempPreset,
        oldTempMax,
        oldTempMin,
        presenceForecastEnabled,
        deviceConfig,
        hide,
        emergencyTemperature,
        monitoringActivated,
        sunRadiated,
        temperaturePredictionFormula,
        heatingSlopeActive,
        energySavingPercentage,
        roomProgram,
        presenceDataConfirmationDate,
        presenceDataConfirmationUser,
        uniqueId,
        roomSize,
        roomType,
        mainPhotoUrl,
        thermSleepTimeMinutes,
        hidden,
        deleted,
        avgTempLastHour,
        avgHumLastHour,
        co2,
        lastUpdateAvgTemp,
        installationCompleted,
        installationComment,
        installationCompletedDate,
        weeklyPresenceForecast,
        lastMonthlyPresenceForecastUpdate,
        technicalComments,
        thermoSleepMinutes
    }
}

/**
 *
 * @param device
 * @returns {{macAddress: *, serialNumber: *, versionFirmware: *, deviceTyp: *, co2: *, temperature: *, presence: *, roomId: *, home: *}}
 */
export function extractDeviceFields(device){
    let serialNumber = device.get(db.Device.SERIAL_NUMBER);
    let roomId = device.get(db.Device.ROOM_ID);
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let home = device.get(db.Device.HOME);
    let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
    let temperature = device.get(db.Device.TEMPERATURE);
    let co2 = device.get(db.Device.CO2);
    let macAddress = device.get(db.Device.MAC_ADDRESS);
    let presence = device.get(db.Device.PRESENCE);


    return {
        serialNumber,
        roomId,
        deviceTyp,
        home,
        versionFirmware,
        temperature,
        co2,
        macAddress,
        presence
    }
}

export function extractTestBatchField(testBatch){
    let name = testBatch.get(db.TestBatch.NAME);
    let status = testBatch.get(db.TestBatch.STATUS);
    let createdAt = testBatch.get(db.TestBatch.CREATED_AT);
    let tag = testBatch.get(db.TestBatch.TAG);
    let type = testBatch.get(db.TestBatch.TYPE);


    return {
        name,
        status,
        createdAt,
        tag,
        type
    }
}

export async function getNumberOfThermAndSense(testBatches){
    let query = new Parse.Query(db.classes.TestBatchDevice);
    query.select([
        db.TestBatchDevice.DEVICE
    ]);
    query.include(db.TestBatchDevice.DEVICE);
    query.containedIn(db.TestBatchDevice.BATCH, testBatches);

    let testBatchDevices = await query.find();

    let numTherm = testBatchDevices
        .filter(testBatchDevice =>
            testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM).length;
    let numSense = testBatchDevices
        .filter(testBatchDevice =>
            testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP).length;

    return {
        numTherm,
        numSense
    }
}

export function checkRequiredFields(object, fieldsToCheck){
    let errorString = null;
    for(let requiredField of fieldsToCheck){
        if(object.get(requiredField) == null) {
            if(!errorString) errorString = '';
            errorString += `${object.className}: ${requiredField} is required \n`;
        }
    }

    return errorString;
}

export function makeId(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

export function isNewMotor(device){
    let serial = device.get(db.Device.SERIAL_NUMBER);
    let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
    let refurbishedDate = device.get(db.Device.REFURBISHED_DATE);


    if(
        mountedEngine == null &&
        (serial && ((serial + ''.toString()).startsWith('21') || (serial + ''.toString()).startsWith('22'))) ||
        refurbishedDate != null
    )
        return true;

    else if (
        refurbishedDate == null &&
        serial && (
            (serial + '').startsWith('20') ||
            (serial + '').startsWith('19') ||
            (serial + '').startsWith('18') ||
            mountedEngine === 'motor_16-20'
        )
    )    
        return false;
}

function isSerieWithNewMotor(serial){
    return serial &&
        (
            (serial + ''.toString()).startsWith('21') ||
            (serial + ''.toString()).startsWith('22') ||
            (serial + ''.toString()).startsWith('23') ||
            (serial + ''.toString()).startsWith('33') ||
            (serial + ''.toString()).startsWith('34')
        );
}
function isSerieWithOldMotor(serial){
    return serial && (
        (serial + '').startsWith('20') ||
        (serial + '').startsWith('19') ||
        (serial + '').startsWith('18')
    );
}

export function isMotorCalibrationOk(device){
    if(!device) return false;

    let serial = device.get(db.Device.SERIAL_NUMBER);
    let motorSpeed = device.get(db.Device.MOTOR_SPEED);
    let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
    let refurbishedDate = device.get(db.Device.REFURBISHED_DATE);
    let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);

    if(!motorCurrentLimits) return false;

    const {
        cL,
        fSpDC,
        maxC,
        minC,
        mCal,
        mT,
        maxCFs
    } = motorCurrentLimits;

    let speedNonOk = true;

    if(
        mountedEngine == null &&
        isSerieWithNewMotor(serial) &&
        motorSpeed === 214
    ){
        speedNonOk = false;
    }

    if (
        refurbishedDate == null &&
        isSerieWithOldMotor(serial) &&
        mountedEngine === 'motor_16-20' &&
        motorSpeed === 100
    )
        speedNonOk = false;

    let fsdcNonOK = fSpDC < 21  || fSpDC > 50;
    let clNonOk = cL > 210 || cL < 130;

    if(motorSpeed === 100) {
        clNonOk = cL < 200;
        fsdcNonOK = fSpDC < 10;
    }

    let resultObject = {
        calibrationOk: !(fsdcNonOK || clNonOk || speedNonOk),
        fsdcNonOK,
        clNonOk,
        speedNonOk
    };

    console.log(refurbishedDate, mountedEngine, serial, motorSpeed, fsdcNonOK, clNonOk, speedNonOk, cL, fSpDC);
    //console.table(resultObject)
    return resultObject;
}

export function getMotorSpeedBasedOnDeviceData(device){
    if(!device) return null;

    let serial = device.get(db.Device.SERIAL_NUMBER);
    let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
    let refurbishedDate = device.get(db.Device.REFURBISHED_DATE);

    if(
        mountedEngine == null &&
        isSerieWithNewMotor(serial)
    ){
        return 214;
    }

    if (
        refurbishedDate == null &&
        mountedEngine === 'motor_16-20' &&
        isSerieWithOldMotor(serial)
    )
        return 100;

    return null;
}

export const toPointerFromId = (parseObjectId, className) => {
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObjectId
    };
};


export async function getDeviceBatchMap(devices) {
    let mapDeviceBatch = {};

    let query = new Parse.Query(db.classes.TestBatchDevice);
    query.include(db.TestBatchDevice.BATCH);
    query.containedIn(db.TestBatchDevice.DEVICE, devices);
    query.limit(100000);
    let testBatchDevices = await query.find();

    testBatchDevices.forEach(testBatchDevice => {
        let deviceId = testBatchDevice.get(db.TestBatchDevice.DEVICE).id;

        mapDeviceBatch[deviceId] = testBatchDevice.get(db.TestBatchDevice.BATCH);
    });

    return mapDeviceBatch;
}

export function extractFieldFromDeviceHistory(deviceHistory){
    let createdAt = deviceHistory.get(db.DeviceHistory.CREATED_AT);
    let device = deviceHistory.get(db.DeviceHistory.DEVICE);
    let oldHome = deviceHistory.get(db.DeviceHistory.OLD_HOME);
    let oldRoom = deviceHistory.get(db.DeviceHistory.OLD_ROOM);
    let newHome = deviceHistory.get(db.DeviceHistory.NEW_HOME);
    let newRoom = deviceHistory.get(db.DeviceHistory.NEW_ROOM);

    let serial = device?.get(db.Device.SERIAL_NUMBER);
    let oldHomeName = oldHome?.get(db.Home.HOME_NAME) ?? 'N/A';
    let oldRoomName = oldRoom?.get(db.Room.ROOM_NAME) ?? 'N/A';
    let newHomeName = newHome?.get(db.Home.HOME_NAME) ?? 'N/A';
    let newRoomName = newRoom?.get(db.Room.ROOM_NAME) ?? 'N/A';

    return {
        createdAt,
        device,
        oldHome,
        oldRoom,
        newHome,
        newRoom,
        serial,
        oldHomeName,
        oldRoomName,
        newHomeName,
        newRoomName
    }
}

export function isWNBThermo(device){
    let deviceType = device?.get(db.Device.DEVICE_TYP);
    let firmwareVersion = device?.get(db.Device.VERSION_FIRMWARE);
    let co2 = device?.get(db.Device.CO2);

    if(co2 === 0 && deviceType === db.Device.DEVICE_TYP$THERM && firmwareVersion?.startsWith('6.')){
        return true;
    }

    return false;
}

export function isWNBPThermo(device){
    let deviceType = device?.get(db.Device.DEVICE_TYP);
    let firmwareVersion = device?.get(db.Device.VERSION_FIRMWARE);
    let co2 = device?.get(db.Device.CO2);

    if(co2 > 10 && deviceType === db.Device.DEVICE_TYP$THERM && firmwareVersion?.startsWith('6.')){
        return true;
    }

    return false;
}

function testNeeded(testBatch){
    let tag = testBatch.get(db.TestBatch.TAG);
    let tags = testBatch.get(db.TestBatch.TAGS);
    let type = testBatch.get(db.TestBatch.TYPE);

    let tests = [
        db.TestBatch.TESTS$VISUAL,
        db.TestBatch.TESTS$TEMPERATURE
    ]

    if(type === db.TestBatch.TYPE$CLEVER_THERMO){
        tests.push(db.TestBatch.TESTS$MOTOR);
        tests.push(db.TestBatch.TESTS$BATTERY);

        if(tag === db.TestBatch.TAG$WNBP){
            tests.push(db.TestBatch.TESTS$CONNECTION);
        }
        if(tag === db.TestBatch.TAG$WNB){
            tests.push(db.TestBatch.TESTS$CONNECTION);
        }
        if(tag === db.TestBatch.TAG$WIFI){
        }
    }

    if(type === db.TestBatch.TYPE$CLEVER_SENSE_CO2){
        if(tag === db.TestBatch.TAG$NB_IOT){
            tests.push(db.TestBatch.TESTS$CONNECTION);
        }
    }

    return tests;
}

export function testBatchNeedTest(batch, test){
    let testsNeeded = testNeeded(batch);

    return testsNeeded.indexOf(test) >= 0;
}

export function getRequiredVersionNumberFromTestBatch(testBatch){
    let tag = testBatch.get(db.TestBatch.TAG);
    let tags = testBatch.get(db.TestBatch.TAGS);
    let type = testBatch.get(db.TestBatch.TYPE);
    /*
    if(hwVersionA === '1.4' && versionFirmware === config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO_14){
        correctVerionNumber = true;
    }
    if(hwVersionA === '2.0' && versionFirmware === config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO){
        correctVerionNumber = true;
    }
     */
    if(type === db.TestBatch.TYPE$CLEVER_THERMO){

        if(tag === db.TestBatch.TAG$WNBP){
            return config.leanManagment.requiredFirmwareVersion.thermo.wnbp;
        }
        if(tag === db.TestBatch.TAG$WNB){
            return config.leanManagment.requiredFirmwareVersion.thermo.wnb;
        }
        if(tag === db.TestBatch.TAG$WIFI){
            return config.leanManagment.requiredFirmwareVersion.thermo.wifi;
        }
    }

    if(type === db.TestBatch.TYPE$CLEVER_SENSE_CO2){
        if(tag === db.TestBatch.TAG$NB_IOT){
            return config.leanManagment.requiredFirmwareVersion.sensor;
        }
    }
}