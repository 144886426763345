import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Badge, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, Table} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import installationStateConfig from '../../../lib/installation-state-config';
import StateMachine from '../../../lib/StateMachine';
import _ from 'lodash';
import config from '../../../config/app';
import moment from 'moment';
import {copyACLToObject, extractSupplierOrderInformation, isRolesInRoles, moveDeviceToRoom} from '../../../lib/util';
import ViewSupplierOrderModal from './view-supplier-order-modal';
import batchStateConfig from '../../../lib/batch-state-config';
import Parse from '../../../lib/parse';
import AddNewSupplierOrderModal from './add-new-supplier-order-modal';
import ConfigModal from './config-modal';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import assert from 'assert';


function replaceAll(str, find, replace) {
    return str.split(find).join(replace);
}

export default class BuildingInstallationTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statusButtonOpen: (new Array(1000)).fill(false),
            editModal: (new Array(props.supplierOrders.length)).fill(null),
            viewModal: (new Array(props.supplierOrders.length)).fill(null),
            configModals: (new Array(props.supplierOrders.length)).fill(null),
            testBatchesWithDischargedDevices: {}
        };

        this.moveTestBatchDevicesToCurrentBuilding = this.moveTestBatchDevicesToCurrentBuilding.bind(this);
    }

    async moveTestBatchDevicesToCurrentBuilding(installation, testBatches){

        async function createToConfigureRoom(home){
            assert(home != null, 'home should be present');

            //Create a room (Room 1, 2, 3 ...)
            let roomObject = new Parse.Object(db.classes.Room);
            roomObject.set(db.Room.ROOM_NAME, 'to-configure');
            roomObject.set(db.Room.HOME, home);
            roomObject.set(db.Room.FLOOR, 0);
            roomObject.set(db.Room.TEMP_MIN, 20);
            roomObject.set(db.Room.TEMP_MAX, 22);
            roomObject.set(db.Room.HIDDEN, true);

            copyACLToObject(home, roomObject);

            return roomObject.save();
        }

        try {
            let home = installation.get(db.SupplierOrder.HOME);

            if(!home) throw Error('No building attached to this order. ');

            if(home.id === 'Fj9TubrTtT'){
                throw new Error('Cannot link to Lean management');
            }
            
            let result = await swal({
                title: 'Warning!',
                text: `All devices linked to this orders will be moved to the room to-configure of the building ${home.get(db.Home.HOME_NAME)}. Continue?`,
                buttons: ['Cancel', 'Yes, I know what I\'m doing.'],
                dangerMode: true
            });

            if (!result) throw new Error('Action aborted by user');

            let room = await (new Parse.Query(db.classes.Room))
                .equalTo(db.Room.ROOM_NAME, 'to-configure')
                .equalTo(db.Room.HOME, home)
                .first();

            if(!room){
                let createToConfigureRoomAnswer = await swal({
                    title: `No room "to-configure" found on selected building "${home.get(db.Home.HOME_NAME)}".`,
                    icon: 'warning',
                    text: `Would you like to create one?`,
                    buttons: ['Abort', 'Confirm'],
                    dangerMode: true
                });

                if (!createToConfigureRoomAnswer) throw new Error('Action aborted by user');

                room = await createToConfigureRoom(home);
            }

            let devicesToSave = [];
            for (let testBatch of testBatches) {
                let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
                    .equalTo(db.TestBatchDevice.BATCH, testBatch)
                    .include(db.TestBatchDevice.DEVICE)
                    .find();

                for(let testBatchDevice of testBatchDevices){
                    let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

                    devicesToSave.push(moveDeviceToRoom(device, room));
                }

            }
            await Parse.Object.saveAll(devicesToSave);

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async checkBatteryLevel(installation, testBatches){
        try {
            let testBatchesWithDischargedDevices = {};
            for(let testBatch of testBatches){
                let testBatchName = testBatch.get(db.TestBatch.NAME);
                let type = testBatch.get(db.TestBatch.TYPE);

                if(type !== db.TestBatch.TYPE$CLEVER_THERMO) continue;

                let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
                    .equalTo(db.TestBatchDevice.BATCH, testBatch)
                    .include(db.TestBatchDevice.DEVICE)
                    .select(`${db.TestBatchDevice.DEVICE}.${db.Device.BATTERY_VOLTAGE}`)
                    .find();

                let dischargedDevice = [];

                for (let testBatchDevice of testBatchDevices) {
                    const device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                    let currentBatteryLevel = device.get(db.Device.BATTERY_VOLTAGE);

                    let isBatteryOk = currentBatteryLevel >= 4 &&
                        currentBatteryLevel < 4.5;

                    if(!isBatteryOk) dischargedDevice.push(device);
                }

                if(testBatchesWithDischargedDevices[testBatch.id] == null)
                    testBatchesWithDischargedDevices[testBatch.id] = {};

                testBatchesWithDischargedDevices[testBatch.id].dischargedDevices = dischargedDevice.length;
            }

            this.setState({testBatchesWithDischargedDevices})

            swal({title: 'Done', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async configureTestKitAccount(supplierOrder, testBatches){
        try {
            assert(!!supplierOrder, 'Supplier order is required.');
            assert(testBatches.length >= 1, 'No test batches attached to this order.');
            assert(
                supplierOrder.get(db.SupplierOrder.TYPE) === db.SupplierOrder.TYPE$TESTKIT,
                'This order is not of type "testkit"'
            );
            assert(
                testBatches
                    .filter(testBatch => testBatch.get(db.TestBatch.TYPE) === db.TestBatch.TYPE$TESTKIT).length > 0,
                'There are no batches with type "testkit" in this order'
            );
            assert(!!supplierOrder.get(db.SupplierOrder.SALES_ORDER_ID), 'Sales order id is required');

            await Parse.Cloud.run('create-testkit-account', {
                salesOrderId: supplierOrder.get(db.SupplierOrder.SALES_ORDER_ID)
            });

            await swal({title: 'Account setup successfully', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e){
            swal('Error', e.message);
        }
    }

    render() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th style={{minWidth: 150}}>Id</th>
                        <th>Configuration</th>
                        <th>Building name</th>
                        <th>Batches</th>
                        <th style={{minWidth: 200}}>Perc completed</th>
                        <th>Due date</th>
                        <th>Shipping date</th>
                        <th>
                            Status <br/>
                            <Badge color={'success'}>no action required</Badge><br/>
                            <Badge color={'warning'}>action required</Badge><br/>
                        </th>
                        <th style={{width: 200}}>Notes</th>
                        {
                            isRolesInRoles(this.props.roles, [ 'Admin']) && <th style={{width: 200}}>
                                Int. Notes
                            </th>
                        }
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.supplierOrders.map((supplierOrder, i) => {
                            let {
                                status,
                                swissPostTrackingNumber,
                                shippingDate,
                                dueDate,
                                actualTherm,
                                actualSense,
                                requiredTherm,
                                requiredSense,
                                requiredThermWNB,
                                requiredThermWNBP,
                                requiredSenseW,
                                requiredSenseWNB,
                                adapterA,
                                adapterB,
                                adapterC,
                                adapterD,
                                roomQrCode,
                                operationId,
                                referenceNumber,
                                testBatches,
                                home,
                                priority,
                                configuration,
                                notes,
                                internalNotes,
                                sentFromSupplierDate,
                                salesOrderId,
                                connectionGuideInsert,
                                responsibleCompany,
                                subject
                            } = extractSupplierOrderInformation(supplierOrder);

                            let sm = new StateMachine(
                                _.merge({}, installationStateConfig, {
                                    init: status,
                                    roles: this.props.roles,
                                    object: supplierOrder,
                                    props: {
                                        editModal: this.state.editModal[i],
                                        configModal: this.state.configModals[i]
                                    }
                                })
                            );

                            let transitions = sm.getTransitions();
                            let allTransitions = sm.getAllTransitions();

                            let homeName = home && home.get(db.Home.HOME_NAME);
                            let homeCity = home && home.get(db.Home.CITY);
                            let homeConfig = home && home.get(db.Home.DEVICES_CONFIG);
                            let homeId = home && home.id;
                            let installationStatusColor = 'success';
                            shippingDate = moment(shippingDate);
                            let today = moment();
                            let mode = 'view';
                            let isAdmin = isRolesInRoles(this.props.roles, ['Admin']);
                            let warning = false;
                            
                            if(connectionGuideInsert) {
                                // eslint-disable-next-line max-len
                                notes = 'Please add the configuration guide insert as we do for the devices on brack.ch. Thank you! ' + (notes ? notes : '')
                            }

                            if(
                                status !== db.SupplierOrder.STATUS$IN_PREPARATION_FOR_CONFIG &&
                                testBatches
                                    .map(testBatch => testBatch.get(db.TestBatch.STATUS))
                                    .filter(testBatchStatus => testBatchStatus === status)
                                    .length !== testBatches.length
                            )
                                warning = true;

                            if(isAdmin)
                                mode = 'edit';

                            if(swissPostTrackingNumber){
                                swissPostTrackingNumber = replaceAll(swissPostTrackingNumber, '.', '');
                            }

                            let trackings = [];

                            if(swissPostTrackingNumber){
                                if(swissPostTrackingNumber.includes(',')){
                                    trackings = swissPostTrackingNumber.split(',');
                                } else {
                                    trackings.push(swissPostTrackingNumber);
                                }
                            }

                            if(installationStateConfig.installationRequireAction(status, this.props.roles)){
                                installationStatusColor= 'warning';
                            }

                            if(
                                status === db.SupplierOrder.STATUS$SENT_FROM_SUPPLIER &&
                                moment(sentFromSupplierDate).diff(moment(), 'days') <= 2
                            ){
                                installationStatusColor= 'success';
                            }

                            let dateColor = 'black';

                            shippingDate.hours(17).minutes(0);

                            if(shippingDate && status !==  db.SupplierOrder.STATUS$SENT_FROM_SUPPLIER){
                                let difference = shippingDate.diff(today, 'hours');

                                if(difference < - 24){
                                    dateColor = 'red';
                                } else if(difference < 0){
                                    dateColor = 'orange';
                                } else {
                                    dateColor = 'green';
                                }
                            }


                            let areThermo = requiredTherm != null && requiredTherm > 0;
                            let areSensp = requiredSense != null && requiredSense > 0;


                            return <tr key={supplierOrder.id}>
                                <td>
                                    {
                                        salesOrderId != null && <a
                                            href={`https://crm.zoho.eu/crm/org20065170472/tab/SalesOrders/${salesOrderId}`}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                        >
                                            {referenceNumber}
                                        </a>
                                    }

                                    {
                                        operationId != null && <a
                                            href={`https://crm.zoho.eu/crm/org20065170472/tab/CustomModule3/${operationId}`}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                        >
                                            {referenceNumber}
                                        </a>
                                    }

                                    { !operationId && !salesOrderId && referenceNumber} <br/>
                                    {subject}
                                </td>
                                <td>
                                    <Badge>{configuration}</Badge>
                                </td>
                                <td>
                                    <a href={`${config.adminDashboard.host}/homes/${homeId}/rooms`}>
                                        {homeName}({homeCity})
                                    </a>
                                </td>
                                <td>
                                    {
                                        testBatches.map(testBatch => {
                                            let testBatchName = testBatch.get(db.TestBatch.NAME);
                                            let batchStateColor = 'success';
                                            let completed = testBatch.get(db.TestBatch.COMPLETED);
                                            let batchStatus = testBatch.get(db.TestBatch.STATUS);
                                            let type = testBatch.get(db.TestBatch.TYPE);
                                            let tag = testBatch.get(db.TestBatch.TAG);
                                            let numberThermo = testBatch.get(db.TestBatch.NUMBER_OF_THERM);
                                            let numberSensor = testBatch.get(db.TestBatch.NUMBER_OF_SENSP);

                                            if(type === db.TestBatch.TYPE$CLEVER_THERMO)
                                                testBatchName = 't' + testBatchName;
                                            else if(type === db.TestBatch.TYPE$CLEVER_SENSE_CO2)
                                                testBatchName = 's' + testBatchName;

                                            if(batchStateConfig.batchRequireAction(batchStatus, this.props.roles)){
                                                batchStateColor = 'warning';
                                            }

                                            if(
                                                batchStatus === db.SupplierOrder.STATUS$SENT_FROM_SUPPLIER &&
                                                moment(sentFromSupplierDate).diff(moment(), 'days') <= 2
                                            ){
                                                batchStateColor = 'success';
                                            }

                                            let iconBatteryEmpty = <span style={{color: 'red'}}><i className="fa fa-battery-quarter" aria-hidden="true"></i></span>;
                                            let iconBatteryFull = <span style={{color: 'green'}}><i className="fa fa-battery-full" aria-hidden="true"></i></span>;
                                            let iconBattery = '';

                                            if(
                                                !!this.state.testBatchesWithDischargedDevices[testBatch.id] &&
                                                this.state.testBatchesWithDischargedDevices[testBatch.id].dischargedDevices > 0
                                            ){
                                                iconBattery = iconBatteryEmpty;
                                            }

                                            if(
                                                !!this.state.testBatchesWithDischargedDevices[testBatch.id] &&
                                                this.state.testBatchesWithDischargedDevices[testBatch.id].dischargedDevices === 0
                                            ){
                                                iconBattery = iconBatteryFull;
                                            }

                                            return <span key={`${Math.round(Math.random() * 10000000)}`}>
                                                <Link
                                                    to={`/batch/${testBatch.id}/${completed ? mode : 'edit'}`}
                                                    target={'_blank'}>
                                                    {testBatchName} (t{numberThermo}&nbsp;s{numberSensor})&nbsp;
                                                </Link>

                                                {
                                                    isAdmin && tag != null && <Badge
                                                        style={{fontSize: 10}}
                                                        color={'success'} pill>
                                                        <i className="fa fa-tag" aria-hidden="true"></i>&nbsp;{tag}
                                                    </Badge>
                                                }

                                                &nbsp;
                                                <Badge color={batchStateColor}>({batchStatus}) </Badge>&nbsp;
                                                {iconBattery}
                                                <br/>
                                            </span>
                                        })
                                    }
                                </td>
                                <td>
                                    {
                                        areThermo && <div>
                                            <img src={thermoIcon} height={30}/> {actualTherm} / {requiredTherm}:
                                            <Progress style={{marginTop: 5, marginBottom: 5}}
                                                value={(actualTherm/requiredTherm) * 100} />
                                            Required T. WNB: {requiredThermWNB}<br/>
                                            Required T. WNBP: {requiredThermWNBP}
                                        </div>
                                    }

                                    {
                                        areSensp && <div>
                                            <img src={co2SensorIcon} height={30}/>  {actualSense} / {requiredSense}:
                                            <Progress style={{marginTop: 5, marginBottom: 5}}
                                                value={(actualSense/requiredSense) * 100} />
                                            Required S. W: {requiredSenseW}<br/>
                                            Required S. WNB: {requiredSenseWNB}
                                        </div>
                                    }

                                    {
                                        [
                                            db.SupplierOrder.ROOM_QR_CODE,
                                            db.SupplierOrder.ADAPTER_A,
                                            db.SupplierOrder.ADAPTER_B,
                                            db.SupplierOrder.ADAPTER_C,
                                            db.SupplierOrder.ADAPTER_D,
                                            db.SupplierOrder.INSERT_A,
                                            db.SupplierOrder.INSERT_B,
                                            db.SupplierOrder.REINFORCEMENT_RING,
                                            db.SupplierOrder.POWERBANK,
                                        ].map((key, i) => {
                                            let value = supplierOrder.get(key);

                                            if(value == null || value === 0) return '';

                                            return <p key={i}>{key}:{value}x&nbsp;<a href={'https://cleveron-assets.s3.amazonaws.com/production/Material%20list%201.0.pdf'} target={'_blank'}><i className="fa fa-info-circle" style={{color: 'green'}}/></a></p>
                                        })
                                    }
                                </td>
                                <td>
                                    {dueDate && <span>{moment(dueDate).format('DD/MM/YYYY')}</span>}
                                </td>
                                <td>
                                    {shippingDate && <span style={{color: dateColor}}>
                                        {moment(shippingDate).format('DD/MM/YYYY')} (by {responsibleCompany})
                                    </span>}
                                    {priority === 'low' && <Badge color="success">priority: low</Badge>}
                                    {priority === 'medium' && <Badge color="warning">priority: medium</Badge>}
                                    {priority === 'high' && <Badge color="danger">priority: high</Badge>}
                                </td>
                                <td>
                                    {
                                        isAdmin && warning && <i
                                            style={{color: 'orange'}}
                                            className="fa fa-exclamation-triangle">
                                        </i>
                                    }
                                    <Badge color={installationStatusColor}>{status}</Badge>
                                    {
                                        status === 'sent-from-supplier' &&
                                        trackings.map((tracking, i) => {
                                            return <p key={i}>
                                                <small>Tracking {i + 1}/{trackings.length}:
                                                    {/* eslint-disable-next-line max-len */}
                                                    <a href={`https://service.post.ch/ekp-web/external/view/${tracking}?lang=en`} target={'_blank'} rel="noreferrer">{tracking}
                                                    </a>
                                                </small>
                                            </p>
                                        })
                                    }
                                </td>
                                <td>{notes}</td>
                                {
                                    isRolesInRoles(this.props.roles, [ 'Admin']) && <td>
                                        {
                                            internalNotes && internalNotes.map((internalNote, i) => {
                                                return <p key={i}>{internalNote[db.SalesOrderNote.NOTE_CONTENT]}</p>;
                                            })
                                        }
                                    </td>
                                }
                                <td>
                                    <ViewSupplierOrderModal
                                        setToggleModal={toggle => {
                                            this.setState(prev => {
                                                prev.viewModal[i] = toggle;

                                                return prev;
                                            });
                                        }}
                                        supplierOrder={supplierOrder}
                                        roles={this.props.roles}
                                    />
                                    <AddNewSupplierOrderModal
                                        setToggleModal={editModal => {
                                            this.setState(prev => {
                                                prev.editModal[i] = editModal;

                                                return prev;
                                            });
                                        }}
                                        supplierOrder={supplierOrder}
                                        callback={() => this.props.loadSupplierOrders()}
                                        roles={this.props.roles}
                                    />
                                    <ConfigModal
                                        setToggleModal={configModal => {
                                            this.setState(prev => {
                                                prev.configModals[i] = configModal;

                                                return prev;
                                            });
                                        }}
                                        home={home}
                                        config={homeConfig ? homeConfig.deviceConfig.data[0] : null}
                                    />
                                    {
                                        isRolesInRoles(this.props.roles, ['Supplier', 'Admin']) && <Fragment>
                                            <Button outline color="primary"
                                                title={'View order'}
                                                onClick={() => {
                                                    this.state.viewModal[i]();
                                                }}>
                                                <i className="fa fa-eye"></i>
                                            </Button>
                                        </Fragment>
                                    }
                                    {
                                        isRolesInRoles(this.props.roles, ['Admin']) && <>
                                            <Button
                                                outline
                                                title={'Modify order'}
                                                onClick={() => {
                                                    this.state.editModal[i].toggle();
                                                }}>
                                                <i className="fa fa-pencil"></i>
                                            </Button>
                                        </>
                                    }
                                    {
                                        (isRolesInRoles(this.props.roles, [ 'Admin'])) && <Button
                                            outline
                                            color="danger"
                                            title={'Delete order.'}
                                            onClick={async () => await this.props.deleteSupplierOrder(supplierOrder)}>
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    }
                                    {
                                        (isRolesInRoles(this.props.roles, [ 'Admin'])) && <Button
                                            outline
                                            color="warning"
                                            title={'Check battery status on all linked test batches'}
                                            onClick={async () => await this.checkBatteryLevel(supplierOrder, testBatches)}>
                                            <i className="fa fa-battery-half" aria-hidden="true"></i>
                                        </Button>
                                    }
                                    {
                                        (isRolesInRoles(this.props.roles, [ 'Admin'])) && <Button
                                            outline
                                            color="warning"
                                            title={'Move test batch devices to current order building.'}
                                            onClick={async () => await this.moveTestBatchDevicesToCurrentBuilding(supplierOrder, testBatches)}>
                                            <i className="fa fa-arrow-right"></i>
                                        </Button>
                                    }
                                    {
                                        (isRolesInRoles(this.props.roles, [ 'Admin'])) && <Button
                                            outline
                                            color="warning"
                                            onClick={async () => await this.configureTestKitAccount(supplierOrder, testBatches)}
                                            title={'Setup the account with rooms for testkit client.'}>
                                            <i className="fa fa-archive"></i>
                                        </Button>
                                    }
                                    {
                                        (transitions.length > 0 ||
                                            isRolesInRoles(this.props.roles, [ 'Admin'])) && <ButtonDropdown
                                            title={'Change status of the order. This also change the status for the linked test batches.'}
                                            isOpen={this.state.statusButtonOpen[i]} toggle={() =>
                                                this.setState(prev => {
                                                    prev.statusButtonOpen[i] = !prev.statusButtonOpen[i];

                                                    return prev;
                                                })}>
                                            <DropdownToggle caret>
                                                Update status
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem header>Select transition</DropdownItem>
                                                {

                                                    transitions.length !== 0 && transitions.map((transition, i) => {
                                                        return <DropdownItem
                                                            key={i}
                                                            onClick={() => this.props.changeStatus(supplierOrder, sm, transition.to)}>
                                                            {transition.name}
                                                        </DropdownItem>
                                                    })
                                                }
                                                {this.props.roles.indexOf('Admin') >= 0 &&
                                                <DropdownItem header>All allowed transition</DropdownItem>}
                                                {

                                                    this.props.roles.indexOf('Admin') >= 0 &&
                                                    allTransitions.length !== 0 &&
                                                    allTransitions.map((transition, i) => {
                                                        return <DropdownItem
                                                            key={i}
                                                            onClick={() => this.props.changeStatus(supplierOrder, sm, transition.to, true)}>
                                                            {transition.name} (force)
                                                        </DropdownItem>
                                                    })
                                                }
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    }
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

BuildingInstallationTable.propTypes = {
    supplierOrders: PropTypes.array.isRequired,
    history: PropTypes.any,
    deleteSupplierOrder: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    changeStatus: PropTypes.func.isRequired,
    loadSupplierOrders: PropTypes.func
};