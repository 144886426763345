const paths = {
    index: '/',
    login: '/login',
    batch: '/batch',
    supplierOrders: '/orders',
    supplierOrder: '/orders/:id',//Create/modify an order
    singleBatchEdit: '/batch/:id/edit', //Create/modify a batch/Verify batch/confirm batch
    singleBatchView: '/batch/:id/view', //Create/modify a batch/Verify batch/confirm batch
    visualTest: '/batch/:id/visual-test', //Visually test a batch
    connectionTest: '/batch/:id/connection-test', //Visually test a batch
    batteryTest: '/batch/:id/battery-test', //Battery test for a batch
    motorTest: '/batch/:id/motor-test', //Motor test for a batch
    temperatureCalibration: '/batch/:id/temperature-calibration', //Motor test for a batch
    maintenance: '/maintenance-tasks',
    singleMaintenance: '/maintenance-tasks/:id',
    maintenanceTaskRoom: '/maintenance-tasks/:id/room/:roomId',
    maintenanceTaskReport: '/maintenance-tasks/:id/report',
    operationDashboard: '/operation-dashboard',
    productionReport: '/production-report',
    listBuildings: '/buildings',
    linkRoomQr: '/link-room-qr',
    assemblyCheck: '/assembly-check'
};

export default paths;
