import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import VisualTestBatchDeviceTable from './visual-test-batch-device-table';
import Loader from '../loader';
import {checkConnectivityDevices, getBatchDevices, renderTestButtons} from '../../utils';

export default class PageVisualTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testBatchDevices: [],
            loading: {
                connectivityCheck: false
            }
        };

        this.getBatchDevices = this.getBatchDevices.bind(this);
        this.onConnectivityCheck = this.onConnectivityCheck.bind(this);
        this.visualTestCheck = this.visualTestCheck.bind(this);
        this.setAllVisualOk = this.setAllVisualOk.bind(this);
    }

    async componentDidMount() {
        await this.getBatchDevices();
        await this.getTestBatch();
    }

    async getTestBatch(){
        try{
            let testBatch = await (new Parse.Query(db.classes.TestBatch)).get(this.props.match.params.id);

            this.setState({testBatch});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async getBatchDevices(){
        try{
            let testBatchDevices = await getBatchDevices(this.props.match.params.id);

            this.setState({testBatchDevices});

            return testBatchDevices;
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onConnectivityCheck(){
        this.setState({loading: {connectivityCheck: true}});

        try{

            let testBatchDevices = await this.getBatchDevices();

            await checkConnectivityDevices(testBatchDevices);

            await this.getBatchDevices();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');

        } finally {
            this.setState({loading: {connectivityCheck: false}});
        }
    }

    async visualTestCheck(testBatchDevice, checked){
        testBatchDevice.set(db.TestBatchDevice.VISUAL_TEST, checked.toString());

        await testBatchDevice.save();

        await this.getBatchDevices();
    }

    async setAllVisualOk(){
        try {
            let testBatchDevices = [];
            for (let testBatchDevice of this.state.testBatchDevices) {
                testBatchDevice.set(db.TestBatchDevice.VISUAL_TEST, 'true');
                testBatchDevices.push(testBatchDevice);
            }

            await Parse.Object.saveAll(testBatchDevices);

            this.setState({testBatchDevices: await getBatchDevices(this.props.match.params.id)});

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    render() {

        return (
            <div>
                <h1 className="text-center"><i className="fa fa-eye"></i> Visual test {this.state.testBatch?.get(db.TestBatch.NAME)}</h1>
                {renderTestButtons()}
                <Button outline color={'primary'} onClick={this.onConnectivityCheck}>
                    Check for connectivity {this.state.loading.connectivityCheck && <Loader/>}
                </Button>

                <Button outline color={'primary'} onClick={this.setAllVisualOk}>
                    Set all visual ok
                </Button>

                <VisualTestBatchDeviceTable
                    testBatchDevices={this.state.testBatchDevices}
                    onVisualTestCheck={this.visualTestCheck}
                />
            </div>
        );
    }
}

PageVisualTest.propTypes = {
    match: PropTypes.any
};