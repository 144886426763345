import React, {Component} from 'react';
import {Alert, Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as db from '../../../config/dbStructure';
import swal from 'sweetalert';

export default class SelectTagsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            selectedTestBatchTags: []
        };

        this.toggleModal = this.toggleModal.bind(this);

        console.log('constructor', this.props.testBatch?.get(db.TestBatch.TAGS));
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);

        console.log('componentDidMount', this.props.testBatch?.get(db.TestBatch.TAGS));

        this.setState(prev => {
            prev.selectedTestBatchTags = this.props.testBatch?.get(db.TestBatch.TAGS) ?? [];

            return prev;
        })
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Custom command
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Select
                                options={
                                    Object.keys(db.TestBatch)
                                        .filter(key =>  key.startsWith('TAGS$'))
                                        .map(key => db.TestBatch[key])
                                        .map(value => ({
                                            value: value,
                                            label: value
                                        }))
                                }
                                isClearable={true}
                                isMulti
                                value={this.state.selectedTestBatchTags.map(tag => ({
                                    value: tag,
                                    label: tag
                                }))}
                                onChange={async items => {
                                    items = items == null ? [] : items;

                                    let selectedTestBatchTags = items.map(item => item.value);

                                    this.setState({selectedTestBatchTags});
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={async () => {
                            try {
                                this.setState({error: false, errorMessage: null});


                                this.props.testBatch.set(db.TestBatch.TAGS, this.state.selectedTestBatchTags);
                                await this.props.testBatch.save();

                                await swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                            } catch(e){
                                console.error(e);
                                this.setState({error: true, errorMessage: e.message});
                            }
                        }}>Update tags</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

SelectTagsModal.propTypes = {
    setToggleModal: PropTypes.func,
    testBatch: PropTypes.object
};