import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Badge, Breadcrumb, BreadcrumbItem, Button, Col, Row, Table} from 'reactstrap';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import {checkBatteryDevice, checkConnection, checkWifiDb} from '../page-operation-task-rooms/utils';
import moment from 'moment';
import Loader from '../loader';
import config from '../../../config/app';
import AddPhotoModal from '../page-operation-task-rooms/add-photo-modal';
import swal from 'sweetalert';
import {
    extractFieldFromRoomTicket,
    extractRoomFields,
    getDeviceBatchMap,
    moveDeviceToRoom,
    toPointerFromId
} from '../../../lib/util';
import paths from '../../paths';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import assert from 'assert';
import AddRoomTicket from './add-room-ticket';
import EditRoom from './room-edit';
import Select from 'react-select';
import Toggle from 'react-toggle';
import _ from "lodash";

export default class PageMaintenanceTaskRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: {},
            roomTickets: [],
            devices: [],
            roooms: [],
            deviceFirmwares: []
        };

        this.toggleAddPhotoModal = null;
        this.toggleAddRoomTicket = null;
        this.toggleEditRoom = null;
    }

    async componentDidMount(){
        this.refresh();
        let deviceFirmwares = await this.getFirmwares();
        this.setState({deviceFirmwares});
    }

    async getDevicesFromRoom(room){
        return new Parse.Query(db.classes.Device)
            .equalTo(db.Device.ROOM_ID, room)
            .select([
                db.Device.LAST_MEASUREMENT_DATE,
                db.Device.SERIAL_NUMBER,
                db.Device.BATTERY_VOLTAGE,
                db.Device.WIFI_STRENGTH,
                db.Device.CONNECTION_WIFI_SSID,
                db.Device.CONNECTION_WIFI_PASSWORD,
                db.Device.CONFIG_WIFI_SSID,
                db.Device.CONFIG_WIFI_PASSWORD,
                db.Device.DEVICE_STATE_FLAG,
                db.Device.ROOM_ID,
                db.Device.HOME,
                db.Device.OWNER,
                db.Device.DEVICE_TYP,
                db.Device.MAC_ADDRESS,
                db.Device.VERSION_FIRMWARE,
                db.Device.LAST_CONNECTION_PROTOCOL
            ])
            .find();
    }

    checkDevices(devices) {
        let unpassedChecks = devices
            .filter(device => {
                let checks = {
                    device: device,
                    mountMode: device.get(db.Device.DEVICE_STATE_FLAG) === db.Device.DEVICE_STATE_FLAG$IN_MOUNT,
                    battery: checkBatteryDevice(device),
                    batteryVoltage: device.get(db.Device.BATTERY_VOLTAGE),
                    wifiDb: checkWifiDb(device),
                    wifiStrength: device.get(db.Device.WIFI_STRENGTH),
                    connection: checkConnection(device),
                    lastConnection: device.get(db.Device.LAST_MEASUREMENT_DATE) &&
                        moment(device.get(db.Device.LAST_MEASUREMENT_DATE)).format('DD/MM/YYYY HH:MM:SS')
                };

                return !checks.battery || !checks.wifiDb || !checks.connection || checks.mountMode === true;
            });

        return unpassedChecks.length === 0;
    }

    checkRoom(room){
        let photoOk =  room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url();

        return photoOk;
    }

    async completeRoom(e, room) {
        try {
            e.preventDefault();

            let {room, devices} = this.state;

            let roomOk = this.checkRoom(room) != null;
            let devicesOk = this.checkDevices(devices);
            let text = null;

            if (!devicesOk || !roomOk) {
                // eslint-disable-next-line max-len
                text = await swal('Devices checks are not completed or the photo is not uploaded. Add a comment if you want to complete the room anyways', {
                    content: 'input',
                    buttons: ['Cancel', 'Ok']
                });

                if (!text || text === '') {
                    return swal({title: 'Not saved', text: ' ', icon: 'warning', button: [''], timer: 500});
                }
            }

            let operationTask = this.state.operationTask;

            let operationTaskCompletedRooms = operationTask.get(db.OperationTask.COMPLETED_ROOMS);

            if(!operationTask) throw Error('No operation task found');

            room.set(db.Room.INSTALLATION_COMPLETED, true);
            room.set(db.Room.INSTALLATION_COMMENT, text);
            room.set(db.Room.INSTALLATION_COMPLETED_DATE, new Date());

            let operationRoomState = {
                operationTaskCompleted: true,
                comment: text,
                operationTaskCompletedDate: new Date()
            };

            if(!operationTaskCompletedRooms){
                operationTaskCompletedRooms = {};
            }

            operationTaskCompletedRooms[room.id] = operationRoomState;

            operationTask.set(db.OperationTask.COMPLETED_ROOMS, operationTaskCompletedRooms);

           await operationTask.save();

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            this.refresh();
        } catch (e) {
            console.error(e);
            return swal('Error', e.message, 'error');
        }
    }

    async refresh(){
        let room = this.state.room || await new Parse.Query(db.classes.Room).get(this.props.match.params.roomId);
        let devices = await this.getDevicesFromRoom(room);
        let operationTask = await new Parse.Query(db.classes.OperationTask).get(this.props.match.params.id);
        let building = await operationTask.get(db.OperationTask.HOME).fetch();
        let rooms = await new Parse.Query(db.classes.Room)
            .equalTo(db.Room.HOME, building)
            .notEqualTo(db.Room.DELETED, true)
            .select([
                db.Room.ROOM_NAME,
                db.Room.MAIN_PHOTO,
                db.Room.MAIN_PHOTO_URL,
                db.Room.ACL,
                db.Room.HOME
            ])
            .find();
        let mapDeviceBatch = await getDeviceBatchMap(devices);

        console.log(mapDeviceBatch);

        let roomTickets = await this.loadRoomTickets(room);

        this.setState({room, devices, operationTask, building, rooms, roomTickets, mapDeviceBatch});
    }

    async deleteRoom(){
        let {room, devices, rooms} = this.state;

        let roomName = room.get(db.Room.ROOM_NAME);

        let willDelete = await swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this room?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancel', 'Delete']
        });

        if (!willDelete) return;

        let toConfigureRooms = rooms.filter(room => room.get(db.Room.ROOM_NAME) === 'to-configure');

        if(toConfigureRooms.length === 0) throw Error('No room "to-configure found on this installation."');

        let toConfigureRoom = toConfigureRooms[0];

        let devicesToSave = [];

        devices.forEach(device => {
            moveDeviceToRoom(device, toConfigureRoom);
            devicesToSave.push(device);
        })

        await Parse.Object.saveAll(devicesToSave);

        room.set(db.Room.DELETED, true);
        room.set(`${roomName}_deleted`);
        room.unset(db.Room.UNIQUE_ID);

        await room.save();

        await swal({
            title: 'Success', text: ' ', icon: 'success',
            button: [''], timer: 1000
        });

        window.open(paths.singleMaintenance.replace(':id', this.props.match.params.id))
    }

    async addDevice(id, room) {
        let isMac = id.split(':').length === 6;

        try {

            if (isMac) {
                id = id.toUpperCase();
            } else {
                if (id.length !== 7) return swal({
                    title: 'Not a valid serial number', text: ' ', icon: 'error',
                    button: [''], timer: 1000
                });

                id = parseInt(id);
            }

            let home = room.get(db.Room.HOME);

            if (!home) {
                throw new Error('No home defined');
            }

            let query = (new Parse.Query(db.classes.Device))
                .include(db.Device.ROOM_ID)
                .include(db.Device.HOME);

            if (isMac)
                query.equalTo(db.Device.MAC_ADDRESS, id);
            else
                query.equalTo(db.Device.SERIAL_NUMBER, id);

            let device = await query.first();

            if (!device) {
                throw new Error('No device found with this serial');
            }


            let currentRoomOnDevice = device.get(db.Device.ROOM_ID);
            let alreadyPresentRoom = currentRoomOnDevice != null;
            let serial = device.get(db.Device.SERIAL_NUMBER);
            let isRoomToConfigure =
                currentRoomOnDevice?.get(db.Room.ROOM_NAME) === db.Room.ROOM_NAME$TO_CONFIGURE;
            let roomCurrentRoomOnDeviceOnTheSameBuidling =
                currentRoomOnDevice?.get(db.Room.HOME).id === home.id;
            let requireConfirmation = alreadyPresentRoom &&
                (!isRoomToConfigure || !roomCurrentRoomOnDeviceOnTheSameBuidling);


            if (requireConfirmation) {
                let result = await swal({
                    title: 'Are you sure?',
                    text: `The device ${serial} is already linked with room ${currentRoomOnDevice?.get(db.Room.ROOM_NAME)}. Do you want to move to the current room?`,
                    buttons: ['Abort', 'Confirm'],
                });

                if (!result) throw new Error('Action aborted by user');
            }

            moveDeviceToRoom(device, room);

            await device.save();

            this.refresh();

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            await swal({title: e.message, text: ' ', icon: 'error', button: [''], timer: 1000});
        }
    }

    async onAddDevice(){
        let {room, devices} = this.state;

        while(true){
            let value = await swal('Scan device', {
                content: 'input',
                buttons: true
            });
            if (!value) return;

            if (value.startsWith('https://devices.cleveron.ch/device/')) {
                value = value.replace('https://devices.cleveron.ch/device/', '');
                value = value.toUpperCase();
            }

            await this.addDevice(value, room);
        }
    }

    async changeDeviceFlag(e, device) {
        e.preventDefault();

        let flag = device.get(db.Device.DEVICE_STATE_FLAG);
        let newFlag = null;

        if (flag === 'in-mount') {
            newFlag = 'online';
        }

        if (flag !== 'in-mount') {
            newFlag = 'in-mount';
        }

        if (!flag) //So it was online by default
        {
            newFlag = 'in-mount';
        }

        device.set(db.Device.DEVICE_STATE_FLAG, newFlag);

        device = await device.save();

        swal({title: 'Success', text: `Mode changed to ${newFlag}`, icon: 'success', button: [''], timer: 1000});

        await this.refresh();
    }

    async deleteDevice(e, device) {
        e.preventDefault();

        try {
            assert(device != null, 'No device selected');

            let serial = device.get(db.Device.SERIAL_NUMBER);
            let result = await swal({
                title: 'Are you sure?',
                text: `Do you really want to delete the device ${serial} from this room?`,
                buttons: ['Abort', 'Confirm'],
            });

            if (!result) throw new Error('Action aborted by user');

            let rooms = this.state.rooms;
            let building = this.state.building;

            let deletedRooms = rooms.filter(room => room.get(db.Room.ROOM_NAME) === 'deleted');

            let deletedRoom;

            if(deletedRooms.length < 1) {
                let newRoom = await new Parse.Object(db.classes.Room)
                    .set(db.Room.ROOM_NAME, 'deleted')
                    .set(db.Room.HOME, building)
                    .set(db.Room.FLOOR, 0)
                    .set(db.Room.HIDDEN, true)
                    .setACL(building.getACL())
                    .save();

                deletedRoom = newRoom;
            } else {
                deletedRoom = deletedRooms[0];
            }

            moveDeviceToRoom(device, deletedRoom);

            await device.save();

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.refresh();
        } catch (e) {
            return await swal('Error', e.message, 'error');
        }
    }

    async loadRoomTickets(room){
        let roomTickets = new Parse.Query(db.classes.RoomTicket)
            .equalTo(db.RoomTicket.ROOM, room)
            .include(db.RoomTicket.AFFECTED_DEVICES)
            .include(db.RoomTicket.USER)
            .include(db.RoomTicket.ISSUE_CATEGORY)
            .notEqualTo(db.RoomTicket.DELETED, true)
            .find();

        return roomTickets;
    }

    async addFirmwareUpdateCommand(firmware){
        function ucFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        let firmwareVersion = firmware.get(db.DeviceFirmware.VERSION);
        let firmwareDeviceType = firmware.get(db.DeviceFirmware.DEVICE_TYPE);

        try {
            let devices = this.state.devices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            for(let device of devices){
                const deviceType = device.get(db.Device.DEVICE_TYP);
                const deviceVersionFirmware = device.get(db.Device.VERSION_FIRMWARE);

                if(deviceType !== firmwareDeviceType) continue;
                if(deviceVersionFirmware === firmwareVersion) continue;

                let query = new Parse.Query('CommandQueue');
                query.equalTo(db.CommandQueue.COMMAND_NAME, 'updateFirmware');
                query.equalTo(db.CommandQueue.DEVICE, toPointerFromId(this.props.match.params.id, 'Device'));

                let commands = await query.find();

                if (commands.length > 0){
                    console.log('There is already a command updateFirmware in the queue');
                    continue;
                }

                const url = `http://simplyhome-assets.s3.amazonaws.com/Simply${ucFirstLetter(deviceType)}_${firmwareVersion}.bin`;

                let command = new Parse.Object(db.classes.CommandQueue);
                command.set(db.CommandQueue.COMMAND_NAME, db.commands.UPDATE_FIRMWARE);
                command.set(db.CommandQueue.DATA, {
                    url,
                    hash: 'abracadabra',
                    size: 500,
                    unit: 'bytes'
                });
                command.set(db.CommandQueue.DEVICE, device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))
                commandsToBeSaved.push(command);

                command = new Parse.Object(db.classes.CommandQueue);
                command.set(db.CommandQueue.COMMAND_NAME, db.commands.START_DEBUG);
                command.set(db.CommandQueue.DATA, {});
                command.set(db.CommandQueue.DEVICE, device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))
                commandsToBeSaved.push(command);
            }

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (err) {
            await swal('Error', err.message, 'error') && console.error(err);
        }
    }

    async getFirmwares(){
        let query = new Parse.Query('DeviceFirmware');
        query.equalTo(db.DeviceFirmware.STATUS, db.DeviceFirmware.STATUS$STABLE);

        return await query.find();
    }

    async toggleRoomField(room, fieldName){
        room.set(fieldName, !room.get(fieldName));
        room = await room.save();
        this.setState({room});
    }

    async changeRoomType(room){
        try {
            let getTag = async () => {
                //--------------- BATCH TAG --------------------------------------------
                let roomTypes = await new Parse.Query(db.classes.RoomType).find();
                let tags = roomTypes.map(roomType => roomType.get(db.RoomType.NAME));

                let buttons = {
                    cancel: 'Cancel'
                };

                tags.forEach(tag => buttons[tag]=_.capitalize(tag));
                let tag = await swal(`Enter type of batch: "${tags.join(', ')}"`, {
                    buttons
                });

                if(tags.indexOf(tag) < 0)
                    throw new Error(`Invalid type of batch should be one of: "${tags.join(', ')}"`);

                return tag;
            };

            let newTag = await getTag();
            room.set(db.Room.ROOM_TYPE, newTag);

            await room.save();

            this.refresh();

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async addStartDiscoveryCommand(){

    }
    async addChangePeerListCommands(){

    }

    render() {
        const renderDevice = (device, mapDeviceBatch) => {
            let deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG);
            let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
            let inMountIcon = deviceStateFlag === 'in-mount' ?
                <Badge color={'warning'}>{deviceStateFlag}</Badge> :
                <Badge color={'success'}>{deviceStateFlag}</Badge>;

            let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
            let lastMeasurementDateReal = lastMeasurementDate == null ?
                moment().subtract(1, 'year') : moment(lastMeasurementDate);
            let configWifiSsid = device.get(db.Device.CONFIG_WIFI_SSID);
            let connectionWifiSsid = device.get(db.Device.CONNECTION_WIFI_SSID);
            let batteryOk = checkBatteryDevice(device);
            let wifiOk = checkWifiDb(device);
            let connectionOk = checkConnection(device, this.state.building);
            let deviceType = device.get(db.Device.DEVICE_TYP);
            let motorCurrents = device.get(db.Device.MOTOR_CURRENT_LIMITS);

            let icon;
            if(deviceType === db.Device.DEVICE_TYP$THERM) icon = thermoIcon;
            if(deviceType === db.Device.DEVICE_TYP$SENSP) icon = co2SensorIcon;
            if(deviceType === db.Device.DEVICE_TYP$SENSE) icon = co2SensorIcon;
            if(deviceType === db.Device.DEVICE_TYP$SENCO) icon = co2SensorIcon;
            if(deviceType === db.Device.DEVICE_TYP$ROUTER_RUT240)
                icon = 'https://ik.imagekit.io/sh/58015183_xxl_YdWVfFKt2F.webp?updatedAt=1641551894782';

            let protocolUsed = device.get(db.Device.LAST_CONNECTION_PROTOCOL);

            let nbIotFirmwareVersion = device.get(db.Device.NB_IOT_FIRMWARE_VERSION);

            let batch = mapDeviceBatch?.[device.id];
            let tag = batch?.get?.(db.TestBatch.TAG);

            let deviceHasCustomWifiConfig = !_.isEmpty(_.omit(device.get(db.Device.DEVICE_CONFIG)?.deviceConfig?.data?.[0], 'type'));


            return <tr key={device.id}>
                <td>
                    <img src={icon} height={30}/>
                </td>
                <td>
                    {batch?.get?.(db.TestBatch.NAME)}
                    {
                        tag != null &&  <Badge color={'success'}>
                            <i className="fa fa-tag" aria-hidden="true"></i>&nbsp;{tag}
                        </Badge>
                    }
                    {
                        motorCurrents != null && motorCurrents.cL === 0 && <Badge color={'danger'}>calibration-required</Badge>
                    }
                    {
                        deviceHasCustomWifiConfig &&  <Badge color={'warning'}><i className="fa fa-exclamation-triangle"/> Custom WiFi config</Badge>
                    }
                </td>
                <td>
                    <a href={`${config.adminDashboard.host}/devices/${device.id}`} target={'_blank'} rel={'noreferrer'}>
                        {device.get(db.Device.SERIAL_NUMBER)}
                        &nbsp;
                    </a>
                </td>
                <td>{versionFirmware}</td>
                <td>{nbIotFirmwareVersion}</td>
                <td>
                    {
                        batteryOk ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    <small>({device.get(db.Device.BATTERY_VOLTAGE)}V)</small>
                </td>
                <td>
                    {
                        wifiOk ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    <small>({device.get(db.Device.WIFI_STRENGTH)}dbm)</small>
                </td>
                <td>
                    {
                        connectionOk ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    <small>({lastMeasurementDateReal.fromNow()})</small>&nbsp;
                    {
                        protocolUsed && <Badge>{protocolUsed}</Badge>
                    }
                    <br/>
                    <small>Configured: {configWifiSsid}</small><br/>
                    <small>Connected: {connectionWifiSsid}</small>
                </td>
                <td>{inMountIcon}</td>
                <td>
                    <Button outline color={'danger'}
                        onClick={e => this.deleteDevice(e, device)}
                        style={{marginLeft: 10}}>
                        <i className="fa fa-trash"></i>
                    </Button>
                    <Button outline color={'primary'} active={true}
                        title="Toggle in-mount mode"
                        onClick={(e) => this.changeDeviceFlag(e, device)}
                        style={{marginLeft: 10}}>
                        <i className="fa fa-wrench"></i>
                    </Button>
                </td>
            </tr>;
        };
        const renderTableDevices = (devices, mapDeviceBatch) => {
            return <Table>
                <thead>
                    <tr>
                        <th>Device</th>
                        <th>Batch</th>
                        <th>Serial</th>
                        <th>Version firmware</th>
                        <th>Version chip</th>
                        <th><i className="fa fa-battery-three-quarters"></i></th>
                        <th><i className="fa fa-wifi"></i></th>
                        <th><i className="fa fa-arrows-h"></i></th>
                        <th><i className="fa fa-wrench"></i></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        devices.map(device => renderDevice(device, mapDeviceBatch))
                    }
                </tbody>
            </Table>;
        }
        const renderActionHeader = (room) => {
            let installationCompleted = room.get(db.Room.INSTALLATION_COMPLETED);
            let roomPhotoUrl = room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url();
            let roomHasAPhoto = room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url();
            let iconPhotoMissing = <span style={{color: 'red'}}>
                <i className="fa fa-times" style={{color: 'red', fontSize: 20}}/>
                <i className="fa fa-picture-o" aria-hidden="true"/>
            </span>;

            let iconPhotoOk = <a href={roomPhotoUrl} target={'_target'}>
                <span style={{color: 'green'}}>
                    <i className="fa fa-picture-o" aria-hidden="true"/>
                </span>
            </a>;
            let photoIcon = iconPhotoMissing;

            if(roomHasAPhoto) {
                photoIcon = iconPhotoOk;
            }

            return <Row>
                <Col md={12}>
                    {
                        !roomHasAPhoto && photoIcon
                    }
                    <Button color={'primary'} outline onClick={() => this.refresh()}>
                        <i className="fa fa-refresh" aria-hidden="true"></i> Refresh
                    </Button>
                    <Button outline color={'primary'} onClick={() => this.toggleAddPhotoModal()}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i> Take photo
                    </Button>
                    <Button outline color={'primary'}
                            onClick={async () => await this.onAddDevice(room)}>
                        <i className="fa fa-plus" aria-hidden="true"></i> Add device
                    </Button>
                    <Button outline color={'primary'} onClick={() => this.toggleAddRoomTicket.toggle()}>
                        <i className="fa fa-plus" aria-hidden="true"></i> Add room Ticket
                    </Button>
                    <Button outline color={'primary'} onClick={() => this.toggleEditRoom.toggle()}>
                        <i className="fa fa-pencil" aria-hidden="true"></i> Edit room
                    </Button>
                    <a href={`${paths.linkRoomQr}?roomId=${this.props.match.params.roomId}`} target={'_blank'} rel={'noreferrer'}>
                        <Button outline color={'primary'}>
                            <i className="fa fa-qrcode"></i> Link QR to room
                        </Button>
                    </a>
                    <Button outline color={'primary'}
                            onClick={() => this.changeRoomType(room)}>
                        Change room type
                    </Button>
                    {
                        !installationCompleted &&
                        <Button outline color={'warning'}
                                onClick={async e => await this.completeRoom(e, room)}>
                            <i className="fa fa-check"></i> Complete
                        </Button>
                    }

                    <div style={{minWidth: 200, maxWidth: 300, display: 'inline-block'}}>
                        <Select
                            options={this.state.deviceFirmwares.map(deviceFirmware => ({value: deviceFirmware, label: deviceFirmware.get(db.DeviceFirmware.VERSION)}))}
                            isClearable={true}
                            value={this.state.selectedDeviceFirmware}
                            onChange={selectedDeviceFirmware => {
                                this.setState({selectedDeviceFirmware});
                            }}
                        />
                    </div>
                    <Button outline color={'warning'} onClick={() => this.addFirmwareUpdateCommand(this.state.selectedDeviceFirmware.value)}>
                        Add firmware update command
                    </Button>
                    <Button outline color={'warning'} onClick={() => {}}>
                        Start discovery now
                    </Button>
                    <Button outline color={'warning'} onClick={() => {}}>
                        Set peer list with thermo in room
                    </Button>
                    <Button outline color={'danger'}
                            onClick={async () => await this.deleteRoom(room)}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </Col>
                <Col md={12}>
                    Force wifi on esp-now devices: <Toggle checked={room.get(db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES)} onChange={async () => {
                    room.set(db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES, !room.get(db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES));
                    room = await room.save();
                    this.setState({room});
                }}/><br/>
                </Col>
            </Row>
        }

        const renderRoomTickets = (roomTickets) => {
            return <Row>
                <Col md={12}>
                    <h1>Room tickets</h1>
                    {
                        roomTickets.length > 0 && <Table>
                            <thead>
                                <tr>
                                    <th>Created at</th>
                                    <th>Reference number</th>
                                    <th>Issue</th>
                                    <th>Affected devices</th>
                                    <th>Billable</th>
                                    <th>Status</th>
                                    <th>Number pieces</th>
                                    <th>Note</th>
                                    <th>User</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                roomTickets.map((roomTicket) => {
                                    const {
                                        issueCategory,
                                        affectedDevices,
                                        billable,
                                        status,
                                        note,
                                        crmOperationId,
                                        crmOperationReferenceNumber,
                                        user,
                                        createdAt,
                                        numberOfPieces
                                    } = extractFieldFromRoomTicket(roomTicket);

                                    let iconCheck = <i className="fa fa-check" style={{color: 'green'}}></i>;
                                    let iconCross = <i className="fa fa-times" style={{color: 'red'}}></i>;

                                    return <tr key={roomTicket.id}>
                                        <td>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{crmOperationReferenceNumber && crmOperationReferenceNumber}</td>
                                        <td>{issueCategory && issueCategory.get(db.IssueCategory.SUB_CATEGORY_LABEL_EN)}</td>
                                        <td>{affectedDevices && affectedDevices.map(device => device.get(db.Device.SERIAL_NUMBER)).join(',')}</td>
                                        <td>{billable ? iconCheck : iconCross}</td>
                                        <td>{status}</td>
                                        <td>{numberOfPieces}</td>
                                        <td>{note}</td>
                                        <td>{user && user.get(db._User.USERNAME)}</td>
                                        <td>
                                            <Button outline color={'danger'}
                                                    onClick={async e => {
                                                        e.preventDefault();
                                                        try{
                                                            roomTicket.set(db.RoomTicket.DELETED, true)
                                                            await roomTicket.save();
                                                            swal({title: 'Deleted', text: ' ', icon: 'success', button: [''], timer: 1000});
                                                            this.refresh();
                                                        }catch (e) {
                                                            swal('Error', e.message, 'error');
                                                        }
                                                    }}
                                                    style={{marginLeft: 10}}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    }
                    {roomTickets.length === 0 && <p>No room tickets</p>}
                </Col>
            </Row>;
        }

        let {room, devices, operationTask, building, roomTickets, mapDeviceBatch} = this.state;

        if(!room) return <Loader/>;

        const {
            roomName,
            floor,
            roomCode,
            roomType,
            uniqueId
        } = extractRoomFields(room);

        let roomPhotoUrl = room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url();
        let roomHasAPhoto = room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url();
        let home = room.get(db.Room.HOME);

        return <div>
            {
                room &&
                <AddPhotoModal setToggleModal={(toggle) => this.toggleAddPhotoModal = toggle}
                    room={room}/>
            }
            {
                room &&
                <AddRoomTicket setToggleModal={modal => this.toggleAddRoomTicket = modal}
                    room={room} devices={devices} operationTask={operationTask}
                    refreshRoomTickets={async () => await this.refresh()}
                />
            }
            {
                room &&
                <EditRoom
                    setToggleModal={modal => this.toggleEditRoom = modal}
                    room={room}
                />
            }

            <Breadcrumb>
                <BreadcrumbItem>
                    <a href={paths.maintenance}>
                        Maintenance tasks
                    </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <a href={paths.singleMaintenance.replace(':id', this.props.match.params.id)}>
                        {building && building.get(db.Home.HOME_NAME)}
                    </a>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                    {roomName}
                </BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col md={12}>
                    <h1>{roomName} {roomCode} <Badge>{roomType}</Badge> Floor {floor}
                        {
                            roomHasAPhoto && <a
                                href={roomPhotoUrl}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <img 
                                    src={roomPhotoUrl} 
                                    height='100' 
                                    width='auto' 
                                    style={{marginLeft: 10, marginRight: 10}}>
                                </img>
                            </a>
                        }
                    </h1> 
                    
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {renderActionHeader(room)}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {
                        mapDeviceBatch && renderTableDevices(devices, mapDeviceBatch)
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h1>Room config</h1>
                    QR Code: {room.get(db.Room.UNIQUE_ID)} <a href={`https://app.cleveron.ch/room/${room.get(db.Room.UNIQUE_ID)}/report`} target={'_blank'}>Qr code portal link</a><br/>
                    Admin dashboard: <a href={`https://admin.cleveron.ch/homes/${home.id}/room-temperature-chart?selectedRoomId=${room.id}`} target={'_blank'}>Link admin chart</a><br/>
                    Hidden: <Toggle checked={room.get(db.Room.HIDDEN)} onChange={async () => await this.toggleRoomField(room, db.Room.HIDDEN)} /><br/>
                    Deleted: <Toggle checked={room.get(db.Room.DELETED)} onChange={async () => await this.toggleRoomField(room, db.Room.DELETED)} /><br/>         
                    Sun: <Toggle checked={room.get(db.Room.SUN_RADIATED)} onChange={async () => await this.toggleRoomField(room, db.Room.SUN_RADIATED)} /><br/>
                    Force wifi on esp-now devices: <Toggle checked={room.get(db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES)} onChange={async () => await this.toggleRoomField(room, db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES)}/><br/>
                </Col>
            </Row>
            {renderRoomTickets(roomTickets)}

        </div>;
    }
}

PageMaintenanceTaskRoom.propTypes = {
    match: PropTypes.any,
    history: PropTypes.any,
    mapDeviceBatch: PropTypes.object
};