import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Badge, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Progress} from 'reactstrap';
import {extractSupplierOrderInformation, getAddressStringFromSupplierOrder, isRolesInRoles} from '../../../lib/util';
import moment from 'moment';

export default class ViewSupplierOrderModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            homes: []
        };

        this.toggle = this.toggle.bind(this);
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);

        this.deviceInput = React.createRef();
    }

    async componentDidMount(){
        this.props.setToggleModal(this.toggle);
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
    }

    onOpened(){
        setTimeout(() => {
            $('#room-id').focus();
        }, 100);
    }

    reset() {
        this.setState({
            isOpen: false,
            homes: [],
            testBatches: [],
            selectedHome: {
                value: 'no-selection',
                label: 'No selection'
            }
        });
    }

    async submit(){
        this.reset();
    }

    render(){
        let isAdmin = isRolesInRoles(this.props.roles, ['Admin']);

        let supplierOrder = this.props.supplierOrder;
        let {
            status,
            swissPostTrackingNumber,
            shippingDate,
            actualTherm,
            actualSense,
            requiredTherm,
            requiredSense,
            salesOrderId,
            roomQrCode,
            adapters,
            referenceNumber,
            orderFile,
            testBatches,
            home,
            responsibleCompany,
            subject
        } = extractSupplierOrderInformation(supplierOrder);

        let addressString = getAddressStringFromSupplierOrder(supplierOrder);

        return <span>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}
                   size={'lg'} onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Order info {referenceNumber}</ModalHeader>
                <ModalBody>
                    <ListGroup>
                        <ListGroupItem>Subject: {subject}</ListGroupItem>
                        {
                            home && <ListGroupItem>Home: <Badge color="secondary">{home.get(db.Home.HOME_NAME)}</Badge></ListGroupItem>
                        }
                         <ListGroupItem>Address: {addressString}</ListGroupItem>
                         <ListGroupItem>Shipping deadline: <Badge color="secondary">{moment(shippingDate).format('DD/MM/YYYY')}</Badge></ListGroupItem>
                        {
                            [
                                db.SupplierOrder.SALES_ORDER_ID,
                                db.SupplierOrder.OPERATION_ID,
                                db.SupplierOrder.REQUIRED_THERM,
                                db.SupplierOrder.REQUIRED_SENSE,

                                db.SupplierOrder.ROOM_QR_CODE,
                                db.SupplierOrder.ADAPTER_A,
                                db.SupplierOrder.ADAPTER_B,
                                db.SupplierOrder.ADAPTER_C,
                                db.SupplierOrder.ADAPTER_D,
                                db.SupplierOrder.INSERT_A,
                                db.SupplierOrder.INSERT_B,
                                db.SupplierOrder.REINFORCEMENT_RING,
                                db.SupplierOrder.POWERBANK,

                                db.SupplierOrder.RESPONSIBLE_COMPANY,
                                db.SupplierOrder.SWISS_POST_TRACKING_NUMBER,

                            ].map((key, i) => {
                                let value = supplierOrder.get(key);

                                if(!value) return;

                                return <ListGroupItem key={i}>{key}: {value}</ListGroupItem>
                            }).filter(item => !!item)
                        }

                        {
                            isAdmin && <ListGroupItem>File:
                                <a target="_blank" rel="noopener noreferrer" href={orderFile && orderFile._url}>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                    {referenceNumber}
                                </a>
                            </ListGroupItem>
                        }
                        {
                            status === db.SupplierOrder.STATUS$READY_TO_BE_SENT && <ListGroupItem>
                                File: <a target="_blank" rel="noopener noreferrer" href={orderFile && orderFile._url}>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i> {referenceNumber}</a>
                            </ListGroupItem>
                        }
                        <ListGroupItem>
                            Test batches:&nbsp;
                            {testBatches.map((testBatch, i) => {
                                return <span key={i}>
                                    <Badge color="secondary">{testBatch.get(db.TestBatch.NAME)}</Badge>
                                    {
                                        i !== (testBatches.length - 1) && ','
                                    }
                                </span>
                            })}
                        </ListGroupItem>
                        <ListGroupItem>
                            Selected therm: {actualTherm} / {requiredTherm}<br/>
                            <Progress value={actualTherm / requiredTherm * 100} />
                        </ListGroupItem>
                        <ListGroupItem>
                            Selected sense: {actualSense} / {requiredSense}
                            <Progress value={actualSense / requiredSense * 100} />
                        </ListGroupItem>
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </span>
    }
}

ViewSupplierOrderModal.propTypes = {
    submit: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func.isRequired,
    supplierOrder: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired
};