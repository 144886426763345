import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import Loader from '../loader';
import Parse from '../../../lib/parse';
import {checkConnectivityDevices, getBatchDevices, renderTestButtons} from '../../utils';
import BatteryTestBatchDeviceTable from './battery-test-batch-device-table';
import config from '../../../config/app';

export default class PageBatteryTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testBatchDevices: [],
            loading: {}
        };

        this.onCheckConnectionTest = this.onCheckConnectionTest.bind(this);
        this.onBatteryLevelCheck = this.onBatteryLevelCheck.bind(this);
    }

    async componentDidMount() {
        let testBatch = await new Parse.Query(db.classes.TestBatch).get(this.props.match.params.id);
        this.setState({testBatchDevices: await getBatchDevices(this.props.match.params.id), testBatch});
    }

    async onCheckConnectionTest(){
        this.setState({loading: {connectivityCheck: true}});

        try {
            await checkConnectivityDevices(this.state.testBatchDevices);
            await getBatchDevices(this.props.match.params.id);
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        } finally {
            this.setState({loading: {connectivityCheck: false}});
        }
    }

    async onBatteryLevelCheck() {
        try {
            let testBatchDevies = this.state.testBatchDevices;

            let listTestBatchDevicesToUpdate = [];
            for (let testBatchDevice of testBatchDevies) {
                const device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let currentBatteryLevel = device.get(db.Device.BATTERY_VOLTAGE);

                let isBatteryOk = currentBatteryLevel >= config.leanManagment.MINIMAL_BATTERY_LEVEL &&
                    currentBatteryLevel < config.leanManagment.MAXIMAL_BATTERY_LEVEL;

                if(isBatteryOk){
                    testBatchDevice.set(db.TestBatchDevice.BATTERY_TEST, 'true');
                } else {
                    testBatchDevice.set(db.TestBatchDevice.BATTERY_TEST, 'false');
                }
                testBatchDevice.set(db.TestBatchDevice.CURRENT_BATTERY_LEVEL, currentBatteryLevel);

                listTestBatchDevicesToUpdate.push(testBatchDevice);
            }

            await Parse.Object.saveAll(listTestBatchDevicesToUpdate);

            swal({title: 'Battery level updated', text: ' ', icon: 'success', button: [''], timer: 1000});

            this.setState({testBatchDevices: await getBatchDevices(this.props.match.params.id)});
        } catch (e) {
            console.error('Error:', e.message);
            swal('Error', e.message, 'error');
        }
    }

    render() {

        return (
            <div>
                <h1 className="text-center">Page battery test {this.state.testBatch?.get(db.TestBatch.NAME)}</h1>
                {renderTestButtons()}
                <Button outline color={'primary'} onClick={this.onCheckConnectionTest}>
                    Check for connectivity {this.state.loading.connectivityCheck && <Loader/>}
                </Button>

                <Button outline color={'primary'} onClick={this.onBatteryLevelCheck}>
                    Check battery level {this.state.loading.batteryLevelCheck && <Loader/>}
                </Button>

                <BatteryTestBatchDeviceTable testBatchDevices={this.state.testBatchDevices} />
            </div>
        );
    }
}

PageBatteryTest.propTypes = {
    match: PropTypes.any
};