import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import swal from 'sweetalert';
import Parse from '../../../lib/parse';
import Select from 'react-select';
import {
    checkRequiredFields,
    extractFieldFromIssueCategory,
    extractFieldFromOperationTask,
    extractFieldFromRoomTicket
} from '../../../lib/util';
import _ from 'lodash';
import Toggle from 'react-toggle';

export default class AddRoomTicket extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            loading: {},
            roomTicket: this.initRoomTicket(props),
            issueCategories: []
        };

        this.toggle = this.toggle.bind(this);
        this.reset = this.reset.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.addNewRoomTicket = this.addNewRoomTicket.bind(this);
        this.modifyRoomTicketField = this.modifyRoomTicketField.bind(this);
    }

    async componentDidMount(){
        this.props.setToggleModal(this);
    }

    initRoomTicket(props){
        let roomTicket = new Parse.Object(db.classes.RoomTicket);

        let {operationId, referenceNumber} = extractFieldFromOperationTask(props.operationTask);

        roomTicket.set(db.RoomTicket.STATUS, db.RoomTicket.STATUS$DRAFT);
        roomTicket.set(db.RoomTicket.ROOM, props.room);
        roomTicket.set(db.RoomTicket.BILLABLE, false);
        roomTicket.set(db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER, referenceNumber);
        roomTicket.set(db.RoomTicket.CRM_OPERATION_ID, operationId);
        roomTicket.set(db.RoomTicket.USER, Parse.User.current());

        return roomTicket;
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
    }

    async onOpened(){
        let issueCategories = await this.loadIssueCategories();

        this.setState({issueCategories});
    }

    init(){}

    reset(){
        this.setState({
            isOpen: false,
            loading: {},
            roomTicket: this.initRoomTicket(this.props),
            issueCategories: []
        });
    }

    async loadIssueCategories(){
        let issueCategories = await new Parse.Query(db.classes.IssueCategory)
            .addDescending(db.IssueCategory.CATEGORY_KEY)
            .find();

        return issueCategories;
    }

    modifyRoomTicketField(fieldName, newValue){
        let {roomTicket} = this.state;
        roomTicket.set(fieldName, newValue);

        this.setState({roomTicket});
    }

    async addNewRoomTicket(){
        let {roomTicket} = this.state;
        let requiredFields = [
            db.RoomTicket.ISSUE_CATEGORY
        ];


        try {
            let errors = checkRequiredFields(roomTicket, requiredFields);
            if(errors) throw Error(errors);

            let {issueCategory, affectedDevices, billable, status, note} = extractFieldFromRoomTicket(roomTicket);
            let {categoryKey} = extractFieldFromIssueCategory(issueCategory);

            if(categoryKey === db.IssueCategory.CATEGORY_KEY$CLEVERON_DEVICES){
                if(billable == null) throw Error('Billable is required');
                if(status == null) throw Error('Status is required');
                if(billable === true) {
                    if(_.isEmpty(note)) throw Error('Notes is required if billable');
                }
            }

            await roomTicket.save();
            await swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
            this.props.refreshRoomTickets();
            this.reset();
        } catch (e){
            await swal('Error', e.message, 'error');
        }
    }

    render(){
        let {issueCategories, roomTicket} = this.state;
        let {devices} = this.props;

        let {
            issueCategory,
            affectedDevices,
            billable,
            status,
            note,
            photo,
            crmOperationReferenceNumber,
            crmOperationId,
            numberOfPieces
        } = extractFieldFromRoomTicket(roomTicket);

        let {
            categoryKey,
            subCategoryKey
        } = extractFieldFromIssueCategory(issueCategory);

        issueCategory = issueCategory ? {
            value: issueCategory,
            label: `${issueCategory.get(db.IssueCategory.CATEGORY_LABEL_EN)}: ${issueCategory.get(db.IssueCategory.SUB_CATEGORY_LABEL_EN)}`
        } : null;

        status = status ? {value: status, label: _.capitalize(status)} : null;

        affectedDevices =  affectedDevices ? affectedDevices.map(device => ({
            value: device,
            label: device.get(db.Device.SERIAL_NUMBER)
        })) : null;

        return <span>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}
                size={'lg'} onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Room ticket </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for={db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER}>Operation reference number:</Label>
                            <Input
                                type="text"
                                name={db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER}
                                value={crmOperationReferenceNumber}
                                disabled
                                onChange={(e) =>  this.modifyRoomTicketField(db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER, e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.RoomTicket.CRM_OPERATION_ID}>Operation id:</Label>
                            <Input
                                type="text"
                                name={db.RoomTicket.CRM_OPERATION_ID}
                                value={crmOperationId}
                                disabled
                                onChange={(e) =>  this.modifyRoomTicketField(db.RoomTicket.CRM_OPERATION_ID, e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.RoomTicket.ISSUE_CATEGORY}>Issue category:</Label>
                            <Select
                                name={db.RoomTicket.ISSUE_CATEGORY}
                                options={issueCategories.map(issueCategory => ({
                                    value: issueCategory,
                                    label: `${issueCategory.get(db.IssueCategory.CATEGORY_LABEL_EN)}: ${issueCategory.get(db.IssueCategory.SUB_CATEGORY_LABEL_EN)}`
                                }))}
                                isClearable={true}
                                value={issueCategory}
                                onChange={item => this.modifyRoomTicketField(db.RoomTicket.ISSUE_CATEGORY, item.value)}

                            />
                        </FormGroup>
                        {
                            categoryKey && categoryKey === db.IssueCategory.CATEGORY_KEY$CLEVERON_DEVICES && <>
                                <FormGroup>
                                    <Label for={db.RoomTicket.AFFECTED_DEVICES}>Affected devices:</Label>
                                    <Select
                                        name={db.RoomTicket.AFFECTED_DEVICES}
                                        options={devices.map(device => ({
                                            value: device,
                                            label: device.get(db.Device.SERIAL_NUMBER)
                                        }))}
                                        isMulti
                                        isClearable={true}
                                        value={affectedDevices}
                                        onChange={items => this.modifyRoomTicketField(db.RoomTicket.AFFECTED_DEVICES, items && items.map(item => item.value))}
                                    />
                                </FormGroup>
                            </>
                        }
                        <FormGroup>
                            <Label for={db.RoomTicket.BILLABLE}>Billable:</Label>
                            <Toggle
                                checked={billable ? billable : false}
                                onChange={() => this.modifyRoomTicketField(db.RoomTicket.BILLABLE, !billable)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={db.RoomTicket.STATUS}>Status:</Label>
                            <Select
                                name={db.RoomTicket.STATUS}
                                options={[
                                    db.RoomTicket.STATUS$DRAFT,
                                    db.RoomTicket.STATUS$CONFIRMED,
                                    db.RoomTicket.STATUS$INVOICED,
                                    db.RoomTicket.STATUS$CLOSED
                                ].map(status => ({value: status, label: _.capitalize(status)}))}
                                isClearable={true}
                                value={status}
                                onChange={item => this.modifyRoomTicketField(db.RoomTicket.STATUS, item && item.value)}
                            />
                        </FormGroup>

                        {
                            categoryKey && categoryKey === db.IssueCategory.CATEGORY_KEY$POWERBANK && <>
                                <FormGroup>
                                    <Label for={db.RoomTicket.NUMBER_PIECES}>Number of pieces:</Label>
                                    <Input
                                        type="number"
                                        name={db.RoomTicket.NUMBER_PIECES}
                                        value={numberOfPieces}
                                        onChange={(e) =>  this.modifyRoomTicketField(db.RoomTicket.NUMBER_PIECES, parseInt(e.target.value))}
                                    />
                                </FormGroup>
                            </>
                        }

                        <FormGroup>
                            <Label for={db.RoomTicket.NOTE}>Notes:</Label>
                            <Input
                                type="textarea"
                                name={db.RoomTicket.NOTE}
                                value={note}
                                onChange={(e) =>  this.modifyRoomTicketField(db.RoomTicket.NOTE, e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                    <Button outline color="primary" onClick={this.addNewRoomTicket}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </span>
    }
}

AddRoomTicket.propTypes = {
    setToggleModal: PropTypes.func,
    callback: PropTypes.func,
    devices: PropTypes.array,
    room: PropTypes.any,
    operationTask: PropTypes.object,
    refreshRoomTickets: PropTypes.func
};

