import * as db from '../../../config/dbStructure';
import moment from 'moment';
import _ from 'lodash';

const MIN_BATTERY = 3.5;
const MIN_WIFI_DB = -90;
const MIN_MINUTES_THERM = 70;
const MIN_MINUTES_SENSOR = 7;

export const checkBatteryDevice = (device) => {
    let deviceType = device.get(db.Device.DEVICE_TYP);
    if (
        deviceType === 'sensp' ||
        deviceType === 'sense' ||
        deviceType === 'senco'
    ) {
        return true;
    }

    if (!device.get(db.Device.BATTERY_VOLTAGE)) return false;

    return device.get(db.Device.BATTERY_VOLTAGE) > MIN_BATTERY;
};

export const checkWifiDb = device => {
    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
    if (!lastMeasurementDate) return false;

    if (!device.get(db.Device.WIFI_STRENGTH)) return false;

    return device.get(db.Device.WIFI_STRENGTH) > MIN_WIFI_DB;
};

export const checkConnection = (device, home) => {
    let devicesConfig;
    let configOnDevice;
    let wifiName;
    let wifiPassword ;

    if(home){
        devicesConfig = home.get(db.Home.DEVICES_CONFIG);
        configOnDevice = _.get(devicesConfig, 'deviceConfig.data[0]');
        wifiName = _.get(configOnDevice, 'wifiName');
        wifiPassword = _.get(configOnDevice, 'wifiPassword');
    }

    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

    let configWifiSsid = device.get(db.Device.CONFIG_WIFI_SSID);
    let connectionWifiSsid = device.get(db.Device.CONNECTION_WIFI_SSID);

    if (!lastMeasurementDate) return false;

    if (connectionWifiSsid !== null && connectionWifiSsid !== '') {
        if (
            !lastMeasurementDate ||
            !configWifiSsid ||
            !connectionWifiSsid ||
            configWifiSsid !== connectionWifiSsid ||
            (wifiName != null && connectionWifiSsid !== wifiName)
        ) return false;
    }

    let diffMinutes = Math.abs(moment(lastMeasurementDate).diff(moment(), 'minutes'));

    if (device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM) {
        return diffMinutes < MIN_MINUTES_THERM;
    }

    return diffMinutes < MIN_MINUTES_SENSOR;
};
