import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Button,
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
} from 'reactstrap';
import swal from 'sweetalert';
import paths from '../../paths';
import Toggle from 'react-toggle';
import moment from 'moment';
import _ from 'lodash';

import Parse from '../../../lib/parse';
import * as db from '../../../config/dbStructure';

import batchStateConfig from '../../../lib/batch-state-config';
import StateMachine from '../../../lib/StateMachine';
import {moveDeviceToRoom, testBatchNeedTest, testNeeded} from '../../../lib/util';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import Select from 'react-select';
import {getBatchDevices} from '../../utils';

export default class PageBatch extends Component {
    constructor(props) {
        super(props);

        let selectedTestBatchStatusesLocalStorageItem = localStorage.getItem('selectedTestBatchStatuses');
        let selectedTestBatchStatuses = selectedTestBatchStatusesLocalStorageItem ?
            JSON.parse(selectedTestBatchStatusesLocalStorageItem) : [
                db.TestBatch.STATUS$NO_STATUS,
                db.TestBatch.STATUS$TESTED
            ];

        this.state = {
            batches: [],
            statusButtonOpen: (new Array(1000)).fill(false),
            showAllBatches: false,
            selectedTestBatchStatuses,
            selectedTag : null,
            testBatchNameFilter: ''
        };

        this.getBatches = this.getBatches.bind(this);
        this.createBatch = this.createBatch.bind(this);
        this.activeTestMode = this.activeTestMode.bind(this);
        this.addExsistingBatch = this.addExsistingBatch.bind(this);
        this.linkToRoom = this.linkToRoom.bind(this);
    }

    async componentDidMount(){
        this.getBatches();
    }

    async createBatch() {
        let getBatchId = async () => {
            //--------------- BATCH ID --------------------------------------------
            let id = await swal('Batch ID', {
                content: 'input',
            });

            if (!id) {
                throw new Error('Batch ID is required');
            }

            return id;
        }
        let checkBatchId = (id) => {
            let currentUser = Parse.User.current();

            if(currentUser.getUsername() === db._User.USERNAME$JACKUTEK){
                if (!id.startsWith('JAK')) {
                    throw new Error('Please enter a valid Batch ID in the form "JAK<XXXXX>"');
                }
            } else if(currentUser.getUsername() === db._User.USERNAME$MOSER_BAER){
                if (!id.startsWith('MBS')) {
                    throw new Error('Please enter a valid Batch ID in the form "MBS<XXXXX>"');
                }
            }

            if (!id.startsWith('BX') && !id.startsWith('MBS') && !id.startsWith('TSK') && !id.startsWith('JAK')) {
                throw new Error('Please enter a valid Batch ID in the form' +
                    ' "BX<XXX>" or "MBS<XXXXX>" or "TSK<XXXXX>" or "JAK<XXXXX>"');
            }
        }
        let checkDuplicateBatchId = async (id) => {
            let exsistingBatch = await new Parse.Query(db.classes.TestBatch)
                .equalTo(db.TestBatch.NAME, id)
                .first();

            if(exsistingBatch != null)
                throw new Error('A Batch with the same ID has been found. Please enter a new Batch-ID');
        }
        let getBatchType = async () => {
            //--------------- BATCH TYPE --------------------------------------------
            let types = [
                db.TestBatch.TYPE$CLEVER_THERMO,
                db.TestBatch.TYPE$CLEVER_SENSE_CO2,
                db.TestBatch.TYPE$TESTKIT
            ];
            let typeButtons = {
                cancel: 'Cancel'
            };
            types.forEach(type => typeButtons[type]=_.capitalize(type));

            let type = await swal(`Enter type of batch: "${types.join(', ')}"`, {
                buttons: typeButtons
            });

            if(types.indexOf(type) < 0)
                throw new Error(`Invalid type of batch should be one of: "${types.join(', ')}"`);

            return type;
        }
        let getTag = async () => {
            let currentUser = Parse.User.current();

            //--------------- BATCH TAG --------------------------------------------
            let tags = [
                db.TestBatch.TAG$NB_IOT,
                db.TestBatch.TAG$WNB,
                db.TestBatch.TAG$WNBP,
                db.TestBatch.TAG$WIFI
            ];

            let tag;
            if(currentUser.getUsername() !== db._User.USERNAME$JACKUTEK){
                let buttons = {
                    cancel: 'Cancel'
                };

                tags.forEach(tag => buttons[tag]=_.capitalize(tag));
                tag = await swal(`Enter the products model that you want to add in the batch: "${tags.join(', ')}"`, {
                    buttons
                });
            } else {
                tag = db.TestBatch.TAG$JAKUTEC;
            }

            if(tags.indexOf(tag) < 0)
                throw new Error(`Invalid products model selected should be one of: "${tags.join(', ')}"`);

            return tag;
        };

        let getTags = async () => {
            let tagsToAdd = [];

            //--------------- BATCH TAG --------------------------------------------
            let tags = [
                db.TestBatch.TAG$WITH_GETTING_STARTED_INSERT,
                db.TestBatch.TAGS$DEFECT,
                db.TestBatch.TAGS$OK_TO_SELL,
                db.TestBatch.TAGS$TO_CHECK,
                db.TestBatch.TAG$CLEVERON,
                db.TestBatch.TAG$YGNIS_CLEVERON,
                db.TestBatch.TAG$REFURBISHED,
                db.TestBatch.TAG$RETURNED,
                db.TestBatch.TAG$DESTROYED,
                db.TestBatch.TAG$JAKUTEC,
            ];

            let additionalTag = true;
            while(additionalTag){
                let tag;
                let buttons = {
                    cancel: 'Cancel'
                };

                tags.forEach(tag => buttons[tag]= _.capitalize(tag));
                tag = await swal(`Enter tag of batch: "${tags.join(', ')}". Current tags: ${tagsToAdd.join(',')}.`, {
                    buttons
                });

                if(tags.indexOf(tag) < 0)
                    throw new Error(`Invalid tag of batch should be one of: "${tags.join(', ')}"`);

                tagsToAdd.push(tag);


                let response = await swal(`Do you want to add additional tags? Current tags: ${tagsToAdd.join(',')}.`, {
                    buttons: ['No', 'Yes']
                });

                console.log('response', response);

                additionalTag = response === true;
            }

            return tagsToAdd;
        }

        try {
            const id = await getBatchId();

            await checkBatchId(id);
            await checkDuplicateBatchId(id);
            const type = await getBatchType();
            const tag = await getTag();
            let tags = await getTags();
            console.log('tags', tags);

            //--------------- BATCH SAVE --------------------------------
            let batch = new Parse.Object('TestBatch');
            batch.set(db.TestBatch.NAME, id);
            batch.set(db.TestBatch.TYPE, type);
            batch.set(db.TestBatch.STATUS, batchStateConfig.init);
            batch.set(db.TestBatch.TAG, tag);
            batch.set(db.TestBatch.TAGS, tags);


            await batch.save();

            swal({
                title: 'Saved',
                text: ' ',
                icon: 'success',
                button: [''],
                timer: 1000
            });

            this.getBatches();
        } catch (e) {
            swal('Error', e.message, 'error');
            console.error(e);
        }
    }

    async getBatches() {
        let currentUser = Parse.User.current();
        let testBatchNameFilter = this.state.testBatchNameFilter;
        let tagFilter = this.state.selectedTag;

        if(!this.props.roles) return;

        let query = (new Parse.Query('TestBatch'))
            .notEqualTo(db.TestBatch.DELETED, true)
            .limit(1000);

        console.log(this.props.roles);

        if(this.state.selectedTestBatchStatuses.length > 0) {
            query.containedIn(db.TestBatch.STATUS, this.state.selectedTestBatchStatuses);
        } else {
            query.containedIn(db.TestBatch.STATUS, [
                db.TestBatch.STATUS$NO_STATUS
            ]);
        }

        if(tagFilter){
            query.equalTo(db.TestBatch.TAG, tagFilter.value);
        }

        if(this.props.roles.indexOf('Admin') >= 0){
            if(testBatchNameFilter && testBatchNameFilter.length >= 7){
                query =  (new Parse.Query('TestBatch'))
                    .notEqualTo(db.TestBatch.DELETED, true)
                    .limit(10);

                query.contains(db.TestBatch.NAME, testBatchNameFilter);
            }
        } else if(this.props.roles.indexOf('Supplier') >= 0){
            let states = batchStateConfig.supplierStates;
            query.containedIn(db.TestBatch.STATUS, states.map(state => state));
            if(currentUser.getUsername() === db._User.USERNAME$JACKUTEK)
                query.equalTo(db.TestBatch.TAG, db.TestBatch.TAG$JAKUTEC);
            else{
                query.startsWith(db.TestBatch.NAME, 'MBS');
                if(tagFilter){
                    query.equalTo(db.TestBatch.TAG, tagFilter.value);
                } else {
                    query.notEqualTo(db.TestBatch.TAG, db.TestBatch.TAG$JAKUTEC);
                }
            }
        } else if(this.props.roles.indexOf('Installer') >= 0){
            let states = batchStateConfig.installerStates;
            query.containedIn(db.TestBatch.STATUS, states.map(state => state));
        }

        try {
            let batches = await query.find();

            let toRefurbishIndex = _.findIndex(batches, batch => batch.get(db.TestBatch.NAME) === 'MBS99998');
            if(toRefurbishIndex >= 0){
                let toRefurbishObject = batches[toRefurbishIndex];
                batches.splice(toRefurbishIndex, 1);
                batches.unshift(toRefurbishObject);
            }

            let returnedIndex = _.findIndex(batches, batch => batch.get(db.TestBatch.NAME) === 'MBS99999');
            if(returnedIndex >= 0){
                let returnedObject = batches[returnedIndex];
                batches.splice(returnedIndex, 1);
                batches.unshift(returnedObject);
            }

            let destroyedIndex = _.findIndex(batches, batch => batch.get(db.TestBatch.NAME) === 'MBS99997');
            if(destroyedIndex >= 0){
                let destroyedObject = batches[destroyedIndex];
                batches.splice(destroyedIndex, 1);
                batches.unshift(destroyedObject);
            }

            return this.setState({batches});
        } catch (e) {
            return console.error(e);
        }
    }

    async deleteBatch(batch){
        try{
            let result = await swal({
                title: 'Are you sure?',
                text: ``,
                buttons: ['Abort', 'Confirm'],
            });

            if(!result) throw new Error('Action aborted by user');

            batch.set(db.TestBatch.DELETED, true);
            await batch.save();
            await this.getBatches();
            swal({title: 'Deleted', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async activeTestMode(batch, checked){
        try{
            batch.set(db.TestBatch.TEST_MODE_ACTIVE, checked);

            await batch.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.getBatches();
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async changeBatchStatus(testBatch, sm, nextStatus, force){
        try {
            await sm.goTo(nextStatus, force);

            testBatch.set(db.TestBatch.STATUS, nextStatus);
            await testBatch.save();

            swal({title: 'Status updated!', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.getBatches();
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }


    async refresh(){
        this.getBatches();
    }


    async linkToRoom(home, room){
        try {
            let devices = [];
            for (let testBatchDevice of this.state.testBatchDevices) {
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

                devices.push(moveDeviceToRoom(device, room));
            }

            await Parse.Object.saveAll(devices);

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async addExsistingBatch(){
        try {
            let id = await swal('Batch ID', {
                content: 'input',
            });

            if (!id.startsWith('BX') && !id.startsWith('MBS') && !id.startsWith('TSK')) {
                throw new Error('Please enter a valid Batch ID in the form "BX<XXX>" or "MBS<XXXXX>" or "TSK<XXXXX>"');
            }

            let exsistingBatch = await new Parse.Query(db.classes.TestBatch)
                .equalTo(db.TestBatch.NAME, id)
                .first()

            if(!exsistingBatch) throw new Error('Batch not found');

            let acceptedStatuses = [db.TestBatch.STATUS$SENT_FROM_SUPPLIER, db.TestBatch.STATUS$RECEIVED_FROM_SUPPLIER];
            let testBatchStatus = exsistingBatch.get(db.TestBatch.STATUS);
            if(acceptedStatuses.indexOf(testBatchStatus)< 0)
                throw new Error(`This batch is not in the state "${acceptedStatuses.join(',')}" so it cannot be transferred to another warehouse.`);

            let testBatchDevices = await getBatchDevices(exsistingBatch.id);

            let home = await new Parse.Query(db.classes.Home).get('zdTV0qRGev');
            let room = await new Parse.Query(db.classes.Room).get('jvE8rflPCg');

            if(home == null)
                throw new Error('Home Lena managemet Germany was not found.');
            if(room.get(db.Room.ROOM_NAME) !== 'Ready-to-be-shipped')
                throw new Error('Was not possible to find the Ready-to-be-shipped room.');

            let devicesToBeSaved = [];
            for(let testBatchDevice of testBatchDevices){
                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                device = moveDeviceToRoom(device, room);
                devicesToBeSaved.push(device);
            }
            await Parse.Object.saveAll(devicesToBeSaved);

            exsistingBatch.set(db.TestBatch.TAG, db.TestBatch.TAG$JAKUTEC);
            exsistingBatch.set(db.TestBatch.STATUS, db.TestBatch.STATUS$TESTED);
            await exsistingBatch.save();

            swal({title: 'Batch added succesfully', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        let completedOrAdmin = (batch) => {
            if(!batch) return false;

            return !batch.get(db.TestBatch.COMPLETED) ||
                this.props.roles.indexOf('Admin') >= 0;
        };


        let sumAvailableThermo = _.sumBy(
            this.state.batches.filter(batch => batch.get(db.TestBatch.STATUS) === db.TestBatch.STATUS$TESTED),
            batch => batch.get(db.TestBatch.NUMBER_OF_THERM)
        );

        let sumAvailableSensor = _.sumBy(
            this.state.batches.filter(batch => batch.get(db.TestBatch.STATUS) === db.TestBatch.STATUS$TESTED),
            batch => batch.get(db.TestBatch.NUMBER_OF_SENSP)
        );

        let isAdmin = this.props.roles.indexOf('Admin') >= 0;

        let currentUser = Parse.User.current();

        return (
            <div className="p-2">
                <Button outline color="primary" onClick={this.createBatch}>
                    <i className="fa fa-plus" aria-hidden="true"></i> Create New Batch
                </Button>

                <Button outline color="primary" onClick={this.addExsistingBatch}>
                    <i className="fa fa-plus" aria-hidden="true"></i> Add an existing batch
                </Button>

                {
                    isAdmin && <Toggle defaultChecked={false}
                        checked={this.state.showAllBatches}
                        style={{marginLeft: 10}}
                        onChange={(e) =>{
                            this.setState({showAllBatches: !this.state.showAllBatches},  this.refresh);
                        }}
                    />
                }

                <span style={{marginLeft: 10}}> {sumAvailableThermo}&nbsp;
                    <img src={thermoIcon} height={30}/> available</span>
                <span style={{marginLeft: 10}}> {sumAvailableSensor}&nbsp;
                    <img src={co2SensorIcon} height={30}/> available</span>
                <p style={{width: 300, display: 'inline-block', marginLeft: 10, marginRight: 10}}>
                    <Form inline={true}>
                        <FormGroup inline={true}>
                            <Label for={db.TestBatch.NAME}>Status:&nbsp;</Label>
                            <Select
                                options={
                                    Object.keys(db.TestBatch)
                                        .filter(key =>  key.startsWith('STATUS$'))
                                        .map(key => db.TestBatch[key])
                                        .map(value => ({
                                            value: value,
                                            label: value
                                        }))
                                }
                                isClearable={true}
                                isMulti
                                value={this.state.selectedTestBatchStatuses.map(status => ({
                                    value: status,
                                    label: status
                                }))}
                                onChange={async items => {
                                    items = items == null ? [] : items;

                                    let selectedTestBatchStatuses = items.map(item => item.value);

                                    localStorage.setItem('selectedTestBatchStatuses', JSON.stringify(selectedTestBatchStatuses));

                                    this.setState({selectedTestBatchStatuses}, () => this.getBatches());
                                }}
                            />
                        </FormGroup>

                    </Form>

                </p>
                <p style={{width: 300, display: 'inline-block', marginLeft: 10, marginRight: 10}}>
                    <Form inline={true}>
                        <FormGroup inline={true} style={{marginLeft: 15}}>
                            <Label for={db.TestBatch.NAME}>Tag:&nbsp;</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        width: 200
                                    })
                                }}
                                options={
                                    Object.keys(db.TestBatch)
                                        .filter(key =>  key.startsWith('TAG$'))
                                        .map(key => db.TestBatch[key])
                                        .map(value => ({
                                            value: value,
                                            label: value
                                        }))
                                }
                                isClearable={true}
                                value={this.state.selectedTag}
                                onChange={async selectedTag => {
                                    this.setState({selectedTag}, () => this.getBatches());
                                }}
                            />
                        </FormGroup>
                    </Form>
                </p>

                {
                    isAdmin && <Form inline={true}  style={{marginLeft: 15}}>
                        <FormGroup inline={true}>
                            <Label for={db.TestBatch.NAME}>Testbatch ID:</Label>
                            <Input type="text"
                                   name={db.TestBatch.NAME}
                                   value={this.state.testBatchNameFilter}
                                   onChange={(e) => this.setState({testBatchNameFilter: e.target.value}, () => this.getBatches())}
                            />
                        </FormGroup>
                    </Form>
                }

                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Created at</th>
                            <th>Type</th>
                            <th>Model</th>
                            <th>Tags</th>
                            <th>Test mode</th>
                            <th>Number of devices</th>
                            <th>Visual test</th>
                            <th>NB-IOT test</th>
                            <th>Battery test</th>
                            <th>Motor test</th>
                            <th>Temp. calibration</th>
                            <th>Completed</th>
                            <th>Date completed</th>
                            <th>
                                Status <br/>
                                <Badge color={'success'}>no action required</Badge><br/>
                                <Badge color={'warning'}>action required</Badge><br/>
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.batches.map((batch, i) => {
                                let status = batch.get(db.TestBatch.STATUS);
                                let createdAt = batch.get(db.TestBatch.CREATED_AT);

                                createdAt = createdAt == null ? null : moment(createdAt);

                                let config = _.merge({}, batchStateConfig, {init: status, roles: this.props.roles, object: batch});
                                let sm = new StateMachine(config);
                                let transitions = sm.getTransitions();
                                let allTransitions = sm.getAllTransitions();
                                let color = 'success';
                                let type = batch.get(db.TestBatch.TYPE);
                                let numberOfThermo = batch.get(db.TestBatch.NUMBER_OF_THERM);
                                let numberOfSensp = batch.get(db.TestBatch.NUMBER_OF_SENSP);
                                let tag = batch.get(db.TestBatch.TAG);
                                let tags = batch.get(db.TestBatch.TAGS);
                                let typeIcon = {
                                    [db.TestBatch.TYPE$CLEVER_THERMO]: <img src={thermoIcon} height={30}/> ,
                                    [db.TestBatch.TYPE$CLEVER_SENSE_CO2]: <img src={co2SensorIcon} height={30}/>
                                };

                                if(batchStateConfig.batchRequireAction(status, this.props.roles)){
                                    color = 'warning';
                                }

                                let editAllowedStates = [
                                    db.TestBatch.STATUS$TO_TEST,
                                    db.TestBatch.STATUS$TESTED,
                                    db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION,
                                    db.TestBatch.STATUS$TO_TURN_ON,
                                    db.TestBatch.STATUS$READY_TO_CONFIG,
                                    db.TestBatch.STATUS$CONFIGURED,
                                    db.TestBatch.STATUS$NO_STATUS
                                ]

                                return <tr key={batch.id}>
                                    <td style={{maxWidth: 100}}>{batch.get(db.TestBatch.NAME)}</td>
                                    <td>{createdAt.format('DD/MM/YYYY HH:mm')}</td>
                                    <td>{typeIcon[type]}</td>
                                    <td>
                                        {
                                            tag != null &&  <Badge>
                                                {tag}
                                            </Badge>
                                        }
                                    </td>
                                    <td>
                                        {
                                            tags != null &&  tags.map((tag, i) => {
                                                return <>
                                                    <Badge color={'success'} key={i}>
                                                        <i className="fa fa-tag" aria-hidden="true"></i>&nbsp;{tag}
                                                    </Badge>&nbsp;
                                                </>
                                            })
                                        }
                                    </td>
                                     <td>
                                        <Toggle defaultChecked={false}
                                                checked={batch.get(db.TestBatch.TEST_MODE_ACTIVE) || false}
                                                onChange={
                                                    async (e) => await this.activeTestMode(batch, e.target.checked)
                                                }
                                        />
                                    </td>

                                    <td>
                                        {
                                            type === db.TestBatch.TYPE$CLEVER_THERMO && <span>{numberOfThermo}</span>
                                        }
                                        {
                                            type === db.TestBatch.TYPE$CLEVER_SENSE_CO2 && <span>{numberOfSensp}</span>
                                        }
                                    </td>
                                    <td>
                                        {
                                            testBatchNeedTest(batch, db.TestBatch.TESTS$VISUAL) && <Button
                                                outline color="primary"
                                                onClick={
                                                    () => window.open(paths.visualTest.replace(':id', batch.id))
                                                }>
                                                <i className="fa fa-eye"></i>
                                            </Button>
                                        }
                                    </td>
                                    <td>
                                        {
                                            testBatchNeedTest(batch, db.TestBatch.TESTS$CONNECTION) &&
                                            <Button outline color="primary"
                                                    onClick={
                                                        () => window.open(paths.connectionTest.replace(':id', batch.id))
                                                    }>
                                                <i className="fa fa-arrows-h"></i>
                                            </Button>

                                        }
                                    </td>
                                    <td>
                                        {
                                            testBatchNeedTest(batch, db.TestBatch.TESTS$BATTERY) && <Button
                                               outline color="primary"
                                               onClick={
                                                   () => window.open(
                                                       paths.batteryTest.replace(':id', batch.id))
                                               }>
                                                <i className="fa fa-battery-three-quarters"></i>
                                            </Button>
                                        }
                                    </td>
                                    <td>
                                        {
                                            testBatchNeedTest(batch, db.TestBatch.TESTS$MOTOR) && <Button
                                                outline color="primary"
                                                onClick={
                                                    () => window.open(paths.motorTest.replace(':id', batch.id))
                                                }>
                                                <i className="fa fa-cog"></i>
                                            </Button>
                                        }
                                    </td>
                                    <td>
                                        {
                                            testBatchNeedTest(batch, db.TestBatch.TESTS$TEMPERATURE) && <Button
                                                outline color="primary"
                                                onClick={
                                                    () => window.open(
                                                        paths.temperatureCalibration.replace(':id', batch.id))
                                                }>
                                                <i className="fa fa-thermometer-three-quarters"></i>
                                            </Button>
                                        }
                                    </td>
                                    <td>
                                        {batch.get(db.TestBatch.COMPLETED) ?
                                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                                            <i className="fa fa-times" style={{color: 'red'}}></i>}
                                    </td>
                                    <td>
                                        {batch.get(db.TestBatch.DATE_COMPLETED) != null ?
                                            moment(batch.get(db.TestBatch.DATE_COMPLETED)).format('DD/MM/YYYY') : '-'}
                                    </td>
                                    <td>
                                        <Badge color={color}>{status}</Badge>
                                    </td>
                                    <td>
                                        <Button outline color="primary"
                                            onClick={
                                                () => window.open(paths.singleBatchView.replace(':id', batch.id), '_blank')
                                            }>
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                        {
                                            (editAllowedStates.indexOf(status) >= 0 || this.props.roles.indexOf('Admin') >= 0) &&
                                            <a href={paths.singleBatchEdit.replace(':id', batch.id)} target="_blank" rel="noopener noreferrer">
                                                <Button
                                                    outline
                                                    color="primary">
                                                    <i className="fa fa-edit"></i>
                                                </Button>
                                            </a>
                                        }
                                        {
                                            (!batch.get(db.TestBatch.COMPLETED) || this.props.roles.indexOf('Admin') >= 0) && <Button
                                                outline
                                                color="danger"
                                                onClick={async () => await this.deleteBatch(batch)}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        }
                                        {
                                            (this.props.roles.indexOf('Admin') >= 0 ||
                                                batch.get(db.TestBatch.STATUS) === 'to-test' ||
                                                batch.get(db.TestBatch.STATUS) === 'to-turn-on') && <ButtonDropdown
                                                isOpen={this.state.statusButtonOpen[i]} toggle={() =>
                                                    this.setState(prev => {
                                                        prev.statusButtonOpen[i] = !prev.statusButtonOpen[i];

                                                        return prev;
                                                    })}>
                                                <DropdownToggle caret color="primary">
                                                    Update status
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem header>Select transition</DropdownItem>
                                                    {

                                                        transitions.length !== 0 && transitions.map((transition, i) => {
                                                            return <DropdownItem
                                                                key={i}
                                                                onClick={() => this.changeBatchStatus(batch, sm, transition.to, false)}>
                                                                {transition.name}
                                                            </DropdownItem>
                                                        })
                                                    }
                                                    {this.props.roles.indexOf('Admin') >= 0 && <DropdownItem header>All allowed transition</DropdownItem>}
                                                    {

                                                        this.props.roles.indexOf('Admin') >= 0 &&
                                                        allTransitions.length !== 0 &&
                                                        allTransitions.map((transition, i) => {
                                                            return <DropdownItem
                                                                key={i}
                                                                onClick={() => this.changeBatchStatus(batch, sm, transition.to, true)}>
                                                                {transition.name}
                                                            </DropdownItem>
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        }
                                    </td>
                                </tr>;
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

PageBatch.propTypes = {
    history: PropTypes.any,
    roles: PropTypes.array
};