import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import config from '../../../config/app';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import swal from 'sweetalert';
import Parse from '../../../lib/parse';
import {validate} from 'uuid';
import {copyACLToObject, moveDeviceToRoom, toPointerFromId} from '../../../lib/util';
import Select from 'react-select';
import _ from 'lodash';

export default class AddNewRoomModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            roomId: '',
            deviceId: '',
            activateDevices: false,
            devices: [],
            roomVerified: false,
            descriptionComment: '',
            roomTypes: [],
            roomSize: {
                value: 'normal',
                label: 'Normal'
            },
            roomType: {
                value: 'classroom',
                label: 'Classroom'
            },
            radiatorsSize: {
                value: 'normal',
                label: 'Normal'
            }
        };

        this.toggle = this.toggle.bind(this);
        this.acceptRoomInstallationId = this.acceptRoomInstallationId.bind(this);
        this.addRoomToHome = this.addRoomToHome.bind(this);
        this.reset = this.reset.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.loadRoomTypes = this.loadRoomTypes.bind(this);

        this.deviceInput = React.createRef();
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggle);
    }

    toggle(){
        let nextStatus = !this.state.isOpen;
        this.setState({isOpen: nextStatus});
        if(!nextStatus) this.reset();
    }

    async onOpened(){
        setTimeout(() => {
            $('#room-id').focus();
        }, 100);

        const roomTypes = await this.loadRoomTypes();

        this.setState({roomTypes});
    }

    async loadRoomTypes(){
        let roomTypes = await new Parse.Query(db.classes.RoomType)
            .find();

        return roomTypes;
    }

    async acceptRoomInstallationId(event){
        if(event.key === 'Enter'){
            event.preventDefault();

            if(validate(this.state.roomId)){

            } else {
                let response = await swal({
                    title: 'Warning!',
                    text: `Missing room id or format is wrong. Continue?`,
                    buttons: ['Cancel', 'Yes'],
                    dangerMode: true
                });

                if (!response)
                    return swal({title: 'Action aborted by user', text: ' ', icon: 'info',
                        button: [''], timer: 1000});
            }

            if(this.state.roomId != null && this.state.roomId !== ''){
                let query = new Parse.Query(db.classes.Room);
                query.equalTo(db.Room.UNIQUE_ID, this.state.roomId);
                query.include(db.Room.HOME);

                let room = await query.first();

                if(room){
                    let roomName = room.get(db.Room.ROOM_NAME);
                    let roomHomeName = room.get(db.Room.HOME) != null ?
                        room.get(db.Room.HOME).get(db.Home.HOME_NAME) : null;
                    this.setState({roomId: ''});

                    // eslint-disable-next-line max-len
                    return swal({title: `Room id has already be used on room "${roomName}" on building "${roomHomeName}". Use another code.`, text: ' ', icon: 'error',
                        button: [''], timer: 1000});
                }
            }

            this.setState({activateDevices: true, roomVerified: true});

            setTimeout(() => {
                $('#devices').focus();
            }, 10);
        }
    }

    async addDevice(id){
        let isMac = id.split(':').length === 6;

        try {

            if(isMac){
                id = id.toUpperCase();
            }
            else {
                if(id.length !== 7) return swal({title: 'Not a valid serial number', text: ' ', icon: 'error',
                    button: [''], timer: 1000});

                id = parseInt(id);
            }

            if(isMac){
                if(this.state.devices.map(device => device.get(db.Device.MAC_ADDRESS)).indexOf(id) >= 0){
                    throw new Error('Duplicate device');
                }
            }else {
                if (this.state.devices.map(device => device.get(db.Device.SERIAL_NUMBER)).indexOf(id) >= 0) {
                    throw new Error('Duplicate device');
                }
            }

            let query =  (new Parse.Query(db.classes.Device))
                .include(db.Device.ROOM_ID)
                .include(db.Device.HOME);

            if(isMac)
                query.equalTo(db.Device.MAC_ADDRESS, id);
            else
                query.equalTo(db.Device.SERIAL_NUMBER, id);

            let device = await query.first();

            if (!device) {
                throw new Error('No device found with this serial');
            }

            this.setState(prev => {
                prev.devices.push(device);
                prev.deviceId = '';

                return prev;
            });
        } catch (e) {
            console.error(e.message);
            swal({title: e.message, text: ' ', icon: 'error', button: [''], timer: 1000});
        }
    }

    reset(){
        this.setState({
            isOpen: false,
            roomId: '',
            deviceId: '',
            activateDevices: false,
            devices: [],
            roomVerified: false
        });
    }

    async addRoomToHome(e){
        e.preventDefault();

        if(
            !this.state.roomFloor ||
            !this.state.roomSize ||
            !this.state.roomType
        )
            return swal('Error', 'Please fill all required fields.', 'error');

        if(!this.state.roomId && !this.state.roomName)
            return swal('Error', 'As the room id has not be provided please fill the room name field.', 'error');

        try {
            let homeId = this.props.homeId;

            let roomUniqueIdShort = null;

            if(this.state.roomId)
                roomUniqueIdShort = this.state.roomId.substr(this.state.roomId.length - 6);

            let home = await (new Parse.Query(db.classes.Home)).get(homeId);

            let room = new Parse.Object(db.classes.Room);

            room.set(db.Room.UNIQUE_ID, this.state.roomId);

            if(this.state.roomName == null){
                if(this.state.roomCode != null && this.state.roomCode !== ''){
                    room.set(db.Room.ROOM_NAME, `${roomUniqueIdShort} ${this.state.roomCode}`);
                } else {
                    room.set(db.Room.ROOM_NAME, roomUniqueIdShort);
                }
            } else {
                room.set(db.Room.ROOM_NAME, this.state.roomName);
            }

            room.set(db.Room.ROOM_CODE, this.state.roomCode);
            room.set(db.Room.HOME, toPointerFromId(homeId, db.classes.Home));
            room.set(db.Room.FLOOR, parseInt(this.state.roomFloor));
            room.set(db.Room.ROOM_SIZE, this.state.roomSize.value);
            room.set(db.Room.ROOM_TYPE, this.state.roomType.value);
            room.set(db.Room.TEMP_MIN, config.leanManagment.DEFAULT_NEW_ROOM_MIN_TEMP);
            room.set(db.Room.TEMP_MAX, config.leanManagment.DEFAULT_NEW_ROOM_MAX_TEMP);
            room.set(db.Room.DESCRIPTION_COMMENT, this.state.descriptionComment);
            room.set(db.Room.RADIATORS_SIZE, this.state.radiatorsSize.value);
            room.set(db.Room.INSTALLATION_TEMPERATURE, parseFloat(this.state.installationTemperature));
            room.set(db.Room.INSTALLATION_SENSOR_POSITION, this.state.sensorPosition?.value);

            copyACLToObject(home, room);

            room = await room.save();

            let devices = [];
            for(let device of this.state.devices){

                let alreadyPresentRoom = device.get(db.Device.ROOM_ID);
                let serial = device.get(db.Device.SERIAL_NUMBER);
                let home = device.get(db.Device.HOME);
                let homeName = home ? home.get(db.Home.HOME_NAME) : null;

                if(alreadyPresentRoom){
                    let result = await swal({
                        title: 'Are you sure?',
                        text: `The device ${serial} is already linked with room ${alreadyPresentRoom.get(db.Room.ROOM_NAME)} on building ${homeName}. Do you want to move to the current room?`,
                        buttons: ['Abort', 'Confirm'],
                    });

                    if(!result) throw new Error('Action aborted by user');
                }

                devices.push(moveDeviceToRoom(device, room));
            }

            await Parse.Object.saveAll(devices);

            await swal({title: 'Room installation saved', text: ' ', icon: 'success', button: [''], timer: 1000});

            this.reset();
        } catch (e) {
            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000});
        }
    }

    render(){
        return <div>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle} size={'lg'}
                onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Add new room</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="room-id">Enter room id (or scan)</Label>
                            <Input type="text" id="room-id" value={this.state.roomId}
                                placeholder="Ex. 484f03b5-a113-4ff7-a287-c9634a95860e"
                                onKeyPress={this.acceptRoomInstallationId}
                                onChange={(e) => {
                                    e.preventDefault();
                                    let value = e.target.value;

                                    if(value.startsWith('https://room.cleveron.ch/room/')){
                                        this.setState({roomId: value.replace('https://room.cleveron.ch/room/', '')});
                                        return;
                                    }

                                    this.setState({roomId: value});
                                }}/>
                        </FormGroup>

                        {
                            this.state.roomVerified && <Fragment>
                                <FormGroup>
                                    <Label for="roomCode">Room name</Label>
                                    <Input type="text" id="roomName" value={this.state.roomName}
                                        placeholder="Ex. Meeting room"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            let value = e.target.value;

                                            this.setState({roomName: value});
                                        }}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="roomCode">Room code (if available)</Label>
                                    <Input type="text" id="roomCode" value={this.state.roomCode}
                                        placeholder="Ex. 100.4"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            let value = e.target.value;

                                            this.setState({roomCode: value});
                                        }}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Room size*</Label>
                                    <Select
                                        value={this.state.roomSize}
                                        onChange={(roomSize) => this.setState({roomSize})}
                                        options={[
                                            {
                                                value: 'small',
                                                label: 'Small'
                                            },
                                            {
                                                value: 'normal',
                                                label: 'Normal'
                                            },
                                            {
                                                value: 'big',
                                                label: 'Big'
                                            }
                                        ]}
                                        isMulti={false}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Label>Room type*</Label>
                                    <Select
                                        value={this.state.roomType}
                                        onChange={roomType => this.setState({roomType})}
                                        options={this.state.roomTypes.map(roomType => ({value: roomType.get(db.RoomType.NAME), label: _.capitalize(roomType.get(db.RoomType.NAME))}))}
                                        isMulti={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Radiators size*</Label>
                                    <Select
                                        value={this.state.radiatorsSize}
                                        onChange={radiatorsSize => this.setState({radiatorsSize})}
                                        options={[
                                            {
                                                value: 'very-small',
                                                label: 'Very small (undersized)'
                                            },
                                            {
                                                value: 'small',
                                                label: 'Small (undersized)'
                                            },
                                            {
                                                value: 'normal',
                                                label: 'Normal'
                                            },
                                            {
                                                value: 'big',
                                                label: 'Big (oversized)'
                                            }
                                        ]}
                                        isMulti={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="floor">Room floor*</Label>
                                    <Input type="text" id="floor" value={this.state.roomFloor}
                                        placeholder="Ex. 1"
                                        onKeyPress={this.acceptRoomInstallationId}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            let value = e.target.value;

                                            this.setState({roomFloor: value});
                                        }}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Sensor position</Label>
                                    <Select
                                        value={this.state.sensorPosition}
                                        onChange={sensorPosition => this.setState({sensorPosition})}
                                        options={[
                                            {
                                                value: 'wall',
                                                label: 'Wall (vertical)'
                                            },
                                            {
                                                value: 'ceiling',
                                                label: 'Ceiling (horizontal)'
                                            },
                                            {
                                                value: 'desk',
                                                label: 'Desk (horizontal)'
                                            }
                                        ]}
                                        isMulti={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="installation-temperature">Room temperature</Label>
                                    <Input type="number" id={'installation-temperature'} value={this.state.installationTemperature}
                                        placeholder="Ex. 23"
                                        onKeyPress={this.acceptRoomInstallationId}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            let value = e.target.value;

                                            this.setState({installationTemperature: value});
                                        }}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="descriptionComment">Description comment (optional)</Label>
                                    <small>Is there andything special in this room? eg. Big windows, partial floor heating, special position of the radiators?</small>
                                    <Input type="textarea" id="descriptionComment" value={this.state.descriptionComment}
                                        placeholder="Ex. 1"
                                        onKeyPress={this.acceptRoomInstallationId}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            let value = e.target.value;

                                            this.setState({descriptionComment: value});
                                        }}/>
                                </FormGroup>
                            </Fragment>
                        }
                        {
                            this.state.activateDevices &&
                            this.state.roomSize != null &&
                            this.state.roomType != null &&
                            this.state.roomFloor != null && <FormGroup>
                                <Label for="devices">Enter device id* (or scan)</Label>
                                <Input type="string"
                                    id="devices"
                                    value={this.state.deviceId}
                                    ref={this.deviceInput}
                                    onKeyPress={async event => {
                                        if(event.key === 'Enter'){
                                            event.preventDefault();
                                            if(this.state.deviceId === '') return;

                                            await this.addDevice(this.state.deviceId);

                                            this.setState({deviceId: ''})
                                        }
                                    }}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let value = e.target.value.toLowerCase();

                                        if(value.startsWith('https://devices.cleveron.ch/device/')){
                                            value = value.replace('https://devices.cleveron.ch/device/', '')
                                            value = value.toUpperCase();
                                        }

                                        this.setState({deviceId: value});
                                    }}/>
                            </FormGroup>
                        }
                    </Form>
                    <ul>
                        {
                            this.state.devices.map(device => {
                                let serial = device.get(db.Device.SERIAL_NUMBER);
                                let room = device.get(db.Device.ROOM_ID);
                                let home = device.get(db.Device.HOME);

                                return <li key={device.id}>{serial} currently on room {room && room.get(db.Room.ROOM_NAME)} ({home && home.get(db.Home.HOME_NAME)})
                                    <span style={{marginLeft: 10}}>
                                        <Button outline color={'danger'} onClick={() => {
                                            this.setState(prev => {
                                                let index = prev.devices.indexOf(device);
                                                prev.devices.splice(index, 1);

                                                return prev;
                                            })
                                        }}><i className="fa fa-trash"></i></Button>
                                    </span>
                                </li>
                            })
                        }
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={this.addRoomToHome}>
                        Add room with selected devices
                    </Button>{' '}
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    }
}

AddNewRoomModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func,
    homeId: PropTypes.string.isRequired,
    afterSave: PropTypes.func
};