import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row, Table} from 'reactstrap';
import Parse from '../../../lib/parse';
import db from '../../../config/dbStructure';
import {extractFieldFromOperationTask, isRolesInRoles} from '../../../lib/util';
import config from '../../../config/app';
import paths from '../../paths';
import moment from 'moment';
import AddNewMaintenanceTask from './add-new-maintenance-task';
import swal from 'sweetalert';
import Loader from '../loader';
import Toggle from 'react-toggle';


export default class PageMaintenanceTasks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            operationTasks: [],
            showAll: false,
            loading: {}
        };

        this.loadOperationTasks = this.loadOperationTasks.bind(this);

        this.toggleNewMaintenanceTaskModal = null;
    }

    async componentDidMount(){
        try {
            await this.loadOperationTasks();
        } catch (e) {
            console.error(e);
        }
    }

    async loadOperationTasks(){
        this.setState({loading: {operationTasks: true}});

        let user =  Parse.User.current();

        let query = new Parse.Query(db.classes.OperationTask);
        query.include(db.OperationTask.HOME);
        query.notEqualTo(db.OperationTask.DELETED, true);

        if(!isRolesInRoles(this.props.roles, ['Admin', 'Support']))
            query.equalTo(db.OperationTask.INSTALLER, Parse.User.current());

        if(!this.state.showAll)
            query.notEqualTo(db.OperationTask.COMPLETED, true);

        query.descending(db.OperationTask.CREATED_AT);
        query.limit(200);

        let operationTasks = await query.find();

        this.setState({operationTasks, loading: {operationTasks: false}});
    }

    render() {
        if(this.state.loading.operationTasks) return <Loader/>;


        var showOperationTaskRow = (operationTask, i) => {
            let {
                home,
                operationId,
                referenceNumber,
                subject,
                type,
                status,
                completed,
                requiredTherm,
                requiredSense,
                installer,
                installationPeriod,
                actualTherm,
                actualSense,
                crmOperationId,
                operationName,
                operationRefNumber
            } = extractFieldFromOperationTask(operationTask);
            let homeName = home && home.get(db.Home.HOME_NAME);
            let homeCity = home && home.get(db.Home.CITY);
            let homeId = home && home.id;
            let installerName = installer && installer.get(db._User.USERNAME);

            return <tr key={operationTask.id}>
                <td>
                    {operationName}
                </td>
                <td>
                    {operationRefNumber}
                </td>
                <td>
                    <a href={`${config.adminDashboard.host}/homes/${homeId}/rooms`}>
                        {homeName}({homeCity})
                    </a>
                </td>
                <td>
                    {installerName}
                </td>
                <td>
                    {installationPeriod}
                </td>
                <td>
                    {
                        operationTask.get(db.OperationTask.COMPLETED) ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    {
                        operationTask.get(db.OperationTask.DATE_COMPLETED) && <small>
                            ({moment(operationTask.get(db.OperationTask.DATE_COMPLETED))
                            .format('DD/MM/YYYY')})
                        </small>
                    }
                </td>
                <td>
                    {
                        ((!operationTask.get(db.TestBatch.COMPLETED) &&
                            isRolesInRoles(this.props.roles, ['Installer'])) ||
                            isRolesInRoles(this.props.roles, ['Admin'])) &&
                        <Button
                            outline
                            color="primary"
                            style={{margin: 0}}
                            onClick={
                                () =>
                                window.open(paths.singleMaintenance.replace(':id', operationTask.id), '_blank')
                            }>
                            <i className="fa fa-wrench"></i>
                        </Button>
                    }
                    {
                        isRolesInRoles(this.props.roles, ['Admin']) &&
                        <Button
                            outline
                            color="primary"
                            style={{margin: 0}}
                            onClick={
                                () =>
                                    window.open(paths.maintenanceTaskReport.replace(':id', operationTask.id), '_blank')
                            }>
                            <i className="fa fa-list-alt" aria-hidden="true"></i>
                        </Button>
                    }
                    {
                        (isRolesInRoles(this.props.roles, [ 'Admin'])) &&
                        <Button
                            outline
                            color="danger"
                            onClick={async () => {
                                try {
                                    operationTask.set(db.OperationTask.DELETED, true);
                                    await operationTask.save();

                                    await this.loadOperationTasks();

                                    await swal({
                                        title: 'Success!',
                                        text: ' ',
                                        icon: 'success',
                                        button: [''],
                                        timer: 1000
                                    });
                                } catch (e) {
                                    console.error(e);
                                    swal('Error', e.message, 'error');
                                }
                            }}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    }
                </td>
            </tr>
        };

        return (
            <Fragment>
                <Row>
                    <Col md={12}>
                        <AddNewMaintenanceTask
                            setToggleModal={toggle => this.toggleNewMaintenanceTaskModal = toggle}
                            submit={async (fields) => {
                                await this.loadOperationTasks();
                            }}
                        />;

                    </Col>

                </Row>
                <h2>
                    Installations&nbsp;
                    {
                        isRolesInRoles(this.props.roles, ['Admin', 'Support']) && <Fragment>
                            <Button outline onClick={() => this.toggleNewMaintenanceTaskModal()}>New tasks</Button>&nbsp;
                        </Fragment>
                    }
                    {
                        isRolesInRoles(this.props.roles, ['Admin', 'Installer']) && <Fragment>
                            <Button outline onClick={this.loadOperationTasks}><i className="fa fa-refresh"
                                aria-hidden="true"></i> Refresh</Button>&nbsp;
                        </Fragment>
                    }
                    <small>Show all:</small> <Toggle
                        checked={this.state.showAll}
                        onChange={() =>{
                            this.setState({showAll: !this.state.showAll},  this.loadOperationTasks);
                        }}
                    />
                </h2>
                <Row>
                    <Col md={12}>
                        <Table>
                            <thead>
                            <tr>
                                <th>Operation name</th>
                                <th>Operation ref.</th>
                                <th>Building name</th>
                                <th>Responsible</th>
                                <th>Period</th>
                                <th>Completed</th>
                                <th style={{minWidth: 151}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.operationTasks
                                    .filter(task => task.get(db.OperationTask.TYPE) === db.OperationTask.TYPE$INSTALLATION)
                                    .map(operationTask => showOperationTaskRow(operationTask))
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <h2>Maintenances</h2>
                <Row>
                    <Col md={12}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Operation name</th>
                                    <th>Operation ref.</th>
                                    <th>Building name</th>
                                    <th>Responsible</th>
                                    <th>Period</th>
                                    <th>Completed</th>
                                    <th style={{minWidth: 151}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.operationTasks
                                        .filter(task => task.get(db.OperationTask.TYPE) === db.OperationTask.TYPE$MAINTENANCE)
                                        .map(operationTask => showOperationTaskRow(operationTask))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

PageMaintenanceTasks.propTypes = {
    history: PropTypes.any,
    roles: PropTypes.array.isRequired
};