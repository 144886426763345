import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Breadcrumb, BreadcrumbItem, Button, Col, Row} from 'reactstrap';
import swal from 'sweetalert';
import assert from 'assert';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import paths from '../../paths';
import * as _ from 'lodash';
import Toggle from 'react-toggle';
import moment from 'moment';
import ReviewModal from './review-modal';
import AddNewRoomModal from './add-new-room';
import {isRolesInRoles, moveDeviceToRoom, toPointerFromId} from '../../../lib/util';
import {renderRoomsV2} from './render-room-list-v2';
import Loader from '../loader';
import {checkBatteryDevice, checkConnection, checkWifiDb} from './utils';

const downloadxls = (data, filename) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${filename}.xlsx`);
}

const floorArray = ['-3','-2', '-1', '0', '1', '2', '3', '4', '5', '6', '7', '8'];

export default class PageInstallation extends Component {
    constructor(props) {
        super(props);

        let selectedFloors = JSON.parse(localStorage.getItem(`${this.props.match.params.id}.selectedFloors`));
        let newVisualization = localStorage.getItem('maintenanceTaskNewVisualization') ?
            localStorage.getItem('maintenanceTaskNewVisualization') === 'true' :
            true;

        this.state = {
            home: null,
            rooms: [],
            roomMap: {},
            groupRoomsDevices: {},
            devicesCheckObject: {},
            installationCheckPassed: false,
            checked: selectedFloors || {
                '-3': true,
                '-2': true,
                '-1': true,
                '0': true,
                '1': true,
                '2': true,
                '3': true,
                '4': true,
                '5': true,
                '6': true
            },
            hideCompletedRooms: false,
            loading: {},
            newVisualization
        };

        this.loadRooms = this.loadRooms.bind(this);
        this.loadDevices = this.loadDevices.bind(this);
        this.getCheckInstallationObject = this.getCheckInstallationObject.bind(this);
        this.completeBuildingInstallation = this.completeBuildingInstallation.bind(this);
        this.refreshInstallationRooms = this.refreshInstallationRooms.bind(this);
        this.checkInstallation = this.checkInstallation.bind(this);
        this.addDevice = this.addDevice.bind(this);
        this.checkRoom = this.checkRoom.bind(this);
        this.allRoomsAreCompleted = this.allRoomsAreCompleted.bind(this);
        this.completeRoom = this.completeRoom.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
        this.changeDeviceFlag = this.changeDeviceFlag.bind(this);
        this.deleteRoom = this.deleteRoom.bind(this);
        this.uncompleteOperation = this.uncompleteOperation.bind(this);

        this.toggleReviewModal = null;
        this.toggleAddNewRoomModal = null;
        this.toggleAddPhotoModal = null;
    }

    async componentDidMount() {
        await this.refreshInstallationRooms(true);

        $(document).ready(function () {
            $('.sticky-nav').sticky({topSpacing: 0, zIndex: 10});
        });
    }
    async loadHome() {
        let query = new Parse.Query(db.classes.OperationTask);
        query.include(db.OperationTask.HOME);

        let installation = await query.get(this.props.match.params.id);

        this.setState({
            installation: installation,
            home: installation.get(db.OperationTask.HOME)
        });

        return {
            installation,
            home: installation.get(db.OperationTask.HOME)
        }
    }
    async loadRooms(home) {
        let selectedFloors = floorArray
            .map(floor => this.state.checked[floor] === true ? floor : null)
            .filter(checked => !!checked);

        this.setState({loading: {rooms: true}});

        let query = new Parse.Query(db.classes.Room);
        query.equalTo(db.Room.HOME, toPointerFromId(home.id, db.classes.Home));
        query.notEqualTo(db.Room.DELETED, true);
        query.containedIn(db.Room.FLOOR, selectedFloors.map(floor => parseInt(floor)));
        if(this.state.hideCompletedRooms)
            query.notEqualTo(db.Room.INSTALLATION_COMPLETED, true);

        let rooms = await query.find();

        const roomMap = {};
        rooms.forEach(room => roomMap[room.id] = room);

        this.toggleAddPhotoModal = (new Array(rooms.length)).fill(null);

        this.setState({rooms, roomMap, loading: {rooms: false}});

        return rooms;
    }
    async loadDevices(rooms) {
        const query = new Parse.Query(db.classes.Device);
        query.containedIn(db.Device.ROOM_ID,
            rooms.map(room => toPointerFromId(room.id, db.classes.Room))
        );
        query.select([
            db.Device.LAST_MEASUREMENT_DATE,
            db.Device.SERIAL_NUMBER,
            db.Device.BATTERY_VOLTAGE,
            db.Device.WIFI_STRENGTH,
            db.Device.CONNECTION_WIFI_SSID,
            db.Device.CONNECTION_WIFI_PASSWORD,
            db.Device.CONFIG_WIFI_SSID,
            db.Device.CONFIG_WIFI_PASSWORD,
            db.Device.DEVICE_STATE_FLAG,
            db.Device.ROOM_ID,
            db.Device.HOME,
            db.Device.OWNER,
            db.Device.DEVICE_TYP,
            db.Device.MAC_ADDRESS
        ]);
        query.limit(5000);
        const devices = await query.find();

        const groupRoomsDevices = _.groupBy(devices, device => device.get(db.Device.ROOM_ID).id);

        this.setState({groupRoomsDevices});
    }
    async getOperationTask(){
        let operationTask = await query.get(this.props.match.params.id);

        return operationTask;
    }
    async completeBuildingInstallation() {
        try {
            let checkObject = this.getCheckInstallationObject();

            checkObject.dateChecked = checkObject.dateChecked.format('DD/MM/YYYY HH:MM:SS');
            checkObject.rooms = checkObject.rooms.map(checkObjectRoom => {
                return {
                    room: checkObjectRoom.room.id,
                    devices: checkObjectRoom.devices.map(deviceObject => {
                        deviceObject.device = deviceObject.device.id;

                        return deviceObject;
                    })
                }
            });

            if (!this.allRoomsAreCompleted()) {
                throw new Error('Some rooms have not be completed yet.');
            }

            this.state.installation.set(db.OperationTask.INSTALLATION_DATA, checkObject);
            this.state.installation.set(db.OperationTask.COMPLETED, true);
            this.state.installation.set(db.OperationTask.DATE_COMPLETED, new Date());

            await this.state.installation.save();

            await swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 500});
        } catch (e) {
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }
    async uncompleteOperation(){
        try{
            this.state.installation.unset(db.OperationTask.INSTALLATION_DATA);
            this.state.installation.unset(db.OperationTask.COMPLETED);
            this.state.installation.unset(db.OperationTask.DATE_COMPLETED);

            await this.state.installation.save();
            await swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 500});
        } catch (e) {
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }
    checkRoom(room) {
        if (!this.state.groupRoomsDevices[room.id]) return true;

        let unpassedChecks = this.state.groupRoomsDevices[room.id]
            .map(device => {
                let checks = {
                    device: device,
                    battery: checkBatteryDevice(device),
                    batteryVoltage: device.get(db.Device.BATTERY_VOLTAGE),
                    wifiDb: checkWifiDb(device),
                    wifiStrength: device.get(db.Device.WIFI_STRENGTH),
                    connection: checkConnection(device),
                    lastConnection: device.get(db.Device.LAST_MEASUREMENT_DATE) &&
                        moment(device.get(db.Device.LAST_MEASUREMENT_DATE)).format('DD/MM/YYYY HH:MM:SS'),
                    checkPhoto: room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url()
                };

                return !checks.battery || !checks.wifiDb || !checks.connection || !checks.checkPhoto;
            })
            .filter(check => check);

        return unpassedChecks.length === 0;
    }
    getCheckInstallationObject() {
        let checkObject = {
            dateChecked: moment()
        };

        let rooms = Object.keys(this.state.groupRoomsDevices);

        let roomsObject = rooms.map((roomId) => {
            let roomCheckObject = {
                room: this.state.roomMap[roomId]
            };

            let devices = this.state.groupRoomsDevices[roomId].map(device => {
                return {
                    device: device,
                    battery: checkBatteryDevice(device),
                    batteryVoltage: device.get(db.Device.BATTERY_VOLTAGE),
                    wifiDb: checkWifiDb(device),
                    wifiStrength: device.get(db.Device.WIFI_STRENGTH),
                    connection: checkConnection(device),
                    lastConnection: device.get(db.Device.LAST_MEASUREMENT_DATE) &&
                        moment(device.get(db.Device.LAST_MEASUREMENT_DATE)).format('DD/MM/YYYY HH:MM:SS')
                }
            });

            roomCheckObject.devices = devices;

            return roomCheckObject;
        });

        checkObject.rooms = roomsObject;
        checkObject.home = this.state.home.id;

        return checkObject;
    }
    checkInstallation(callback) {
        let checkObject = this.getCheckInstallationObject();

        let roomsNotReady = checkObject.rooms.map(roomObject => {
            let room = roomObject.room;
            let devices = roomObject.devices.filter(device => !device.battery || !device.wifiDb || !device.connection);
            if (devices.length > 0)
                return room;
        }).filter(room => !!room);

        if (roomsNotReady.length === 0) {
            swal('All room are installed correctly', ' ', 'success');

            this.setState({installationCheckPassed: true, checkObject});
            return;
        }

        this.setState({checkObject}, callback);
    }
    getReportInstallation() {

    }
    async refreshInstallationRooms(isAtLoading) {
        try {
            let result = await this.loadHome();
            let rooms = await this.loadRooms(result.home);
            await this.loadDevices(rooms);
            let operationTask = await this.getOperationTask();

            this.setState({operationTask});

            swal({title: 'Refresh done!', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e) {
            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000});
        }
    }
    async addDevice(id, room) {
        let isMac = id.split(':').length === 6;

        try {

            if (isMac) {
                id = id.toUpperCase();
            } else {
                if (id.length !== 7) return swal({
                    title: 'Not a valid serial number', text: ' ', icon: 'error',
                    button: [''], timer: 1000
                });

                id = parseInt(id);
            }

            let home = room.get(db.Room.HOME);

            if (!home) {
                throw new Error('No home defined');
            }

            let query = (new Parse.Query(db.classes.Device))
                .include(db.Device.ROOM_ID)
                .include(db.Device.HOME);

            if (isMac)
                query.equalTo(db.Device.MAC_ADDRESS, id);
            else
                query.equalTo(db.Device.SERIAL_NUMBER, id);

            let device = await query.first();

            if (!device) {
                throw new Error('No device found with this serial');
            }


            let alreadyPresentRoom = device.get(db.Device.ROOM_ID);
            let serial = device.get(db.Device.SERIAL_NUMBER);

            if (alreadyPresentRoom) {
                let result = await swal({
                    title: 'Are you sure?',
                    text: `The device ${serial} is already linked with room ${alreadyPresentRoom.get(db.Room.ROOM_NAME)}. Do you want to move to the current room?`,
                    buttons: ['Abort', 'Confirm'],
                });

                if (!result) throw new Error('Action aborted by user');
            }

            moveDeviceToRoom(device, room);

            await device.save();

            this.refreshInstallationRooms();

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal({title: e.message, text: ' ', icon: 'error', button: [''], timer: 1000});
        }
    }
    allRoomsAreCompleted() {
        let uncompletedRooms = this.state.rooms
            .filter(room => !room.get(db.Room.INSTALLATION_COMPLETED));

        return uncompletedRooms.length <= 0;
    }
    async completeRoom(e, room) {
        try {
            e.preventDefault();

            let roomOk = this.checkRoom(room);
            let text = null;

            if (!roomOk) {
                // eslint-disable-next-line max-len
                text = await swal('Devices checks are not completed or the photo is not uploaded. Add a comment if you want to complete the room anyways', {
                    content: 'input',
                    buttons: ['Cancel', 'Ok']
                });

                if (!text || text === '') {
                    return swal({title: 'Not saved', text: ' ', icon: 'warning', button: [''], timer: 500});
                }
            }

            room.set(db.Room.INSTALLATION_COMPLETED, true);
            room.set(db.Room.INSTALLATION_COMMENT, text);
            room.set(db.Room.INSTALLATION_COMPLETED_DATE, new Date());

            await room.save();

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            this.refreshInstallationRooms();
        } catch (e) {
            console.error(e);
            return swal('Error', e.message, 'error');
        }
    }
    async deleteDevice(e, device) {
        e.preventDefault();

        try {
            assert(device != null, 'No device selected');

            let serial = device.get(db.Device.SERIAL_NUMBER);
            let result = await swal({
                title: 'Are you sure?',
                text: `Do you really want to delete the device ${serial} from this room?`,
                buttons: ['Abort', 'Confirm'],
            });

            if (!result) throw new Error('Action aborted by user');

            let rooms = this.state.rooms;

            let toConfigureRooms = rooms.filter(room => room.get(db.Room.ROOM_NAME) === 'to-configure');

            if(toConfigureRooms.length !== 1) throw Error('No room "to-configure found on this installation."');

            let toConfigureRoom = toConfigureRooms[0];

            moveDeviceToRoom(device, toConfigureRoom);

            await device.save();

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.refreshInstallationRooms();
        } catch (e) {
            return await swal('Error', e.message, 'error');
        }
    }
    async changeDeviceFlag(e, device) {
        e.preventDefault();

        let flag = device.get(db.Device.DEVICE_STATE_FLAG);
        let newFlag = null;

        if (flag === 'in-mount') {
            newFlag = 'online';
        }

        if (flag !== 'in-mount') {
            newFlag = 'in-mount';
        }

        if (!flag) //So it was online by default
        {
            newFlag = 'in-mount';
        }

        device.set(db.Device.DEVICE_STATE_FLAG, newFlag);

        device = await device.save();

        swal({title: 'Success', text: `Mode changed to ${newFlag}`, icon: 'success', button: [''], timer: 1000});

        await this.refreshInstallationRooms();
    }
    async deleteRoom(room){
        let roomId = room.id;

        let willDelete = await swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this room?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancel', 'Delete']
        });

        if (!willDelete) return;


        if (this.state.groupRoomsDevices[roomId]) {
            let deviceToResetRoom = [];
            this.state.groupRoomsDevices[roomId].forEach(device => {
                device.unset(db.Device.ROOM_ID);
                device.unset(db.Device.HOME);
                device.unset(db.Device.OWNER);

                deviceToResetRoom.push(device);
            });

            await Parse.Object.saveAll(deviceToResetRoom);
        }


        room.set(db.Room.DELETED, true);
        room.unset(db.Room.ROOM_NAME);
        room.unset(db.Room.UNIQUE_ID);

        await room.save();

        await swal({
            title: 'Success', text: ' ', icon: 'success',
            button: [''], timer: 1000
        });

        await this.refreshInstallationRooms()
    }
    async onAddDevice(room){
        let value = await swal('Scan device', {
            content: 'input',
            buttons: true
        });
        if (!value) return;

        if (value.startsWith('https://devices.cleveron.ch/device/')) {
            value = value.replace('https://devices.cleveron.ch/device/', '');
            value = value.toUpperCase();
        }

        await this.addDevice(value, room);
    }

    render() {
        if(this.state.rooms.length === 0 && this.state.loading.rooms) return <Loader/>;


        let roomsObject = this.state.rooms.map(room => {
            let roomJSON = room.toJSON();

            return _.pick(roomJSON, [
                db.Room.ROOM_NAME,
                db.Room.ROOM_CODE,
                db.Room.ROOM_TYPE,
                db.Room.UNIQUE_ID,
                db.Room.NUMBER_RADIATORS,
                db.Room.NUMBER_SENSP
            ]);
        });

        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><a href={paths.maintenance}>Maintenance tasks</a></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.home && this.state.home.get(db.Home.HOME_NAME)}</BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col>
                        <h1>Building {this.state.home && this.state.home.get(db.Home.HOME_NAME)} {
                            this.state.installation &&
                            this.state.installation.get(db.OperationTask.COMPLETED) === true &&
                            <span>(Completed)</span>
                        }</h1>
                    </Col>
                </Row>
                <Row className={'page-installation sticky-nav'} style={{backgroundColor: 'white'}}>
                    <Col md={12}>
                        <Button color={'primary'} outline onClick={this.refreshInstallationRooms}>
                            <i className="fa fa-refresh" aria-hidden="true"></i> Refresh
                        </Button>
                        {
                            this.state.installation && !this.state.installation.get(db.OperationTask.COMPLETED) &&
                            <Fragment>
                                <Button color={'primary'} outline onClick={async () => {
                                    try {
                                        this.checkInstallation(() => this.toggleReviewModal());
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }}>Check installation</Button>

                                <Button color={'primary'} outline onClick={() => {
                                    //if(this.allRoomsAreCompleted())
                                    return this.toggleAddNewRoomModal();

                                    //swal('Error', 'Please complete all previous rooms before continuing', 'error');
                                }}><i className="fa fa-plus" aria-hidden="true"></i> Add new room</Button>

                                <Button color={'warning'} outline onClick={this.completeBuildingInstallation}><i
                                    className="fa fa-check"></i> Complete operation</Button>

                                <a href={paths.linkRoomQr} target={'_blank'} rel={'noreferrer'}>
                                    <Button outline color={'primary'}>
                                        <i className="fa fa-qrcode"></i> Link QR to room
                                    </Button>
                                </a>

                                <Button outline color={'primary'} onClick={async (e) => {
                                    e.preventDefault();

                                    try {
                                        assert(this.state.home != null, 'Home is required');
                            
                                        let willDelete = await swal({
                                            title: 'Are you sure?',
                                            text: 'All the vertical images will be rotate to horizontal',
                                            icon: 'warning',
                                            buttons: true,
                                            dangerMode: true
                                        });
                            
                                        if (!willDelete) {
                                            return this.setState({error: true, errorMessage: 'Aborted by user'});
                                        }
    
                                        await Parse.Cloud.run('admin-rotate-image-of-building', {
                                            buildingId: this.state.home.id
                                        });
    
                                        swal('Images rotated correctly', '', 'success');
                                    } catch (e){
                                        console.error(e);
                                    }
                                }}>
                                    <i className="fa fa-repeat"></i> Rotate room images
                                </Button>

                                <Button  outline color={'primary'} onClick={() => downloadxls(roomsObject, `${this.state.home.get(db.Home.HOME_NAME)}_rooms`)}>
                                    Download rooms
                                </Button>

                                <div className={'second-row-menu'}>
                                    Floor: &nbsp;
                                    {
                                        floorArray.map(floor => {
                                            return <Fragment key={floor}>
                                                <Toggle
                                                    className={'floor-checkbox'}
                                                    checked={this.state.checked[floor] || false}
                                                    icons={{
                                                        checked: <span style={{color: 'white'}}>{floor}</span>,
                                                        unchecked: <span style={{color: 'white'}}>{floor}</span>
                                                    }}
                                                    onChange={e =>{
                                                        e.preventDefault();
                                                        this.setState(prev => {
                                                            prev.checked[floor] = !prev.checked[floor];

                                                            localStorage.setItem(
                                                                `${this.props.match.params.id}.selectedFloors`,
                                                                JSON.stringify(prev.checked)
                                                            );

                                                            return prev;
                                                        },  this.refreshInstallationRooms);
                                                    }}
                                                />
                                            </Fragment>
                                        })
                                    }
                                    &nbsp; &nbsp;
                                    Hide completed rooms: <Toggle
                                        checked={this.state.hideCompletedRooms || false}
                                        onChange={e =>{
                                            e.preventDefault();
                                            this.setState(
                                                {
                                                    hideCompletedRooms: !this.state.hideCompletedRooms
                                                },
                                                this.refreshInstallationRooms
                                            );
                                        }}
                                    />
                                </div>
                            </Fragment>
                        }
                        {
                            this.state.installation &&
                            !!this.state.installation.get(db.OperationTask.COMPLETED) &&
                            isRolesInRoles(this.props.roles, ['Admin']) && <>
                                <Button color={'warning'} outline onClick={this.uncompleteOperation}>
                                    <i className="fa fa-check"></i> Un-complete operation
                                </Button>
                            </>
                        }
                    </Col>
                    <ReviewModal setToggleModal={(toggle) => this.toggleReviewModal = toggle}
                        reviewObject={this.state.checkObject}/>
                    {this.state.home && <AddNewRoomModal
                        setToggleModal={(toggle) => this.toggleAddNewRoomModal = toggle}
                        homeId={this.state.home.id}
                        afterSave={() => this.refreshInstallationRooms()}
                    />}
                </Row>
                <Row>
                    <Col md={12}>
                        {
                            this.state.rooms.length > 0 && renderRoomsV2(this)
                        }
                        {
                            this.state.length === 0 && <Row style={{marginTop: 40}}>
                                <Col md={3}></Col>
                                <Col md={6}>
                                    <h2>No rooms to show. Please start by adding a new room or review your filters.</h2>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

PageInstallation.propTypes = {
    match: PropTypes.any,
    roles: PropTypes.array
};