import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Table} from 'reactstrap';
import * as db from '../../../config/dbStructure';
import _ from 'lodash';
import Loader from '../loader';
import PageSingleBatch from './index.js';
import config from '../../../config/app';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import moment from "moment";
import {testBatchTagNBIOT} from "../../utils";
import {getRequiredVersionNumberFromTestBatch, isWNBPThermo, isWNBThermo, testBatchNeedTest} from "../../../lib/util";

export default class TestBatchDeviceTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!this.props.testBatchDevices) return <Loader/>;
        if(!this.props.testBatch) return <Loader/>;

        let testBatchType = this.props.testBatch.get(db.TestBatch.TYPE);
        let tag = this.props.testBatch.get(db.TestBatch.TAG);

        let temperatureCheck = (testBatchDevice) => {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let deviceType = device.get(db.Device.DEVICE_TYP);

            if(deviceType === db.Device.DEVICE_TYP$SENSP){
                return testBatchDevice.get(db.TestBatchDevice.TEMPERATURE_TEST) === true &&
                    testBatchDevice.get(db.TestBatchDevice.CO2_TEST) === true;
            } else if (deviceType === db.Device.DEVICE_TYP$THERM){
                return testBatchDevice.get(db.TestBatchDevice.TEMPERATURE_TEST) === true;
            }

            return false;
        };

        let firmwareCheck = (testBatchDevice) => {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let deviceType = device.get(db.Device.DEVICE_TYP);
            let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);

            let icon = <i className="fa fa-times" style={{color: 'red'}}></i>;
            if(deviceType === db.Device.DEVICE_TYP$SENSP && firmwareVersion === config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2){
                icon = <i className="fa fa-check" style={{color: 'green'}}></i>;
            } else if (deviceType === db.Device.DEVICE_TYP$THERM && firmwareVersion === config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO){
                icon = <i className="fa fa-check" style={{color: 'green'}}></i>;
            }

            return <div>
                {firmwareVersion} {icon}
            </div>;
        };

        let renderNbIotColumn = (device) => {
            let iccid = device.get(db.Device.ICCID);
            let lastNbIotConnection = device.get(db.Device.LAST_NB_IOT_CONNECTION);
            let nbIotFirmwareVersion = device.get(db.Device.NB_IOT_FIRMWARE_VERSION);

            lastNbIotConnection = lastNbIotConnection != null ? moment(lastNbIotConnection) : null;

            let diffMinutes = 10000;


            if(lastNbIotConnection)
                diffMinutes = moment().diff(lastNbIotConnection, 'minutes');


            let nbIotConnectionOk = false;

            if(diffMinutes < 30){
                nbIotConnectionOk = true;
            }

            return <>
                {
                    testBatchTagNBIOT(tag) && <td>
                        Iccid: {iccid && iccid}
                        <br/>
                        <span>
                            Last NB connection: {lastNbIotConnection ? lastNbIotConnection?.fromNow() : 'none'}&nbsp;

                            {
                                nbIotConnectionOk && <i className="fa fa-check" style={{color: 'green'}}></i>
                            }
                            {
                                !nbIotConnectionOk  && <i className="fa fa-times" style={{color: 'red'}}></i>
                            }
                        </span>

                        <br/>
                        <span>
                            NB chip version: {nbIotFirmwareVersion ? nbIotFirmwareVersion : 'none'}&nbsp;
                            {
                                nbIotFirmwareVersion === config.leanManagment.REQUIRED_NB_IOT_FIRMWARE_VERSION && <i className="fa fa-check" style={{color: 'green'}}></i>
                            }
                            {
                                nbIotFirmwareVersion !== config.leanManagment.REQUIRED_NB_IOT_FIRMWARE_VERSION && <i className="fa fa-times" style={{color: 'red'}}></i>
                            }
                        </span>

                    </td>
                }
            </>
        }

        let  renderTestBatchDeviceRow = (testBatch, testBatchDevice, i) => {
            let {
                device,
                homeName,
                wifiName,
                wifiPassword,
                actualConnectionWifi,
                ledInternalConfig,
                hwVersionA
            } = PageSingleBatch.getInfoFromTestBatchDevice(testBatchDevice);

            let deviceType = device.get(db.Device.DEVICE_TYP);
            let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);
            let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
            let icon = null;
            let correctVerionNumber = getRequiredVersionNumberFromTestBatch(testBatch);
            let deviceConfig = device.get(db.Device.DEVICE_CONFIG);
            let motorSpeed = device.get(db.Device.MOTOR_SPEED);
            let lastConnectionProtocol = device.get(db.Device.LAST_CONNECTION_PROTOCOL);
            let lastConnectionDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
            let co2 = device.get(db.Device.CO2);
            let refurbishedDate = device.get(db.Device.REFURBISHED_DATE);

            lastConnectionDate = lastConnectionDate != null ?  moment(lastConnectionDate).fromNow() : null;
            refurbishedDate = refurbishedDate != null ?  moment(refurbishedDate) : null;

            let row = (i % 4) + 1;
            let column = Math.trunc(i / 4);
            let columnMap = ['A', 'B', 'C', 'D', 'E'];

            let isWNBP = isWNBPThermo(device);
            let isWNB = isWNBThermo(device);

            switch (deviceType){
                case db.Device.DEVICE_TYP$SENSP:
                    icon = co2SensorIcon;
                    break;
                case db.Device.DEVICE_TYP$THERM:
                    icon = thermoIcon;
                    break;
                case db.Device.DEVICE_TYP$ROUTER_RUT240:
                    icon = 'https://ik.imagekit.io/sh/58015183_xxl_YdWVfFKt2F.webp?updatedAt=1641551894782';
            }

            return <tr key={testBatchDevice.id}>
                <td scope="row">
                    <a
                        href={'https://cleveron-assets.s3.amazonaws.com/lean-management/batch-map-explaination.png'}
                        target={'_blank'}
                    >
                        {columnMap[column]}{row}
                    </a>
                </td>
                <td>
                    <a href={`${config.adminDashboard.host}/devices/${device.id}`}
                       target={'_blank'} rel={'noreferrer'}>
                        {device.get(db.Device.SERIAL_NUMBER)}
                    </a><br/>

                    {
                        motorCurrentLimits != null && motorCurrentLimits.cL === 0 && <Badge color={'danger'}>calibration-required</Badge>
                    }

                    {
                        refurbishedDate != null && <Badge color={'warning'}>refurbished ({refurbishedDate.format('MM/YYYY')})</Badge>
                    }
                </td>
                <td>
                    <a
                        href={`${config.adminDashboard.host}/devices/${device.id}`}
                        target={'_blank'} rel={'noreferrer'}>
                        {device.get(db.Device.MAC_ADDRESS)} ({hwVersionA})
                    </a>
                </td>
                <td>
                    <img src={icon} height={30}/>
                    {/*
                    <br/>
                    {isWNBP && <span>WNBP</span>}
                    {isWNB && <span>WNB</span>}
                    */}

                </td>
                {
                    (testBatchType === db.TestBatch.TYPE$CLEVER_THERMO || testBatchType === db.TestBatch.TYPE$TESTKIT) && <Fragment>
                        <td style={{minWidth: 120}}>{device.get(db.Device.MOUNTED_ENGINE)}</td>
                    </Fragment>
                }

                <td>{firmwareCheck(testBatchDevice)}</td>
                {
                    this.props.roles.indexOf('Admin') < 0 && testBatchType === db.TestBatch.TYPE$CLEVER_THERMO &&  <td>
                        {co2}
                    </td>
                }
                {this.props.roles.indexOf('Admin') >= 0 && <Fragment>

                    <td>
                        <a href={`${config.adminDashboard.host}/rooms/${device.id}/devices`}>
                            {(device.get(db.Device.ROOM_ID) &&
                                device.get(db.Device.ROOM_ID).get(db.Room.ROOM_NAME)) || 'Not defined'}
                        </a>
                    </td>
                    <td>{homeName}</td>
                    <td>{wifiName}:{wifiPassword} - {actualConnectionWifi}</td>

                    {
                        (testBatchType === db.TestBatch.TYPE$CLEVER_THERMO || testBatchType === db.TestBatch.TYPE$TESTKIT) &&
                        <td style={{maxWidth: 300, overflow: 'auto'}}>{motorCurrentLimits && JSON.stringify(motorCurrentLimits)}</td>
                    }

                    {
                        testBatchType === db.TestBatch.TYPE$CLEVER_THERMO &&  <td>
                            {co2}
                        </td>
                    }

                    {
                        testBatchType === db.TestBatch.TYPE$CLEVER_THERMO &&  <td>
                            {motorSpeed}
                        </td>
                    }

                    <td>{deviceConfig && JSON.stringify(deviceConfig?.data?.[0])}</td>
                    <td>{ledInternalConfig && ledInternalConfig.split(';').map((threshold, i) => <p key={i}>{threshold}</p>)}</td>
                </Fragment>}
                {testBatchTagNBIOT(tag) && renderNbIotColumn(device)}
                <td>
                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'connected' && <span><i className="fa fa-check" style={{color: 'green'}}></i> {lastConnectionProtocol} <br/> {lastConnectionDate || ''}</span>}
                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'No debug' && <span><i className="fa fa-times" style={{color: 'orange'}}></i>{lastConnectionProtocol} <br/> {lastConnectionDate || ''}</span>}
                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'Not connected' && <span><i className="fa fa-times" style={{color: 'red'}}></i>{lastConnectionProtocol} <br/> {lastConnectionDate || ''}</span>}
                </td>
                {
                    testBatchNeedTest(testBatch, db.TestBatch.TESTS$VISUAL) && <td>
                        {testBatchDevice.get(db.TestBatchDevice.VISUAL_TEST) === 'true' ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>}
                    </td>
                }
                {
                    testBatchNeedTest(testBatch, db.TestBatch.TESTS$CONNECTION) && <td>
                        {testBatchDevice.get(db.TestBatchDevice.NB_IOT_CONNECTION_TEST) === true ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>}
                    </td>
                }
                {
                    testBatchNeedTest(testBatch, db.TestBatch.TESTS$BATTERY) && <td>
                        {testBatchDevice.get(db.TestBatchDevice.BATTERY_TEST) === 'true' ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>}
                    </td>
                }
                {
                    testBatchNeedTest(testBatch, db.TestBatch.TESTS$MOTOR) && <td>
                        {testBatchDevice.get(db.TestBatchDevice.MOTOR_UP_TEST) === 'true' &&
                        testBatchDevice.get(db.TestBatchDevice.MOTOR_DOWN_TEST) === 'true'?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>}
                    </td>
                }
                {
                    testBatchNeedTest(testBatch, db.TestBatch.TESTS$TEMPERATURE) && <td>
                        {
                            temperatureCheck(testBatchDevice) ?
                                <i className="fa fa-check" style={{color: 'green'}}></i> :
                                <i className="fa fa-times" style={{color: 'red'}}></i>
                        }
                    </td>
                }
                <td>
                    {device.get(db.Device.DEVICE_STATE_FLAG) === 'in-mount' ?
                        <i className="fa fa-check" style={{color: 'green'}}></i> :
                        <i className="fa fa-times" style={{color: 'red'}}></i>}
                </td>
                <td>
                    {correctVerionNumber ?
                        <i className="fa fa-check" style={{color: 'green'}}></i> :
                        <i className="fa fa-times" style={{color: 'red'}}></i>}
                </td>
                <td>
                    <Button onClick={() => this.props.moveUpTestBatchDevice(testBatchDevice)}><i className="fa fa-angle-up"></i></Button>
                    <Button onClick={() => this.props.moveDownTestBatchDevice(testBatchDevice)}><i className="fa fa-angle-down"></i></Button>
                    <Button onClick={() => this.props.moveToCoordinate(testBatchDevice)}><i className="fa fa-arrows"></i></Button>
                </td>
            </tr>
        }

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Pos.</th>
                        <th>Serial</th>
                        <th>Mac addr.</th>
                        <th>Type</th>
                        {
                            (testBatchType === db.TestBatch.TYPE$CLEVER_THERMO || testBatchType === db.TestBatch.TYPE$TESTKIT) && <Fragment>
                                <th>Mounted engine</th>
                            </Fragment>
                        }
                        <th>Firmware v.</th>
                        {this.props.roles.indexOf('Admin') >= 0 && <th>Room</th>}
                        {this.props.roles.indexOf('Admin') >= 0 &&<th>Home</th>}
                        {this.props.roles.indexOf('Admin') >= 0 && <th>Wifi</th>}
                        {
                            this.props.roles.indexOf('Admin') >= 0 &&
                            (testBatchType === db.TestBatch.TYPE$CLEVER_THERMO || testBatchType === db.TestBatch.TYPE$TESTKIT) && <Fragment>
                                 <th style={{maxWidth: 100}}>Motor c.</th>
                            </Fragment>
                        }
                        {testBatchType === db.TestBatch.TYPE$CLEVER_THERMO && <th>Co2</th>}
                        {this.props.roles.indexOf('Admin') >= 0 && testBatchType === db.TestBatch.TYPE$CLEVER_THERMO && <th>Motor speed</th>}
                        {this.props.roles.indexOf('Admin') >= 0 && <th>Dev. Config</th>}
                        {this.props.roles.indexOf('Admin') >= 0 && <th>LED Config</th>}
                        {testBatchTagNBIOT(tag) && <th>NB-IOT</th>}
                        <th><i className="fa fa-arrows-h"></i></th>
                        {testBatchNeedTest(this.props.testBatch, db.TestBatch.TESTS$VISUAL) && <th><i className="fa fa-eye"></i></th>}
                        {testBatchNeedTest(this.props.testBatch, db.TestBatch.TESTS$CONNECTION) && <th><i className="fa fa-arrows-h"></i></th>}
                        {testBatchNeedTest(this.props.testBatch, db.TestBatch.TESTS$BATTERY) && <th><i className="fa fa-battery-three-quarters"></i></th>}
                        {testBatchNeedTest(this.props.testBatch, db.TestBatch.TESTS$MOTOR) && <th><i className="fa fa-cog"></i></th>}
                        {testBatchNeedTest(this.props.testBatch, db.TestBatch.TESTS$TEMPERATURE) && <th><i className="fa fa-thermometer-three-quarters"></i></th>}
                        <th><i className="fa fa-flag"></i></th>
                        <th><i className="fa fa-download" aria-hidden="true"></i></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.testBatchDeviceOrder != null && this.props.testBatchDeviceOrder.map((testBatchDeviceId, i) => {
                            let testBatchDevice = _.find(this.props.testBatchDevices, { 'id': testBatchDeviceId });


                            if(testBatchDevice == null){

                                let row = (i % 4) + 1;
                                let column = Math.trunc(i / 4);
                                let columnMap = ['A', 'B', 'C', 'D', 'E'];

                                return <tr>
                                    <td>{columnMap[column]}{row}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            }

                            return renderTestBatchDeviceRow(this.props.testBatch, testBatchDevice, i);
                        })
                    }

                    {
                        this.props.testBatchDeviceOrder == null && this.props.testBatchDevices.map((testBatchDevice, i) => {

                            return renderTestBatchDeviceRow(this.props.testBatch, testBatchDevice, i);
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

TestBatchDeviceTable.propTypes = {
    testBatchDevices: PropTypes.array,
    getBatchDevices: PropTypes.func,
    testBatch: PropTypes.any,
    edit: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
    testBatchDeviceOrder: PropTypes.array,
    moveUpTestBatchDevice: PropTypes.func,
    moveDownTestBatchDevice: PropTypes.func,
    moveToCoordinate: PropTypes.func,
};