import axios from 'axios';
import swal from 'sweetalert';
import {removeCharacter$FromObjectKeys} from './util';

export async function getOperationFromId(operationId){
    try {
        let response = await axios.get(`/api/crm/operation/${operationId}`);

        let operation = response.data;

        if(!operation || operation.length === 0) throw new Error('No Operation found');

        operation = removeCharacter$FromObjectKeys(operation);

        return operation;
    } catch (e) {
        console.error(e);
        swal('Error', e.message, 'error');

        throw e;
    }
}

export async function getSalesOrderFromId(salesOrderId){
    try {
        let response = await axios.get(`/api/crm/sales-order/${salesOrderId}`);

        let salesOrder = response.data;

        if(!salesOrder || salesOrder.length === 0) throw new Error('No sales order found');

        salesOrder = removeCharacter$FromObjectKeys(salesOrder);

        return salesOrder;
    } catch (e) {
        console.error(e);
        swal('Error', e.message, 'error');

        throw e;
    }
}

export async function getContact(contactId){
    let contacts = await axios.get(`/api/crm/contacts/${contactId}`);

    console.log('getContact', contacts);

    let contact = contacts.data;

    if(!contact){
        throw new Error('Contact not found');
    }

    return removeCharacter$FromObjectKeys(contact);
}

export async function getBuilding(buildingId){
    let buildings = await axios.get(`/api/crm/building/${buildingId}`);

    let building = buildings.data;

    if(!building){
        throw new Error('Building not found');
    }

    return removeCharacter$FromObjectKeys(building);
}

export async function updateBuilding(buildingId, updateObject){
    let result = await axios.put(`/api/crm/building/${buildingId}`, {});
}