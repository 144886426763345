/* eslint-disable max-len */
import Parse from './parse';
import StateMachine from './StateMachine';
import _ from 'lodash';
import batchStateConfig from './batch-state-config';
import swal from 'sweetalert';
import {copyACLToObject, isRolesInRoles, moveDeviceToRoom} from './util';
import assert from 'assert';

let db = require('../config/dbStructure.js');


async function createToConfigureRoom(home){
    assert(home != null, 'home should be present');

    //Create a room (Room 1, 2, 3 ...)
    let roomObject = new Parse.Object(db.classes.Room);
    roomObject.set(db.Room.ROOM_NAME, 'to-configure');
    roomObject.set(db.Room.HOME, home);
    roomObject.set(db.Room.FLOOR, 0);
    roomObject.set(db.Room.TEMP_MIN, 20);
    roomObject.set(db.Room.TEMP_MAX, 22);
    roomObject.set(db.Room.HIDDEN, true);

    copyACLToObject(home, roomObject);

    return roomObject.save();
}

/**
 *
 * @param stateMachine
 * @param stateTo
 * @param force
 * @returns {Promise<void>}
 */
async function updateInstallationTestBatches(stateMachine, stateTo, force){
    let testBatches = stateMachine.object.get(db.SupplierOrder.TEST_BATCHES);

    let installationBatchesToInstall = [];
    for(let testBatch of testBatches){
        let status = testBatch.get(db.TestBatch.STATUS);

        let config = _.merge({}, batchStateConfig, {init: status, roles: stateMachine.roles, object: testBatch});
        let sm = new StateMachine(config);

        let isOldState = sm.isNextStateAnOldState(stateTo);
        if(isOldState) continue;

        await sm.goTo(stateTo, force);

        testBatch.set(db.TestBatch.STATUS, stateTo);
        installationBatchesToInstall.push(testBatch);
    }

    await Parse.Object.saveAll(installationBatchesToInstall);
}

async function checkAllowedAdminActionActivated(home){
    let homeName = home.get(db.Home.HOME_NAME);
    let city = home.get(db.Home.CITY);
    let allowAdminActions = home.get(db.Home.ALLOW_ADMIN_ACTIONS)

    if(!allowAdminActions) {
        let result = await swal({
            title: 'No configuration allowd on this building',
            icon: 'warning',
            text: `Do you want to allow communication config to building ${homeName} in ${city}? Warning that this can lead to connection lost to all devices!`,
            buttons: ['Abort', 'Confirm'],
            dangerMode: true
        });

        if (!result) throw new Error('Action aborted by user');

        home.set(db.Home.ALLOW_ADMIN_ACTIONS, true);

        await home.save();

        swal('Saved', '', 'success');
    }
}
async function checkAllowedAdminActionDeactivated(home){
    let homeName = home.get(db.Home.HOME_NAME);
    let city = home.get(db.Home.CITY);
    let allowAdminActions = home.get(db.Home.ALLOW_ADMIN_ACTIONS)

    if(allowAdminActions) {
        let result = await swal({
            title: 'The configuration is still allowed on this building',
            icon: 'warning',
            text: `The configuration is still allowed for building ${homeName} in ${city}! We strongly recommend to disable it when the configuration is finished.`,
            buttons: ['Abort', 'Deactivate'],
            dangerMode: true
        });

        if (!result) throw new Error('Action aborted by user');

        home.set(db.Home.ALLOW_ADMIN_ACTIONS, false);

        await home.save();

        swal('Saved', '', 'success');
    }

}
async function moveTestBatchDevicesToCurrentBuilding(supplierOrder, testBatches){
    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    let home = supplierOrder.get(db.SupplierOrder.HOME);
    let homeName = home.get(db.Home.HOME_NAME);
    let city = home.get(db.Home.CITY);

    let batchesNames = testBatches.map(testBatch => testBatch.get(db.TestBatch.NAME)).join(',');

    let result = await swal({
        title: 'Move devices for configuration',
        icon: 'warning',
        text: `All devices on batches ${batchesNames} will be moved to building ${homeName} in ${city} this is needed for proceeding in the configuration. Continue?`,
        buttons: ['Abort', 'Confirm'],
        dangerMode: true
    });

    if (!result) throw new Error('Action aborted by user');

    if(home.id === 'Fj9TubrTtT'){
        throw new Error('Cannot link to building "No building"');
    }

    let room = await (new Parse.Query(db.classes.Room))
        .equalTo(db.Room.ROOM_NAME, 'to-configure')
        .equalTo(db.Room.HOME, home)
        .first();

    if(!room){
        let createToConfigureRoomAnswer = await swal({
            title: `No room "to-configure" found on selected building "${home.get(db.Home.HOME_NAME)}".`,
            icon: 'warning',
            text: `Would you like to create one?`,
            buttons: ['Abort', 'Confirm'],
            dangerMode: true
        });

        if (!createToConfigureRoomAnswer) throw new Error('Action aborted by user');

        room = await createToConfigureRoom(home);
    }

    let devicesToSave = [];
    for (let testBatch of testBatches) {
        let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
            .equalTo(db.TestBatchDevice.BATCH, testBatch)
            .include(db.TestBatchDevice.DEVICE)
            .find();

        for(let testBatchDevice of testBatchDevices){
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

            devicesToSave.push(moveDeviceToRoom(device, room));
        }

    }

    await Parse.Object.saveAll(devicesToSave);

    swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
}
async function moveTestBatchesDevicesTo(supplierOrder, testBatches){
    let room = await new Parse.Query(db.classes.Room).get('');

    let devicesToSave = [];
    for (let testBatch of testBatches) {
        let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
            .equalTo(db.TestBatchDevice.BATCH, testBatch)
            .include(db.TestBatchDevice.DEVICE)
            .find();

        for(let testBatchDevice of testBatchDevices){
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

            devicesToSave.push(moveDeviceToRoom(device, room));
        }

    }

    //await Parse.Object.saveAll(devicesToSave);

    //swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
}
async function addTrackingNumber(sm){
    let carrier = await swal(`Select the carrier"`, {
        buttons: {
            'swiss-post': 'Swiss Post',
            'dhl-germany': 'DHL'
        }
    });

    // eslint-disable-next-line max-len
    let text = await swal('Enter tracking code. If more than one, list them with the "," separator. Ex: 99.00.123456.12345678,99.00.123456.12345679.', {
        content: 'input',
        buttons: ['Cancel', 'Ok']
    });

    if (!text || text === '') {
        throw new Error('No tracking code provided')
    }

    if(text.includes(',')){
        let trackingNumberString = text.split(',');

        trackingNumberString.forEach(trackingNumber => {

            if(carrier === 'swiss-post'){
                if(trackingNumber.length !== 18){
                    throw new Error('The tracking code is not valid. Please verify that has 18 numbers.')
                }
            } else if (carrier === 'dhl-germany'){
                if(trackingNumber.length !== 20){
                    throw new Error('The tracking code is not valid. Please verify that has 20 numbers.')
                }
            }
        });
    }

    sm.object.set(db.SupplierOrder.SWISS_POST_TRACKING_NUMBER, text);
    sm.object.set(db.SupplierOrder.SHIPPING_CARRIER, carrier);
    let newInstallationObject = await sm.object.save();

    sm.setObject(newInstallationObject);
}
async function toggleManualConfiguration(supplierOrder){
    let testBatches = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);

    let devicesToSave = [];
    for(let testBatch of testBatches){
        let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
            .equalTo(db.TestBatchDevice.BATCH, testBatch)
            .include(db.TestBatchDevice.DEVICE)
            .find();

        for(let testBatchDevice of testBatchDevices){
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let commercialLabel = device.get(db.Device.COMMERCIAL_LABEL);
            if(commercialLabel !== db.Device.COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH){
                device.set(db.Device.COMMERCIAL_LABEL, db.Device.COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH);
            }

            devicesToSave.push(device);
        }
    }

    await Parse.Object.saveAll(devicesToSave);
}

let prerequisite = () => {
    //Check that all batched attached are "ready to config"
};

async function canBeSetToConfigured(sm) {
    let supplierOrder = sm.getObject();
    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);
    let home = supplierOrder.get(db.SupplierOrder.HOME);
    let testBatches = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    for(let testBatch of testBatches){
        let status = testBatch.get(db.TestBatch.STATUS);

        if(status !== db.TestBatch.STATUS$CONFIGURED) throw new Error('Please configure all the linked batches');
    }

    await checkAllowedAdminActionDeactivated(home);
}

let canBeSentToInstaller = async (sm) => {
    let installation = sm.object;
    let testBatches = installation.get(db.SupplierOrder.TEST_BATCHES)

    let dischargedBatches = [];

    for(let testBatch of testBatches){
        let status = testBatch.get(db.TestBatch.STATUS);

        let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
            .equalTo(db.TestBatchDevice.BATCH, testBatch)
            .notEqualTo(db.TestBatchDevice.DELETED, true)
            .find();

        let batteryOk = true;
        for(let testBatchDevice of testBatchDevices){
            let batteryLevel = testBatchDevice.get(db.TestBatchDevice.CURRENT_BATTERY_LEVEL);

        }

        if(!batteryOk){
            dischargedBatches.push(testBatch.get(db.TestBatch.NAME));
        }
    }

    if(dischargedBatches.length > 0)
        throw new Error(`Test batches: "${dischargedBatches.join(',')}" cannot be sent to installer because the batteries are not anymore at maximum level.`)
};

let canBeSetToReadyToConfig = (stateMachine) => {
    let supplierOrder = stateMachine.object;
    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    let testBatches = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);

    let allReadyToConfig = true;

    for(let testBatch of testBatches){
        let status = testBatch.get(db.TestBatch.STATUS);
        if(
            status !== db.TestBatch.STATUS$READY_TO_CONFIG &&
            status !== db.TestBatch.STATUS$CONFIGURED
        ){
            allReadyToConfig = false;
        }
    }

    if(!allReadyToConfig) throw Error('Order cannot be set to "ready-to-config" as some test batch have to be turned on first.');
}

async function canBeSetToToTurnOn(sm){
    let props = sm.getProps();
    let supplierOrder = sm.getObject();
    let testBatches = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);
    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);
    let connectionGuideInsert = supplierOrder.get(db.SupplierOrder.CONNECTION_GUIDE_INSERT);

    if(testBatches.length === 0) throw Error('No batches connected to this order. Aborting.')

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    let home = await supplierOrder.get(db.SupplierOrder.HOME).fetch();

    await moveTestBatchDevicesToCurrentBuilding(supplierOrder, testBatches);

    if(connectionGuideInsert)
        await toggleManualConfiguration(supplierOrder);

    function ensureFooIsSet(condition) {
        return new Promise(function (resolve, reject) {
            (function waitForFoo(){
                if(condition() === true){
                    return resolve();
                }
                setTimeout(waitForFoo, 200);
            })();
        });
    }

    async function checkConfig(configuration, home, configModal){
        let devicesConfig = home.get(db.Home.DEVICES_CONFIG);

        let config = _.get(devicesConfig, 'deviceConfig.data[0]');
        let wifiName = _.get(config, 'wifiName');
        let wifiPassword = _.get(config, 'wifiPassword');
        let typeConfig = _.get(config, 'type')
        if(
            (configuration === db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION && devicesConfig == null) ||
            (configuration === db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION && (_.isEmpty(wifiName) || _.isEmpty(wifiPassword) || typeConfig !== 'CommunicationConfig'))
        ){
            let result = await swal({
                title: 'No configuration found on this building.',
                text: `Do you want to add a new Wifi configuration?`,
                buttons: ['Abort', 'Confirm'],
            });

            if (!result) throw new Error('Action aborted by user');

            props.configModal.toggleModal();
            await ensureFooIsSet(() => !props.configModal.getState().isModalOpen);

            home = await home.fetch();

            await checkConfig(configuration, home, configModal);
        }
    }

    await checkConfig(configuration, home, props.configModal);

    await checkAllowedAdminActionActivated(home);
}

async function canBeSetToReadyToBeSent(){

}

async function afterReadyToConfig(stateMachine, toState, force){
    if(!stateMachine.isRole('Admin')) return;

    let result = await swal({
        title: 'Do you want to execute the "post state change" on related list?',
        text: ``,
        buttons: ['Cancel', 'Yes, I know what I\'m doing.'],
        dangerMode: true
    });

    result && await updateInstallationTestBatches(stateMachine, toState, force);
}

let afterSentFromSupplier = async (stateMachine) => {
    await updateInstallationTestBatches(stateMachine, 'sent-from-supplier')
}

export default {
    init: 'initialized',
    prerequisite: prerequisite,
    greenStates: {
        Supplier: [
            db.SupplierOrder.STATUS$IN_PREPARATION_FOR_CONFIG,
            db.SupplierOrder.STATUS$READY_TO_CONFIG,
            db.SupplierOrder.STATUS$SENT_FROM_SUPPLIER,
            db.SupplierOrder.STATUS$DELEYED
        ],
        Admin: [],
        Installer: [
            'installed-to-customer',
            'sent-from-installer-to-supplier'
        ]
    },
    orangeStates: {
        Supplier: [
            db.SupplierOrder.STATUS$TO_TURN_ON,
            db.SupplierOrder.STATUS$CONFIGURED,
            db.SupplierOrder.STATUS$READY_TO_BE_SENT
        ],
        Admin: [
            'initialized',
            'in-preparation-for-config',
            'ready-to-config',
            'sent-from-supplier',
            db.SupplierOrder.STATUS$DELEYED
        ],
        Installer: [
            'sent-from-supplier',
            'received-from-supplier',
            'in-preparation-to-install-to-customer',
            'ready-to-install-to-customer',
            'returned-from-customer-to-installer'
        ]
    },
    installerStates: [
        'sent-from-supplier',
        'received-from-supplier',
        'in-preparation-to-install-to-customer',
        'ready-to-install-to-customer',
        'installed-to-customer',
        'returned-from-customer-to-installer',
        'sent-from-installer-to-supplier'
    ],
    supplierStates: [
        'in-preparation-for-config',
        'deleyed',
        'ready-to-config',
        'to-turn-on',
        'configured',
        'ready-to-be-sent',
        'sent-from-supplier',
    ],
    allStates: [
        db.SupplierOrder.STATUS$IN_PREPARATION_FOR_CONFIG,
        db.SupplierOrder.STATUS$TO_TURN_ON,
        db.SupplierOrder.STATUS$READY_TO_CONFIG,
        db.SupplierOrder.STATUS$CONFIGURED,
        db.SupplierOrder.STATUS$READY_TO_BE_SENT,
        db.SupplierOrder.STATUS$SENT_FROM_SUPPLIER
    ],
    transitions: [
        {name: 'setToInPreparationForConfig', from: 'initialized', to: 'in-preparation-for-config', roles: ['Admin']},
        {name: 'setToTurnedOn', from: 'in-preparation-for-config', to: 'to-turn-on', roles: ['Admin'], requirementsOk: canBeSetToToTurnOn, postStateChange: supplerOrder => updateInstallationTestBatches(supplerOrder, 'to-turn-on')},
        {name: 'setToReadyToConfig', from: 'to-turn-on', to: 'ready-to-config', roles: ['Admin', 'Supplier'], requirementsOk: canBeSetToReadyToConfig, postStateChange: (supplerOrder, force) => afterReadyToConfig(supplerOrder, 'ready-to-config', force)},
        {name: 'setConfigured', from: 'ready-to-config', to: 'configured', roles: ['Admin'], requirementsOk: canBeSetToConfigured, postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'configured', force)},
        {name: 'setReadyToBeSent', from: 'configured', to: 'ready-to-be-sent', roles: ['Supplier'], requirementsOk: canBeSentToInstaller, postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'ready-to-be-sent', force)},
        {name: 'setSentFromSupplier', from: 'ready-to-be-sent', to: 'sent-from-supplier', roles: ['Supplier', 'Installer'], requirementsOk: addTrackingNumber, postStateChange: afterSentFromSupplier}, //Automatic
        {name: 'setReceivedFromSupplier', from: 'sent-from-supplier', to: 'received-from-supplier', roles: ['Installer'],  postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'received-from-supplier', force)},
        {name: 'setInPreparationToInstallToCustomer', from: 'received-from-supplier', to: 'in-preparation-to-install-to-customer', roles: ['Installer'], postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'in-preparation-to-install-to-customer', force)},
        {name: 'setReadyToInstallToCustomer', from: 'in-preparation-to-install-to-customer', to: 'ready-to-install-to-customer', roles: ['Installer'], postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'ready-to-install-to-customer', force)},
        {name: 'setInstalledToCustomer', from: 'ready-to-install-to-customer', to: 'installed-to-customer', roles: ['Installer'], postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'installed-to-customer', force) },
        {name: 'setInstallationConcluded', from: 'installed-to-customer', to: 'installation-concluded', role: ['Admin'], postStateChange: (sm, force) => updateInstallationTestBatches(sm, 'installation-concluded', force)},
        {name: 'setDeleyed', to: db.SupplierOrder.STATUS$DELEYED, role: ['Admin']},
        {name: 'setInitialized', to: db.SupplierOrder.STATUS$INITIALIZED, role: ['Admin']}
    ],
    installationRequireAction: function (status, roles) {
        let role = isRolesInRoles(roles, ['Supplier']) ? 'Supplier': null;
        role = isRolesInRoles(roles, ['Installer']) ? 'Installer': role;
        role = isRolesInRoles(roles, ['Admin']) ? 'Admin': role;

        let states = this.orangeStates[role] || [db.TestBatch.STATUS$NO_STATUS];

        if(states.indexOf(status) >= 0){
            return true;
        }

        return false;
    }
};