import React                          from 'react';
import ReactDOM                       from 'react-dom';

import App from './app';

import {createBrowserHistory}  from 'history';
const customHistory = createBrowserHistory();

if (document.getElementById('app')) {
    ReactDOM.render(
        (
            <App history={customHistory}/>
        ),
        document.getElementById('app')
    );
}

