import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import {Table} from 'reactstrap';
import * as db from '../../../config/dbStructure';
import config from '../../../config/app';

export default class VisualTestBatchDeviceTable extends Component {
    constructor(props) {
        super(props);
    }




    render() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Device</th>
                        <th><i className="fa fa-arrows-h"></i></th>
                        <th><i className="fa fa-eye"></i></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.testBatchDevices.map(testBatchDevice => {
                            return <tr key={testBatchDevice.id}>
                                <th scope="row">{testBatchDevice.id}</th>
                                <td>
                                    <a href={`${config.adminDashboard.host}/devices/${testBatchDevice.get(db.TestBatchDevice.DEVICE).id}`}>
                                        {testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.SERIAL_NUMBER)}
                                        ({testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.MAC_ADDRESS)})
                                    </a>
                                </td>
                                <td>
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) == null &&
                                    <i className="fa fa-times" style={{color: 'red'}}></i> }
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'connected' &&
                                    <i className="fa fa-check" style={{color: 'green'}}></i> }
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'No debug' &&
                                    <i className="fa fa-times" style={{color: 'orange'}}></i>}
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'Not connected' &&
                                    <i className="fa fa-times" style={{color: 'red'}}></i>}
                                </td>
                                <td>
                                    <Toggle defaultChecked={false}
                                        checked={testBatchDevice.get(db.TestBatchDevice.VISUAL_TEST) === 'true'}
                                        onChange={
                                            (e) => this.props.onVisualTestCheck(testBatchDevice, e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

VisualTestBatchDeviceTable.propTypes = {
    testBatchDevices: PropTypes.array.isRequired,
    onVisualTestCheck: PropTypes.func.isRequired
};