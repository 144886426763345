import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Table} from 'reactstrap';
import Parse from '../../../lib/parse';
import db from '../../../config/dbStructure';
import {extractHomeFields, isRolesInRoles} from '../../../lib/util';
import Loader from '../loader';

export default class PageListBuildings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homes: [],
            loading: {}
        };

        this.loadBuildings = this.loadBuildings.bind(this);
    }

    async componentDidMount(){
        try {
            await this.loadBuildings();
        } catch (e) {
            console.error(e);
        }
    }

    async loadBuildings(){
        this.setState({loading: {homes: true}});

        let query = new Parse.Query(db.classes.Home);

        if(!isRolesInRoles(this.props.roles, ['Admin', 'Support']))
            query.equalTo(db.Home.INSTALLER, Parse.User.current())

        query.notEqualTo(db.OperationTask.DELETED, true);

        let homes = await query.find();

        this.setState({homes, loading: {homes: false}});
    }

    render() {
        if(this.state.loading.homes) return <Loader/>;

        return (
            <Fragment>
                <Row>
                    {
                        isRolesInRoles(this.props.roles, ['Admin', 'Installer']) && <Fragment>
                            <Button outline onClick={this.loadBuildings}><i className="fa fa-refresh"
                                                                            aria-hidden="true"></i> Refresh</Button>
                        </Fragment>
                    }
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Num. Thermo</th>
                                <th>Num. Sense</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.homes.map((home) => {
                                    let {
                                        homeName,
                                        city,
                                        canton,
                                        zip,
                                        address,
                                        numTherm,
                                        numSensp
                                    } = extractHomeFields(home)

                                    return <tr key={home.id}>
                                        <td>
                                            {homeName}
                                        </td>
                                        <td>
                                            {address}, {zip} {city} ({canton})
                                        </td>
                                        <td>
                                            {numTherm}
                                        </td>
                                        <td>
                                            {numSensp}
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Row>
            </Fragment>
        )
    }
}

PageListBuildings.propTypes = {
    history: PropTypes.any,
    roles: PropTypes.array.isRequired
};