import * as db from '../../../config/dbStructure';
import * as _ from 'lodash';
import moment from 'moment';
import {Button, Col, Row, Table} from 'reactstrap';
import React from 'react';
import {checkBatteryDevice, checkConnection, checkWifiDb} from './utils';
import paths from '../../paths';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';

const renderRooms = (componentContext) => {
    let renderRoom = (room, i, componentContext) => {
        let roomId = room.id;
        let floor = room.get(db.Room.FLOOR);
        let duration = moment(room.get(db.Room.INSTALLATION_COMPLETED_DATE))
            .diff(room.get(db.Room.CREATED_AT), 'minutes');
        let roomPhotoUrl = room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url();
        let code = room.get(db.Room.ROOM_CODE);
        let roomName = room.get(db.Room.ROOM_NAME);

        let iconCheck = <i className="fa fa-check" style={{color: 'green'}}></i>;
        let iconCross = <i className="fa fa-times" style={{color: 'red'}}></i>;

        let iconPhotoMissing = <span style={{color: 'red'}}>
            <i className="fa fa-times" style={{color: 'red', fontSize: 20}}/>
            <i className="fa fa-picture-o" aria-hidden="true"/>
        </span>;

        let iconPhotoOk = <a href={roomPhotoUrl} target={'_target'}>
            <span style={{color: 'green'}}>
                <i className="fa fa-picture-o" aria-hidden="true"/>
            </span>
        </a>;

        let photoIcon = iconPhotoMissing;

        if(room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO).url()) {
            photoIcon = iconPhotoOk;
        }


        let devices = (componentContext.state.groupRoomsDevices[roomId] &&
                componentContext.state.groupRoomsDevices[roomId]) || [] ;

        let devicesBatteryOk = true;
        let devicesWifiOk = true;
        let deviceConnectionOk = true;

        devices.forEach(device => {
            let batteryOk = checkBatteryDevice(device);
            let wifiOk = checkWifiDb(device);
            let connectionOk = checkConnection(device);

            devicesBatteryOk = devicesBatteryOk && batteryOk;
            devicesWifiOk = devicesWifiOk && wifiOk;
            deviceConnectionOk = deviceConnectionOk && connectionOk;
        });

        let completedRooms = componentContext.operationTask;

        let roomCompleted = completedRooms[room.id]?.completed;

        return <tr key={room.id}>
            <td>
                {photoIcon} {roomName}
            </td>
            <td>
                {code}
            </td>
            <td>
                {floor}
            </td>
            <td>
                {moment(room.get(db.Room.CREATED_AT)).format('DD/MM/YYYY HH:mm')}
            </td>
            <td>
                {duration} min.
            </td>
            <td>
                <span style={{marginLeft: 10}}> {room.get(db.Room.NUMBER_RADIATORS)}&nbsp;<img src={thermoIcon} height={30}/></span>
                <span style={{marginLeft: 10}}> {room.get(db.Room.NUMBER_SENSP)}&nbsp;<img src={co2SensorIcon} height={30}/></span>
            </td>
            <td>
                {roomCompleted && <span><i className="fa fa-check" style={{color: 'green'}}></i> </span>}
            </td>
            <td>{devicesBatteryOk ? iconCheck : iconCross}</td>
            <td>{devicesWifiOk ? iconCheck : iconCross}</td>
            <td>{deviceConnectionOk ? iconCheck : iconCross}</td>
            <td>
                <Button outline color={'primary'} onClick={() =>
                        window.open(
                            paths.maintenanceTaskRoom
                                .replace(':id', componentContext.state.installation.id)
                                .replace(':roomId', roomId)
                        )
                }>
                    <i className="fa fa-eye"></i>
                </Button>
                <Button outline color={'danger'} onClick={async () => await componentContext.deleteRoom(room)}>
                    <i className="fa fa-trash"></i>
                </Button>
            </td>
        </tr>;
    };

    let {rooms} = componentContext.state;

    return <Table>
        <thead>
            <tr>
                <th>Room name</th>
                <th>Code</th>
                <th>Floor</th>
                <th>Creation data</th>
                <th>Install time</th>
                <th>Number devices</th>
                <th>Completed</th>
                <th><i className="fa fa-battery-three-quarters"></i></th>
                <th><i className="fa fa-wifi"></i></th>
                <th><i className="fa fa-arrows-h"></i></th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
        {
            rooms.length > 0 &&
            _.sortBy(rooms, room => room.get(db.Room.FLOOR))
                .map((room, i) => renderRoom(room, i, componentContext))
        }
        </tbody>
    </Table>;
};

export let renderRoomsV2 = (componentContext) => {

    let {rooms} = componentContext.state;

    return <>
        <Row>
        <Col md={12}>
                {
                    rooms.length > 0 && renderRooms(componentContext)
                }
                {
                    rooms.length === 0 && <Row style={{marginTop: 40}}>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <h2>No rooms to show. Please start by adding a new room or review your filters.</h2>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                }
            </Col>
        </Row>
    </>;
};