import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import swal from 'sweetalert';
import {extractRoomFields} from '../../../lib/util';

export default class EditRoom extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            loading: {},
            room: this.props.room
        };

        this.toggle = this.toggle.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.modifyRoomField = this.modifyRoomField.bind(this);
        this.saveRoom = this.saveRoom.bind(this);
    }

    async componentDidMount(){
        this.props.setToggleModal(this);
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
    }

    async onOpened(){}

    modifyRoomField(fieldName, newValue){
        let {room} = this.state;
        room.set(fieldName, newValue);

        this.setState({room});
    }

    async saveRoom(){
        try {
            await this.state.room.save();

            swal('Success', 'Room data saved!');
        } catch (e) {
            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000});
        }
    }

    render(){
        if(!this.state.room) return '';

        let {
            roomName,
            roomCode,
            floor,
            tempMax,
            tempMin,
            emergencyTemperature
        } = extractRoomFields(this.state.room);

        return <span>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}
                size={'lg'} onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Edit room</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for={db.Room.ROOM_NAME}>Room name:</Label>
                            <Input
                                type="text"
                                name={db.Room.ROOM_NAME}
                                value={roomName}
                                onChange={(e) =>
                                    this.modifyRoomField(db.Room.ROOM_NAME, e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Room.ROOM_CODE}>Room code:</Label>
                            <Input
                                type="text"
                                name={db.Room.ROOM_CODE}
                                value={roomCode}
                                onChange={(e) =>
                                    this.modifyRoomField(db.Room.ROOM_CODE, e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Room.FLOOR}>Floor:</Label>
                            <Input
                                type="number"
                                name={db.Room.FLOOR}
                                value={floor}
                                onChange={(e) =>
                                    this.modifyRoomField(db.Room.FLOOR, parseInt(e.target.value))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Room.TEMP_MIN}>Temp min:</Label>
                            <Input
                                type="number"
                                name={db.Room.TEMP_MIN}
                                value={tempMin}
                                onChange={(e) =>
                                    this.modifyRoomField(db.Room.TEMP_MIN, parseInt(e.target.value))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Room.TEMP_MAX}>Temp max:</Label>
                            <Input
                                type="number"
                                name={db.Room.TEMP_MAX}
                                value={tempMax}
                                onChange={(e) =>
                                    this.modifyRoomField(db.Room.TEMP_MAX, parseInt(e.target.value))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Room.EMERGENCY_TEMPERATURE}>Custom autonomous temperature (Default temp. max):</Label>
                            <Input
                                type="number"
                                name={db.Room.EMERGENCY_TEMPERATURE}
                                value={emergencyTemperature}
                                onChange={(e) =>
                                    this.modifyRoomField(db.Room.EMERGENCY_TEMPERATURE, parseInt(e.target.value))}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                    <Button outline color="primary" onClick={this.saveRoom}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </span>
    }
}

EditRoom.propTypes = {
    setToggleModal: PropTypes.func,
    callback: PropTypes.func,
    room: PropTypes.any,
};

