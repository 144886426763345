/* eslint-disable max-len */
import Parse from './parse';
import _ from 'lodash';
import {isNewMotor, isRolesInRoles} from './util';
import moment from 'moment';
import config from '../config/app';

let db = require('../config/dbStructure');

let isTested = async (testBatch) => {
    if(testBatch.object.get(db.TestBatch.COMPLETED) !== true){
        throw new Error('Test batch should be set to "completed".');
    }
};


async function checkFirmwareVersion(testBatch){
    let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, testBatch)
        .include(db.TestBatchDevice.DEVICE)
        .find();

    let error = false;
    let errorString = '';

    for(let testBatchDevice of testBatchDevices) {
        let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
        let deviceType = device.get(db.Device.DEVICE_TYP);
        let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);
        let serial = device.get(db.Device.SERIAL_NUMBER);

        if(
            deviceType === db.Device.DEVICE_TYP$THERM &&
            firmwareVersion !== config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO
        ){
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${serial} -> v${firmwareVersion} != v${config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO}\n`;
        }

        if(
            deviceType === db.Device.DEVICE_TYP$SENSP &&
            firmwareVersion !== config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2
        ){
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${serial} -> v${firmwareVersion} != v${config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2}\n`;
        }
    }

    if(error) throw Error(`Following devices are not configured correctly: \n$${errorString}`);
}
async function checkConfiguredDevices(supplierOrder, testBatch){

    let testBatchType = testBatch.get(db.TestBatch.TYPE);
    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    let home = supplierOrder.get(db.SupplierOrder.HOME);
    let devicesConfig = home.get(db.Home.DEVICES_CONFIG);
    let configOnDevice = _.get(devicesConfig, 'deviceConfig.data[0]');
    let wifiName = _.get(configOnDevice, 'wifiName');
    let wifiPassword = _.get(configOnDevice, 'wifiPassword');
    let error = false;
    let errorString = '';

    let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, testBatch)
        .include(db.TestBatchDevice.DEVICE)
        .find();

    let roomToConfig = await (new Parse.Query(db.classes.Room))
        .equalTo(db.Room.HOME, home)
        .equalTo(db.Room.ROOM_NAME, 'to-configure')
        .first();

    let roomToConfigName = roomToConfig.get(db.Room.ROOM_NAME);
    let roomToConfigBuildingId = roomToConfig.get(db.Room.HOME)?.id;



    if(!roomToConfig) throw new Error(`Room "to-configure" not found on building ${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)}). Please check with the IT support.`);

    for(let testBatchDevice of testBatchDevices){
        let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
        let room = device.get(db.Device.ROOM_ID);
        let roomName = room.get(db.Room.ROOM_NAME);
        let deviceType = device.get(db.Device.DEVICE_TYP);

        let configuredWifiName = device.get(db.Device.CONFIG_WIFI_SSID);
        let configuredWifiPassword = device.get(db.Device.CONFIG_WIFI_PASSWORD);
        let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);
        let serial = device.get(db.Device.SERIAL_NUMBER);
        let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
        let motorSpeed = device.get(db.Device.MOTOR_SPEED);
        let commercialLabel = device.get(db.Device.COMMERCIAL_LABEL);

        /**
         * @type {{
         *    cL: number
              fSL: number
              fSpDC: number
              maxC: number
              minC: number
              mCal: number
         * }}
         */
        let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);


        let isNewMotorFlag = isNewMotor(device);


        if(room == null || room.id !== roomToConfig.id) {
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} is currently linked to a wrong room or building. (${roomName} (${room.id}) instead of ${roomToConfigName} (${roomToConfig.id}) on ${home.get(db.Home.HOME_NAME)}) \n`;
        }

        if(isNewMotorFlag){
            if(motorSpeed && motorSpeed !== 214){
                errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> motor speed ${motorSpeed} != 214 \n`;
            }

            if(
                motorCurrentLimits &&
                motorCurrentLimits.cL &&
                (motorCurrentLimits.cL > 219  || motorCurrentLimits.cL < 150)
            ){
                error = true;
                errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> motorCurrentLimits.cL ${motorCurrentLimits.cL} > 200mA OR < 150mA \n`;
            }

            if(
                motorCurrentLimits &&
                motorCurrentLimits.fSpDC &&
                (motorCurrentLimits.fSpDC < 21  || motorCurrentLimits.fSpDC > 50)
            ){
                error = true;
                errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> motorCurrentLimits.fSpDC ${motorCurrentLimits.cL} < 25mA  OR > 50mA \n`;
            }
        }

        if(isNewMotorFlag === false) {
            if(motorSpeed && motorSpeed !== 100) {
                error = true;
                errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> motor speed ${motorSpeed} != 100 \n`;
            }
            if(motorCurrentLimits &&
                motorCurrentLimits.cL &&
                motorCurrentLimits.cL < 200
            ){
                error = true;
                errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> motorCurrentLimits.cL ${motorCurrentLimits.cL} < 200mA \n`;
            }
        }

        if(
            configuredWifiName !== wifiName ||
            configuredWifiPassword !== wifiPassword
        ) {
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> ${configuredWifiName}:${configuredWifiPassword}\n`;
        }

        if(
            deviceType === db.Device.DEVICE_TYP$THERM &&
            firmwareVersion !== config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO
        ){
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> v${firmwareVersion} != v${config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO}\n`;
        }

        if(
            deviceType === db.Device.DEVICE_TYP$SENSP &&
            firmwareVersion !== config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2
        ){
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> v${firmwareVersion} != v${config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2}\n`;
        }

        if(
            supplierOrder.get(db.SupplierOrder.CONNECTION_GUIDE_INSERT) &&
            commercialLabel !== db.Device.COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH){
            error = true;
            errorString += `${testBatch.get(db.TestBatch.NAME)} device ${device.get(db.Device.SERIAL_NUMBER)} -> commercial label != ${db.Device.COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH}\n`;
        }
    }

    if(error) throw Error(`Following devices are not configured correctly: \n Home config: ${wifiName}:${wifiPassword} \n${errorString}`);
}
async function checkBatteryDevices(supplierOrder, testBatch){
    let name = testBatch.get(db.TestBatch.NAME);
    let type = testBatch.get(db.TestBatch.TYPE);
    if(type !== db.TestBatch.TYPE$CLEVER_THERMO) return;

    let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, testBatch)
        .include(db.TestBatchDevice.DEVICE)
        .find();

    let error = false;
    let errorString = '';

    for(let testBatchDevice of testBatchDevices) {
        let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
        let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);
        let serial = device.get(db.Device.SERIAL_NUMBER);

        if(batteryVoltage < 3.9){
            error = true;
            errorString += `Batch ${name} has discharged device ${serial}.\n`;
        }
    }

    if(error){
        errorString += 'Please recharge it and turn it ON for 1-2 minutes so that it will update his battery level in to the system.';
        throw Error(errorString);
    }
}
async function getSupplierOrderFromTestBatch(testBatch){
    let supplierOrders = await (new Parse.Query(db.classes.SupplierOrder))
        .equalTo(db.SupplierOrder.TEST_BATCHES, testBatch)
        .include(db.SupplierOrder.HOME)
        .notEqualTo(db.SupplierOrder.DELETED, true)
        .find();

    if(supplierOrders.length === 0) throw Error('No supplier order is connected to this batch.');

    let closedStatuses = [
        db.SupplierOrder.STATUS$SENT_FROM_SUPPLIER,
        db.SupplierOrder.STATUS$RECEIVED_FROM_SUPPLIER
    ];

    let openSupplierOrders = supplierOrders.filter(supplierOrder => {
        let status = supplierOrder.get(db.SupplierOrder.STATUS);

        return closedStatuses.indexOf(status) < 0;
    })

    if(openSupplierOrders.length > 1)
        // eslint-disable-next-line max-len
        throw Error(`This batch is already assigned to too many open orders: ${openSupplierOrders.map(supplierOrder => supplierOrder.get(db.SupplierOrder.REFERENCE_NUMBER)).join(',')}. Aborting.`);


    return openSupplierOrders[0];
}
let resetForceLedConfiguredColor = async (testBatch) => {
    let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, testBatch)
        .include(db.TestBatchDevice.DEVICE)
        .find();

    let devices = testBatchDevices.map(testBatchDevice => testBatchDevice.get(db.TestBatchDevice.DEVICE));

    for(let device of devices){
        device.unset(db.Device.FORCE_LED_COLOR);
    }

    await Parse.Object.saveAll(devices);
}

let setForceLedColor = async (testBatch) => {
    let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, testBatch)
        .include(db.TestBatchDevice.DEVICE)
        .find();

    let devicesToSave = [];
    for(let testBatchDevice of testBatchDevices) {
        let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);

        device.set(db.Device.FORCE_LED_COLOR, db.Device.FORCE_LED_COLOR$WHITE);

        devicesToSave.push(device);
    }

    await Parse.Object.saveAll(devicesToSave);
}

let canBeSetToReadyToConfig = async (stateMaschine, force) => {
    let testBatch = await stateMaschine.object.fetch();

    let testBatchDevices = await (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, testBatch)
        .notEqualTo(db.TestBatchDevice.DELETED, true)
        .find();

    if(testBatchDevices.length === 0 && force !== true)
        throw new Error('No devices are connected to this batch. Please add at least 1 devices to the batch.');

    let supplierOrder = await getSupplierOrderFromTestBatch(testBatch);

    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    if(force === true) return;

    let lastConnectionCheck = testBatch.get(db.TestBatch.LAST_CONNECTION_CHECK);

    if(lastConnectionCheck == null)
        throw new Error('Connection must be checked before setting this status.');

    let isLastConnectionCheckLessThanXMinutes = Math.abs(moment(lastConnectionCheck).diff(moment(), 'minutes')) <= 5;

    if(!isLastConnectionCheckLessThanXMinutes)
        throw new Error('Connection must be checked before setting this status.');

    let devicesNotOk = testBatchDevices.filter(testBatchDevice => testBatchDevice.get(db.TestBatchDevice.CONNECTED) !== 'connected');

    if(devicesNotOk.length > 0){
        throw new Error('Not all devices are connected and in debug mode to be able to receive the new configuration. Please re-check the connection of all devices connected to this batch.')
    }
};
let canBeSetToConfigured = async (stateMachine) => {
    let testBatch = stateMachine.getObject();

    let supplierOrder = await getSupplierOrderFromTestBatch(testBatch);

    let configuration = supplierOrder.get(db.SupplierOrder.CONFIGURATION);

    if(configuration === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) {
        await checkFirmwareVersion(testBatch); //Check firmware version also if offline

        return;
    }

    await checkConfiguredDevices(supplierOrder, testBatch);

    await checkBatteryDevices(supplierOrder, testBatch);
};
let canBeSetToReadyToBeSent = async (sm) => {
    let testBatch = sm.getObject();

    let testBatchType = testBatch.get(db.TestBatch.TYPE);

    if(testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2) return;

    let supplierOrder = await getSupplierOrderFromTestBatch(testBatch);
    let supplierOrderType = supplierOrder.get(db.SupplierOrder.CONFIGURATION);

    if(supplierOrderType === db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY) return;

    await checkBatteryDevices(supplierOrder, testBatch);
}

let afterSetToTurnOn = async (stateMachine) => {
    let testBatch = await stateMachine.object.fetch();
}
let afterConfigured = async (stateMachine) => {
    let testBatch = await stateMachine.object.fetch();

    testBatch.set(db.TestBatch.TEST_MODE_ACTIVE, false);

    await testBatch.save();
}

let afterTested = async (stateMachine) => {
    let testBatch = await stateMachine.object.fetch();

    testBatch.set(db.TestBatch.TEST_MODE_ACTIVE, false);

    await testBatch.save();
}

let afterReadyToBeSent = async (stateMachine) => {
    let testBatch = await stateMachine.object.fetch();

    await resetForceLedConfiguredColor(testBatch);
}

//module.exports = {
export default {
    init: db.TestBatch.STATUS$TO_TEST,
    greenStates: {
        Supplier: [
            db.TestBatch.STATUS$TESTED,
            db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION,
            db.TestBatch.STATUS$READY_TO_CONFIG,
            db.TestBatch.STATUS$SENT_FROM_SUPPLIER,
            db.TestBatch.STATUS$NO_STATUS
        ],
        Admin: [
            db.TestBatch.STATUS$TO_TEST,
            db.TestBatch.STATUS$TO_TURN_ON,
            'deleyed',
            db.TestBatch.STATUS$CONFIGURED,
            db.TestBatch.STATUS$READY_TO_BE_SENT,
            'sent-from-installer-to-supplier'
        ],
        Installer: []
    },
    orangeStates: {
        Supplier: [
            db.TestBatch.STATUS$TO_TEST,
            db.TestBatch.STATUS$TO_TURN_ON,
            db.TestBatch.STATUS$CONFIGURED,
            db.TestBatch.STATUS$READY_TO_BE_SENT,
            'sent-from-installer-to-supplier'
        ],
        Admin: [
            db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION,
            db.TestBatch.STATUS$READY_TO_CONFIG,
            db.TestBatch.STATUS$SENT_FROM_SUPPLIER
        ],
        Installer: []
    },
    supplierStates: [
        db.TestBatch.STATUS$TO_TEST,
        db.TestBatch.STATUS$TESTED,
        db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION,
        db.TestBatch.STATUS$TO_TURN_ON,
        db.TestBatch.STATUS$READY_TO_CONFIG,
        db.TestBatch.STATUS$CONFIGURED,
        db.TestBatch.STATUS$READY_TO_BE_SENT,
        db.TestBatch.STATUS$SENT_FROM_SUPPLIER,
        db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION,
        db.TestBatch.STATUS$NO_STATUS,
    ],
    installerStates: [
        db.TestBatch.STATUS$SENT_FROM_SUPPLIER,
        db.TestBatch.STATUS$RECEIVED_FROM_SUPPLIER,
        'in-preparation-to-install-to-customer',
        'ready-to-install-to-customer',
        'installed-to-customer',
        'installation-concluded',
        'returned-from-customer-to-installer',
        'sent-from-installer-to-supplier'
    ],
    transitions: [
        {name: 'setToToTest', to: db.TestBatch.STATUS$TO_TEST, type: 'manual', roles: ['Admin']},
        {name: 'setToTested', from: db.TestBatch.STATUS$TO_TEST, to: db.TestBatch.STATUS$TESTED, type: 'manual', roles: ['Supplier'], requirementsOk: isTested, postStateChange: afterTested},
        {name: 'setReservedForInstallation', from: db.TestBatch.STATUS$TESTED, to: db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION, type: 'auto', roles: ['Admin']},
        {name: 'setToTurnOn', from: db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION, to: db.TestBatch.STATUS$TO_TURN_ON, type: 'auto', roles: ['Admin'], postStateChange: afterSetToTurnOn},
        {name: 'setReadyToConfig', from: db.TestBatch.STATUS$TO_TURN_ON, to: db.TestBatch.STATUS$READY_TO_CONFIG, type: 'auto', roles: ['Supplier'], requirementsOk: canBeSetToReadyToConfig},
        {name: 'setConfigured', from: db.TestBatch.STATUS$READY_TO_CONFIG, to: db.TestBatch.STATUS$CONFIGURED, type: 'auto', roles: ['Admin'], requirementsOk: canBeSetToConfigured, postStateChange: afterConfigured},
        {name: 'setReadyToBeSent', from: db.TestBatch.STATUS$CONFIGURED, to: db.TestBatch.STATUS$READY_TO_BE_SENT, type: 'auto', roles: ['Supplier'], requirementsOk: canBeSetToReadyToBeSent, postStateChange: afterReadyToBeSent},
        {name: 'setSentFromSupplier', from: db.TestBatch.STATUS$READY_TO_BE_SENT, to: db.TestBatch.STATUS$SENT_FROM_SUPPLIER, type: 'auto', roles: ['Supplier', 'Installer']},
        {name: 'setReceivedFromSupplier', from: db.TestBatch.STATUS$SENT_FROM_SUPPLIER, to: db.TestBatch.STATUS$RECEIVED_FROM_SUPPLIER, type: 'auto', roles: ['Installer']},
        {name: 'setInPreparationToInstallToCustomer', from: db.TestBatch.STATUS$RECEIVED_FROM_SUPPLIER, to: 'in-preparation-to-install-to-customer', type: 'auto', roles: ['Installer']},
        {name: 'setReadyToInstallToCustomer', from: 'in-preparation-to-install-to-customer', to: 'ready-to-install-to-customer', type: 'auto', roles: ['Installer']},
        {name: 'setInstalledToCustomer', from: 'ready-to-install-to-customer', to: 'installed-to-customer', type: 'auto', roles: ['Installer']},
        {name: 'setInstallationConcluded', from: 'installed-to-customer', to: 'installation-concluded', type: 'auto',},
        {name: 'setSentFromInstallerToSupplier', from: 'returned-from-customer-to-installer', to: 'sent-from-installer-to-supplier', type: 'auto', roles: ['Installer']},
        {name: 'setReceivedToSupplierFromInstaller', from: 'sent-from-installer-to-supplier', to: 'received-to-supplier-from-installer', type: 'auto', roles: ['Supplier']},
        {name: 'setInPreparationForConfig', from: 'received-to-supplier-from-installer', to: db.TestBatch.STATUS$TO_TEST, type: 'auto', roles: ['Supplier']}
    ],
    batchRequireAction: function (status, roles) {
        let role = isRolesInRoles(roles, ['Supplier']) ? 'Supplier': null;
        role = isRolesInRoles(roles, ['Installer']) ? 'Installer': role;
        role = isRolesInRoles(roles, ['Admin']) ? 'Admin': role;


        let states = this.orangeStates[role] || [db.TestBatch.STATUS$NO_STATUS];
        if(states.indexOf(status) >= 0){
            return true;
        }

        return false;
    }
};