const _ = require('lodash');

const dbStructure = {
    classes: {
        _User: '_User',
        _Session: '_Session',
        Device: 'Device',
        Room: 'Room',
        Measurement: 'Measurement',
        CalendarEvent: 'CalendarEvent',
        Home: 'Home',
        HomeSystem: 'HomeSystem',
        ProgramEvent: 'ProgramEvent',
        PresencePreset: 'PresencePreset',
        RoomPresencePreset: 'RoomPresencePreset',
        Newsletter: 'Newsletter',
        TestClass: 'TestClass',
        CommandQueue: 'CommandQueue',
        DeviceState: 'DeviceState',
        RoomReport: 'RoomReport',
        HomeReport: 'HomeReport',
        PrintJobQueue: 'PrintJobQueue',
        TestBatchDevice: 'TestBatchDevice',
        TestBatch: 'TestBatch',
        Installation: 'Installation',
        DeviceHistory: 'DeviceHistory',
        RoomFeedback: 'RoomFeedback',
        RoomHistory: 'RoomHistory',
        RoomPresencePresetHistory: 'RoomPresencePresetHistory',
        DeviceTicket: 'DeviceTicket',
        RoomReportComments: 'RoomReportComments',
        HomeReportPhrase: 'HomeReportPhrase',
        DocumentDownload: 'DocumentDownload',
        RoomInstallationId: 'RoomInstallationId',
        InstallationTestBatch: 'InstallationTestBatch',
        Task: 'Task',
        LMAddress: 'LMAddress',
        RoomInstallationIdPage: 'RoomInstallationIdPage',
        SupplierOrder: 'SupplierOrder',
        OperationTask: 'OperationTask',
        RoomTicket: 'RoomTicket',
        IssueCategory: 'IssueCategory',
        RoomType: 'RoomType',
        DeviceFirmware: 'DeviceFirmware',
        TestBatchDeviceHistory: 'TestBatchDeviceHistory'
    },
    commands: {
        START_DEBUG:  'startDebug',
        STOP_DEBUG:  'stopDebug',
        UPDATE_FIRMWARE:  'updateFirmware',
        CHANGE_COMMUNICATION_CONFIG:  'changeCommunicationConfig',
        CHANGE_MOTOR_POSITION:  'changeMotorPosition',
        CHANGE_SLEEP_TIME:  'changeSleepTime',
        CHANGE_EMERGENCY_TEMPERATURE:  'changeEmergencyTemperature',
        CHANGE_MOTOR_CURRENT: 'changeMotorCurrent',
        SET_LED_COLOR: 'setLEDcolor',
        CHANGE_MOTOR_SPEED: 'changeMotorSpeed',
        CALIBRATE_MOTOR: 'calibrateMotor',
        CALIBRATE_TEMP: 'calibrateTemp',
        START_TEST_NB: 'startTestNb',
        STOP_TEST_NB: 'stopTestNb',
    },
    roles: {
        ADMIN: 'Admin',
        LEAN_MANAGEMENT: 'Lean management',
        BUILDING_ADMIN: 'Building Admin',
        INSTALLER: 'Installer',
        REDACTOR: 'Redactor',
        SUPPLIER: 'Supplier',
        SUPPORT: 'Support'
    },
    _User: {
        MONGO: {
            LINKED_HOME: '_p_linkedHome',
            LINKED_DEVICE: '_p_linkedDevice'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USERNAME: 'username',
        USERNAME$JACKUTEK: 'JakuTec',
        USERNAME$MOSER_BAER:'Moser-Baer',
        PASSWORD: 'password',
        EMAIL: 'email',
        EMAIL_VERIFIED: 'emailVerified',
        AUTH_DATA: 'authData',
        PHONE: 'phone',
        UTC_TIME: 'utcTime',
        NAME: 'name',
        LAST_NAME: 'lastName',
        LOCATION: 'location',
        CITY: 'city',
        IS_DEVICE: 'isDevice',
        IS_COUPLED: 'isCoupled',
        LINKED_DEVICE: 'linkedDevice',
        LINKED_HOME: 'linkedHome',
        LANGUAGE: 'language',
        TYPE_OF_CUSTOMER: 'typeOfCustomer',
        TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER: 'clever-sense-customer',
        TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER: 'full-system-customer',
        TYPE_OF_CUSTOMER$INSTALLER_PARTNER: 'installer-partner',
        FIRST_NAME: 'firstName',
        ACTIVE: 'active',
        DELETED: 'deleted'
    },
    _Role: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        USERS: 'users',
        ROLES: 'roles',
        WHERE: 'where',
        LIMIT: 'limit'
    },
    _Session: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        RESTRICTED: 'restricted',
        USER: 'user',
        INSTALLATION_ID: 'installationId',
        SESSION_TOKEN: 'sessionToken',
        EXPIRES_AT: 'expiresAt',
        CREATED_WITH: 'createdWith'
    },
    Device: {
        MONGO: {
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at',
            LAST_MEASUREMENT: '_p_lastMeasurement',
            OWNER: '_p_owner',
            ROOM_ID: '_p_roomId'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DEVICE_NAME: 'deviceName',
        OWNER: 'owner',
        ROOM_ID: 'roomId',
        TEMP_MIN: 'tempMin',
        TEMP_DEFAULT: 'tempDefault',
        MAC_ADDRESS: 'macAddress',
        DEVICE_CONFIG: 'deviceConfig',
        STATIC_IP: 'staticIp',
        DEVICE_STATE_FLAG: 'deviceStateFlag',
        DEVICE_STATE_FLAG$IN_MOUNT: 'in-mount',
        DEVICE_STATE_FLAG$INACTIVE: 'inactive',
        DEVICE_STATE_FLAG$ONLINE: 'online',
        DEVICE_STATE_FLAG$WORK_IN_PROGRESS: 'work-in-progress',
        DEVICE_STATE_FLAG$_CLIENT_ACTION_REQUIRED: 'client-action-required',
        MOTOR_MOUVEMENTS: 'motorMouvements',
        TOTAL_MEASUREMENTS: 'totalMeasurements',
        MOTOR_MOUVEMENT_SAVE_RATIO: 'motorMouvementSaveRatio',
        DEVICE_TYP: 'deviceTyp',
        DEVICE_TYP$THERM: 'therm',
        DEVICE_TYP$SENSE: 'sense',
        DEVICE_TYP$SENCO: 'senco',
        DEVICE_TYP$SENSP: 'sensp',
        DEVICE_TYP$ROUTER_RUT240: 'router-rut240',
        SERIAL_NUMBER: 'serialNumber',
        INSTALLATION_INFO: 'installationInfo',
        MOTOR_ENERGY_SAVING_ACTIVE: 'motorEnergySavingActive',
        LAST_MEASUREMENT_DATE: 'lastMeasurementDate',
        LAST_MEASUREMENT: 'lastMeasurement',
        CORRECTION_TEMP: 'correctionTemp',
        WIFI_STRENGTH: 'wifiStrength',
        BATTERY_VOLTAGE: 'batteryVoltage',
        MODE: 'mode',
        HOME: 'home',
        DISCHARGE_SLOPE: 'dischargeSlope',
        LAST_MODE_CHANGE: 'lastModeChange',
        LAST_DEVICE_STATE_FLAG_CHANGE: 'lastDeviceStateFlagChange',
        CO2: 'co2',
        MEASURED_TEMP: 'measuredTemp',
        HUMIDITY: 'humidity',
        PRESENCE: 'presence',
        MOTOR_POSITION: 'motorPosition',
        LOCAL_IP: 'localIp',
        VERSION_FIRMWARE: 'versionFirmware',
        CONFIG_WIFI_SSID: 'configWifiSsid',
        CONFIG_WIFI_PASSWORD: 'configWifiPassword',
        CONNECTION_WIFI_SSID: 'connectionWifiSsid',
        CONNECTION_WIFI_PASSWORD: 'connectionWifiPassword',
        LAST_RESPONSE_MOTOR_COMMAND_VALUE: 'lastResponseMotorCommandValue',
        LAST_RESPONSE_MOTOR_COMMAND_VALUE_DATE: 'lastResponseMotorCommandValueDate',
        LAST_RESPONSE_SLEEP_COMMAND_VALUE: 'lastResponseSleepCommandValue',
        COMMERCIAL_LABEL: 'commercialLabel',
        COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH: 'available-on-brack-ch',
        HEADER_X_REQUEST_PORT: 'headerXRequestPort',
        HEADER_REQUEST_HOST: 'headerRequestHost',
        MOTOR_CURRENT_LIMITS: 'motorCurrentLimits',
        MOTOR_SPEED: 'motorSpeed',
        FIRMWARE_TESTING_ACTIVE: 'firmwareTestingActive',
        FIRMWARE_TESTING_STATUS: 'firmwareTestingStatus',
        FORCE_LED_COLOR: 'forceLedColor',
        FORCE_LED_COLOR$TURNED_OFF: 'turnedOff',
        FORCE_LED_COLOR$GREEN: 'green',
        FORCE_LED_COLOR$YELLOW: 'yellow',
        FORCE_LED_COLOR$RED: 'red',
        FORCE_LED_COLOR$WHITE: 'white',
        LED_COLOR_CONFIG: 'ledColorConfig',
        MOUNTED_ENGINE: 'mountedEngine',
        MOUNTED_ENGINE$MOTOR_16_20: 'motor_16-20',
        TEMPERATURE: 'temperature',
        ROOM_TEMP_CORRECTION: 'roomTempCorrection',
        NUMBER_MOTOR_POSITION_VALUE: 'numberMotorPositionValue',
        NUMBER_MOTOR_RESPONSE_VALUE: 'numberMotorResponseValue',
        NUMBER_COMMAND_VALUE: 'numberCommandValue',
        /**
         * {100: 1, 101: 2, 102: 3, 101}
         */
        NUMBER_CHANGE_MOTOR_CURRENT_COMMANDS: 'numberChangeMotorCurrent',
        NUMBER_CALIBRATION_COMMANDS: 'numberCalibrationCommands',
        NUMBER_CONSECUTIVE_MOTOR_RESPONSE_VALUE: 'numberConsecutiveMotorResponseValue',
        MAX_CONSECUTIVE_MOTOR_RESPONSE_VALUE: 'maxConsecutiveMotorResponseValue',
        LAST_CALIBRATION_COMMAND: 'lastCalibrationCommand',
        LAST_CALIBRATION_RESULT: 'lastCalibrationResult',
        MOTOR_TAG: 'motorTags', //Array of string
        MOTOR_TAG$ERROR_CALIBRATION: 'errorCalibration',
        MOTOR_TAG$ERROR_FAULT_MOTOR: 'errorFaultMotor',
        MOTOR_TAG$ERROR_STUCK_MOTOR: 'errorStuckMotor',
        MOTOR_TAG$ERROR_BROKEN_MOTOR: 'errorBrokenMotor',
        MOTOR_CURRENT_RESULT: 'motorCurrentResult', //Object
        HISTORY_MOTOR_TAGS: 'historyMotorTags',
        COUNTER_MOTOR_TAGS: 'counterMotorTags',
        MOTOR_ANALYSIS_KPI: 'motorAnalysisKpi',
        /* [
            {
                response: ''
            }
        ]*/
        NEXT_WANTED_COMMAND: 'nextWantedCommand',
        NEXT_WANTED_COMMAND$HEAT: 'heat',
        NEXT_WANTED_COMMAND$NO_HEAT: 'no-heat',
        DATE_ADDED_NEXT_WANTED_COMMAND: 'dateAddedNextWantedCommand',
        DATE_EXECUTED_NEXT_WANTED_COMMAND: 'dateExecutedNextWantedCommand',
        DIAGNOSTIC_PREDICTED_STATE: 'diagnosticPredictedState',
        DIAGNOSTIC_PREDICTION_EXPLAIN: 'diagnosticPredictionExplain',
        DEFECT_SCORE: 'defectScore',
        DEFECT_SCORE_NORMALIZED: 'defectScoreNormalized',
        DEFECT_SCORE_EXPLAIN: 'defectScoreExplain',
        DEFECT_PREDICTION: 'defectPrediction',
        DEFECT_PREDICTION$DEFECT: 'defect',
        DEFECT_PREDICTION$NO_DEFECT: 'no-defect',
        DEFECT_ACTUAL_STATUS: 'defectActualStatus',
        DEFECT_ACTUAL_STATUS$DEFECT: 'defect',
        DEFECT_ACTUAL_STATUS$NO_DEFECT: 'no-defect',
        LAST_MOTOR_ANALYSIS: 'lastMotorAnalysis',
        TEMP_TARGET_TEMP_RMSE: 'tempTargetTempRmse',
        NUMBER_OF_MEASUREMENTS: 'numberOfMeasurements',
        NUMBER_ROOM_FEEDBACKS: 'numberRoomFeedbacks',
        NB_IOT_ENABLED: 'nbIotEnabled',
        ICCID: 'iccid',
        NB_IOT_SIGNAL_QUALITY: 'nbIotSignalQuality',
        REFURBISHED_DATE: 'refurbishedDate',
        LAST_CONNECTION_PROTOCOL: 'lastConnectionProtocol',
        LAST_CONNECTION_PROTOCOL$ESP_NOW: 'esp-now',
        LAST_CONNECTION_PROTOCOL$NB_IOT: 'nb-iot',
        LAST_CONNECTION_PROTOCOL$WIFI: 'wifi',
        LAST_NB_IOT_CONNECTION: 'lastNbIotConnection',
        NB_IOT_FIRMWARE_VERSION: 'nbIotFirmwareVersion',
        DELETED: 'deleted'
    },
    Room: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM_NAME: 'roomName',
        ROOM_NAME$TO_CONFIGURE: 'to-configure',
        TEMP_MIN: 'tempMin',
        TEMP_MAX: 'tempMax',
        AREA: 'area',
        HEIGHT: 'height',
        WINDOWS: 'windows',
        HOME: 'home',
        DATA: 'data',
        PRESENCE_FORECAST_MATRIX: 'presenceForecastMatrix',
        STATE_FLAG: 'stateFlag',
        CORRECTION_TEMP: 'correctionTemp',
        FLOOR: 'floor',
        MAIN_PHOTO: 'mainPhoto',
        NUMBER_RADIATORS: 'numberRadiators',
        NUMBER_SENSE: 'numberSense',
        NUMBER_SENSP: 'numberSensp',
        NUMBER_SENCO: 'numberSenco',
        MOTOR_ON_VALUE: 'motorOnValue',
        PID_ACTIVE: 'pidActive',
        MIN_VALUE_WHEN_MAX_TEMP_PRESET: 'minValueWhenMaxTempPreset',
        OLD_TEMP_MAX: 'oldTempMax',
        OLD_TEMP_MIN: 'oldTempMin',
        PRESENCE_FORECAST_ENABLED: 'presenceForecastEnabled',
        DEVICE_CONFIG: 'deviceConfig',
        HIDE: 'hide',
        EMERGENCY_TEMPERATURE: 'emergencyTemperature',
        MONITORING_ACTIVATED: 'monitoringActivated',
        SUN_RADIATED: 'sunRadiated',
        TEMPERATURE_PREDICTION_FORMULA: 'temperaturePredictionFormula',
        HEATING_SLOPE_ACTIVE: 'heatingSlopeActive',
        ENERGY_SAVING_PERCENTAGE: 'energySavingPercentage',
        ROOM_PROGRAM: 'roomProgram',
        PRESENCE_DATA_CONFIRMATION_DATE: 'presenceDataConfirmationDate',
        PRESENCE_DATA_CONFIRMATION_USER: 'presenceDataConfirmationUser',
        UNIQUE_ID: 'uniqueId',
        ROOM_SIZE: 'roomSize',
        ROOM_TYPE: 'roomType',
        MAIN_PHOTO_URL: 'mainPhotoUrl',
        THERMO_SLEEP_TIME_MINUTES: 'thermSleepTimeMinutes',
        INSTALLATION_COMPLETED: 'installationCompleted',
        INSTALLATION_COMMENT: 'installationComment',
        INSTALLATION_COMPLETED_DATE: 'installationCompletedDate',
        ROOM_CODE: 'roomCode',
        AVG_TEMP_LAST_HOUR: 'avgTempLastHour',
        AVG_HUM_LAST_HOUR: 'avgHumLastHour',
        LAST_UPDATE_AVG_TEMP: 'lastUpdateAvgTemp',
        DESCRIPTION_COMMENT: 'installationDescription',
        RADIATORS_SIZE: 'radiatorsSize',
        DELETED: 'deleted',
        HIDDEN: 'hidden',
        CO2: 'co2',
        WEEKLY_PRESENCE_FORECAST: 'weeklyPresenceForecast',
        LAST_MONTHLY_PRESENCE_FORECAST_UPDATE: 'lastMonthlyPresenceForecastUpdate',
        TECHNICAL_COMMENTS: 'technicalComments',
        INSTALLATION_TEMPERATURE: 'installationTemperature',
        INSTALLATION_SENSOR_POSITION: 'installationSensorPosition',
        ROOM_TYPE$RESERVE: 'reserve',
        BUILDING_USER_MAX_TEMP_CORRECTION: 'buildingUserMaxTempCorrection',
        BUILDING_USER_MIN_TEMP_CORRECTION: 'buildingUserMinTempCorrection',
        LAST_WINDOWS_OPENING_DETECTION_MODEL_TRAINING: 'lastWindowsOpeningDetectionModelTraining',
        WINDOWS_DETECTION_PARAMETERS: 'windowsDetectionParameters',
        FORCE_HEATING_X_HOURS_BEFORE: 'forceHeatingXHoursBefore',
        INSTALLATION_DESCRIPTION_COMMENT: 'installationDescriptionComment',
        THERMO_CORRECTION_TEMP: 'thermoCorrectionTemp',
        SHOW_ONLY_SENSOR_ON_WEB_APP_CHART: 'showOnlySensorOnWebAppChart',
        FORCE_WIFI_ON_ESP_NOW_DEVICES: 'forceWifiOnEspNowDevices'
    },
    Measurement: {
        MONGO: {
            TEMP: 'temp',
            TARGET_TEMP: 'target_temp',
            DEVICE_ID: '_p_deviceId',
            ROOM_ID: '_p_roomId',
            DEVICE_TYP: 'deviceTyp',
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at',
            REQUEST: 'request',
            WEATHER_TEMP_C: 'weather_temp_c',
            WEATHER_RELATIVE_HUMIDITY: 'weather_relative_humidity',
            MEASURED_TEMP: 'measuredTemp',
            HUM: 'hum',
            MAC_ADDRESS: 'macAddress',
            PRESENCE: 'presence',
            DEVICET_TYP: 'deviceTyp',
            WPERM: '_wperm',
            RPERM: '_rperm',
            ACL: '_acl',
            EXPECTED_TEMP: 'expected_temp',
            MOTOR_TARGET_STATE: 'motorTargetState',
            RESPONSE: 'response'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEMP: 'temp',
        HUM: 'hum',
        PRESENCE: 'presence',
        MAC_ADDRESS: 'macAddress',
        DEVICE_ID: 'deviceId',
        ROOM_ID: 'roomId',
        MESSAGE: 'message',
        WEATHER_TEMP_C: 'weather_temp_c',
        WEATHER_RELATIVE_HUMIDITY: 'weather_relative_humidity',
        VOLTAGE: 'voltage',
        DEVICE_TYP: 'deviceTyp',
        MOTOR_STATE: 'motorState',
        TARGET_TEMP: 'targetTemp',
        MOTOR_TARGET_STATE: 'motorTargetState',
        EXPECTED_TEMP: 'expected_temp',
        REQUEST: 'request',
        RESPONSE: 'response',
        CO2: 'co2',
        MEASURED_TEMP: 'measuredTemp',
        CURRENT_TARGET_TEMP_ON_PROGRAM: 'currentTargetTempOnProgram',
        BATTERY_VOLTAGE: 'batteryVoltage',
        MEMORY_BYTES: 'memoryBytes',
        MODE: 'mode',
        MODE$DEBUG: 'debug',
        MOTOR_CURRENT_LIMIT: 'motorCurrentLimit',
        ROOM_MIN_TEMP: 'roomMinTemp',
        ROOM_MAX_TEMP: 'roomMaxTemp',
        CURRENT_ROOM_TEMP: 'currentRoomTemp',
        REQUEST_DURATION_MS: 'requestDurationMs',
        VERSION_FIRMWARE: 'versionFirmware',
        VERSION_HARDWARE: 'versionHardware',
        VERSION_PROTOCOL: 'versionProtocol',
        HEADER_X_REQUEST_PORT: 'headerXRequestPort',
        HEADER_REQUEST_HOST: 'headerRequestHost',
        WIFI_NAME: 'wifiName',
        WIFI_PASSWORD: 'wifiPassword',
        SERVER_ADDRESS: 'serverAddress',
        SERVER_PORT: 'serverPort',
        WIFI_STRENGTH: 'wifiStrength',
        IP: 'ip',
        OFFLINE_MODE: 'offlineMode',
        MOTOR_POSITION: 'motorPosition',
        EMERGENCY_TEMPERATURE: 'emergencyTemperature',
        RESPONSE_MOTOR_COMMAND_VALUE: 'responseMotorCommandValue',
        RESPONSE_COLOR: 'responseColor',
        RESPONSE_SLEEP_COMMAND_VALUE: 'responseSleepCommandValue'
    },
    Home: {
        ACL: 'ACL',
        ADDRESS: 'address',
        ALLOW_ADMIN_ACTIONS: 'allowAdminActions',
        ALLOW_UPDATE: 'allowUpdate',
        AREA: 'area',
        AREA_WALL: 'areaWall',
        BUILDING_IMAGE: 'buildingImage',
        CANTON: 'canton',
        CITY: 'city',
        CO2_PREDICTION_ACTIVATED: 'co2PredictionActivated',
        COUNTRY: 'country',
        CREATED_AT: 'createdAt',
        DELETED: 'deleted',
        DEVICES_CONFIG: 'devicesConfig',
        DEVICES_OFFLINE_NOTIFICATION: 'devicesOfflineNotification',
        EMISSIONS_REDUCTION_POTENTIAL_KG: 'emissionsReductionPotentialKg',
        ENERGY_REPORT_COMPLIANT: 'energyReportCompliant',
        ENERGY_REPORT_NOTIFICATION: 'energyReportNotification',
        HEATING_SYSTEM_TYPE: 'heatingSystemType',
        HIDDEN: 'hidden',
        HOME_NAME: 'homeName',
        INSTALLATION_STATUS$DEMOUNTED: 'demounted',
        INSTALLATION_STATUS$ONLINE: 'online',
        INSTALLATION_STATUS$TO_INSTALL: 'to-install',
        INSTALLATION_STATUS: 'installationStatus',
        LANGUAGE: 'language',
        MONITORING_ACTIVATED: 'monitoringActivated',
        MOTOR_ENERGY_SAVING_ACTIVE: 'motorEnergySavingActive',
        MOTOR_ON_VALUE: 'motorOnValue',
        NUMBER_RADIATORS: 'numberRadiators',
        NUMBER_SENCO: 'numberSenco',
        NUMBER_SENSE: 'numberSense',
        NUMBER_SENSP: 'numberSensp',
        OBJECT_ID: 'objectId',
        OFFLINE_DEVICES_NOTIFICATION: 'offlineDevicesNotification',
        OWNER: 'Owner',
        PEOPLE: 'people',
        PID_ACTIVE: 'pidActive',
        PRESENCE_FORECAST_WEEK: 'presenceForecastWeek',
        RELATIVE_HUMIDITY: 'relative_humidity',
        SAVING_PERCENTAGE: 'savingPercentage',
        SENSP_COLOR_OFF: 'senspColorOff',
        SUMMER_MODE: 'summerMode',
        SYSTEM: 'system',
        TEMP_C: 'temp_c',
        TIME_ZONE: 'time_zone',
        TYPE: 'type',
        TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER: 'clever-sense-customer',
        TYPE_OF_CUSTOMER$CUSTOMER: 'customer',
        TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER: 'full-system-customer',
        TYPE_OF_CUSTOMER: 'typeOfCustomer',
        UPDATED_AT: 'updatedAt',
        U_VALUE: 'uValue',
        VOLUME: 'volume',
        WEATHER_FORECAST_TODAY: 'weatherForecastToday',
        WINDOWS: 'windows',
        YEAR: 'year',
        ZIP: 'zip',
        INSTALLER: 'installer'
    },
    HomeSystem: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        SYSTEM_NAME: 'systemName',
        PRICE_KWH: 'priceKwh',
        CO_EMISSION: 'coEmission',
        HEAT_COMBUSTION: 'heatCombustion'
    },
    ProgramEvent: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TITLE: 'title',
        START: 'start',
        END: 'end',
        ALL_DAY: 'allDay'
    },
    PresencePreset: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        PRESENCE_PRESET: 'presencePreset'
    },
    RoomPresencePreset: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        PRESENCE_PRESET: 'presencePreset',
        APPLIES_AT: 'appliesAt',
        IS_FIXED: 'isFixed',
        APPLIES_AT_DAY: 'appliesAtDay',
        APPLIES_AT_UTC: 'appliesAtUtc'
    },
    Newsletter: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        EMAIL: 'email'
    },
    TestClass: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEST: 'test'
    },
    CommandQueue: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        COMMAND_NAME: 'commandName',
        DATA: 'data',
        DEVICE: 'device',
        ROOM: 'room',
        USER: 'user',
        ADDED_FROM: 'addedFrom',
        ADDED_FROM$CLOUD: 'cloud',
        ADDED_FROM$TEMPERATURE_FEEDBACK: 'temp-feedback',
        ADDED_FROM$MANUAL: 'manual',
        DELETED: 'deleted'
    },
    DeviceState: {
        MONGO: {
            ID: '_id',
            BATTERY_VOLTAGE: 'batteryVoltage',
            MODE: 'mode',
            DEVICE: '_p_device',
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        BATTERY_VOLTAGE: 'batteryVoltage',
        MEMORY_BYTES: 'memoryBytes',
        MODE: 'mode',
        DEBUG: 'debug',
        DEVICE: 'device',
        MOTOR_CURRENT_LIMIT: 'motorCurrentLimit'
    },
    HomeReport: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DATA: 'data',
        FROM_DATE: 'fromDate',
        TO_DATE: 'toDate',
        HOME: 'home',
        GENERATED_AT: 'generatedAt',
        STATUS: 'status',
        STATUS$DATA_UPDATED: 'data-updated',
        STATUS$PUBLISHED: 'published',
        STATUS$TO_CHECK: 'to-check',
        PUBLISHED_AT: 'publishedAt',
        MONTH: 'month',
        YEAR: 'year',
        ROOM_COMMENTS: 'roomComments'
    },
    PrintJobQueue: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        PRINTER_ID: 'printerId',
        SERIES_N: 'seriesN',
        MAC_ADDRESS: 'macAddress',
        DEVICE_TYPE: 'deviceType',
        STATUS: 'status',
        STATUS$IN_QUEUE: 'in-queue'
    },
    TestBatchDevice: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DESCRIPTION: 'description',
        DEVICE: 'device',
        BATCH: 'batch',
        CONNECTED: 'connected',
        BATTERY_TEST: 'batteryTest',
        VISUAL_TEST: 'visualTest',
        MOTOR_UP_TEST: 'motorUpTest',
        MOTOR_DOWN_TEST: 'motorDownTest',
        MOTOR_UP_TEST_MODIFIED_DATE: 'motorUpTestModifiedDate',
        MOTOR_DOWN_TEST_MODIFIED_DATE: 'motorDownTestModifiedDate',
        NB_IOT_CONNECTION_TEST: 'nbIotConnectionTest',
        TESTED: 'tested',
        DATE_TESTED: 'dateTested',
        TESTED_BY: 'testedBy',
        CURRENT_BATTERY_LEVEL: 'currentBatteryLevel',
        TEMPERATURE_TEST: 'temperatureTest',
        CURRENT_TEMPERATURE: 'currentTemperature',
        CURRENT_CORRECTION_NEEDED: 'currentCorrectionNeeded',
        REFERENCE_TEMPERATURE: 'referenceTemperature',
        CO2_TEST: 'co2Test',
        REFERENCE_CO2: 'referenceCo2',
        CURRENT_CO2: 'currentCo2',
        DEVICE_TYP: 'deviceTyp',
        REFURBISHED_DATE: 'refurbishedDate'
    },
    TestBatch: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        DESCRIPTION: 'description',
        TEST_MODE_ACTIVE: 'testModeActive',
        MOTOR_UP_TEST_ACTIVE: 'motorUpTestActive',
        MOTOR_DOWN_TEST_ACTIVE: 'motorDownTestActive',
        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        COMPLETED_BY: 'completedBy',
        COMPLETION_COMMENT: 'completionComment',
        STATUS: 'status',

        STATUS$TO_TEST: 'to-test',
        STATUS$TESTED: 'tested',
        STATUS$RESERVED_FOR_INSTALLATION: 'reserved-for-installation',
        STATUS$IN_PREPARATION_FOR_CONFIG: 'in-preparation-for-config',
        STATUS$TO_TURN_ON: 'to-turn-on',
        STATUS$READY_TO_CONFIG: 'ready-to-config',
        STATUS$CONFIGURED: 'configured',
        STATUS$NO_STATUS: 'no-status',
        STATUS$READY_TO_BE_SENT: 'ready-to-be-sent',
        STATUS$SENT_FROM_SUPPLIER: 'sent-from-supplier',
        STATUS$RECEIVED_FROM_SUPPLIER: 'received-from-supplier',


        LAST_CONNECTION_CHECK: 'lastConnectionCheck',
        TYPE: 'type',
        TYPE$CLEVER_THERMO: 'clever-thermo',
        TYPE$CLEVER_SENSE_CO2: 'clever-sense-co2',
        TYPE$TESTKIT: 'testkit',
        EDITABLE: 'editable',
        STATUS_HISTORY: 'statusHistory',
        NUMBER_OF_SENSP: 'numberOfSensp',
        NUMBER_OF_THERM: 'numberOfTherm',
        TAG: 'tag',
        TAG$CLEVERON: 'cleveron',
        TAG$YGNIS_CLEVERON: 'ygnis-cleveron',
        TAG$SWISSCOM_CLEVERON: 'swisscom-cleveron',
        TAG$RETURNED: 'returned',
        TAG$DESTROYED: 'destroyed',
        TAG$WITH_GETTING_STARTED_INSERT: 'with-getting-started-insert',
        TAG$NB_IOT: 'nb-iot',
        TAG$JAKUTEC: 'jakutec',
        TAG$REFURBISHED: 'refurbished',
        TAG$WNB: 'clever-thermo-nb-iot',
        TAG$WNBP: 'clever-thermo-nb-iot-presence',
        TAG$WNBP_MODIFIED: 'clever-thermo-nb-iot-presence-modified',
        TAG$TO_REFURBISH: 'to-refurbish',
        TAG$WIFI: 'wifi',

        TAGS: 'tags',
        TAGS$DEFECT: 'defect',
        TAGS$OK_TO_SELL: 'ok-to-sell',
        TAGS$TO_CHECK: 'to-check',
        TAGS$CLEVERON: 'cleveron',
        TAGS$YGNIS_CLEVERON: 'ygnis-cleveron',
        TAGS$SWISSCOM_CLEVERON: 'swisscom-cleveron',
        TAGS$RETURNED: 'returned',
        TAGS$DESTROYED: 'destroyed',
        TAGS$WITH_GETTING_STARTED_INSERT: 'with-getting-started-insert',
        TAGS$JAKUTEC: 'jakutec',
        TAGS$REFURBISHED: 'refurbished',
        TAGS$TO_REFURBISH: 'to-refurbish',


        TESTS$VISUAL: 'visual',
        TESTS$CONNECTION: 'connection',
        TESTS$BATTERY: 'battery',
        TESTS$MOTOR: 'motor',
        TESTS$TEMPERATURE: 'temperature',

        CONFIGURATION_LOGS: 'configurationLogs',
        ROOM_QR_CODES: 'roomQRCodes',
        TEST_BATCH_DEVICE_ORDER: 'testBatchDeviceOrder',
        DELETED: 'deleted'
    },
    Installation: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        HOME: 'home',
        STATUS: 'status',
        STATUS$IN_PREPARATION_FOR_CONFIG: 'in-preparation-for-config',
        STATUS$READY_TO_CONFIG: 'ready-to-config',
        STATUS$CONFIGURED: 'configured',
        STATUS$SENT_FROM_SUPPLIER: 'sent-from-supplier',
        STATUS$RECEIVED_TO_INSTALLER_FROM_SUPPLIER: 'received-to-installer-from-supplier',
        STATUS$IN_PREPARATION_TO_INSTALL_TO_CUSTOMER: 'in-preparation-to-install-to-customer',
        STATUS$READY_TO_INSTALL_TO_CUSTOMER: 'ready-to-install-to-customer',
        STATUS$INSTALLED_TO_CUSTOMER: 'installed-to-customer',
        STATUS$READY_TO_BE_SENT: 'ready-to-be-sent',
        STATUS$INITIALIZED: 'initialized',
        STATUS$TO_TURN_ON: 'to-turn-on',
        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        CHECK_BATTERY: 'checkBattery',
        CHECK_SIGNAL_DB: 'checkSignalDb',
        CHECK_CONNECTION: 'checkConnection',
        LAST_CHECK_DATE: 'lastCheckDate',
        CHECK_DATA: 'checkData',
        INSTALLATION_DATA: 'installationData',
        INSTALLER_ADDRESS: 'installerAddress',
        INSTALLATION_ADDRESS: 'installationAddress',
        INSTALLATION_WITHIN: 'installationWithin',
        INSTALLATION_SCHEDULED_DATE: 'installationScheduledDate',
        CONTACT_NAME: 'contactName',
        CONTACT_PHONE: 'contactPhone',
        CONTACT_STATUS: 'contactInstallationStatus',
        CONTACT_STATUS$CONTACTED: 'contacted',
        CONTACT_STATUS$SCHEDULED: 'scheduled',
        REQUIRED_THERM: 'requiredTherm',
        REQUIRED_SENSE: 'requiredSense',
        ACTUAL_THERM: 'actualTherm',
        ACTUAL_SENSE: 'actualSense',
        SWISS_POST_TRACKING_NUMBER: 'swissPostTrackingNumber',
        SALES_ORDER_REFERENCE_NUMBER: 'salesOrderReferenceNumber',
        QUOTE_REFERENCE_NUMBER: 'quoteReferenceNumber',
        SALES_ORDER_SUBJECT: 'salesOrderSubject',
        SHIPPING_DATE: 'shippingDate',
        ADAPTERS: 'adapters',
        ROOM_QR_CODE: 'roomQrCode',
        INSTALLER_CONTACT: 'installerContact',
        SHIPMENT_CONTACT: 'shipmentContact',
        INSTALLATION_CONTACT: 'installationContact',
        SALES_ORDER_ID: 'salesOrderId',
        SALES_ORDER: 'salesOrder',
        REFERENCE_NUMBER: 'referenceNumber',
        TYPE: 'type',
        TYPE$ORDER_SHIPPING_ONLY: 'order-shipping-only',
        TYPE$ORDER_WITH_CONFIGURATION: 'order-with-configuration',
        TYPE$MAINTENANCE: 'maintenance',
        STATUS_HISTORY: 'statusHistory',
        SENT_FROM_SUPPLIER_DATE: 'sentFromSupplierDate',
        ORDER_FILE: 'orderFile',
        CRM_OPERATION_REFERENCE_NUMBER: 'crmOperationReferenceNumber',
        CRM_OPERATION_ID: 'crmOperationId',
        DELETED: 'deleted'
    },
    SupplierOrder: {
        label: function(key){
            let specialLabels = {
                powerbank: 'Powerbank',
                routerRut240: 'Router (RUT 240)',
                adapterA: 'Adapter A',
                adapterB: 'Adapter B',
                adapterC: 'Adapter C',
                adapterD: 'Adapter D',
                insertA: 'Insert A',
                insertB: 'Insert B',
                insertC: 'Insert C',
                requiredSense: 'CLEVER Sense',
                requiredTherm: 'CLEVER Therm',
                roomQrCode: 'Room QR Code',
            }

            if(specialLabels[key] != null) return specialLabels[key];

            return _.upperFirst(_.startCase(key).toLowerCase());
        },
        ACL: 'ACL',
        ACTUAL_SENSE: 'actualSense',
        ACTUAL_THERM: 'actualTherm',
        ADAPTERS: 'adapters',
        CREATED_AT: 'createdAt',
        DELETED: 'deleted',
        HIDDEN: 'hidden',
        HOME: 'home',
        NOTES: 'notes',
        OPERATION_ID: 'operationId',
        ORDER_FILE: 'orderFile',
        ORIGINAL_CREATION_DATE: 'originalCreationDate',
        PRIORITY: 'priority',
        REFERENCE_NUMBER: 'referenceNumber',
        REQUIRED_SENSE: 'requiredSense',
        REQUIRED_SENSP_W: 'requiredSenspW',
        REQUIRED_SENSP_WNB: 'requiredSenspWNB',
        REQUIRED_THERM: 'requiredTherm',
        REQUIRED_THERM_WNB: 'requiredThermWNB',
        REQUIRED_THERM_WNBP: 'requiredThermWNBP',
        ROOM_QR_CODE: 'roomQrCode',
        SALES_ORDER_ID: 'salesOrderId',
        PRODUCTION_DUE_DATE: 'productionDueDate',
        SENT_FROM_SUPPLIER_DATE: 'sentFromSupplierDate',
        SHIPPING_CITY: 'shippingCity',
        SHIPPING_COMPANY_NAME: 'shippingCompanyName',
        SHIPPING_COUNTRY: 'shippingCountry',
        SHIPPING_DATE: 'shippingDate',
        SHIPPING_DEPARTMENT: 'shippingDepartment',
        SHIPPING_FIRST_NAME: 'shippingFirstName',
        SHIPPING_LAST_NAME: 'shippingLastName',
        SHIPPING_STREET: 'shippingStreet',
        SHIPPING_STREET_NUMBER: 'shippingStreetNumber',
        SHIPPING_ZIP: 'shippingZip',
        STATUS$CONFIGURED: 'configured',
        STATUS$DELEYED: 'deleyed',
        STATUS$INITIALIZED: 'initialized',
        STATUS$INSTALLED_TO_CUSTOMER: 'installed-to-customer',
        STATUS$IN_PREPARATION_FOR_CONFIG: 'in-preparation-for-config',
        STATUS$IN_PREPARATION_TO_INSTALL_TO_CUSTOMER: 'in-preparation-to-install-to-customer',
        STATUS$READY_TO_BE_SENT: 'ready-to-be-sent',
        STATUS$READY_TO_CONFIG: 'ready-to-config',
        STATUS$READY_TO_INSTALL_TO_CUSTOMER: 'ready-to-install-to-customer',
        STATUS$SENT_FROM_SUPPLIER: 'sent-from-supplier',
        STATUS$TO_TURN_ON: 'to-turn-on',
        STATUS$RECEIVED_FROM_SUPPLIER: 'received-from-supplier',
        STATUS: 'status',
        STATUS_HISTORY: 'statusHistory',
        SUBJECT: 'subject',
        SWISS_POST_TRACKING_NUMBER: 'swissPostTrackingNumber',
        SHIPPING_CARRIER: 'shippingCarrier',
        TEST_BATCHES: 'testBatches',
        TYPE: 'type',
        TYPE$TESTKIT: 'testkit',
        TYPE$ONLY_SENSORS: 'only-sensors',
        TYPE$FULL_SYSTEM: 'full-system',
        TYPE$REPLACEMENT: 'replacement',
        CONFIGURATION: 'configuration',
        CONFIGURATION$SHIPPING_ONLY: 'shipping-only',
        CONFIGURATION$WITH_CONFIGURATION: 'with-configuration',
        UPDATED_AT: 'updatedAt',
        CONNECTION_GUIDE_INSERT: 'connectionGuideInsert',
        INSERT_A: 'insertA',
        INSERT_B: 'insertB',
        REINFORCEMENT_RING: 'reinforcementRing',
        RESPONSIBLE_COMPANY: 'responsibleCompany',
        RESPONSIBLE_COMPANY$CLEVERON_AG: 'cleveron_ag',
        RESPONSIBLE_COMPANY$MOSERBAER_AG: 'moserbaer_ag',
        RESPONSIBLE_COMPANY$JAKUTEC: 'jakutec',
        POWERBANK: 'powerbank',
        ROUTER_RUT_240: 'routerRut240',
        ADAPTER_A: 'adapterA',
        ADAPTER_B: 'adapterB',
        ADAPTER_C: 'adapterC',
        ADAPTER_D: 'adapterD',
        INTERNAL_NOTES: 'internalNotes'
    },
    OperationTask: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ORIGINAL_CREATION_DATE: 'originalCreationDate',

        HOME: 'home',

        //Status information
        STATUS: 'status',

        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        INSTALLATION_DATA: 'installationData',
        INSTALLATION_SCHEDULED_DATE: 'installationScheduledDate', //Rename SCHEDULED_DATE
        REQUIRED_THERM: 'requiredTherm',
        REQUIRED_SENSE: 'requiredSense',
        OPERATION_ID: 'operationId',
        ROOM_QR_CODE: 'roomQrCode',
        TYPE: 'type',
        TYPE$INSTALLATION: 'installation',
        TYPE$MAINTENANCE: 'maintenance',
        TYPE$REPLACE_DEVICE: 'replaceDevice',
        TYPE$RECHARGE: 'buildingRecharge',
        REFERENCE_NUMBER: 'referenceNumber',
        NOTES: 'notes',
        SUBJECT: 'subject',

        //Shipping information
        SHIPPING_CITY: 'shippingCity',
        SHIPPING_COMPANY_NAME: 'shippingCompanyName',
        SHIPPING_COUNTRY: 'shippingCountry',
        SHIPPING_DATE: 'shippingDate',
        SHIPPING_DEPARTMENT: 'shippingDepartment',
        SHIPPING_FIRST_NAME: 'shippingFirstName',
        SHIPPING_LAST_NAME: 'shippingLastName',
        SHIPPING_STREET: 'shippingStreet',
        SHIPPING_STREET_NUMBER: 'shippingStreetNumber',
        SHIPPING_ZIP: 'shippingZip',

        INSTALLER: 'installer',
        INSTALLATION_PERIOD: 'installationPeriod',
        COMPLETED_ROOMS: 'completedRooms',

        DELETED: 'deleted'
    },
    Task: {
        HOME: 'home',
        TYPE: 'type',
        TYPE$REPLACE_DEVICE: 'replaceDevice',
        TYPE$BUILDING_RECHARGE: 'buildingRecharge',
        STATUS: 'status',
        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        DELETED: 'deleted'
    },
    DeviceHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        DEVICE: 'device',
        NEW_OWNER: 'newOwner',
        OLD_OWNER: 'oldOwner',
        NEW_ROOM: 'newRoom',
        OLD_ROOM: 'oldRoom',
        OLD_HOME: 'oldHome',
        NEW_HOME: 'newHome',
        NEW_VALUES: 'newValues',
        OLD_VALUES: 'oldValues'
    },
    RoomFeedback: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        EMAIL: 'email',
        ROOM: 'room',
        TEXT: 'text',
        TEMPERATURE: 'temperature',
        HUMIDITY: 'humidity',
        FEEDBACK_VALUE: 'feedbackValue',
        SELECT_TEXT: 'selectText',
        LANGUAGE: 'language',
        ROOM_NAME: 'roomName',
        HOME_NAME: 'homeName',
        HOME: 'home'
    },
    RoomHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        ROOM: 'room',
        OLD_ROOM: 'oldRoom',
        NEW_ROOM: 'newRoom',
        DESCRIPTION: 'description'
    },
    RoomPresencePresetHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        ROOM_PRESENCE_PRESET: 'RoomPresencePreset',
        OLD_ROOM_PRESENCE_PRESET: 'oldRoomPresencePreset',
        NEW_ROOM_PRESENCE_PRESET: 'newRoomPresencePreset',
        DESCRIPTION: 'description'
    },
    DeviceTicket: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DEVICE: 'device',
        COMMENT: 'comment',
        TEST_BATCH: 'testBatch'
    },
    ParseSession: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user'
    },
    CalendarEvent: {
        MONGO: {
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at',
            HOME: '_p_home',
            ROOM: '_p_room'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        HOME: 'home',
        START_DATE: 'startDate',
        END_DATE: 'endDate',
        STATE: 'state',
        TEMP: 'temp',
        ROOM_ID: 'roomId',
        DEVICE_ID: 'deviceId',
        EVENT_NAME: 'eventName',
        OUTLOOK_ID: 'outlookId',
        SKEDDA_EVENT_ID: 'skeddaEventId',
        EVENT_TYPE: 'eventType',
        EVENT_TYPE$HEAT: 'heat',
        EVENT_TYPE$NO_HEAT: 'no-heat',
        ACTIVE: 'active',
        DELETED: 'deleted'
    },
    RoomReportComments: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        HOME_REPORT: 'homeReport',
        ROOM: 'room',
        COMMENTS: 'comments'
    },
    HomeReportPhrase: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        CODE: 'code',
        TEXT_DE: 'text_de',
        TEXT_FR: 'text_fr',
        TEXT_EN: 'text_en',
        TEXT_IT: 'text_it'
    },
    DocumentDownload: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        FILE: 'file',
        URL: 'url',
        LANGUAGE: 'language',
        TYPE: 'type',
        HOME: 'home',
        KEY: 'key',
        DOCUMENT_NAME: 'documentName',
        STATUS: 'status',
        DATE: 'date'
    },
    RoomInstallationId: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        UUID: 'uuid'
    },
    InstallationTestBatch: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEST_BATCH: 'testBatch',
        INSTALLATION: 'installation'
    },
    SalesOrder: {
        OWNER: {NAME: 'name', ID: 'id', EMAIL: 'email'},
        CURRENCY_SYMBOL: '$currency_symbol',
        TAX: 'Tax',
        ORDER_TYPE: 'Order_Type',
        STATE: '$state',
        CONVERTED: '$converted',
        PROCESS_FLOW: '$process_flow',
        DEAL_NAME: 'Deal_Name',
        EXCHANGE_RATE: 'Exchange_Rate',
        CURRENCY: 'Currency',
        BILLING_COUNTRY: 'Billing_Country',
        ID: 'id',
        APPROVED: '$approved',
        QUOTE_NAME: {
            NAME: 'Quote_Name.name',
            ID: 'Quote_Name.id'
        },
        STATUS: 'Status',
        GRAND_TOTAL: 'Grand_Total',
        APPROVAL: {
            DELEGATE: 'delegate',
            APPROVE: 'approve',
            REJECT: 'reject',
            RESUBMIT: 'resubmit'
        },
        BILLING_STREET: 'Billing_Street',
        ADJUSTMENT: 'Adjustment',
        CREATED_TIME: 'Created_Time',
        EDITABLE: '$editable',
        BILLING_CODE: 'Billing_Code',
        PRODUCT_DETAILS: 'Product_Details',
        EXCISE_DUTY: 'Excise_Duty',
        SHIPPING_CITY: 'Shipping_City',
        SHIPPING_COUNTRY: 'Shipping_Country',
        SHIPPING_CODE: 'Shipping_Code',
        SHIPPING_COMPANY: 'Shipping_Company',
        BILLING_CITY: 'Billing_City',
        CREATED_BY: {NAME: 'name', ID: 'id', EMAIL: 'email'},
        DELAYED_DATE: 'Delayed_Date',
        SHIPPING_STREET: 'Shipping_Street',
        SHIPPING_STREET_NUMBER: 'Shipping_Street_Number',
        BUILDING: {
            ID: 'Building.id',
            NAME: 'Building.name'
        },
        DISCOUNT: 'Discount',
        DESCRIPTION: 'Description',
        REFERENCE_NUMBER: 'Reference_Number',
        SHIPPING_STATE: 'Shipping_State',
        REVIEW_PROCESS: {APPROVE: 'approve', REJECT: 'reject', RESUBMIT: 'resubmit'},
        MODIFIED_BY: {NAME: 'name', ID: 'id', EMAIL: 'email'},
        REVIEW: '$review',
        LANGUAGE: 'Language',
        ACCOUNT_NAME: {NAME: 'Account_Name.name', ID: 'Account_Name.id'},
        SALES_COMMISSION: 'Sales_Commission',
        MODIFIED_TIME: 'Modified_Time',
        SHIPPING_CONTACT_NAME: {
            NAME: 'Shipping_Contact_Name.name',
            ID: 'Shipping_Contact_Name.id'},
        DUE_DATE: 'Due_Date',
        AUTO_COMMUNICATION: 'Auto_Communication',
        TERMS_AND_CONDITIONS: 'Terms_and_Conditions',
        SUB_TOTAL: 'Sub_Total',
        SUBJECT: 'Subject',
        ORCHESTRATION: '$orchestration',
        CONTACT_NAME: {NAME: 'name', ID: 'id'},
        SHIPPING_DATE: 'Shipping_Date',
        IN_MERGE: '$in_merge',
        S_O_NUMBER: 'SO_Number',
        BILLING_STATE: 'Billing_State',
        LINE_TAX: '$line_tax',
        TAG: 'Tag',
        APPROVAL_STATE: '$approval_state',
        PENDING: 'Pending',
        SHIPPING_DEPARTMENT: 'Shipping_Department',
        USERNAME: 'Username',
        PASSWORD: 'Password',
        CREDENTIALS: 'Credentials',
        CONFIGURATION: 'Configuration',
        BUILDING_TYPE: 'Building_Type'
    },
    ZohoContact: {
        ACQUISITION_STAGES: 'Acquisition_Stages',
        OWNER: { NAME: 'name', ID: 'id', EMAIL: 'email' },
        INTERESTED_IN: 'Interested_in',
        EMAIL: 'Email',
        ENERGIESTADT_SINCE: 'Energiestadt_since',
        CURRENCY_SYMBOL: '$currency_symbol',
        LEAD_SCORE: 'Lead_Score',
        LETTER_SALUTATION: 'Letter_salutation',
        VISITOR_SCORE: 'Visitor_Score',
        ENERGIESTADT: 'Energiestadt',
        MAILING_STATE: 'Mailing_State',
        OTHER_STATE: 'Other_State',
        MAILCHIMP_MAIL_CHIMP_LIST: 'mailchimp0.MailChimp_List',
        OTHER_COUNTRY: 'Other_Country',
        LAST_ACTIVITY_TIME: 'Last_Activity_Time',
        DEPARTMENT: 'Department',
        STATE: '$state',
        UNSUBSCRIBED_MODE: 'Unsubscribed_Mode',
        PROCESS_FLOW: '$process_flow',
        ASSISTANT: 'Assistant',
        EXCHANGE_RATE: 'Exchange_Rate',
        CURRENCY: 'Currency',
        MAILING_COUNTRY: 'Mailing_Country',
        ID: 'id',
        APPROVED: '$approved',
        APPROVAL: {
            DELEGATE: 'delegate',
            APPROVE: 'approve',
            REJECT: 'reject',
            RESUBMIT: 'resubmit'
        },
        FIRST_VISITED_U_R_L: 'First_Visited_URL',
        DAYS_VISITED: 'Days_Visited',
        OTHER_CITY: 'Other_City',
        CREATED_TIME: 'Created_Time',
        BERATER: 'Berater',
        EDITABLE: '$editable',
        HOME_PHONE: 'Home_Phone',
        MAILCHIMP_MAIL_CHIMP_CONTACT_CREATED_BY: 'mailchimp0.MailChimp_Contact_Created_By',
        LAST_VISITED_TIME: 'Last_Visited_Time',
        CREATED_BY: { NAME: 'name', ID: 'id', EMAIL: 'email' },
        SECONDARY_EMAIL: 'Secondary_Email',
        DESCRIPTION: 'Description',
        VENDOR_NAME: 'Vendor_Name',
        MAILING_ZIP: 'Mailing_Zip',
        RESIDENT_POPULATION: 'Resident_Population',
        NUMBER_OF_CHATS: 'Number_Of_Chats',
        BERATER_EMAIL: 'Berater_Email',
        REVIEW_PROCESS: { APPROVE: 'approve', REJECT: 'reject', RESUBMIT: 'resubmit' },
        OTHER_ZIP: 'Other_Zip',
        MAILING_STREET: 'Mailing_Street',
        AVERAGE_TIME_SPENT_MINUTES: 'Average_Time_Spent_Minutes',
        MAILCHIMP_STATUS: 'mailchimp0__Status',
        SALUTATION: 'Salutation',
        CAMPAIGN_SCOPE: 'Campaign_Scope',
        FIRST_NAME: 'First_Name',
        FULL_NAME: 'Full_Name',
        MAILCHIMP_MAIL_CHIMP_ID: 'mailchimp0__MailChimp_Id',
        CONTACT_TYPE: 'Contact_Type',
        SALES_INFORMATION: 'Sales_information',
        MODIFIED_BY: { NAME: 'name', ID: 'id', EMAIL: 'email' },
        CONTACT_AGAIN: 'Contact_Again',
        REVIEW: '$review',
        LANGUAGE: 'Language',
        PHONE: 'Phone',
        L_INKED_IN: 'LInkedIn',
        ACCOUNT_NAME: { NAME: 'name', ID: 'id' },
        EMAIL_OPT_OUT: 'Email_Opt_Out',
        MODIFIED_TIME: 'Modified_Time',
        FUNNEL_STATUS: 'Funnel_Status',
        DATE_OF_BIRTH: 'Date_of_Birth',
        MAILING_CITY: 'Mailing_City',
        UNSUBSCRIBED_TIME: 'Unsubscribed_Time',
        TITLE: 'Title',
        OTHER_STREET: 'Other_Street',
        MOBILE: 'Mobile',
        ORCHESTRATION: '$orchestration',
        M_H_CAMPAIGN_SCORE: 'MH_Campaign_Score',
        FIRST_VISITED_TIME: 'First_Visited_Time',
        LAST_NAME: 'Last_Name',
        IN_MERGE: '$in_merge',
        REFERRER: 'Referrer',
        LEAD_SOURCE: 'Lead_Source',
        TAG: 'Tag',
        FAX: 'Fax',
        CANTON: 'Canton',
        APPROVAL_STATE: '$approval_state'
    },
    LMAddress: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        STREET_NUMBER: 'streetNumber',
        COUNTRY: 'country',
        CITY: 'city',
        ZIP: 'zip',
        COMPANY_NAME: 'companyName',
        STREET: 'street',
        DEPARTMENT: 'department'
    },
    ZohoBuilding: {
        CANTON_REGION: 'Canton_Region',
        ACCOUNT: { NAME: 'Account.name', ID: 'Account.id' },
        OWNER: { NAME: 'Owner.name', ID: 'Owner.id', EMAIL: 'Owner.email' },
        EMAIL: 'Email',
        S_L_A: 'SLA',
        ADMIN_PASSWORD: 'Admin_Password',
        NAME: 'Name',
        LAST_ACTIVITY_TIME: 'Last_Activity_Time',
        ADMINISTRATOR: { NAME: 'Administrator.name', ID: 'Administrator.id' },
        UNSUBSCRIBED_MODE: 'Unsubscribed_Mode',
        QUANTITY_OF_THERMOSTATS: 'Quantity_of_Thermostats',
        DATE_OF_INSTALLATION: 'Date_of_Installation1',
        YEARLY_RECURRING_REVENUE: 'Yearly_Recurring_Revenue',
        CURRENCY: 'Currency',
        CONTRACT_END: 'Contract_End',
        ZIP_CODE: 'Zip_Code',
        ID: 'id',
        W_I_F_I_SECURITY: 'WI_FI_Security',
        INSTALLATION_ID: 'Installation_Id',
        FUTURE_REVENUE: 'Future_revenue',
        BUILDING_ADDRESS: 'Building_Address',
        QUANTITY_OF_SENSORS: 'Quantity_of_Sensors',
        QUANTITY_OF_C_O_SENSORS: 'Quantity_of_CO2_Sensors',
        CREATED_TIME: 'Created_Time',
        W_I_F_I_PASSWORD: 'WI_FI_Password2',
        E_G_I_D_NUMBER: 'EGID_Number',
        CITY: 'City',
        COUNTRY: 'Country',
        INSTALLATION_PARTNER: 'Installation_Partner',
        CREATED_BY: {
            NAME: 'Created_By.name',
            ID: 'Created_By.id',
            EMAIL: 'Created_By.email'
        },
        ESTIMATED_DATE_OF_INSTALLATION: 'Estimated_date_of_installation',
        CONTRACT_LENGTH: 'Contract_Length',
        ONE_TIME_REVENUE: 'One_Time_revenue',
        HEATING_SYSTEM: 'Heating_System',
        BUILDING_LAYOUTS: 'Building_Layouts',
        RECORD_IMAGE: 'Record_Image',
        MODIFIED_BY: {
            NAME: 'Modified_By.name',
            ID: 'Modified_By.id',
            EMAIL: 'Modified_By.email'
        },
        TECHNICAL_STATUS: 'Technical_Status',
        LANGUAGE: 'Language',
        PHONE: 'Phone',
        CLOUD_BUILDING_ID: 'Cloud_Building_Id',
        EMAIL_OPT_OUT: 'Email_Opt_Out',
        SALES_ORDER: { NAME: 'Sales_Order.name', ID: 'Sales_Order.id' },
        ADMIN_USERNAME: 'Admin_Username',
        MODIFIED_TIME: 'Modified_Time',
        PROJECT_STATUS: 'Project_Status',
        YEAR_OF_CONSTRUCTION: 'Year_of_construction',
        AUTO_COMMUNICATION: 'Auto_Communication',
        BUILDING_RESPONSIBLE: 'Building_Responsible',
        UNSUBSCRIBED_TIME: 'Unsubscribed_Time',
        BUILDING_AREA: 'Building_area',
        EXIT_POSSIBILITY: 'Exit_possibility',
        TECHNICAL_RESPONSIBLE: 'Technical_Responsible',
        ADAPTER_C: 'Adapter_C',
        I_T_RESPONSIBLE: 'IT_Responsible',
        ADAPTER_B: 'Adapter_B',
        ADAPTER_A: 'Adapter_A',
        W_I_F_I_S_S_I_D: 'WI_FI_SSID',
        LAST_STATUS_UPDATE: 'Last_Status_Update',
        TAG: 'Tag',
        INSTALLATION_REQUIREMENTS_SENT: 'Installation_requirements_sent',
        CURRENCY_SYMBOL: 'currency_symbol',
        STATE: 'state',
        PROCESS_FLOW: 'process_flow',
        APPROVED: 'approved',
        APPROVAL: {
            DELEGATE: 'approval.delegate',
            APPROVE: 'approval.approve',
            REJECT: 'approval.reject',
            RESUBMIT: 'approval.resubmit'
        },
        EDITABLE: 'editable',
        REVIEW_PROCESS: {
            APPROVE: 'review_process.approve',
            REJECT: 'review_process.reject',
            RESUBMIT: 'review_process.resubmit'
        },
        REVIEW: 'review',
        ORCHESTRATION: 'orchestration',
        IN_MERGE: 'in_merge',
        APPROVAL_STATE: 'approval_state',
        ADAPTER_1: 'Adapter_1',
        ADAPTER_2: 'Adapter_2',
        ADAPTER_3: 'Adapter_3',
        QUANTITY_ADAPTER_1: 'Quantity_Adapter_1',
        QUANTITY_ADAPTER_2: 'Quantity_Adapter_2',
        QUANTITY_ADAPTER_3: 'Quantity_Adapter_3'
    },
    RoomInstallationIdPage: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        UUID: 'uuid',
        STATUS: 'status'
    },
    ZohoOperation: {
        ACCOUNT: 'Account',
        OWNER: { NAME: 'Owner.name', ID: 'Owner.id', EMAIL: 'Owner.email' },
        EMAIL: 'Email',
        SUBFORM: 'Subform_2',
        NAME: 'Name',
        LAST_ACTIVITY_TIME: 'Last_Activity_Time',
        TOTAL_COSTS: 'Total_Costs',
        UNSUBSCRIBED_MODE: 'Unsubscribed_Mode',
        EXCHANGE_RATE: 'Exchange_Rate',
        QUANTITY_OF_THERMOSTATS: 'Quantity_of_Thermostats',
        CURRENCY: 'Currency',
        ID: 'id',
        STATUS: 'Status',
        BUILDING_ADDRESS: 'Building_Address',
        QUANTITY_OF_SENSORS: 'Quantity_of_Sensors',
        CREATED_TIME: 'Created_Time',
        DEADLINE: 'Deadline',
        TRACKING_NUMBER: 'Tracking_Number',
        CREATED_BY: {
            NAME: 'Created_By.name',
            ID: 'Created_By.id',
            EMAIL: 'Created_By.email'
        },
        FILE: 'File',
        BUILDING: { NAME: 'Building.name', ID: 'Building.id' },
        COSTS: 'Costs',
        CATEGORY: 'Category',
        CATEGORY$MAINTENANCE: 'Maintenance',
        CATEGORY$INSTALLATION: 'Installation',
        DATE_AND_TIME: 'Date_and_Time',
        REFERENCE_NUMBER: 'Reference_Number',
        SUPPORT_TICKET_URL: 'Support_Ticket_Url',
        TOTAL_BILLABLE_TIME: 'Total_Billable_Time',
        MODIFIED_BY: {
            NAME: 'Modified_By.name',
            ID: 'Modified_By.id',
            EMAIL: 'Modified_By.email'
        },
        LANGUAGE: 'Language',
        PHONE: 'Phone',
        SUPPORT_TICKET_ID: 'Support_Ticket_Id',
        SHIPPING_CONTACT: {
            ID: 'Shipping_Contact.id',
            NAME: 'Shipping_Contact.name'
        },
        HOURLY_RATE: 'Hourly_Rate',
        MODIFIED_TIME: 'Modified_Time',
        BUILDING_RESPONSIBLE: {
            NAME: 'Building_Responsible.name',
            ID: 'Building_Responsible.id'
        },
        UNSUBSCRIBED_TIME: 'Unsubscribed_Time',
        REPORTING: 'Reporting',
        TECHNICAL_RESPONSIBLE: {
            NAME: 'Technical_Responsible.name',
            ID: 'Technical_Responsible.id'
        },
        PLANNED_TIME: 'Planned_Time',
        TAG: 'Tag',
        TOTAL_BILLABLE_COSTS: 'Total_Billable_Costs',
        CURRENCY_SYMBOL: 'currency_symbol',
        STATE: 'state',
        PROCESS_FLOW: 'process_flow',
        APPROVED: 'approved',
        APPROVAL: {
            DELEGATE: 'approval.delegate',
            APPROVE: 'approval.approve',
            REJECT: 'approval.reject',
            RESUBMIT: 'approval.resubmit'
        },
        EDITABLE: 'editable',
        REVIEW_PROCESS: {
            APPROVE: 'review_process.approve',
            REJECT: 'review_process.reject',
            RESUBMIT: 'review_process.resubmit'
        },
        REVIEW: 'review',
        ORCHESTRATION: 'orchestration',
        IN_MERGE: 'in_merge',
        APPROVAL_STATE: 'approval_state',
        SHIPPING_COMPANY: 'Shipping_Company',
        SHIPPING_DEPARTMENT: 'Shipping_Department',
        SHIPPING_STREET: 'Shipping_Street',
        SHIPPING_CITY: 'Shipping_City',
        SHIPPING_COUNTRY: 'Shipping_Country',
        SHIPPING_CODE: 'Shipping_Code',
        SHIPPING_STREET_NUMBER: 'Shipping_Street_Number',
    },
    SalesOrderNote: {
        OWNER: { NAME: 'Owner.name', ID: 'Owner.id', EMAIL: 'Owner.email' },
        MODIFIED_TIME: 'Modified_Time',
        CREATED_TIME: 'Created_Time',
        PARENT_ID: { NAME: 'Parent_Id.name', ID: 'Parent_Id.id' },
        MODIFIED_BY: {
            NAME: 'Modified_By.name',
            ID: 'Modified_By.id',
            EMAIL: 'Modified_By.email'
        },
        ID: 'id',
        CREATED_BY: {
            NAME: 'Created_By.name',
            ID: 'Created_By.id',
            EMAIL: 'Created_By.email'
        },
        NOTE_TITLE: 'Note_Title',
        NOTE_CONTENT: 'Note_Content',
        ATTACHMENTS: 'attachments',
        EDITABLE: 'editable',
        SE_MODULE: 'se_module',
        IS_SHARED_TO_CLIENT: 'is_shared_to_client',
        SIZE: 'size',
        STATE: 'state',
        VOICE_NOTE: 'voice_note'
    },
    RoomTicket: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        ISSUE_CATEGORY: 'issueCategory',
        AFFECTED_DEVICES: 'affectedDevices',
        BILLABLE: 'billable',
        STATUS: 'status',
        STATUS$DRAFT: 'draft',
        STATUS$CONFIRMED: 'confirmed',
        STATUS$INVOICED: 'invoiced',
        STATUS$CLOSED: 'closed',
        NOTE: 'note',
        PHOTO: 'photo',
        CRM_OPERATION_REFERENCE_NUMBER: 'crmOperationReferenceNumber',
        CRM_OPERATION_ID: 'crmOperationId',
        NUMBER_PIECES: 'numberPieces',
        USER: 'user',
        DELETED: 'deleted',
    },
    IssueCategory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        CATEGORY_KEY: 'categoryKey',
        CATEGORY_KEY$WIFI: 'wifi',
        CATEGORY_KEY$RADIATORS: 'radiators',
        CATEGORY_KEY$CLEVERON_DEVICES: 'cleveron-devices',
        CATEGORY_KEY$POWERBANK: 'powerbank',
        CATEGORY_LABEL_EN: 'categoryLabelEN',
        SUB_CATEGORY_KEY: 'subCategoryKey',
        SUB_CATEGORY_LABEL_EN: 'subCategoryLabelEN'
    },
    RoomType: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name'
    },
    DeviceFirmware: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        VERSION: 'version',
        HARDWARE_VERSION: 'hardwareVersion',
        DEVICE_TYPE: 'deviceType',
        THERM_BIN: 'thermBin',
        SENSP_BIN: 'senspBin',
        STATUS: 'status',
        STATUS$IN_TESTING: 'in-testing',
        STATUS$STABLE: 'stable'
    },
    TestBatchDeviceHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DEVICE: 'device',
        USER: 'user',
        OLD_BATCH: 'oldBatch',
        NEW_BATCH: 'newBatch'
    },
};

module.exports = dbStructure;
