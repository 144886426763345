import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

export default class  ReviewModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggle);
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
    }


    getListRooms(object){
        return object.rooms.map(roomObject => {
            let room = roomObject.room;
            let devices = roomObject.devices.filter(device => !device.battery || !device.wifiDb || !device.connection);
            if(devices.length > 0)
                return room;
        }).filter(room => !!room);
    }


    printReviewObject(object){
        if(!object) return '';
        if(!object.rooms) return '';
        if(object.rooms.length === 0) return <div>All check are passed successfully.</div>;

        return <div>
            <h5>Check following rooms</h5>
            <ul>
                {
                    object.rooms.map(roomObject => {
                        let room = roomObject.room;
                        let devices = roomObject.devices.filter(device => !device.battery || !device.wifiDb || !device.connection);
                        if(devices.length > 0)
                            return <li key={room.id}>"{room && room.get(db.Room.ROOM_NAME)}" on floor {room.get(db.Room.FLOOR)}</li>;

                        return '';
                    })
                }
            </ul>

        </div>;
    }

    render(){
        return <div>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Check installation</ModalHeader>
                <ModalBody>
                    {this.printReviewObject(this.props.reviewObject)}
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={this.toggle}>Ok</Button>{' '}
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    }
}

ReviewModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func,
    reviewObject: PropTypes.object
};