import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Table} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import Loader from '../loader';
import {checkConnectivityDevices, getBatchDevices, renderTestButtons} from '../../utils';
import config from "../../../config/app";
import Toggle from "react-toggle";
import PageSingleBatch from "../page-single-batch";
import moment from 'moment';


function isConnectionNBIOTOk(testBatchDevice){
    let {
        device
    } = PageSingleBatch.getInfoFromTestBatchDevice(testBatchDevice);

    let lastConnectionProtocol = device.get(db.Device.LAST_CONNECTION_PROTOCOL);
    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

    lastMeasurementDate = lastMeasurementDate == null ? lastMeasurementDate : moment(lastMeasurementDate);

    if(lastMeasurementDate == null){
        throw new Error(`No connection in the last hour`);
    }

    if(
        lastConnectionProtocol !== db.Device.LAST_CONNECTION_PROTOCOL$NB_IOT
    ){
        throw new Error(`Last connection is not ${db.Device.LAST_CONNECTION_PROTOCOL$NB_IOT}`);
    }
    let diff = Math.abs(lastMeasurementDate.diff(moment(), 'minutes'));

    if(diff > 10){
        throw new Error(`No connection in the last 10 minutes`);
    }
}

export default class PageConnectionTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testBatchDevices: [],
            loading: {
                connectivityCheck: false
            }
        };

        this.getBatchDevices = this.getBatchDevices.bind(this);
        this.onConnectivityCheck = this.onConnectivityCheck.bind(this);
        this.visualTestCheck = this.visualTestCheck.bind(this);
        this.setAllVisualOk = this.setAllVisualOk.bind(this);
        this.sendCommandStartTestNb = this.sendCommandStartTestNb.bind(this);
    }

    async componentDidMount() {
        await this.getBatchDevices();
        await this.getTestBatch();
    }

    async getTestBatch(){
        try{
            let testBatch = await (new Parse.Query(db.classes.TestBatch)).get(this.props.match.params.id);

            this.setState({testBatch});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async getBatchDevices(){
        try{
            let testBatchDevices = await getBatchDevices(this.props.match.params.id);

            this.setState({testBatchDevices});

            return testBatchDevices;
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onConnectivityCheck(){
        this.setState({loading: {connectivityCheck: true}});

        try{

            let testBatchDevices = await this.getBatchDevices();

            await checkConnectivityDevices(testBatchDevices);

            await this.getBatchDevices();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');

        } finally {
            this.setState({loading: {connectivityCheck: false}});
        }
    }

    async visualTestCheck(testBatchDevice, checked){
        try {
            isConnectionNBIOTOk(testBatchDevice);

            testBatchDevice.set(db.TestBatchDevice.NB_IOT_CONNECTION_TEST, checked);

            await testBatchDevice.save();

            await this.getBatchDevices();
        } catch (e) {
            console.error(e);

            swal('Error', e.message, 'error');
        }
    }

    async setAllVisualOk(){
        try {
            let testBatchDevices = [];
            for (let testBatchDevice of this.state.testBatchDevices) {
                try{
                    isConnectionNBIOTOk(testBatchDevice);
                    testBatchDevice.set(db.TestBatchDevice.NB_IOT_CONNECTION_TEST, true);
                    testBatchDevices.push(testBatchDevice);
                }catch (e) {

                }
            }

            await Parse.Object.saveAll(testBatchDevices);

            this.setState({testBatchDevices: await getBatchDevices(this.props.match.params.id)});

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async sendCommandStartTestNb(){
        try {
            let testBatchDevices = this.state.testBatchDevices;
            let currentUser = Parse.User.current();

            let commandsToBeSaved = [];
            testBatchDevices.forEach(testBatchDevice => {
                let nbIotConnectionTest = testBatchDevice.get(db.TestBatchDevice.NB_IOT_CONNECTION_TEST);

                if(nbIotConnectionTest === true) return;

                let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                let command = new Parse.Object('CommandQueue');
                command.set('commandName', db.commands.START_TEST_NB);
                command.set('data', {});
                command.set('device', device);
                command.set(db.CommandQueue.USER, currentUser);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));

                let command2 = new Parse.Object('CommandQueue');
                command2.set('commandName', db.commands.CHANGE_SLEEP_TIME);
                command2.set('data', {
                    "value": 2,
                    "unit": "minutes"
                });
                command2.set('device', device);
                command2.set(db.CommandQueue.USER, currentUser);
                command2.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID))

                commandsToBeSaved.push(command);
                commandsToBeSaved.push(command2);
            });

            console.log(`Saving ${commandsToBeSaved.length} commands`);

            await Parse.Object.saveAll(commandsToBeSaved);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        return (
            <div>
                <h1 className="text-center"><i className="fa fa-arrows-h"></i> NB-IOT Connectivity test {this.state.testBatch?.get(db.TestBatch.NAME)}</h1>
                {renderTestButtons(this.state.testBatch)}
                <Button outline color={'primary'} onClick={this.onConnectivityCheck}>
                    Check for connectivity {this.state.loading.connectivityCheck && <Loader/>}
                </Button>

                <Button outline color={'primary'} onClick={this.setAllVisualOk}>
                    Set all nb-iot connection ok
                </Button>
                <Button outline color={'warning'} onClick={(e) => this.sendCommandStartTestNb(e)}>
                    Force nb-iot connection
                </Button>

                <Table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Device</th>
                            <th><i className="fa fa-arrows-h"></i></th>
                            <th><i className="fa fa-eye"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.testBatchDevices.map((testBatchDevice, i) => {
                                let {
                                    device
                                } = PageSingleBatch.getInfoFromTestBatchDevice(testBatchDevice);

                                let lastConnectionProtocol = device.get(db.Device.LAST_CONNECTION_PROTOCOL);

                                let lastConnectionDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

                                lastConnectionDate = lastConnectionDate != null ?  moment(lastConnectionDate).fromNow() : null;

                                let row = (i % 4) + 1;
                                let column = Math.trunc(i / 4);
                                let columnMap = ['A', 'B', 'C', 'D', 'E'];


                                return <tr key={testBatchDevice.id}>
                                    <td>
                                        <a
                                            href={'https://cleveron-assets.s3.amazonaws.com/lean-management/batch-map-explaination.png'}
                                            target={'_blank'}
                                        >
                                            {columnMap[column]}{row}
                                        </a>
                                    </td>
                                    <td>
                                        <a href={`${config.adminDashboard.host}/devices/${testBatchDevice.get(db.TestBatchDevice.DEVICE).id}`}>
                                            {testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.SERIAL_NUMBER)}
                                            ({testBatchDevice.get(db.TestBatchDevice.DEVICE).get(db.Device.MAC_ADDRESS)})
                                        </a>
                                    </td>
                                    <td>
                                        {testBatchDevice.get(db.TestBatchDevice.CONNECTED) == null &&
                                            <i className="fa fa-times" style={{color: 'red'}}></i> }
                                        {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'connected' &&
                                            <i className="fa fa-check" style={{color: 'green'}}></i> }
                                        {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'No debug' &&
                                            <i className="fa fa-times" style={{color: 'orange'}}></i>}
                                        {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'Not connected' &&
                                            <i className="fa fa-times" style={{color: 'red'}}></i>}

                                        {lastConnectionProtocol} ({lastConnectionDate || ''})
                                    </td>
                                    <td>
                                        <Toggle
                                            defaultChecked={false}
                                            checked={testBatchDevice.get(db.TestBatchDevice.NB_IOT_CONNECTION_TEST)}
                                                onChange={
                                                    (e) => this.visualTestCheck(testBatchDevice, e.target.checked)
                                                }
                                        />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

PageConnectionTest.propTypes = {
    match: PropTypes.any
};