import _ from 'lodash';
import {isRolesInRoles} from './util';
import swal from 'sweetalert';

class StateMachine {
    constructor(props){
        if(!props) throw new Error('Init value is required');
        if(!props.init) throw new Error('Init value is required');
        if(!props.transitions) throw new Error('transitions value is required');

        this.state = props.init;
        this.transitions = props.transitions;
        this.roles = props.roles || [];
        this.object = props.object;
        this.props = props.props;
    }

    async goTo(nextState, force){
        if(force) {
            let result = await swal({
                title: 'Warning!',
                text: `You are forcing a status change on this batch / order without any check. Are you sure to do so? This can lead to misconfiguration and quality assurance issues. Ask the IT responsible if you are unsure.`,
                buttons: ['Cancel', 'Yes, I know what I\'m doing.'],
                dangerMode: true
            });

            if (!result) throw new Error('Action aborted by user');

            this.state = nextState;

            let transition = this.getAllTransitions().filter(transaction => transaction.to === nextState)[0];

            if(transition.postStateChange){
                result && await transition.postStateChange(this, force);
            }

            return;
        }

        if(this.state === nextState) return;

        let possibleTransitions = this.getTransitions().filter(transaction => transaction.to === nextState);

        if(possibleTransitions.length === 0){

            throw new Error(`No transition found from ${this.state} to ${nextState}`);
        }

        if(possibleTransitions.length > 1)
            throw new Error(`Too many transitions found from ${this.state} to ${nextState}`);

        let transition = possibleTransitions[0];

        if(transition.requirementsOk){
            await transition.requirementsOk(this);
        }

        this.state = nextState;

        if(transition.postStateChange){
            await transition.postStateChange(this);
        }
    }

    getTransitions(){
        return this.transitions.filter(transition => {
            return transition.from === this.state && (isRolesInRoles(transition.roles, this.roles) || this.roles.indexOf('Admin') >= 0)
        });
    }

    isNextStateAnOldState(nextState){
        function isOldState(currentState, nextState, transitions, level){
            let mapFromTo = {};
            let mapToFrom = {};

            transitions.forEach(transition => {
                let from = transition.from;
                let to = transition.to;

                if(!mapFromTo[from]){
                    mapFromTo[from] = to;
                } else {
                    throw new Error(`From ${from} is already exsisting`);
                }

                if(!mapToFrom[to]){
                    mapToFrom[to] = from;
                }else {
                    throw new Error(`To ${to} is already exsisting`);
                }
            });

            let from = mapToFrom[currentState];

            if(!from){
                return false;
            }

            if(from === nextState && level !== 0){
                return true;
            }

            return isOldState(from, nextState, transitions, level++);
        }

        let oldState = isOldState(this.state, nextState, this.getAllTransitions(), 0);

        return oldState;
    }

    getAllTransitions(){
        if(this.roles.indexOf('Admin') >= 0)
            return this.transitions;

        return this.transitions.filter(transition => {
            return isRolesInRoles(transition.roles, this.roles) || this.roles.indexOf('Admin') >= 0
        });
    }

    getAllViewStates(){
        if(this.roles.indexOf('Admin') >= 0) {

        }
    }

    getAllAvailableStates(){
        let stateList = [];
        this.transitions.forEach(transition => stateList.push(transition.from) && stateList.push(transition.to));

        return _.uniq(stateList);
    }

    setObject(newObject){
        this.object = newObject;
    }
    getObject(){
        return this.object;
    }

    getState(){
        return this.state;
    }

    getProps(){
        return this.props;
    }

    setProps(props){
        this.props = props;
    }

    isRole(role){
        return this.roles.indexOf(role) >= 0;
    }
}

export default StateMachine;
