'use strict';
import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';

// import components here
import PropTypes from 'prop-types';
import Parse from '../lib/parse';
import paths from './paths';
import db from '../config/dbStructure';

import Loader from './components/loader';
import PageBatch from './components/page-list-batches';
import PageSingleBatch from './components/page-single-batch';
import PageVisualTest from './components/page-visual-test';
import PageMotorTest from './components/page-motor-test';
import PageBatteryTest from './components/page-battery-test';
import PageTemperatureCalibration from './components/page-temperature-calibration';
import PageLogin from './components/page-login/index';
import PageSupplierOrders from './components/page-supplier-orders';
import PageMaintenanceTasks from './components/page-operation-tasks';
import PageMaintenance from './components/page-operation-task-rooms';
import NavBar from './components/nav-bar';
import PageOperationDashboard from './components/page-operation-dashboard/operation-dashboard';
import PageListBuildings from './components/page-list-buildings';
import PageLinkRoomQr from './components/page-link-room-qr';
import PageMaintenanceTaskRoom from './components/page-operation-task-room-detail';
import PageOperationTaskReport from './components/page-operation-task-report';
import PageProductionReport from './components/page-production-report';
import PageConnectionTest from './components/page-connection-test';
import PageAssemblyCheck from './components/page-assembly-check';

import '../style/main.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-toggle/style.css';
import '../node_modules/font-awesome/css/font-awesome.css';

if (NODE_ENV === 'production') {
    console.log(NODE_ENV);
    Sentry.init({
        dsn: 'https://5d27cb093cc1455dbee8168cf43f4559@o1003745.ingest.sentry.io/4505567238619136',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', 'https://leanmanagement.cleveron.ch'],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export default class App extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            menuSelected: '/testing',
            roles: []
        }
    }

    async componentDidMount(){
        let roles = await this.getRole();

        this.setState({loading: false, roles: roles && roles.map(role => role.get(db._Role.NAME))});
    }

    async getRole() {
        try {
            let queryRole = (new Parse.Query(Parse.Role)).equalTo('users', Parse.User.current());

            let roles =  await queryRole.find();

            return roles;
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        if(this.state.loading || this.state.isAdmin === null) return <Loader/>;

        console.log('App render');

        return <Router history={this.props.history} >

                <NavBar
                    selected={this.props.history.location.pathname}
                    roles={this.state.roles}
                    history={this.props.history}
                />

                <Switch>
                    <Route exact history={this.props.history} path={paths.index}
                        render={(props) => <PageLogin history={this.props.history}  roles={this.state.roles}/>}
                    />
                </Switch>
            <Switch>
                <Route exact history={this.props.history} path={paths.login}
                       render={(props) => <PageLogin history={this.props.history}  roles={this.state.roles}/>}
                />
            </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.batch}
                        render={(props) => <PageBatch {...props} roles={this.state.roles}/>}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.supplierOrders}
                        render={(props) => <PageSupplierOrders {...props} roles={this.state.roles}/>}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.singleBatchEdit}
                        render={(props) => <PageSingleBatch {...props} edit={true} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.singleBatchView}
                        render={(props) => <PageSingleBatch {...props} edit={false} roles={this.state.roles}/>} />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.visualTest} component={PageVisualTest} />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.connectionTest}
                        component={PageConnectionTest} />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.motorTest} component={PageMotorTest} />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.batteryTest} component={PageBatteryTest} />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.temperatureCalibration} component={PageTemperatureCalibration} />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.maintenance}
                        render={(props) => <PageMaintenanceTasks {...props} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.maintenanceTaskReport}
                        render={(props) => <PageOperationTaskReport {...props} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.singleMaintenance}
                        render={(props) => <PageMaintenance {...props} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.maintenanceTaskRoom}
                        render={(props) => <PageMaintenanceTaskRoom {...props} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.listBuildings}
                        render={(props) => <PageListBuildings {...props} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history}
                        path={paths.linkRoomQr}
                        render={(props) => <PageLinkRoomQr {...props} roles={this.state.roles} />}
                    />
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.operationDashboard} component={PageOperationDashboard}/>
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.productionReport} component={PageProductionReport}/>
                </Switch>
                <Switch>
                    <Route exact history={this.props.history} path={paths.assemblyCheck} component={PageAssemblyCheck}/>
                </Switch>

        </Router>;
    }
}

App.propTypes = {
    history: PropTypes.any
};