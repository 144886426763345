import React, {Component} from 'react';

export default class PageOperationDashboard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <iframe frameBorder={0} width={'100%'} height={'100vh'} style={{height: '100vh'}}
                        src="https://analytics.zoho.eu/open-view/43018000002215032"></iframe>
                <a
                    href={'https://crm.zoho.eu/crm/org20065170472/tab/Reports/154068000009790020?folderId=154068000008517697&repType=tabular'}
                    target="_blank"
                    rel="noopener noreferrer"
                >Buildings report</a>
            </div>
        )
    }
}