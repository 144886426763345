import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormGroup, Label, Row, Table} from 'reactstrap';
import * as db from '../../../config/dbStructure';
import Parse from '../../../lib/parse';
import moment from 'moment';
import config from '../../../config/app';
import swal from 'sweetalert';
import {
    extractFieldFromDeviceHistory,
    extractFieldFromOperationTask,
    extractFieldFromRoomTicket,
    toPointerFromId
} from '../../../lib/util';
import paths from '../../paths';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import Select from "react-select";
import _ from "lodash";
import Toggle from "react-toggle";

const downloadxls = (data, operationTask) => {
    let {
        referenceNumber
    } = extractFieldFromOperationTask(operationTask);
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${referenceNumber}_room_tickets.xlsx`);
}
const convertRoomTicketToPlainObject = (roomTicket) => {
    const {
        createdAt,
        issueCategory,
        affectedDevices,
        billable,
        status,
        note,
        photo,
        crmOperationReferenceNumber,
        crmOperationId,
        user,
        room,
        numberOfPieces
    } = extractFieldFromRoomTicket(roomTicket);

    let object =  {
        createdAt: moment(createdAt).format('DD/MM/YYYY HH:mm'),
        building: room && room.get(db.Room.HOME) && room.get(db.Room.HOME).get(db.Home.HOME_NAME),
        room: room && `${room.get(db.Room.ROOM_NAME)}`,
        floor: room && `${room.get(db.Room.FLOOR)}`,
        issueCategory: `${issueCategory.get(db.IssueCategory.CATEGORY_KEY)}`,
        issueSubCategory: `${issueCategory.get(db.IssueCategory.SUB_CATEGORY_KEY)}`,
        affectedDevices: affectedDevices && `${affectedDevices.map(device => device.get(db.Device.SERIAL_NUMBER)).join(',')}`,
        billable,
        status,
        numberOfPieces,
        note,
        photo: photo?.url(),
        crmOperationReferenceNumber,
        crmOperationId,
        user: `${user.get(db._User.USERNAME)}`
    }

    return object;
}

function filterRoomTicketsCategory(roomTickets, categoryName){
    if(categoryName != null) {
        roomTickets = roomTickets.filter(roomTicket => roomTicket?.get(db.RoomTicket.ISSUE_CATEGORY)?.get(db.IssueCategory.CATEGORY_KEY) === categoryName);
    }

    return roomTickets;
}

export default class PageOperationTaskReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: {},
            roomTickets: [],
            devices: [],
            roooms: [],
            issueCategories: [],
            deviceHistoriesFromBuilding: [],
            deviceHistoriesToBuilding: [],
            showAllBuildingTickets: false
        };
    }

    async componentDidMount(){
        this.refresh();
    }

    async getDevicesFromBuilding(operationTask, roomIdMap){
        let home = operationTask.get(db.OperationTask.HOME);

        return new Parse.Query(db.classes.Device)
            .equalTo(db.Device.HOME, home)
            .limit(10000)
            .find();
    }

    fillInstallationDataWithObjects(installationData, roomIdMap, deviceIdMap){
        installationData.rooms = installationData.rooms.map(room => {
            room.room = roomIdMap[room.room];
            room.devices = room.devices.map(device => {
                device.device = deviceIdMap[device.device];

                return device;
            })

            return room;
        });

        return installationData;
    }

    async refresh(){
        let operationTask = await new Parse.Query(db.classes.OperationTask).get(this.props.match.params.id);
        let installationData = operationTask.get(db.OperationTask.INSTALLATION_DATA);
        let building = await operationTask.get(db.OperationTask.HOME).fetch();
        let devices = await this.getDevicesFromBuilding(operationTask);
        let rooms = await new Parse.Query(db.classes.Room)
            .equalTo(db.Room.HOME, building)
            .notEqualTo(db.Room.DELETED, true)
            .select([
                db.Room.ROOM_NAME,
                db.Room.MAIN_PHOTO,
                db.Room.MAIN_PHOTO_URL,
                db.Room.ACL
            ])
            .limit(10000)
            .find();

        const {
            deviceHistoriesFromBuilding,
            deviceHistoriesToBuilding
        } = await this.loadDeviceHistories(building);

        let roomsIdMap = {};
        rooms.forEach(room => {
            roomsIdMap[room.id] = room;
        });

        let devicesIdMap = {};
        devices.forEach(device => {
            devicesIdMap[device.id] = device;
        });

        if(installationData != null)
            installationData = this.fillInstallationDataWithObjects(installationData, roomsIdMap, devicesIdMap);

        let roomTickets = await this.loadRoomTickets(operationTask, rooms, this.state.showAllBuildingTickets);
        let issueCategories = await this.loadIssueCategories();

        this.setState({
            devices,
            operationTask,
            building,
            rooms,
            roomTickets,
            installationData,
            issueCategories,
            deviceHistoriesFromBuilding,
            deviceHistoriesToBuilding
        });
    }

    async loadIssueCategories(){
        let issueCategories = new Parse.Query(db.classes.IssueCategory)
            .limit(1000)
            .find();

        return issueCategories;
    }

    async loadRoomTickets(operationTask, rooms, showAllBuildingTickets){
        let referenceNumber = operationTask.get(db.OperationTask.REFERENCE_NUMBER);

        let roomTicketsQuery = new Parse.Query(db.classes.RoomTicket)
            .include(db.RoomTicket.AFFECTED_DEVICES)
            .include(db.RoomTicket.USER)
            .include(db.RoomTicket.ISSUE_CATEGORY)
            .include(db.RoomTicket.ROOM)
            .notEqualTo(db.RoomTicket.DELETED, true)
            .containedIn(db.RoomTicket.ROOM, rooms);

        if(!showAllBuildingTickets)
            roomTicketsQuery.equalTo(db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER, referenceNumber)

        roomTicketsQuery.limit(100000);

        let roomTickets = await roomTicketsQuery.find();

        return roomTickets;
    }

    async changeStatusRoomTickets(){
        try {
            let roomTickets = this.state.roomTickets;


            let statuses = [
                db.RoomTicket.STATUS$CONFIRMED,
                db.RoomTicket.STATUS$DRAFT,
                db.RoomTicket.STATUS$CLOSED
            ];

            let buttons = {
                cancel: 'Cancel'
            };

            statuses.forEach(status => buttons[status]=status);
            let selectedStatus = await swal('Select a status', {
                buttons
            });

            if(!selectedStatus) return;

            roomTickets = roomTickets.map(roomTicket => roomTicket.set(db.RoomTicket.STATUS, selectedStatus));

            await Parse.Object.saveAll(roomTickets);

            swal('Saved', '', 'success');

            this.refresh();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async loadDeviceHistories(building){
        building = building || this.state.building;

        let deviceHistoriesFromBuilding = await new Parse.Query(db.classes.DeviceHistory)
            .equalTo(db.DeviceHistory.OLD_HOME, toPointerFromId(building.id, db.classes.Home))
            .include(db.DeviceHistory.DEVICE)
            .include(db.DeviceHistory.OLD_HOME)
            .include(db.DeviceHistory.NEW_HOME)
            .include(db.DeviceHistory.OLD_ROOM)
            .include(db.DeviceHistory.NEW_ROOM)
            .limit(200)
            .find();

        let deviceHistoriesToBuilding = await new Parse.Query(db.classes.DeviceHistory)
            .equalTo(db.DeviceHistory.NEW_HOME, toPointerFromId(building.id, db.classes.Home))
            .include(db.DeviceHistory.DEVICE)
            .include(db.DeviceHistory.OLD_HOME)
            .include(db.DeviceHistory.NEW_HOME)
            .include(db.DeviceHistory.OLD_ROOM)
            .include(db.DeviceHistory.NEW_ROOM)
            .limit(200)
            .find();

        return {
            deviceHistoriesFromBuilding,
            deviceHistoriesToBuilding
        };
    }

    render() {
        const renderDevices = deviceData => {
            let {
                device,
                battery,
                batteryVoltage,
                connection,
                wifiDb,
                wifiStrength,
                lastConnection
            } = deviceData;

            if(!device) return <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>;

            let inMountIcon = device?.get(db.Device.DEVICE_STATE_FLAG) === 'in-mount' ?
                <i className="fa fa-wrench" style={{color: 'orange'}}></i>:
                <i className="fa fa-wrench" style={{color: 'green'}}></i>;

            let lastMeasurementDateReal = moment(lastConnection, 'DD/MM/YYYY HH:mm:ss');
            let configWifiSsid = device.get(db.Device.CONFIG_WIFI_SSID);
            let connectionWifiSsid = device.get(db.Device.CONNECTION_WIFI_SSID);
            let batteryOk = battery;
            let wifiOk = wifiDb;
            let connectionOk = connection;
            let deviceType = device.get(db.Device.DEVICE_TYP);
            let icon;
            if(deviceType === db.Device.DEVICE_TYP$THERM) icon = thermoIcon;
            if(deviceType === db.Device.DEVICE_TYP$SENSP) icon = co2SensorIcon;
            if(deviceType === db.Device.DEVICE_TYP$SENSE) icon = co2SensorIcon;
            if(deviceType === db.Device.DEVICE_TYP$SENCO) icon = co2SensorIcon;
            if(deviceType === db.Device.DEVICE_TYP$ROUTER_RUT240)
                icon = 'https://ik.imagekit.io/sh/58015183_xxl_YdWVfFKt2F.webp?updatedAt=1641551894782';

            return <tr key={device.id}>
                <td>
                    <img src={icon} height={30}/>
                </td>
                <td>
                    <a href={`${config.adminDashboard.host}/devices/${device.id}`} target={'_blank'} rel={'noreferrer'}>
                        {device.get(db.Device.SERIAL_NUMBER)}
                        &nbsp;{inMountIcon}
                    </a>
                </td>
                <td>{device.get(db.Device.VERSION_FIRMWARE)}</td>
                <td>
                    {
                        batteryOk ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    <small>({batteryVoltage}V)</small>
                </td>
                <td>
                    {
                        wifiOk ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    <small>({wifiStrength}dbm)</small>
                </td>
                <td>
                    {
                        connectionOk ?
                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                            <i className="fa fa-times" style={{color: 'red'}}></i>
                    }
                    <small>({lastMeasurementDateReal.format('DD/MM/YYYY HH:mm:ss')})</small><br/>
                    {/*<small>Configured: {configWifiSsid}</small><br/>
                    <small>Connected: {connectionWifiSsid}</small>*/}
                </td>
            </tr>;
        };
        const renderTableDevices = devices => {
            return <Table>
                <thead>
                    <tr>
                        <th>Device</th>
                        <th>Serial</th>
                        <th>Version firmware</th>
                        <th><i className="fa fa-battery-three-quarters"></i></th>
                        <th><i className="fa fa-wifi"></i></th>
                        <th><i className="fa fa-arrows-h"></i></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        devices.map(renderDevices)
                    }
                </tbody>
            </Table>;
        }

        const renderRoomTickets = roomTickets => {
            return <Row>
                <Col md={12}>
                    <h1 style={{display: 'inline'}}>
                        Room tickets &nbsp;
                        <Button outline
                                onClick={() => downloadxls(roomTickets.map(item => convertRoomTicketToPlainObject(item)), this.state.operationTask)}>
                            Export excel
                        </Button>
                        <Button onClick={async () => await this.changeStatusRoomTickets()}>
                            Change status all tickets
                        </Button>
                    </h1>
                </Col>
                <Col md={12}>
                    <Form inline={true} style={{marginLeft: 15, display: 'inline-block'}}>
                        <FormGroup inline={true} style={{marginBottom: 10}}>
                            <Label for={db.TestBatch.NAME}>Category:</Label>&nbsp;
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        width: 200
                                    })
                                }}
                                options={
                                    _.uniq(
                                        this.state.issueCategories
                                            .map(issueCategory => issueCategory.get(db.IssueCategory.CATEGORY_KEY))
                                    )
                                        .map(value => ({
                                            value: value,
                                            label: value
                                        }))
                                }
                                isClearable={true}
                                value={this.state.selectedCategory}
                                onChange={async selectedCategory => {
                                    this.setState({selectedCategory});
                                }}
                            />
                        </FormGroup>
                        <FormGroup inline={true} style={{marginBottom: 10}}>
                            Show all room tickets for the building:
                            <Toggle defaultChecked={false}
                                    checked={this.state.showAllBuildingTickets}
                                    style={{marginLeft: 15}}
                                    onChange={(e) =>{
                                        this.setState({showAllBuildingTickets: !this.state.showAllBuildingTickets},  this.refresh);
                                    }}
                            />
                        </FormGroup>
                    </Form>
                </Col>

                <Col md={12}>
                    <p>Total number of pieces: {roomTickets.map(roomTicket => roomTicket.get(db.RoomTicket.NUMBER_PIECES) || 0).reduce((a, b) => a + b, 0)}</p>
                    {
                        roomTickets.length > 0 && <Table>
                            <thead>
                            <tr>
                                <th>Created at</th>
                                <th>Reference number</th>
                                <th>Category</th>
                                <th>Sub category</th>
                                <th>Affected devices</th>
                                <th>Billable</th>
                                <th>Status</th>
                                <th>Number of pieces</th>
                                <th>Note</th>
                                <th>Building id</th>
                                <th>Room</th>
                                <th>Floor</th>
                                <th>User</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                roomTickets.map((roomTicket) => {
                                    const {
                                        issueCategory,
                                        affectedDevices,
                                        billable,
                                        status,
                                        note,
                                        crmOperationId,
                                        crmOperationReferenceNumber,
                                        user,
                                        room,
                                        createdAt,
                                        numberOfPieces
                                    } = extractFieldFromRoomTicket(roomTicket);

                                    let iconCheck = <i className="fa fa-check" style={{color: 'green'}}></i>;
                                    let iconCross = <i className="fa fa-times" style={{color: 'red'}}></i>;

                                    return <tr key={roomTicket.id}>
                                        <td>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{crmOperationReferenceNumber && crmOperationReferenceNumber}</td>
                                        <td>{issueCategory && issueCategory.get(db.IssueCategory.CATEGORY_LABEL_EN)}</td>
                                        <td>
                                            {issueCategory && issueCategory.get(db.IssueCategory.SUB_CATEGORY_LABEL_EN)}&nbsp;
                                            <a href={'javascript: ;'} onClick={async () => {
                                                try {
                                                    let categories = await new Parse.Query(db.classes.IssueCategory)
                                                        .descending(db.IssueCategory.CATEGORY_KEY)
                                                        .find();

                                                    let buttons = {
                                                        cancel: 'Cancel'
                                                    };

                                                    categories.forEach(category => buttons[category.id]=`${category.get(db.IssueCategory.CATEGORY_KEY)}.${category.get(db.IssueCategory.SUB_CATEGORY_KEY)}`);
                                                    let selectedCategory = await swal('Select a category', {
                                                        buttons
                                                    });

                                                    if(!selectedCategory) return;

                                                    roomTicket.set(db.RoomTicket.ISSUE_CATEGORY, toPointerFromId(selectedCategory, db.classes.IssueCategory));

                                                    await roomTicket.save();

                                                    this.refresh();

                                                    swal('Success', '', 'success');
                                                }catch (e) {
                                                    console.error(e);
                                                    swal('Error', e.message, 'error');
                                                }

                                            }}>Edit category</a>
                                        </td>
                                        <td>{affectedDevices && affectedDevices.map(device => device.get(db.Device.SERIAL_NUMBER)).join(',')}</td>
                                        <td>{billable ? iconCheck : iconCross}</td>
                                        <td>{status}</td>
                                        <td>{numberOfPieces}</td>
                                        <td>
                                            {note}&nbsp;
                                            <a href={'javascript: ;'} onClick={async () => {
                                                try {
                                                    let text = await swal({
                                                        heightAuto: false,
                                                        title: 'Enter text',
                                                        content: {
                                                            element: 'input',
                                                            attributes: {
                                                                placeholder: 'Enter text',
                                                                type: 'text',
                                                                value: note
                                                            },
                                                        }
                                                    });

                                                    if(!text) return;

                                                    roomTicket.set(db.RoomTicket.NOTE, text);

                                                    await roomTicket.save();
                                                    this.refresh();

                                                    swal('Success', '', 'success');
                                                } catch (e) {
                                                    console.error(e);
                                                    swal('Error', e.message, 'error');
                                                }
                                            }}>Edit text</a>
                                        </td>
                                        <td>{room && room.get(db.Room.HOME) && room.get(db.Room.HOME).id}</td>
                                        <td><a href={`/maintenance-tasks/${this.props.match.params.id}/room/${room && room.id}`} target={'_blank'}>{room && room.get(db.Room.ROOM_NAME)}</a></td>
                                        <td>{room && room.get(db.Room.FLOOR)}</td>
                                        <td>{user && user.get(db._User.USERNAME)}</td>
                                        <td>
                                            <Button outline color={'danger'}
                                                    onClick={async e => {
                                                        e.preventDefault();
                                                        try{
                                                            roomTicket.set(db.RoomTicket.DELETED, true)
                                                            await roomTicket.save();
                                                            swal({title: 'Deleted', text: ' ', icon: 'success', button: [''], timer: 1000});
                                                            this.refresh();
                                                        }catch (e) {
                                                            swal('Error', e.message, 'error');
                                                        }
                                                    }}
                                                    style={{marginLeft: 10}}>
                                                <i className="fa fa-trash"></i>
                                            </Button>

                                            <Button onClick={async () => {
                                                let statuses = [
                                                    db.RoomTicket.STATUS$CONFIRMED,
                                                    db.RoomTicket.STATUS$DRAFT,
                                                    db.RoomTicket.STATUS$CLOSED
                                                ];

                                                let buttons = {
                                                    cancel: 'Cancel'
                                                };

                                                statuses.forEach(status => buttons[status]=status);
                                                let selectedStatus = await swal('Select a status', {
                                                    buttons
                                                });

                                                if(!selectedStatus) return;

                                                roomTicket.set(db.RoomTicket.STATUS, selectedStatus);

                                                await roomTicket.save();

                                                this.refresh();
                                            }}>
                                                Change status
                                            </Button>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>;
        };

        const renderDeviceHistories = (deviceHistories, title) => {
            return <Row>
                <Col>
                    <h1 style={{display: 'inline'}}>{title} &nbsp;</h1>
                </Col>
                <Col md={12}>
                    {
                        deviceHistories.length > 0 && <Table>
                            <thead>
                            <tr>
                                <th>Created at</th>
                                <th>Device</th>
                                <th>Old home</th>
                                <th>Old Room</th>
                                <th>New Home</th>
                                <th>New room</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                deviceHistories.map((deviceHistory) => {
                                    const {
                                        createdAt,
                                        device,
                                        oldHome,
                                        oldRoom,
                                        newHome,
                                        newRoom,
                                        serial,
                                        oldHomeName,
                                        oldRoomName,
                                        newHomeName,
                                        newRoomName
                                    } = extractFieldFromDeviceHistory(deviceHistory)

                                    return <tr key={deviceHistory.id}>
                                        <td>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{serial}</td>
                                        <td>{oldHomeName}</td>
                                        <td>{oldRoomName}</td>
                                        <td>{newHomeName}</td>
                                        <td>{newRoomName}</td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>
        }


        let {
            devices,
            operationTask,
            building,
            roomTickets,
            selectedCategory,
            deviceHistoriesFromBuilding,
            deviceHistoriesToBuilding
        } = this.state;

        roomTickets = filterRoomTicketsCategory(roomTickets, selectedCategory?.value);

        return <div>
            <Breadcrumb>
                <BreadcrumbItem>
                    <a href={paths.maintenance}>
                        Maintenance tasks
                    </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <a href={paths.singleMaintenance.replace(':id', this.props.match.params.id)}>
                        {building && building.get(db.Home.HOME_NAME)}
                    </a>
                </BreadcrumbItem>
            </Breadcrumb>
            {/*<Row>
                <pre>
                    {JSON.stringify(this.state.roomTickets.map(convertRoomTicketToPlainObject), null, 2)}
                </pre>
            </Row>*/}
            <Row>
                <Col md={12}>
                    <h1>
                        {this.state.operationTask?.get(db.OperationTask.HOME)?.get(db.Home.HOME_NAME)} ({building?.id}) - Report
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {
                        !this.state.installationData && <h2>No installation data - complete the operation</h2>
                    }
                    {
                        this.state.installationData &&
                        this.state.installationData.rooms.map((roomData, i) => {
                            let {room, devices} = roomData;

                            return <Col md={12} key={i}>
                                <h2>{room.get(db.Room.ROOM_NAME)}</h2>
                                {renderTableDevices(devices)}
                            </Col>;
                        })
                    }
                </Col>
            </Row>
            {renderRoomTickets(roomTickets)}
            {renderDeviceHistories(deviceHistoriesFromBuilding, 'Device history (from this building)')}
            {renderDeviceHistories(deviceHistoriesToBuilding, 'Device history (to this building)')}
        </div>;
    }
}

PageOperationTaskReport.propTypes = {
    match: PropTypes.any,
    history: PropTypes.any
};