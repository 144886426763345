import React, {Component} from 'react';
import {Button, Col, Row} from 'reactstrap';
import moment from 'moment';
import * as db from '../../../config/dbStructure';
import _ from 'lodash';
import Parse from '../../../lib/parse';

const downloadJsonToXLS = (json, fileName = 'download') => {
    let timestamp = moment().format('YYYYMMDDHHmm');
    let ws = XLSX.utils.json_to_sheet(json);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${fileName}_${timestamp}.xlsx`);
}

function groupByDateFormat(objects, groupFormat){
    let array = [];

    let objectsWithDate = objects.map(object => {
        let createdAt = moment(object.get(db.Device.CREATED_AT));

        let day = createdAt.date();
        let month = createdAt.month();
        let year = createdAt.year();

        let sortKey = createdAt.format(groupFormat);

        return {
            sortKey,
            date: `${day}/${month}/${year}`,
            n: 1
        }
    });

    let groupedObjects = _.groupBy(objectsWithDate, item => item.sortKey);

    let groupedSum = Object.keys(groupedObjects).map(key => {
        let sum =  _.sumBy(groupedObjects[key], item => item.n);
        let date = moment(key, groupFormat);

        return {
            key,
            number: sum,
            date: date.format('DD/MM/YYYY')
        };
    });

    let sortedSum = _.sortBy(groupedSum, ['key']);

    return sortedSum;
}

export default class PageProductionReport extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        let testBatches = await this.fetchTestBatches();
        let devices = await this.fetchDevices();

        this.setState({testBatches, devices});
    }

    async fetchTestBatches(){
        let testBatches = await new Parse.Query(db.classes.TestBatch)
            .greaterThanOrEqualTo(db.TestBatch.CREATED_AT, moment().subtract(3, 'month').toDate())
            .select([db.TestBatch.CREATED_AT])
            .startsWith(db.TestBatch.NAME, 'MBS')
            .limit(10000)
            .find();

        return testBatches;
    }

    async fetchDevices(){
        let devices = await new Parse.Query(db.classes.Device)
            .greaterThanOrEqualTo(db.Device.CREATED_AT, moment().subtract(3, 'month').toDate())
            .select([db.Device.CREATED_AT])
            .limit(10000)
            .find();

        return devices;
    }


    downloadDevicesPerDay(devices){
        let groupedObjects = groupByDateFormat(devices, 'YYYYDDMM');

        downloadJsonToXLS(groupedObjects, 'created_devices_per_day');
    }

    downloadDevicesPerWeek(devices){
        let groupedObjects = groupByDateFormat(devices, 'w');

        downloadJsonToXLS(groupedObjects, 'created_devices_per_week');
    }

    downloadTestBatchesPerDay(testBatches){
        let groupedObjects = groupByDateFormat(testBatches, 'YYYYDDMM');

        downloadJsonToXLS(groupedObjects, 'created_test_batches_per_day');
    }

    downloadTestBatchesPerWeek(testBatches){
        let groupedObjects = groupByDateFormat(testBatches, 'w');

        downloadJsonToXLS(groupedObjects, 'created_test_batches_per_week');
    }

    render() {
        let devices = this.state.devices;
        let testBatches = this.state.testBatches;

        return (
            <div>
                <Row>
                    <Col md={12}><h1>Production report</h1></Col>
                    <Col md={12}>
                        <Button onClick={() => this.downloadDevicesPerDay(devices)}>Download device created per day</Button><br/>
                        <Button onClick={() => this.downloadDevicesPerWeek(devices)}>Download devices created per week</Button><br/>
                        <Button onClick={() => this.downloadTestBatchesPerDay(testBatches)}>Download batch created per day</Button><br/>
                        <Button onClick={() => this.downloadTestBatchesPerWeek(testBatches)}>Download batch created per week</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}