import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'reactstrap';
import * as db from '../../../config/dbStructure';
import config from '../../../config/app';
import moment from 'moment';

export default class TemperatureTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const referenceTemperature = this.props.referenceTemperature;
        let testBatchType = this.props.testBatch?.get(db.TestBatch.TYPE);

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Device</th>
                        <th><i className="fa fa-arrows-h"></i></th>
                        <th>Fir. Ver.</th>
                        <th>Temp. check</th>
                        <th>Correction applied</th>
                        {
                            testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2 &&
                            <th>Co2 check</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.testBatchDevices.map(testBatchDevice => {
                            let currentCo2 = testBatchDevice.get(db.TestBatchDevice.CURRENT_CO2);
                            let co2Ok = testBatchDevice.get(db.TestBatchDevice.CO2_TEST);
                            let referenceCo2 = testBatchDevice.get(db.TestBatchDevice.REFERENCE_CO2);
                            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
                            let correctionTemp = device.get(db.Device.CORRECTION_TEMP);
                            let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
                            let deviceType = device.get(db.Device.DEVICE_TYP);
                            let measurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
                            let correctVerionNumber = false;

                            switch (deviceType){
                                case db.Device.DEVICE_TYP$SENSP:
                                    correctVerionNumber =
                                        versionFirmware === config.leanManagment.REQUIRED_FIRMWARE_VERSION_SENSE_CO2;
                                    break;
                                case db.Device.DEVICE_TYP$THERM:
                                    correctVerionNumber =
                                        versionFirmware === config.leanManagment.REQUIRED_FIRMWARE_VERSION_THERMO;
                                    break;
                            }

                            return <tr key={testBatchDevice.id}>
                                <th scope="row">{testBatchDevice.id}</th>
                                <td>
                                    <a href={`${config.adminDashboard.host}/devices/${device.id}`}>{device.get(db.Device.SERIAL_NUMBER)}</a>
                                </td>
                                <td>
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) == null &&
                                    <i className="fa fa-times" style={{color: 'red'}}></i> }
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'connected' &&
                                    <i className="fa fa-check" style={{color: 'green'}}></i> }
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'No debug' &&
                                    <i className="fa fa-times" style={{color: 'orange'}}></i>}
                                    {testBatchDevice.get(db.TestBatchDevice.CONNECTED) === 'Not connected' &&
                                    <i className="fa fa-times" style={{color: 'red'}}></i>}
                                    {measurementDate && moment(measurementDate).fromNow()}
                                </td>
                                <td>
                                    {correctVerionNumber ?
                                        <i className="fa fa-check" style={{color: 'green'}}></i> :
                                        <i className="fa fa-times" style={{color: 'red'}}></i>}
                                    {versionFirmware}
                                </td>
                                <td>
                                    {testBatchDevice.get(db.TestBatchDevice.TEMPERATURE_TEST) === true ?
                                        <i className="fa fa-check" style={{color: 'green'}}></i> :
                                        <i className="fa fa-times" style={{color: 'red'}}></i>}
                                    {device.get(db.Device.TEMPERATURE)?.toFixed(2)}°C
                                    &nbsp;
                                    {this.props.referenceTemperature}°C
                                    &nbsp;
                                    +/- {config.leanManagment.MAXIMAL_TEMPERATURE_ERROR_GAP}°
                                </td>
                                <td>
                                    {correctionTemp?.toFixed(2) ?? 0}
                                </td>
                                {
                                    testBatchType === db.TestBatch.TYPE$CLEVER_SENSE_CO2 &&
                                    <td>
                                        {co2Ok ?
                                            <i className="fa fa-check" style={{color: 'green'}}></i> :
                                            <i className="fa fa-times" style={{color: 'red'}}></i>}
                                        (
                                        {currentCo2}
                                        )
                                    </td>
                                }
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

TemperatureTable.propTypes = {
    testBatchDevices: PropTypes.array.isRequired,
    currentCo2Map: PropTypes.array,
    avgCo2: PropTypes.number
};