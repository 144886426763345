'use strict';

/**
 * Created by Simone Cogno on 28.08.2019
 */


module.exports = {
    log: {},
    api: {},
    rendering: {},
    server: {},
    leanManagment: {}
};
