'use strict';

import axios from 'axios';
import * as db from '../config/dbStructure';
import Parse from '../lib/parse';
import swal from 'sweetalert';
import moment from 'moment';
import config from '../config/app';
import {validate} from 'uuid';
import _ from 'lodash';
import {Button, Col, Row} from "reactstrap";
import paths from "./paths";
import React from "react";
import {testBatchNeedTest, testNeeded, toPointerFromId} from "../lib/util";

/**
 *
 * @param {Array<Parse.Object>} devices
 * @returns {Promise<AxiosResponse<T>>}
 */
export function onConnectivityCheck(testBatchId){
    return axios.post(
        // eslint-disable-next-line max-len
        `${config.leanManagment.host}/api/device-testing/check-connectivity-devices`, {
            testBatchId: testBatchId,
            sessionToken: Parse.User.current().getSessionToken()
        });


}

/**
 *
 * @param {string} batchId
 * @returns {Promise<Object[Parse.Object]>}
 */
export function getBatchDevices(batchId) {
    return (new Parse.Query(db.classes.TestBatchDevice))
        .equalTo(db.TestBatchDevice.BATCH, toPointerFromId(batchId, 'TestBatch'))
        .include(db.TestBatchDevice.DEVICE)
        .include(`${db.TestBatchDevice.DEVICE}.${db.Device.ROOM_ID}`)
        .include(`${db.TestBatchDevice.DEVICE}.${db.Device.HOME}`)
        .include(`${db.TestBatchDevice.DEVICE}.${db.Device.LAST_MEASUREMENT}`)
        .find();
}

export async function checkConnectivityDevices(testBatchDevices) {
    try {
        let listTestBatchDevicesToUpdate = [];

        for (let testBatchDevice of testBatchDevices) {
            let device = testBatchDevice.get(db.TestBatchDevice.DEVICE);
            let deviceTyp = device.get(db.Device.DEVICE_TYP);
            let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
            let deviceMode = device.get(db.Device.MODE);

            let isInDebug = deviceMode === 'debug';
            let isLastConnectionOrange = false;
            let isLastConnectionGreen = false;
            let timeOrange = null;
            let timeRed = null;
            let timeGreen = 5;

            switch (deviceTyp) {
                case db.Device.DEVICE_TYP$THERM:
                    timeOrange = 10;
                    break;
                default:
                    timeOrange = 6;
            }

            if(lastMeasurementDate){
                isLastConnectionOrange =
                    Math.abs(moment(lastMeasurementDate).diff(moment(), 'minutes')) <=
                    timeOrange;

                isLastConnectionGreen =
                    Math.abs(moment(lastMeasurementDate).diff(moment(), 'minutes')) <=
                    timeGreen;
            }

            if(isLastConnectionGreen){
                testBatchDevice.set(db.TestBatchDevice.CONNECTED, 'connected');
            } else if(!isInDebug && isLastConnectionOrange){
                testBatchDevice.set(db.TestBatchDevice.CONNECTED, 'No debug');
            } else {
                testBatchDevice.set(db.TestBatchDevice.CONNECTED, 'Not connected');
            }

            listTestBatchDevicesToUpdate.push(testBatchDevice);
        }

        await Parse.Object.saveAll(listTestBatchDevicesToUpdate);

        swal({title: 'Connection updated', text: ' ', icon: 'success', button: [''], timer: 1000});
    } catch (e) {
        console.error('Error:', e.message);
        swal('Error', e.message, 'error');
    }
}



async function acceptRoomInstallationId(roomUuid){
    let valid = validate(roomUuid);

    if(!valid)
        return swal({title: 'No room installation id found.', text: ' ', icon: 'info',
            button: [''], timer: 1000});

    let rid = await (new Parse.Query(db.classes.RoomInstallationId))
        .equalTo(db.RoomInstallationId.UUID, roomUuid)
        .first();

    if(!rid)
        return swal({title: 'No room installation id found.', text: ' ', icon: 'info',
            button: [''], timer: 1000});

    let query = new Parse.Query(db.classes.Room);
    query.equalTo(db.Room.UNIQUE_ID, roomUuid);
    query.include(db.Room.HOME);

    let room = await query.find();

    if(room.length > 1) {

        if(room){
            let roomName = room.get(db.Room.ROOM_NAME);
            let roomHomeName = room.get(db.Room.HOME) != null ?
                room.get(db.Room.HOME).get(db.Home.HOME_NAME) : null;
            this.setState({roomId: ''});

            // eslint-disable-next-line max-len
            return swal({title: `Room id has already be used on room "${roomName}" on building "${roomHomeName}". Use another code.`, text: ' ', icon: 'error',
                button: [''], timer: 1000});
        }


        this.setState({activateDevices: true, roomVerified: true});

        setTimeout(() => {
            $('#devices').focus();
        }, 10);
    }
}


export async function getTag() {
    let currentUser = Parse.User.current();

    //--------------- BATCH TAG --------------------------------------------
    let tags = [
        db.TestBatch.TAG$CLEVERON,
        db.TestBatch.TAG$YGNIS_CLEVERON,
        db.TestBatch.TAG$REFURBISHED,
        db.TestBatch.TAG$RETURNED,
        db.TestBatch.TAG$DESTROYED,
        db.TestBatch.TAG$WITH_GETTING_STARTED_INSERT,
        db.TestBatch.TAG$NB_IOT,
        db.TestBatch.TAG$JAKUTEC,
        db.TestBatch.TAG$WNB,
        db.TestBatch.TAG$WNBP,
        db.TestBatch.TAG$WNBP_MODIFIED,
        db.TestBatch.TAG$WIFI
    ];

    let buttons = {
        cancel: 'Cancel'
    };

    tags.forEach(tag => buttons[tag]=_.capitalize(tag));
    let tag = await swal(`Enter tag of batch: "${tags.join(', ')}"`, {
        buttons
    });

    if(tags.indexOf(tag) < 0)
        throw new Error(`Invalid tag of batch should be one of: "${tags.join(', ')}"`);

    return tag;
}


export function isAValidRoom(room){
    let roomName = room.get(db.Room.ROOM_NAME);
    let roomType = room.get(db.Room.ROOM_TYPE);

    if(roomName.includes('no-count')) return false;
    if(roomName.includes('deleted')) return false;
    if(roomName.includes('Deleted')) return false;
    if(roomName.includes('config')) return false;
    if(roomName.includes('Config')) return false;
    if(roomName.includes('to-configure')) return false;
    if(roomName.includes('Reserve')) return false;
    if(roomName.includes('Riserva')) return false;
    if(roomName.includes('reserve')) return false;
    if(roomName.includes('riserva')) return false;

    if(roomName === db.Room.ROOM_NAME$TO_CONFIGURE) return false;
    if(roomType === db.Room.ROOM_TYPE$RESERVE) return false;

    return true;
}

export function renderTestButtons(testBatch) {
    if(!testBatch) return <></>;

    return <Row>
        <Col md={12}>
            <div className="center-200">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Button outline color="primary"
                                        href={paths.singleBatchView.replace(':id', testBatch?.id)}
                                        target={'_blank'}
                                >
                                    <i className="fa fa-list"></i>
                                </Button>
                            </td>
                            {
                                testBatchNeedTest(testBatch, db.TestBatch.TESTS$VISUAL) && <td>

                                    <Button outline color="primary"
                                            href={paths.visualTest.replace(':id', testBatch?.id)}
                                            target={'_blank'}
                                    >
                                        <i className="fa fa-eye"></i>
                                    </Button>
                                </td>

                            }
                            {
                                testBatchNeedTest(testBatch, db.TestBatch.TESTS$CONNECTION) && <td>
                                    <Button outline color="primary"
                                            href={paths.connectionTest.replace(':id', testBatch?.id)}
                                            target={'_blank'}
                                    >
                                        <i className="fa fa-arrows-h"></i>
                                    </Button>
                                </td>

                            }
                            {
                                testBatchNeedTest(testBatch, db.TestBatch.TESTS$BATTERY) && <td>
                                    <Button outline color="primary"
                                            href={paths.batteryTest.replace(':id', testBatch?.id)}
                                            target={'_blank'}
                                    >
                                        <i className="fa fa-battery-three-quarters"></i>
                                    </Button>
                                </td>
                            }
                            {
                                testBatchNeedTest(testBatch, db.TestBatch.TESTS$MOTOR) && <td>
                                    <Button outline color="primary"
                                            href={paths.motorTest.replace(':id', testBatch?.id)}
                                            target={'_blank'}
                                    >
                                        <i className="fa fa-cog"></i>
                                    </Button>
                                </td>
                            }
                            {
                                testBatchNeedTest(testBatch, db.TestBatch.TESTS$TEMPERATURE) && <td>
                                    <Button outline color="primary"
                                            href={paths.temperatureCalibration.replace(':id', testBatch?.id)}
                                            target={'_blank'}
                                    >
                                        <i className="fa fa-thermometer-three-quarters"></i>
                                    </Button>
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>

            </div>
        </Col>
    </Row>
}


export function moveUpItemArray(array, item){
    let currentIndex = array.indexOf(item);

    let previousIndex = currentIndex - 1;

    if(previousIndex < 0) previousIndex = 0;

    let copyPreviousItem = array[previousIndex];

    array[previousIndex] = item;

    array[currentIndex] = copyPreviousItem;

    return array;
}

export function moveDownItemArray(array, item){
    let currentIndex = array.indexOf(item);

    let nextIndex = currentIndex + 1;

    if(nextIndex > (array.length - 1)) nextIndex = (array.length - 1);

    let copyPreviousItem = array[nextIndex];

    array[nextIndex] = item;

    array[currentIndex] = copyPreviousItem;

    return array;
}


export function insertElementAndEliminateIfExsisting(array, item){
    let currentIndex = array.indexOf(item);

    if(currentIndex < 0){
        array.push(item);
    } else {
        array[currentIndex] = null;
        array.push(item);
    }

    return array;
}

export function inseertAtIndex(array, item, index){
    let currentIndex = array.indexOf(item);

    //The place is taken, switch them!
    if(array[index] != null) {
        let currentIndex = array.indexOf(item);
        let exsistingItem = array[index];
        array[index] = item;
        array[currentIndex] = exsistingItem;

        return array;
    }

    //If the place is free then delete the previous position
    if(currentIndex >= 0) array[currentIndex] = null;

    if((array.length - 1) < index){
        let numberOfMissingIndex = index - (array.length - 1);

        for(let i = 0; i<numberOfMissingIndex; i++) array.push(null);
    }

    array.splice(index, 1, item);

    return array;
}


export function testBatchTagNBIOT(tag){
    let showNbIotInfo = (
        tag === db.TestBatch.TAG$NB_IOT ||
        tag === db.TestBatch.TAG$WNBP ||
        tag === db.TestBatch.TAG$WNB ||
        tag === db.TestBatch.TAG$WNBP_MODIFIED
    );

    return showNbIotInfo;
}