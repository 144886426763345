import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import swal from 'sweetalert';
import Parse from '../../../lib/parse';
import Compress from 'compress.js';

const compress = new Compress();

export default class AddPhotoModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.reset = this.reset.bind(this);
        this.attachUploadEvent = this.attachUploadEvent.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggle);
    }

    onOpened(){
        this.attachUploadEvent();
    }

    attachUploadEvent(){
        let onChange = (evt, id) => {
            const files = [...evt.target.files];
            compress.compress(files, {
                size: 2,
                quality: .75
            }).then((data) => {
                let file = data[0];

                if (file.initialHeightInPx > file.initialWidthInPx) {
                    return swal(
                        'Error',
                        // eslint-disable-next-line max-len
                        'The width should be bigger that the height, if you use a tablet or smartphone be sure to keep it horizontally.',
                        'error'
                    );
                }

                console.log('attachUploadEvent', file);

                this.setState({file: data[0]});
            })
        };

        let attachListener = () => {
            // Attach listener
            const upload = document.getElementById(`room-image-upload-${this.props.room.id}`);
            if(upload){
                upload.removeEventListener('change', (evt) => onChange(evt, this.props.room.id));
                upload.addEventListener('change',  (evt) => onChange(evt, this.props.room.id), false);
            } else {
                setTimeout(attachListener, 200);
            }
        };

        setTimeout(attachListener, 100);
    }



    toggle(){
        let nextStatus = !this.state.isOpen;
        this.setState({isOpen: nextStatus});
        if(!nextStatus) this.reset();
    }

    reset(){
        const upload = document.getElementById(`room-image-upload-${this.props.room.id}`);
        upload.removeEventListener('change');

        this.setState({
            isOpen: false
        });
    }

    async uploadFile() {
        try {
            if (this.state.file) {
                let parseFile = new Parse.File('photo.png', {base64: this.state.file.data}, this.state.file.ext);

                let fileObject = await parseFile.save();

                this.props.room.set(db.Room.MAIN_PHOTO, fileObject);

                await this.props.room.save();

                swal({
                    title: 'Photo saved successfull',
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });

                this.setState({file: null, isOpen: false});
            } else {
                swal('Error', 'No image selected. Please select an image and retry', 'error');
            }
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }


    render(){
        //if(!this.props.room) return '';

        return <div>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle} size={'lg'}
                   onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Add photo to room</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for={`room-image-upload-${this.props.room.id || ''}`}>Select an image or take it right away:</Label>
                            <Input type="file" accept="image/*" id={`room-image-upload-${this.props.room.id || ''}`}/>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={this.uploadFile}>
                        Save photo
                    </Button>{' '}
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    }
}

AddPhotoModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func,
    room: PropTypes.object.isRequired
};