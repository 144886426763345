import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress} from 'reactstrap';
import swal from 'sweetalert';
import Parse from '../../../lib/parse';
import Select from 'react-select';
import moment from 'moment-business-days';
import {
    checkRequiredFields,
    createBasicACL,
    extractHomeFields,
    extractSupplierOrderInformation,
    extractUserFields,
    getNumberOfThermAndSense,
    makeId
} from '../../../lib/util';
import _ from 'lodash';
import assert from 'assert';
import {getBuilding, getContact, getOperationFromId, getSalesOrderFromId} from '../../../lib/zohoService';
import Toggle from 'react-toggle';

function setPermissionHome(user, home){
    assert(user.id != null, 'User id is null');

    let homeACL = createBasicACL([user.id], [user.id])

    home.setACL(homeACL);
}

let cantonsList = [
    {value: 'ZH', label: 'Canton of Zürich'},
    {value: 'BE', label: 'Bern (canton)'},
    {value: 'LU', label: 'Canton of Lucerne'},
    {value: 'UR', label: 'Canton of Uri'},
    {value: 'SZ', label: 'Schwyz (canton)'},
    {value: 'OW', label: 'Obwalden'},
    {value: 'NW', label: 'Nidwalden'},
    {value: 'GL', label: 'Canton of Glarus'},
    {value: 'ZG', label: 'Canton of Zug'},
    {value: 'FR', label: 'Canton of Fribourg'},
    {value: 'SO', label: 'Canton of Solothurn'},
    {value: 'BS', label: 'Basel-Stadt'},
    {value: 'BL', label: 'Basel-Landschaft'},
    {value: 'SH', label: 'Canton of Schaffhausen'},
    {value: 'AR', label: 'Appenzell Ausserrhoden'},
    {value: 'AI', label: 'Appenzell Innerrhoden'},
    {value: 'SG', label: 'Canton of St. Gallen'},
    {value: 'GR', label: 'Graubünden'},
    {value: 'AG', label: 'Aargau'},
    {value: 'TG', label: 'Thurgau'},
    {value: 'TI', label: 'Ticino'},
    {value: 'VD', label: 'Vaud'},
    {value: 'VS', label: 'Valais'},
    {value: 'NE', label: 'Canton of Neuchâtel'},
    {value: 'GE', label: 'Canton of Geneva'},
    {value: 'JU', label: 'Canton of Jura'}
];
let cantonsMap = {
    'ZH': 'Canton of Zürich',
    'BE': 'Bern (canton)',
    'LU': 'Canton of Lucerne',
    'UR': 'Canton of Uri',
    'SZ': 'Schwyz (canton)',
    'OW': 'Obwalden',
    'NW': 'Nidwalden',
    'GL': 'Canton of Glarus',
    'ZG': 'Canton of Zug',
    'FR': 'Canton of Fribourg',
    'SO': 'Canton of Solothurn',
    'BS': 'Basel-Stadt',
    'BL': 'Basel-Landschaft',
    'SH': 'Canton of Schaffhausen',
    'AR': 'Appenzell Ausserrhoden',
    'AI': 'Appenzell Innerrhoden',
    'SG': 'Canton of St. Gallen',
    'GR': 'Graubünden',
    'AG': 'Aargau',
    'TG': 'Thurgau',
    'TI': 'Ticino',
    'VD': 'Vaud',
    'VS': 'Valais',
    'NE': 'Canton of Neuchâtel',
    'GE': 'Canton of Geneva',
    'JU': 'Canton of Jura'
}

export default class AddNewSupplierOrderModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            homes: [],
            testBatches: [],
            users: [],
            supplierOrder: props.supplierOrder || this.initSupplierOrder(),
            previouslySelectedTestBatch: (
                props.supplierOrder && props.supplierOrder.get(db.SupplierOrder.TEST_BATCHES)
            ) || [],
            loading: {},
            newHome: this.initHome(),
            newUser: this.initUser(),
            selectedUser: null
        };

        this.toggle = this.toggle.bind(this);
        this.reset = this.reset.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.addNewInstallation = this.addNewInstallation.bind(this);
        this.fillOrderFromOperation = this.fillOrderFromOperation.bind(this);
        this.fillOrderFromSalesOrder = this.fillOrderFromSalesOrder.bind(this);
        this.modifySupplierOrderField = this.modifySupplierOrderField.bind(this);
        this.modifyUser = this.modifyUser.bind(this);
        this.modifyHome = this.modifyHome.bind(this);
    }

    async componentDidMount(){
        this.props.setToggleModal(this);
    }

    getState(){
        return this.state;
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
    }

    async onOpened(){
        this.loadHomes();
        this.loadTestBatch();
        this.loadUsers();
    }

    initSupplierOrder(){
        let supplierOrder = new Parse.Object(db.classes.SupplierOrder);

        supplierOrder.set(db.SupplierOrder.REQUIRED_THERM,              0);
        supplierOrder.set(db.SupplierOrder.REQUIRED_SENSE,              0);
        supplierOrder.set(db.SupplierOrder.ACTUAL_THERM,                0);
        supplierOrder.set(db.SupplierOrder.ACTUAL_SENSE,                0);
        supplierOrder.set(db.SupplierOrder.SALES_ORDER_ID,              null);
        supplierOrder.set(db.SupplierOrder.OPERATION,                   null);
        supplierOrder.set(db.SupplierOrder.SHIPPING_DATE,               null);
        supplierOrder.set(db.SupplierOrder.ADAPTERS,                    {A: 0, B: 0, C: 0, D: 0});
        supplierOrder.set(db.SupplierOrder.ROOM_QR_CODE,                0);
        supplierOrder.set(db.SupplierOrder.HOME,                        null);
        supplierOrder.set(db.SupplierOrder.REFERENCE_NUMBER,            '');
        supplierOrder.set(db.SupplierOrder.CONFIGURATION,                        null);
        supplierOrder.set(db.SupplierOrder.STATUS,                      db.SupplierOrder.STATUS$INITIALIZED);
        supplierOrder.set(db.SupplierOrder.TEST_BATCHES,                []);

        return supplierOrder;
    }

    initHome(){
        let home = new Parse.Object(db.classes.Home);

        home.set(db.Home.U_VALUE, 1);

        return home;
    }
    initUser(){
        let user = new Parse.Object(db.classes._User);

        user.set(db._User.PASSWORD, makeId(6));


        return user;
    }

    reset(){
        this.setState({
            isOpen: false,
            homes: [],
            testBatches: [],
            supplierOrder: this.initSupplierOrder()
        });
    }

    async updateTestBatchStatus(supplierOrder, previouslySelectedTestBatch){
        let selectedTestBatch = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);

        let testBatchToSave = [];
        for(let testBatch of previouslySelectedTestBatch){
            if(selectedTestBatch.indexOf(testBatch) < 0){
                //previously selected test batch is now not present enymore on selected testbatches
                //set batch back to status tested
                testBatch.set(db.TestBatch.STATUS, db.TestBatch.STATUS$TESTED)

                testBatchToSave.push(testBatch);
            }
        }

        await Parse.Object.saveAll(testBatchToSave);

        testBatchToSave = [];

        for(let testBatch of selectedTestBatch) {
            if(previouslySelectedTestBatch.indexOf(testBatch) < 0){
                //this newly selected test batch were not selected before
                //we set his status to "reseved-for-installation"
                testBatch.set(db.TestBatch.STATUS, db.TestBatch.STATUS$RESERVED_FOR_INSTALLATION);

                testBatchToSave.push(testBatch);
            }
        }

        await Parse.Object.saveAll(testBatchToSave);
    }
    async addNewInstallation(){
        try {
            let supplierOrderRequiredFields = [
                db.SupplierOrder.REQUIRED_THERM,
                db.SupplierOrder.REQUIRED_SENSE,
                db.SupplierOrder.ROOM_QR_CODE,
                db.SupplierOrder.REFERENCE_NUMBER,
                db.SupplierOrder.CONFIGURATION,
                db.SupplierOrder.SUBJECT,
                db.SupplierOrder.ADAPTERS,
                db.SupplierOrder.TEST_BATCHES,
                db.SupplierOrder.SHIPPING_FIRST_NAME,
                db.SupplierOrder.SHIPPING_LAST_NAME,
                db.SupplierOrder.SHIPPING_CITY,
                db.SupplierOrder.SHIPPING_COMPANY_NAME,
                db.SupplierOrder.SHIPPING_COUNTRY,
                db.SupplierOrder.SHIPPING_STREET,
                db.SupplierOrder.SHIPPING_STREET_NUMBER,
                db.SupplierOrder.SHIPPING_DEPARTMENT,
                db.SupplierOrder.SHIPPING_ZIP
            ];

            let homeRequiredFields = [
                db.Home.LANGUAGE,
                db.Home.CITY,
                db.Home.COUNTRY,
                db.Home.ADDRESS,
                db.Home.YEAR,
                db.Home.CANTON,
                db.Home.ZIP,
                db.Home.HOME_NAME,
                db.Home.OWNER,
                db.Home.U_VALUE,
                db.Home.TYPE_OF_CUSTOMER
            ];

            let userRequiredFields = [
                db._User.FIRST_NAME,
                db._User.LAST_NAME,
                db._User.LANGUAGE,
                db._User.EMAIL,
                db._User.USERNAME,
                db._User.PASSWORD,
                db._User.LANGUAGE,
                db._User.TYPE_OF_CUSTOMER
            ];

            let supplierOrder = this.state.supplierOrder;
            let newUser = this.state.newUser;
            let newHome = this.state.newHome;
            let selectedUser = this.state.selectedUser;

            if(!supplierOrder.id){//It's a new supplier order
                let exsistingOrder = await (new Parse.Query(db.classes.SupplierOrder))
                    .equalTo(db.SupplierOrder.REFERENCE_NUMBER, supplierOrder.get(db.SupplierOrder.REFERENCE_NUMBER))
                    .first();

                if(exsistingOrder) {
                    let result = await swal({
                        title: 'Another order with the same reference number was found. Continue?',
                        text: '',
                        buttons: ['Cancel', 'Yes add'],
                    });

                    if(!result) return;
                }
            }

            let supplierOrderErrorString = checkRequiredFields(supplierOrder, supplierOrderRequiredFields);

            if(!moment(supplierOrder.get(db.SupplierOrder.SHIPPING_DATE)).isValid())
                supplierOrderErrorString += `Invalid ${db.SupplierOrder.SHIPPING_DATE} \n`;

            if(supplierOrderErrorString) throw new Error(supplierOrderErrorString);

            let home = supplierOrder.get(db.SupplierOrder.HOME);

            if(!home && supplierOrder.get(db.SupplierOrder.CONFIGURATION) === db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION) {
                if(!newHome) throw Error('Please fill informations about the building');

                if(selectedUser){
                    newHome.set(db.Home.OWNER, selectedUser);
                    setPermissionHome(selectedUser, newHome);
                } else {
                    if(!newUser) throw Error('Please fill informations about the user');

                    let existingUser = await Parse.Cloud.run('getUserByEmail', {userEmail: newUser.get(db._User.EMAIL)});

                    if(existingUser){
                        let result = await swal({
                            title: 'Existing user',
                            text: `User with username: ${existingUser.get(db._User.USERNAME)} and e-mail: ${existingUser.get(db._User.EMAIL)} was found on the CLEVERON Cloud database. This building will be linked on this account. Continue?`,
                            buttons: ['Cancel', 'Yes'],
                        });

                        if(!result) throw Error('Action aborted by user');

                        this.setState({newUser: existingUser, selectedUser: existingUser});

                        newHome.set(db.Home.OWNER, existingUser);
                        setPermissionHome(existingUser, newHome);
                    } else {
                        let result = await swal({
                            title: 'New user',
                            text: `A new user with e-mail: ${newUser.get(db._User.EMAIL)} and password: ${newUser.get(db._User.PASSWORD)} will be created on the CLEVERON Cloud. Continue?`,
                            buttons: ['Cancel', 'Yes'],
                        });

                        if(!result) throw Error('Action aborted by user');

                        let userErrorString = checkRequiredFields(newUser, userRequiredFields);
                        if(userErrorString) throw new Error(userErrorString);

                        let user = await newUser.save();

                        this.setState({newUser: user, selectedUser: user});

                        newHome.set(db.Home.OWNER, user);
                        setPermissionHome(user, newHome);

                        /*let buildingId = salesOrder[db.SalesOrder.BUILDING.ID];
                        if(!buildingId)
                            await updateBuilding(buildingId, {
                                [db.ZohoBuilding.ADMIN_USERNAME]:  newUser.get(db._User.EMAIL),
                                [db.ZohoBuilding.ADMIN_PASSWORD]: newUser.get(db._User.PASSWORD)
                            });*/
                    }
                }

                let homeErrorString = checkRequiredFields(newHome, homeRequiredFields);
                if(homeErrorString) throw new Error(homeErrorString);

                home = await newHome.save();

                supplierOrder.set(db.SupplierOrder.HOME, home);
            }

            await supplierOrder.save();

            await this.updateTestBatchStatus(supplierOrder, this.state.previouslySelectedTestBatch);

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            this.reset();

            await this.props.callback({});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async fillOrderFromOperation(){
        try {
            let operationId = await swal('Operation id', {
                content: 'input',
                buttons: ['Cancel', 'Ok']
            });

            if (!operationId || operationId === '') {
                throw new Error('No operation id provided');
            }

            let operation  = await getOperationFromId(operationId);
            let category = operation[db.ZohoOperation.CATEGORY];

            let supportedCategories = [
                'Network enhancement',
                'Device replacement',
                'Actions'
            ]

            let categoriesConfigurationMap = {
                'Network enhancement': db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY,
                'Device replacement': db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION,
                'Actions': db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY,
            }

            let responsibleCompanyMap = {
                'Network enhancement': db.SupplierOrder.RESPONSIBLE_COMPANY$CLEVERON_AG,
                'Device replacement': db.SupplierOrder.RESPONSIBLE_COMPANY$MOSERBAER_AG,
                'Actions': db.SupplierOrder.RESPONSIBLE_COMPANY$CLEVERON_AG,
            }

            assert(operation != null, 'No operation found.');
            assert(_.get(operation, db.ZohoOperation.SHIPPING_CONTACT.ID) != null, 'No shipping contact id found.');

            let shipmentContactId = _.get(operation, db.ZohoOperation.SHIPPING_CONTACT.ID);

            let shipmentContact = await getContact(shipmentContactId);
            let buildingId = _.get(operation, db.ZohoOperation.BUILDING.ID);
            let building = await getBuilding(buildingId);

            let requiredSensor = operation[db.ZohoOperation.QUANTITY_OF_SENSORS] || 0;
            let requiredTherm = operation[db.ZohoOperation.QUANTITY_OF_THERMOSTATS] || 0;

            let homeId = building[db.ZohoBuilding.CLOUD_BUILDING_ID];

            if(buildingId != null && homeId == null)
                throw new Error(`No cloud id was set on CRM Building with id ${buildingId}.`);

            let home = await (new Parse.Query(db.classes.Home).get(homeId));

            if (!home) throw new Error('Home not found');

            let supplierOrder = this.state.supplierOrder;

            supplierOrder.set(db.SupplierOrder.REQUIRED_THERM,              requiredTherm);
            supplierOrder.set(db.SupplierOrder.REQUIRED_SENSE,              requiredSensor);
            supplierOrder.set(db.SupplierOrder.ROOM_QR_CODE,                requiredSensor);
            supplierOrder.set(db.SupplierOrder.OPERATION_ID,                operation[db.ZohoOperation.ID]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_DATE,               moment(operation[db.SalesOrder.DUE_DATE]).businessSubtract(5, 'day').toDate());
            supplierOrder.set(db.SupplierOrder.HOME,                        home);
            supplierOrder.set(db.SupplierOrder.REFERENCE_NUMBER,            operation[db.ZohoOperation.REFERENCE_NUMBER]);
            supplierOrder.set(db.SupplierOrder.CONFIGURATION,               categoriesConfigurationMap[category]);
            supplierOrder.set(db.SupplierOrder.SUBJECT,                     operation[db.ZohoOperation.NAME]);

            supplierOrder.set(db.SupplierOrder.SHIPPING_FIRST_NAME,         shipmentContact[db.ZohoContact.FIRST_NAME]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_LAST_NAME,          shipmentContact[db.ZohoContact.LAST_NAME]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_CITY,               operation[db.ZohoOperation.SHIPPING_CITY]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_COMPANY_NAME,       operation[db.ZohoOperation.SHIPPING_COMPANY]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_COUNTRY,            operation[db.ZohoOperation.SHIPPING_COUNTRY] || 'Switzerland');
            supplierOrder.set(db.SupplierOrder.SHIPPING_STREET,             operation[db.ZohoOperation.SHIPPING_STREET]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_STREET_NUMBER,      operation[db.ZohoOperation.SHIPPING_STREET_NUMBER]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_DEPARTMENT,         operation[db.ZohoOperation.SHIPPING_DEPARTMENT]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_ZIP,                operation[db.ZohoOperation.SHIPPING_CODE] + '');
            supplierOrder.set(db.SupplierOrder.RESPONSIBLE_COMPANY,         responsibleCompanyMap[category]);

            this.setState({supplierOrder});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async fillOrderFromSalesOrder(){
        try {
            let newUser = this.state.newUser;
            let newHome = this.state.newHome;

            let salesOrderId = await swal('Sales order id', {
                content: 'input',
                buttons: ['Cancel', 'Ok']
            });

            if (!salesOrderId || salesOrderId === '') {
                throw new Error('No sales order id provided');
            }
            let salesOrder  = await getSalesOrderFromId(salesOrderId);
            let shipmentContactId = _.get(salesOrder, db.SalesOrder.SHIPPING_CONTACT_NAME.ID);
            let shipmentContact = await getContact(shipmentContactId);
            let buildingId = _.get(salesOrder, db.SalesOrder.BUILDING.ID);
            let building, homeId;

            let requiredSensor = 0;
            let requiredTherm = 0;
            let requiredThermWNB = 0;
            let requiredThermWNBP = 0;
            let roomQrCode = 0;
            let routerRut240 = 0;
            let insertA = 0;
            let insertB = 0;
            let insertC = 0;
            let responsibleCompany = db.SupplierOrder.RESPONSIBLE_COMPANY$MOSERBAER_AG;

            if (buildingId) {
                building = await getBuilding(buildingId);
                console.log(building);
                let administratorContactId = _.get(building, db.ZohoBuilding.ADMINISTRATOR.ID);
                let administratorContact = await getContact(administratorContactId);


                let crmLanguageMap = {
                    German: 'de',
                    Italian: 'it',
                    French: 'fr',
                    English: 'en'
                }

                newUser.set(db._User.FIRST_NAME, administratorContact[db.ZohoContact.FIRST_NAME]);
                newUser.set(db._User.LAST_NAME, administratorContact[db.ZohoContact.LAST_NAME]);
                newUser.set(db._User.LANGUAGE, crmLanguageMap[administratorContact[db.ZohoContact.LANGUAGE]]);
                newUser.set(db._User.EMAIL, administratorContact[db.ZohoContact.EMAIL]);
                newUser.set(db._User.USERNAME, administratorContact[db.ZohoContact.EMAIL]);

                newHome.set(db.Home.LANGUAGE, crmLanguageMap[administratorContact[db.ZohoContact.LANGUAGE]]);
                newHome.set(db.Home.CITY, building[db.ZohoBuilding.CITY]);
                newHome.set(db.Home.COUNTRY, building[db.ZohoBuilding.COUNTRY]);
                newHome.set(db.Home.ADDRESS, building[db.ZohoBuilding.BUILDING_ADDRESS]);
                newHome.set(db.Home.YEAR, building[db.ZohoBuilding.YEAR_OF_CONSTRUCTION]);
                newHome.set(db.Home.CANTON, building[db.ZohoBuilding.CANTON_REGION]);
                newHome.set(db.Home.AREA, building[db.ZohoBuilding.BUILDING_AREA]);
                newHome.set(db.Home.ZIP, building[db.ZohoBuilding.ZIP_CODE] + '');
                newHome.set(db.Home.HOME_NAME, building[db.ZohoBuilding.NAME]);
                newHome.set(db.Home.HEATING_SYSTEM_TYPE, building[db.ZohoBuilding.HEATING_SYSTEM]);

                homeId = (building && building[db.ZohoBuilding.CLOUD_BUILDING_ID]);

                if(homeId !== null && homeId.includes(',')){ //In CRM there can be multiple ids in the same fields
                    let homeIds = homeId.split(',');

                    let firstHomeId = homeIds[0];

                    homeId = firstHomeId;
                }
            }

            let configuration = building ? db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION :
                db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY;

            let sensors = salesOrder[db.SalesOrder.PRODUCT_DETAILS]
                .filter(product => product.product.Product_Code === 'clever-sense-2020');

            sensors = sensors.concat(
                salesOrder[db.SalesOrder.PRODUCT_DETAILS]
                    .filter(product => product.product.Product_Code === 'clever-sense-20')
            );

            let thermo = salesOrder[db.SalesOrder.PRODUCT_DETAILS]
                .filter(product => product.product.Product_Code?.startsWith('clever-thermo'));

            let thermoWNB = salesOrder[db.SalesOrder.PRODUCT_DETAILS]
                .filter(product => product.product.Product_Code?.startsWith('clever-thermo-WNB'));

            let thermoWNBP = salesOrder[db.SalesOrder.PRODUCT_DETAILS]
                .filter(product => product.product.Product_Code?.startsWith('clever-thermo-WNBP'));

            let isTestKit = salesOrder[db.SalesOrder.PRODUCT_DETAILS]
                .filter(item => item.product.id === '154068000014485657').length > 0;


            let requiredAdapterA;
            let adapter1Type = building[db.ZohoBuilding.ADAPTER_1];
            let adapter1Quantity = building[db.ZohoBuilding.QUANTITY_ADAPTER_1];
            let adapter2Type = building[db.ZohoBuilding.ADAPTER_2];
            let adapter2Quantity = building[db.ZohoBuilding.QUANTITY_ADAPTER_2];
            let adapter3Type = building[db.ZohoBuilding.ADAPTER_3];
            let adapter3Quantity = building[db.ZohoBuilding.QUANTITY_ADAPTER_3];

            let adapterTypes = [
                adapter1Type,
                adapter2Type,
                adapter3Type
            ];
            let adapterQuantities = [
                adapter1Quantity,
                adapter2Quantity,
                adapter3Quantity
            ];

            let adapterMap = {
                A: 0,
                B: 0,
                C: 0,
                D: 0
            }

            let adapterTypesKeys = ['A', 'B', 'C', 'D'];

            adapterTypesKeys.forEach((type, i) => {
                let adapterType = adapterTypes[i];
                let adapterQuantity = adapterQuantities[i];
                if(adapter1Type === type){
                    adapterMap[type]= adapterQuantity;
                }
            });

            if(sensors.length > 0){
                requiredSensor = sensors[0].quantity;
            }

            if(thermo.length > 0){
                requiredTherm = thermo.map(therm => therm.quantity).reduce((a, b) => a + b, 0)
            }
            if(thermoWNB.length > 0){
                requiredThermWNB = thermoWNB[0].quantity;
            }
            if(thermoWNBP.length > 0){
                requiredThermWNBP = thermoWNBP[0].quantity;
            }

            if(isTestKit){
                requiredTherm = 6;
                requiredSensor = 2;
                roomQrCode = 2;
                routerRut240 = 1;
                insertC = 1;
                responsibleCompany = db.SupplierOrder.RESPONSIBLE_COMPANY$CLEVERON_AG;
            }

            //Type of customer
            let typeOfCustomer;
            if(thermo.length > 0) {
                typeOfCustomer = db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER;
            } else if(thermo.length === 0 && sensors.length > 0){
                typeOfCustomer = db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER;
            }

            newUser.set(db._User.TYPE_OF_CUSTOMER, typeOfCustomer);
            newHome.set(db.Home.TYPE_OF_CUSTOMER, typeOfCustomer);

            let home;
            if(homeId) home = await (new Parse.Query(db.classes.Home).get(homeId));

            let supplierOrder = this.state.supplierOrder;

            if(configuration !== db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION){
                supplierOrder.set(db.SupplierOrder.INSERT_A, 1);
            }

            supplierOrder.set(db.SupplierOrder.REQUIRED_THERM,              requiredTherm);
            supplierOrder.set(db.SupplierOrder.REQUIRED_THERM_WNB,          requiredThermWNB);
            supplierOrder.set(db.SupplierOrder.REQUIRED_THERM_WNBP,         requiredThermWNBP);
            supplierOrder.set(db.SupplierOrder.REQUIRED_SENSE,              requiredSensor);
            supplierOrder.set(db.SupplierOrder.ROOM_QR_CODE,                requiredSensor);
            supplierOrder.set(db.SupplierOrder.SALES_ORDER_ID,              salesOrder[db.SalesOrder.ID]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_DATE,               moment(salesOrder[db.SalesOrder.DUE_DATE]).businessSubtract(5, 'day').toDate());
            supplierOrder.set(db.SupplierOrder.HOME,                        home);
            supplierOrder.set(db.SupplierOrder.REFERENCE_NUMBER,            _.get(salesOrder, db.SalesOrder.REFERENCE_NUMBER));
            supplierOrder.set(db.SupplierOrder.CONFIGURATION,               configuration);
            supplierOrder.set(db.SupplierOrder.SUBJECT,                     salesOrder[db.SalesOrder.SUBJECT]);


            supplierOrder.set(db.SupplierOrder.ADAPTER_A, adapterMap['A']);
            supplierOrder.set(db.SupplierOrder.ADAPTER_B, adapterMap['B']);
            supplierOrder.set(db.SupplierOrder.ADAPTER_C, adapterMap['C']);
            supplierOrder.set(db.SupplierOrder.ADAPTER_D, adapterMap['D']);

            supplierOrder.set(db.SupplierOrder.SHIPPING_FIRST_NAME,          shipmentContact[db.ZohoContact.FIRST_NAME]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_LAST_NAME,          shipmentContact[db.ZohoContact.LAST_NAME]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_CITY,               salesOrder[db.SalesOrder.SHIPPING_CITY]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_COMPANY_NAME,       _.get(salesOrder, db.SalesOrder.SHIPPING_COMPANY));
            supplierOrder.set(db.SupplierOrder.SHIPPING_COUNTRY,            _.get(salesOrder, db.SalesOrder.SHIPPING_COUNTRY));
            supplierOrder.set(db.SupplierOrder.SHIPPING_STREET,             salesOrder[db.SalesOrder.SHIPPING_STREET]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_STREET_NUMBER,      salesOrder[db.SalesOrder.SHIPPING_STREET_NUMBER]);
            supplierOrder.set(db.SupplierOrder.SHIPPING_DEPARTMENT,         _.get(salesOrder, db.SalesOrder.SHIPPING_DEPARTMENT));
            supplierOrder.set(db.SupplierOrder.SHIPPING_ZIP,                salesOrder[db.SalesOrder.SHIPPING_CODE] + '');
            supplierOrder.set(db.SupplierOrder.RESPONSIBLE_COMPANY,         db.SupplierOrder.RESPONSIBLE_COMPANY$MOSERBAER_AG);

            this.setState({supplierOrder, newHome, newUser});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    modifySupplierOrderField(fieldName, newValue){
        let supplierOrder = this.state.supplierOrder;
        supplierOrder.set(fieldName, newValue);

        return supplierOrder;
    }

    modifyUser(fieldName, newValue){
        let user = this.state.newUser;

        user.set(fieldName, newValue);

        return user;
    }

    modifyHome(fieldName, newValue){
        let home = this.state.newHome;

        home.set(fieldName, newValue);

        return home;
    }

    render(){
        let supplierOrder = this.state.supplierOrder;
        let {
            configuration
        } = extractSupplierOrderInformation(this.state.supplierOrder);

        let shippingFields = () => {
            let supplierOrder = this.state.supplierOrder;

            let {
                shippingFirstName,
                shippingLastName,
                shippingCity,
                shippingCompanyName,
                shippingCountry,
                shippingStreet,
                shippingStreetNumber,
                shippingDepartment,
                shippingZip
            } = extractSupplierOrderInformation(supplierOrder);

            return <>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_FIRST_NAME}>First name:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_FIRST_NAME}
                        value={shippingFirstName}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_FIRST_NAME, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_LAST_NAME}>Last name:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_LAST_NAME}
                        value={shippingLastName}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_LAST_NAME, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_CITY}>City:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_CITY}
                        value={shippingCity}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_CITY, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_COMPANY_NAME}>Company name:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_COMPANY_NAME}
                        value={shippingCompanyName}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_COMPANY_NAME, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_COUNTRY}>Country:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_COUNTRY}
                        value={shippingCountry}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_COUNTRY, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_STREET}>Street:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_STREET}
                        value={shippingStreet}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_STREET, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_STREET_NUMBER}>Street number:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_STREET_NUMBER}
                        value={shippingStreetNumber}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_STREET_NUMBER, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_DEPARTMENT}>Department:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_DEPARTMENT}
                        value={shippingDepartment}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_DEPARTMENT, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_ZIP}>Shipping zip:</Label>
                    <Input type="text" name={db.SupplierOrder.SHIPPING_ZIP}
                        value={shippingZip}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_ZIP, e.target.value)})}
                    />
                </FormGroup>
            </>
        }

        let orderInformation = () => {
            let supplierOrder = this.state.supplierOrder;

            let {
                shippingDate,
                dueDate,
                requiredTherm,
                requiredSense,
                requiredThermWNB,
                requiredThermWNBP,
                requiredSenseW,
                requiredSenseWNB,
                roomQrCode,
                adapters,
                referenceNumber,
                configuration,
                subject,
                notes,
                priority,
                connectionGuideInsert,
                responsibleCompany,
                adapterA,
                adapterB,
                adapterC,
                adapterD,
                insertA,
                insertB,
                reinforcementRing,
                routerRut240,
                powerbank,
                swissPostTrackingNumber,
                operationId,
                salesOrderId,
                type
            } = extractSupplierOrderInformation(supplierOrder);

            configuration = configuration ? {
                value: configuration,
                label: configuration
            } : null;


            responsibleCompany = responsibleCompany ? {
                value: responsibleCompany,
                label: responsibleCompany
            } : null;

            let types = Object.keys(db.SupplierOrder)
                .filter(field => field.startsWith('TYPE$'))
                .map(field => {
                    return {
                        value: db.SupplierOrder[field],
                        label: db.SupplierOrder[field]
                    }
                });

            let priorities = [
                {
                    value: 'high',
                    label: 'High (It cannot be moved / deleyed)'
                },
                {
                    value: 'medium',
                    label: 'Medium (It can be moved but with low flexibility)'
                },
                {
                    value: 'low',
                    label: 'Low (Can be moved with relative low impact on the client)'
                }
            ];

            if(adapters == null) adapters = {}

            return <>
                <FormGroup>
                    <Label for="select-home">Configuration:</Label>
                    <Select
                        options={[
                            db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY,
                            db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION
                        ].map(configuration => {
                            return {
                                value: configuration,
                                label: configuration
                            }
                        })}
                        value={configuration}
                        onChange={item => {
                            this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.CONFIGURATION, item.value)});
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="select-home">Responsible shipping company:</Label>
                    <Select
                        options={[
                            db.SupplierOrder.RESPONSIBLE_COMPANY$CLEVERON_AG,
                            db.SupplierOrder.RESPONSIBLE_COMPANY$MOSERBAER_AG,
                            db.SupplierOrder.RESPONSIBLE_COMPANY$JAKUTEC
                        ].map(responsibleCompany => {
                            return {
                                value: responsibleCompany,
                                label: responsibleCompany
                            }
                        })}
                        value={responsibleCompany}
                        onChange={item => {
                            this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.RESPONSIBLE_COMPANY, item.value)});
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.REFERENCE_NUMBER}>Reference number:</Label>
                    <Input type="text" name={db.SupplierOrder.REFERENCE_NUMBER}
                        value={referenceNumber}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REFERENCE_NUMBER, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SALES_ORDER_ID}>{db.SupplierOrder.label(db.SupplierOrder.SALES_ORDER_ID)}:</Label>
                    <Input type="text" name={db.SupplierOrder.SALES_ORDER_ID}
                        value={salesOrderId}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SALES_ORDER_ID, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.OPERATION_ID}>{db.SupplierOrder.label(db.SupplierOrder.OPERATION_ID)}:</Label>
                    <Input type="text" name={db.SupplierOrder.OPERATION_ID}
                        value={operationId}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.OPERATION_ID, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SUBJECT}>Subject:</Label>
                    <Input type="text"
                        name={db.SupplierOrder.SUBJECT}
                        value={subject}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SUBJECT, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SHIPPING_DATE}>Shipping date:</Label>
                    <Input
                        type="date"
                        name={db.SupplierOrder.SHIPPING_DATE}
                        id={db.SupplierOrder.SHIPPING_DATE}
                        placeholder="Shipping date"
                        pattern="\d{2}-\d{2}-\d{4}"
                        value={shippingDate &&
                        moment(shippingDate).format('YYYY-MM-DD')}
                        onChange={(e) => {
                            this.setState({
                                supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SHIPPING_DATE,  moment(e.target.value, 'YYYY-MM-DD').toDate())
                            })
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.SWISS_POST_TRACKING_NUMBER}>Swiss post tracking number:</Label>
                    <Input type="text" name={db.SupplierOrder.SWISS_POST_TRACKING_NUMBER}
                        value={swissPostTrackingNumber}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.SWISS_POST_TRACKING_NUMBER, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.PRODUCTION_DUE_DATE}>Due date:</Label>
                    <Input
                        type="date"
                        name={db.SupplierOrder.PRODUCTION_DUE_DATE}
                        id={db.SupplierOrder.PRODUCTION_DUE_DATE}
                        placeholder={db.SupplierOrder.PRODUCTION_DUE_DATE}
                        pattern="\d{2}-\d{2}-\d{4}"
                        value={dueDate &&
                        moment(dueDate).format('YYYY-MM-DD')}
                        onChange={(e) => {
                            this.setState({
                                supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.PRODUCTION_DUE_DATE,  moment(e.target.value, 'YYYY-MM-DD').toDate())
                            })
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Priority</Label>
                    <Select
                        options={priorities}
                        isClearable={true}
                        value={priority && {
                            value: priority,
                            label: priorities.filter(priorityItem => priorityItem.value === priority)[0].label
                        }}
                        onChange={item => {
                            if(!item) item = {};

                            this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.PRIORITY, item.value)});
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Order type</Label>
                    <Select
                        options={types}
                        isClearable={true}
                        value={type && {
                            value: type,
                            label: type
                        }}
                        onChange={item => {
                            if(!item) item = {};

                            this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.TYPE, item.value)});
                        }}
                    />
                </FormGroup>
                <hr/>
                {
                    [
                        db.SupplierOrder.ROOM_QR_CODE,
                        db.SupplierOrder.ADAPTER_A,
                        db.SupplierOrder.ADAPTER_B,
                        db.SupplierOrder.ADAPTER_C,
                        db.SupplierOrder.ADAPTER_D,
                        db.SupplierOrder.INSERT_A,
                        db.SupplierOrder.INSERT_B,
                        db.SupplierOrder.REINFORCEMENT_RING,
                        db.SupplierOrder.POWERBANK,
                        db.SupplierOrder.ROUTER_RUT_240
                    ].map((key, i) => {
                        return <FormGroup key={i}>
                            <Label for={key}>{key}:</Label>
                            <Input type="number" name={key}
                                value={supplierOrder.get(key)}
                                onChange={(e) => this.setState({
                                    supplierOrder: this.modifySupplierOrderField(key, parseInt(e.target.value))
                                })}
                            />
                        </FormGroup>
                    })
                }

                <hr/>
                <FormGroup>
                    <Label for="required-therm">Required therm</Label>
                    <Input type="number" name="required-therm" id="required-therm"
                        value={requiredTherm}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REQUIRED_THERM, parseInt(e.target.value))})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.REQUIRED_THERM_WNB}>Required therm WNB</Label>
                    <Input type="number" name={db.SupplierOrder.REQUIRED_THERM_WNB} id={db.SupplierOrder.REQUIRED_THERM_WNB}
                        value={requiredThermWNB}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REQUIRED_THERM_WNB, parseInt(e.target.value))})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.REQUIRED_THERM_WNBP}>Required therm WNBP</Label>
                    <Input type="number"
                           name={db.SupplierOrder.REQUIRED_THERM_WNBP}
                           id={db.SupplierOrder.REQUIRED_THERM_WNBP}
                        value={requiredThermWNBP}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REQUIRED_THERM_WNBP, parseInt(e.target.value))})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="required-sense">Required sense</Label>
                    <Input type="number" name="required-sense" id="required-sense"
                        value={requiredSense}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REQUIRED_SENSE, parseInt(e.target.value))})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.REQUIRED_SENSP_W}>Required sensor W</Label>
                    <Input type="number" name={db.SupplierOrder.REQUIRED_SENSP_W} id={db.SupplierOrder.REQUIRED_SENSP_W}
                        value={requiredSenseW}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REQUIRED_SENSP_W, parseInt(e.target.value))})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.REQUIRED_SENSP_WNB}>Required sensor WNB</Label>
                    <Input type="number"
                           name={db.SupplierOrder.REQUIRED_SENSP_WNB}
                           id={db.SupplierOrder.REQUIRED_SENSP_WNB}
                        value={requiredSenseWNB}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.REQUIRED_SENSP_WNB, parseInt(e.target.value))})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.NOTES}>Notes:</Label>
                    <Input type="textarea" name={db.SupplierOrder.ADAPTERS}
                        value={notes}
                        onChange={(e) => this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.NOTES, e.target.value)})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={db.SupplierOrder.CONNECTION_GUIDE_INSERT}>Connection guide insert (installed manually by customer): {connectionGuideInsert}</Label><br/>
                    <Toggle defaultChecked={false}
                        checked={connectionGuideInsert}
                        onChange={() => {
                            this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.CONNECTION_GUIDE_INSERT, !connectionGuideInsert)})
                        }}
                    />
                </FormGroup>
            </>
        }

        let testBatches = () => {
            let supplierOrder = this.state.supplierOrder;
            let {
                actualTherm,
                actualSense,
                requiredTherm,
                requiredSense,
                testBatches,
            } = extractSupplierOrderInformation(supplierOrder);

            testBatches = testBatches == null ? [] : testBatches;

            return <>
                <FormGroup>
                    <Label for="select-home">Select test batches</Label>
                    <Select
                        options={this.state.testBatches.map(testBatch => {
                            return {
                                value: testBatch,
                                label: `${testBatch.get(db.TestBatch.NAME)} ${testBatch.get(db.TestBatch.TYPE)} t${testBatch.get(db.TestBatch.NUMBER_OF_THERM)} s${testBatch.get(db.TestBatch.NUMBER_OF_SENSP)} ${testBatch.get(db.TestBatch.TAG)}`
                            }
                        })}
                        isLoading={this.state.loading.testBatches}
                        isClearable={true}
                        isMulti
                        value={testBatches.map(testBatch => {
                            return {
                                value: testBatch,
                                label: `${testBatch.get(db.TestBatch.NAME)} ${testBatch.get(db.TestBatch.TYPE)} t${testBatch.get(db.TestBatch.NUMBER_OF_THERM)} s${testBatch.get(db.TestBatch.NUMBER_OF_SENSP)} ${testBatch.get(db.TestBatch.TAG)}`
                            }
                        })}
                        onChange={async items => {
                            items = items == null ? [] : items;

                            let selectedTestBatches = items.map(item => item.value);
                            let supplierOrder = this.modifySupplierOrderField(db.SupplierOrder.TEST_BATCHES, selectedTestBatches);

                            let {numTherm, numSense} = await getNumberOfThermAndSense(selectedTestBatches);

                            supplierOrder.set(db.SupplierOrder.ACTUAL_THERM, numTherm);
                            supplierOrder.set(db.SupplierOrder.ACTUAL_SENSE, numSense);

                            this.setState({supplierOrder});
                        }}
                    />
                </FormGroup>
                <div>
                    Selected therm: {actualTherm} / {requiredTherm}<br/>
                    <Progress value={actualTherm/requiredTherm * 100} />
                    Selected sense: {actualSense} / {requiredSense}
                    <Progress value={actualSense/ requiredSense * 100} />
                </div>
            </>
        }

        let buildingAndUserInformation = () => {
            let supplierOrder = this.state.supplierOrder;
            let selectedUser = this.state.selectedUser;
            let newUser = this.state.newUser;
            let newHome = this.state.newHome;

            let {home} = extractSupplierOrderInformation(supplierOrder);
            let {
                username,
                email,
                typeOfCustomer,
                password,
                language: userLanguage
            } = extractUserFields(newUser)
            let {
                homeName,
                city,
                address,
                year,
                language: homeLanguage,
                canton,
                zip,
                country,
                heatingSystem,
                typeOfCustomer: homeTypeOfCustomer
            } = extractHomeFields(newHome);

            home = home ? {
                value: home,
                label: home.get(db.Home.HOME_NAME)
            } : null;

            selectedUser = selectedUser ? {
                value: selectedUser,
                label: selectedUser.get(db._User.EMAIL)
            } : null;

            return <>
                <FormGroup>
                    <Label for="select-home">Home</Label>
                    <Select
                        options={this.state.homes.map(home => {
                            return {
                                value: home,
                                label: home.get(db.Home.HOME_NAME)
                            }
                        })}
                        isLoading={this.state.loading.homes}
                        isClearable={true}
                        value={home}
                        onChange={item => {
                            if(!item) item = {};

                            this.setState({supplierOrder: this.modifySupplierOrderField(db.SupplierOrder.HOME, item.value)});
                        }}
                    />
                </FormGroup>

                {
                    !home && <>
                        <FormGroup>
                            <Label for="select-home">User</Label>
                            <Select
                                options={this.state.users.map(user => {
                                    return {
                                        value: user,
                                        label: user.get(db._User.EMAIL)
                                    }
                                })}
                                isLoading={this.state.loading.users}
                                isClearable={true}
                                value={selectedUser}
                                onChange={item => {
                                    if(!item) item = {};

                                    this.setState({user: item.value});
                                }}
                            />
                        </FormGroup>
                        {
                            !selectedUser && <>
                                <h6 className="mt-5">User information</h6>
                                <hr/>
                                <FormGroup>
                                    <Label for={db._User.USERNAME}>Username:</Label>
                                    <Input type="text" name={db._User.USERNAME}
                                        value={email}
                                        onChange={(e) => this.setState({user: this.modifyUser(db._User.USERNAME, e.target.value)})}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for={db._User.EMAIL}>E-mail:</Label>
                                    <Input type="email" name={db._User.USERNAME}
                                        value={email}
                                        onChange={(e) => this.setState({user: this.modifyUser(db._User.EMAIL, e.target.value)})}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for={db._User.PASSWORD}>Password:</Label>
                                    <Input type="text" name={db._User.PASSWORD}
                                        value={password}
                                        onChange={(e) => this.setState({user: this.modifyUser(db._User.PASSWORD, e.target.value)})}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="select-home">Type of customer</Label>
                                    <Select
                                        options={[
                                            {
                                                value: db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                                                label: db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                                            },
                                            {
                                                value: db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
                                                label: db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
                                            }
                                        ]}
                                        value={{value: typeOfCustomer, label: typeOfCustomer}}
                                        onChange={item => {
                                            this.setState({user: this.modifyUser(db._User.TYPE_OF_CUSTOMER, item.value)});
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for={db._User.LANGUAGE}>Language</Label>
                                    <Select
                                        options={[
                                            {
                                                value: 'de',
                                                label: 'de'
                                            },
                                            {
                                                value: 'it',
                                                label: 'it'
                                            },
                                            {
                                                value: 'fr',
                                                label: 'fr',
                                            },
                                            {
                                                value: 'en',
                                                label: 'en',
                                            }
                                        ]}
                                        value={{value: userLanguage, label: userLanguage}}
                                        onChange={item => {
                                            this.setState({user: this.modifyUser(db._User.LANGUAGE, item.value)});
                                        }}
                                    />
                                </FormGroup>
                            </>
                        }
                        <h6 className="mt-5">Building information</h6>
                        <hr/>
                        <FormGroup>
                            <Label for={db.Home.HOME_NAME}>Building name:</Label>
                            <Input type="text" name={db.Home.HOME_NAME}
                                value={homeName}
                                onChange={(e) => this.setState({newHome: this.modifyHome(db.Home.HOME_NAME, e.target.value)})}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={db.Home.CITY}>City:</Label>
                            <Input type="text" name={db.Home.CITY}
                                value={city}
                                onChange={(e) => this.setState({newHome: this.modifyHome(db.Home.CITY, e.target.value)})}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={db.Home.CANTON}>Canton</Label>
                            <Select
                                options={cantonsList}
                                value={{value: canton, label: cantonsMap[canton]}}
                                onChange={item => {
                                    this.setState({newHome: this.modifyHome(db.Home.CANTON, item.value)});
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={db.Home.ADDRESS}>Address:</Label>
                            <Input type="text" name={db.Home.ADDRESS}
                                value={address}
                                onChange={(e) => this.setState({newHome: this.modifyHome(db.Home.ADDRESS, e.target.value)})}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={db.Home.ZIP}>Zip:</Label>
                            <Input type="text" name={db.Home.ZIP}
                                value={zip}
                                onChange={(e) => this.setState({newHome: this.modifyHome(db.Home.ZIP, e.target.value)})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Home.COUNTRY}>Country</Label>
                            <Select
                                options={[
                                    {
                                        value: 'CH',
                                        label: 'Switzerland'
                                    },
                                    {
                                        value: 'DE',
                                        label: 'Germany'
                                    }
                                ]}
                                value={{value: country, label: country}}
                                onChange={item => {
                                    this.setState({newHome: this.modifyHome(db.Home.COUNTRY, item.value)});
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Home.YEAR}>Year of construction:</Label>
                            <Input type="number" name={db.Home.YEAR}
                                value={year}
                                onChange={(e) => this.setState({newHome: this.modifyHome(db.Home.YEAR, parseInt(e.target.value))})}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={db.Home.HEATING_SYSTEM_TYPE}>Heating system:</Label>
                            <Input type="text" name={db.Home.HEATING_SYSTEM_TYPE}
                                value={heatingSystem}
                                onChange={(e) => this.setState({newHome: this.modifyHome(db.Home.HEATING_SYSTEM_TYPE, e.target.value)})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.Home.LANGUAGE}>Language</Label>
                            <Select
                                options={[
                                    {
                                        value: 'de',
                                        label: 'de'
                                    },
                                    {
                                        value: 'it',
                                        label: 'it'
                                    },
                                    {
                                        value: 'fr',
                                        label: 'fr',
                                    },
                                    {
                                        value: 'en',
                                        label: 'en',
                                    }
                                ]}
                                value={{value: homeLanguage, label: homeLanguage}}
                                onChange={item => {
                                    this.setState({newHome: this.modifyHome(db.Home.LANGUAGE, item.value)});
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="select-home">Home type of customer</Label>
                            <Select
                                options={[
                                    {
                                        value: db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                                        label: db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                                    },
                                    {
                                        value: db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
                                        label: db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
                                    }
                                ]}
                                value={{value: homeTypeOfCustomer, label: homeTypeOfCustomer}}
                                onChange={item => {
                                    this.setState({home: this.modifyHome(db.Home.TYPE_OF_CUSTOMER, item.value)});
                                }}
                            />
                        </FormGroup>
                    </>
                }

            </>
        }

        return <span>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}
                size={'lg'} onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Add new order from operation Id </ModalHeader>
                <ModalBody>
                    <Form>
                        154068000009540332
                        154068000009312037
                        <FormGroup>
                            <Button outline color="primary" onClick={this.fillOrderFromOperation}>
                                Fill from operation
                            </Button>
                            <Button outline color="primary" onClick={this.fillOrderFromSalesOrder}>
                                Fill from sales order
                            </Button>
                        </FormGroup>

                        {
                            (configuration == null || configuration === db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION) && <>
                                <h5 className="mt-5">Building and User information</h5>
                                <hr/>
                                {buildingAndUserInformation()}
                            </>
                        }

                        <h5 className="mt-5">Order information</h5>
                        <hr/>
                        {orderInformation()}
                        <h5 className="mt-5">Shipping information</h5>
                        <hr/>
                        {shippingFields()}
                        <h5 className="mt-5">Test batches</h5>
                        <hr/>
                        {testBatches()}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={this.addNewInstallation}>
                        {
                            supplierOrder.id && 'Edit order'
                        }
                        {
                            !supplierOrder.id && 'Add new order'
                        }
                    </Button>{' '}
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </span>
    }

    //loading data
    async loadUsers(){
        this.setState(prev => {
            prev.loading.users = true;

            return prev;
        });

        let users = await Parse.Cloud.run('getListUsers');

        this.setState(prev => {
            prev.loading.users = false;
            prev.users = users;

            return prev;
        });
    }

    async loadHomes(){
        this.setState(prev => {
            prev.loading.homes = true;

            return prev;
        });

        let query = new Parse.Query(db.classes.Home);
        query.select([
            db.Home.HOME_NAME,
            db.Home.OWNER
        ]);
        query.limit(10000);
        query.notEqualTo(db.Home.DELETED, true);

        let homes = await query.find();

        this.setState(prev => {
            prev.loading.homes = false;
            prev.homes = homes;

            return prev;
        });
    }
    async loadTestBatch(){
        this.setState(prev => {
            prev.loading.testBatches = true;

            return prev;
        });
        let query = new Parse.Query(db.classes.TestBatch);

        query.equalTo(db.TestBatch.STATUS, db.TestBatch.STATUS$TESTED);
        query.select([
            db.TestBatch.NAME,
            db.TestBatch.STATUS,
            db.TestBatch.TYPE,
            db.TestBatch.NUMBER_OF_SENSP,
            db.TestBatch.NUMBER_OF_THERM,
            db.TestBatch.TAG
        ]);
        query.descending([
            db.TestBatch.NUMBER_OF_THERM,
            db.TestBatch.NUMBER_OF_SENSP
        ])
        query.limit(10000);
        query.notEqualTo(db.TestBatch.DELETED, true);

        let testBatches = await query.find();

        this.setState(prev => {
            prev.loading.testBatches = false;
            prev.testBatches = testBatches;

            return prev;
        });
    }
}

AddNewSupplierOrderModal.propTypes = {
    setToggleModal: PropTypes.func,
    callback: PropTypes.func,
    supplierOrder: PropTypes.object
};

