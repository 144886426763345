import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Input, Label, Row,} from 'reactstrap';
import Parse from '../../../lib/parse';
import db from '../../../config/dbStructure';
import BuildingInstallationTable from './table-building-installation';
import swal from 'sweetalert';
import installationStateConfig from '../../../lib/installation-state-config';
import {isRolesInRoles} from '../../../lib/util';
import Toggle from 'react-toggle';
import AddNewSupplierOrderModal from './add-new-supplier-order-modal';
import co2SensorIcon from '../../../assets/icon/product/air-quality.svg';
import thermoIcon from '../../../assets/icon/product/filter.svg';
import moment from 'moment';

function getStyle(color){
    let customNodeStyles = {
        background: color || '#28a745',
        color: '#000',
        padding: 10,
        width: 200
    };

    return customNodeStyles;
}

const elements2 = [
    {
        id: '100',
        data: {label: 'No action required'},
        position: { x: 400, y: 0 },
        type: 'input',
        connectable: false,
        style: getStyle('#28a745')
    },
    {
        id: '200',
        data: {label: 'Action required'},
        position: { x: 400, y: 50 },
        type: 'input',
        connectable: false,
        style: getStyle('#ffc107')
    }
]

installationStateConfig.allStates.map((state, i) => {

    let style = getStyle();

    if(installationStateConfig.orangeStates.Supplier.indexOf(state) >= 0)
        style = getStyle('#ffc107');

    elements2.push({
        id: i,
        data: {label: state},
        position: { x: i * 240 + 400, y: 120 },
        sourcePosition: 'right',
        targetPosition: 'left',
        style
    });

    if(i === 0) return;

    elements2.push({
        id: `e${i - 1}-${i}`,
        source: i - 1,
        target: i,
        animated: false,
        type: 'straight',
        arrowHeadType: 'arrowclosed'
    });
})

export default class PageSupplierOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplierOrders: [],
            selectedOption: null,
            showAllSupplierOrder: false,
            supplierOrderReferenceNumberFilter: ''
        };

        this.loadSupplierOrders = this.loadSupplierOrders.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.deleteSupplierOrder = this.deleteSupplierOrder.bind(this);
        this.refresh = this.refresh.bind(this);

        this.toggleNewSupplierOrderModal = null;
    }

    async componentDidMount(){
        await this.loadSupplierOrders();
    }

    async loadSupplierOrders(){
        let currentUser = Parse.User.current();
        let supplierOrderReferenceNumberFilter = this.state.supplierOrderReferenceNumberFilter;

        let query = new Parse.Query(db.classes.SupplierOrder);
        query.include(db.SupplierOrder.HOME);
        query.include(db.SupplierOrder.INSTALLATION_ADDRESS);
        query.include(db.SupplierOrder.TEST_BATCHES);
        query.exists(db.SupplierOrder.STATUS);
        query.notEqualTo(db.SupplierOrder.DELETED, true);
        query.ascending(db.SupplierOrder.SHIPPING_DATE);
        query.limit(100);

        if(currentUser.get(db._User.USERNAME) === 'Moser-Baer')
            query.equalTo(db.SupplierOrder.RESPONSIBLE_COMPANY, db.SupplierOrder.RESPONSIBLE_COMPANY$MOSERBAER_AG);
        else if(currentUser.get(db._User.USERNAME) === 'JakuTec')
            query.equalTo(db.SupplierOrder.RESPONSIBLE_COMPANY, db.SupplierOrder.RESPONSIBLE_COMPANY$JAKUTEC);

        if(this.props.roles.indexOf('Installer') >= 0){
            let states = installationStateConfig.installerStates;
            query.containedIn(db.SupplierOrder.STATUS, states.map(state => state));
        }

        if(this.props.roles.indexOf('Supplier') >= 0){
            let states = installationStateConfig.supplierStates;
            query.containedIn(db.SupplierOrder.STATUS, states.map(state => state));
            query.containedIn(db.SupplierOrder.CONFIGURATION, [
                db.SupplierOrder.CONFIGURATION$SHIPPING_ONLY,
                db.SupplierOrder.CONFIGURATION$WITH_CONFIGURATION,
            ]);
        }

        if(this.props.roles.indexOf('Admin') >= 0){
            if(!this.state.showAllSupplierOrder) {
                query.containedIn(db.SupplierOrder.STATUS, [
                    'initialized',
                    'in-preparation-for-config',
                    'to-turn-on',
                    'ready-to-config',
                    'deleyed',
                    'configured',
                    'ready-to-be-sent',
                    'sent-from-supplier',
                ]);
            }
            query.greaterThanOrEqualTo(db.SupplierOrder.CREATED_AT, moment().subtract(6, 'month').toDate());

            query.containedIn(db.SupplierOrder.RESPONSIBLE_COMPANY, [
                db.SupplierOrder.RESPONSIBLE_COMPANY$MOSERBAER_AG,
                db.SupplierOrder.RESPONSIBLE_COMPANY$CLEVERON_AG,
                db.SupplierOrder.RESPONSIBLE_COMPANY$JAKUTEC,
                null
            ]);

            if(supplierOrderReferenceNumberFilter && supplierOrderReferenceNumberFilter.length >= 10){
                query = new Parse.Query(db.classes.SupplierOrder);
                query.include(db.SupplierOrder.HOME);
                query.include(db.SupplierOrder.INSTALLATION_ADDRESS);
                query.include(db.SupplierOrder.TEST_BATCHES);
                query.exists(db.SupplierOrder.STATUS);
                query.notEqualTo(db.SupplierOrder.DELETED, true);
                query.ascending(db.SupplierOrder.SHIPPING_DATE);
                query.limit(10);
                query.equalTo(db.SupplierOrder.REFERENCE_NUMBER, supplierOrderReferenceNumberFilter);
            }
        }

        let supplierOrders = await query.find();

        this.setState({supplierOrders});
    }

    async deleteSupplierOrder(supplierOrder){
        try {
            let result = await swal({
                title: 'Are you sure?',
                text: '',
                buttons: ['Cancel', 'Yes delete'],
            });

            if (result) {
                let testBatches = supplierOrder.get(db.SupplierOrder.TEST_BATCHES);

                testBatches = testBatches
                    .filter(testBatch =>
                        testBatch.get(db.TestBatch.STATUS) !== db.TestBatch.STATUS$SENT_FROM_SUPPLIER_TO_INSTALLER ||
                        testBatch.get(db.TestBatch.STATUS) !== db.TestBatch.STATUS$RECEIVED_TO_INSTALLER_FROM_SUPPLIER
                    )
                    .map(testBatch => {
                        testBatch.set(db.TestBatch.STATUS, db.TestBatch.STATUS$TESTED);

                        return testBatch;
                    });

                await Parse.Object.saveAll(testBatches);

                supplierOrder.set(db.SupplierOrder.DELETED, true);
                await supplierOrder.save();

                swal({
                    title: 'Deleted',
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });

                await this.loadSupplierOrders();
            }
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async changeStatus(installation, sm, nextStatus, force){
        try {
            await sm.goTo(nextStatus, force);

            installation.set(db.TestBatch.STATUS, nextStatus);
            await installation.save();

            swal({title: 'Status updated!', text: ' ', icon: 'success', button: [''], timer: 1000});

            await this.loadSupplierOrders();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async refresh(){
        await this.loadSupplierOrders();
    }

    render() {

        let sumActualThermo = this.state.supplierOrders
            .filter(order => order.get(db.SupplierOrder.STATUS) !== db.SupplierOrder.STATUS$DELEYED)
            .map(order => order.get(db.SupplierOrder.ACTUAL_THERM))
            .reduce((a, b) => a + b, 0);
        let sumActualSensor = this.state.supplierOrders
            .filter(order => order.get(db.SupplierOrder.STATUS) !== db.SupplierOrder.STATUS$DELEYED)
            .map(order => order.get(db.SupplierOrder.ACTUAL_SENSE))
            .reduce((a, b) => a + b, 0);

        let sumRequiredThermo = this.state.supplierOrders
            .filter(order => order.get(db.SupplierOrder.STATUS) !== db.SupplierOrder.STATUS$DELEYED)
            .map(order => order.get(db.SupplierOrder.REQUIRED_THERM))
            .reduce((a, b) => a + b, 0);

        let sumRequiredSensor = this.state.supplierOrders
            .filter(order => order.get(db.SupplierOrder.STATUS) !== db.SupplierOrder.STATUS$DELEYED)
            .map(order => order.get(db.SupplierOrder.REQUIRED_SENSE))
            .reduce((a, b) => a + b, 0);

        let isAdmin = isRolesInRoles(this.props.roles, ['Admin']);

        return (
            <Fragment>
                <Row>
                    <Col md={12}>
                        {
                            isAdmin && <Fragment>
                                <Button outline onClick={this.toggleNewSupplierOrderModal}>New order</Button>
                                <AddNewSupplierOrderModal
                                    setToggleModal={newSupplierModal => this.toggleNewSupplierOrderModal = newSupplierModal.toggle}
                                    callback={async (data) => {
                                        await this.loadSupplierOrders();
                                    }}
                                />
                                {
                                    this.props.roles.indexOf('Admin') >= 0 && <Toggle defaultChecked={false}
                                        checked={this.state.showAllSupplierOrder}
                                        onChange={(e) =>{
                                            this.setState({showAllSupplierOrder: !this.state.showAllSupplierOrder},  this.refresh);
                                        }}
                                    />
                                }
                            </Fragment>
                        }
                        &nbsp;
                        <img src={thermoIcon} height={30}/> &nbsp; {sumActualThermo} / {sumRequiredThermo} &nbsp;
                        <img src={co2SensorIcon} height={30}/> &nbsp; {sumActualSensor} / {sumRequiredSensor} &nbsp;
                        {
                            isAdmin && <Form inline={true}  style={{display: 'inline-block', marginLeft: 15}}>
                                <FormGroup inline={true}>
                                    <Label for={db.SupplierOrder.REFERENCE_NUMBER}>Supplier Order Reference Number:</Label>
                                    <Input type="text"
                                        name={db.SupplierOrder.REFERENCE_NUMBER}
                                        value={this.state.supplierOrderReferenceNumberFilter}
                                        onChange={(e) => this.setState({supplierOrderReferenceNumberFilter: e.target.value}, () => this.loadSupplierOrders())}
                                    />
                                </FormGroup>
                            </Form>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <BuildingInstallationTable
                            {...this.props}
                            supplierOrders={this.state.supplierOrders}
                            history={this.props.history}
                            deleteSupplierOrder={this.deleteSupplierOrder}
                            changeStatus={this.changeStatus}
                            loadSupplierOrders={() => {
                                this.loadSupplierOrders();
                            }}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }

    async handleChange(selectedOption){
        this.setState({ selectedOption });
    }
}

PageSupplierOrders.propTypes = {
    history: PropTypes.any,
    roles: PropTypes.array.isRequired
};