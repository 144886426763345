import React from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../config/dbStructure';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import swal from 'sweetalert';
import Parse from '../../../lib/parse';
import Select from 'react-select';

import {checkRequiredFields, extractFieldFromOperationTask} from '../../../lib/util';
import {getBuilding, getOperationFromId} from '../../../lib/zohoService';
import _ from 'lodash';

export default class AddNewMaintenanceTask extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            homes: [],
            operationTask: this.initializeOperatinTask(),
            installerUsers: []
        };

        this.toggle = this.toggle.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.reset = this.reset.bind(this);
        this.addNewInstallation = this.addNewInstallation.bind(this);
        this.fillTaskFromOperation = this.fillTaskFromOperation.bind(this);
        this.loadHomes = this.loadHomes.bind(this);
        this.loadInstallerUsers = this.loadInstallerUsers.bind(this);
    }

    async componentDidMount(){
        this.props.setToggleModal(this.toggle);
        this.loadInstallerUsers();
    }

    initializeOperatinTask(){
        let operationTask = new Parse.Object(db.classes.OperationTask);

        operationTask.set(db.OperationTask.COMPLETED, false);

        return operationTask;

    }


    async loadHomes(){
        let query = new Parse.Query(db.classes.Home);
        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY
        ]);
        query.notEqualTo(db.OperationTask.DELETED, true);
        query.limit(10000);

        let homes = await query.find();

        this.setState({homes})
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen});
    }

    reset(){
        this.setState({
            isOpen: false,
            homes: [],
            testBatches: []
        });
    }

    modifyOperationTaskField(fieldName, newValue){
        let supplierOrder = this.state.operationTask;
        supplierOrder.set(fieldName, newValue);

        return supplierOrder;
    }


    async addNewInstallation(){
        try {
            let operationTask = this.state.operationTask;
            let referenceNumber = operationTask.get(db.OperationTask.REFERENCE_NUMBER);

            let requiredFields = [
                db.OperationTask.HOME,
                db.OperationTask.OPERATION_ID,
                db.OperationTask.SUBJECT,
                db.OperationTask.REFERENCE_NUMBER,
                db.OperationTask.TYPE,
                db.OperationTask.INSTALLER
            ];

            let errorString = checkRequiredFields(operationTask, requiredFields);

            if(errorString) {
                throw new Error(errorString);
            }

            if(!referenceNumber.startsWith('OP-')){
                throw new Error('Operation Reference Number is invalid, should start with "OP-"');
            }

            await operationTask.save();

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

            this.reset();

            this.props.submit();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onOpened(){
        await this.loadHomes();
    }

    async fillTaskFromOperation(){
        try {
            let operationId = await swal('Operation id', {
                content: 'input',
                buttons: ['Cancel', 'Ok']
            });

            if (!operationId || operationId === '') {
                throw new Error('No operation id provided');
            }

            let operation = await getOperationFromId(operationId);
            let buildingId = _.get(operation, db.ZohoOperation.BUILDING.ID);
            let operationCategory= operation[db.ZohoOperation.CATEGORY];
            let building = await getBuilding(buildingId);
            let homeId = building[db.ZohoBuilding.CLOUD_BUILDING_ID];
            let home;

            if(homeId) {
                home = await (new Parse.Query(db.classes.Home).get(homeId));

                if (!home) throw new Error('Home not found');
            }

            let operationTask = this.state.operationTask;

            operationTask.set(db.OperationTask.HOME,                home);
            operationTask.set(db.OperationTask.REFERENCE_NUMBER,    operation[db.ZohoOperation.REFERENCE_NUMBER]);
            operationTask.set(db.OperationTask.SUBJECT,             operation[db.ZohoOperation.NAME]);
            operationTask.set(db.OperationTask.OPERATION_ID,        operation[db.ZohoOperation.ID]);

            switch (operationCategory) {
                case db.ZohoOperation.CATEGORY$INSTALLATION:
                    operationTask.set(db.OperationTask.TYPE,  db.OperationTask.TYPE$INSTALLATION);
                    break;
                case db.ZohoOperation.CATEGORY$MAINTENANCE:
                    operationTask.set(db.OperationTask.TYPE,  db.OperationTask.TYPE$INSTALLATION);
                    break;
            }

            this.setState({operationTask});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async loadInstallerUsers(){
        let installerUsers = await new Parse.Query(db.classes._User)
            .contains(db._User.TYPE_OF_CUSTOMER, db._User.TYPE_OF_CUSTOMER$INSTALLER_PARTNER)
            .find();

        this.setState({installerUsers})
    }

    render(){

        let {
            home,
            operationId,
            referenceNumber,
            subject,
            type,
            completed,
            installer,
            installationPeriod
        } = extractFieldFromOperationTask(this.state.operationTask);

        home = home ? {
            value: home,
            label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`
        } : null;

        type = type ? {
            value: type,
            label: type
        } : null;

        return <span>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}
                   size={'lg'} onOpened={this.onOpened}>
                <ModalHeader toggle={this.toggle}>Add new maintenance task</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Button outline color="primary" onClick={this.fillTaskFromOperation}>
                                Fill from operation
                            </Button>
                        </FormGroup>
                        <FormGroup>
                            <Label for="select-home">Select home</Label>
                            <Select
                                options={this.state.homes.map(home => {
                                    return {
                                        value: home,
                                        label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`
                                    }
                                })}
                                value={home}
                                onChange={item => {
                                    if(!item) item = {};

                                    this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.HOME, item.value)});
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.OperationTask.OPERATION_ID}>Operation id:</Label>
                            <Input type="text" name={db.OperationTask.OPERATION_ID}
                                value={operationId}
                                onChange={(e) => this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.OPERATION_ID, e.target.value)})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.OperationTask.REFERENCE_NUMBER}>Operation reference number:</Label>
                            <Input type="text" name={db.OperationTask.REFERENCE_NUMBER}
                                value={referenceNumber}
                                onChange={(e) => this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.REFERENCE_NUMBER, e.target.value)})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.OperationTask.SUBJECT}>Operation title:</Label>
                            <Input type="text" name={db.OperationTask.SUBJECT}
                                value={subject}
                                onChange={(e) => this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.SUBJECT, e.target.value)})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.OperationTask.INSTALLATION_PERIOD}>Installation period:</Label>
                            <Input type="text" name={db.OperationTask.INSTALLATION_PERIOD}
                                value={installationPeriod}
                                onChange={(e) => this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.INSTALLATION_PERIOD, e.target.value)})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.OperationTask.TYPE}>Operation type:</Label>
                            <Select
                                options={[
                                    db.OperationTask.TYPE$MAINTENANCE,
                                    db.OperationTask.TYPE$INSTALLATION
                                ].map(type => {
                                    return {
                                        value: type,
                                        label: type
                                    }
                                })}
                                value={type}
                                onChange={item => {
                                    this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.TYPE, item.value)});
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={db.OperationTask.INSTALLER}>Installer:</Label>
                            <Select
                                options={this.state.installerUsers.map(user => {
                                    return {
                                        value: user,
                                        label: user.get(db._User.USERNAME)
                                    }
                                })}
                                value={installer ? {value: installer, label: installer.get(db._User.USERNAME)} : null}
                                onChange={item => {
                                    this.setState({operationTask: this.modifyOperationTaskField(db.OperationTask.INSTALLER, item.value)});
                                }}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={this.addNewInstallation}>Add new maintenance task</Button>{' '}
                    <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </span>
    }
}

AddNewMaintenanceTask.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func,
    submit: PropTypes.func
};