/**
 * Created by Simo on 28.10.16.
 */
const _ = require('lodash');
const localConfig = require('./app.local');

module.exports = _.merge({
    log: {
        filterDomains: [
            'dashboard.stage.simplyhome.tech',
            'app.simplyhome.tech',
            'admindashboard.simplyhome.tech'
        ],
        requestWhitelist: ['url', 'method', 'query', 'body'],
        responseWhitelist: ['statusCode', 'body'],
    },
    api: {
        host: 'https://server.cleveron.ch'
    },
    leanManagment: {
        host: 'https://leanmanagement.cleveron.ch',
        toTestRoom: '47Vk3ljLmF',
        leanManagementUserId: 'hRNLgsBHZF',
        toConfig: null,
        MINIMAL_BATTERY_LEVEL: 4.05,
        MINIMAL_TIME_LAST_CONNECTION_MINUTES: 40,
        MINIMAL_TIME_LAST_CONNECTION_DEBUG_MINUTES: 5,
        MAXIMAL_TEMPERATURE_ERROR_GAP: 1.5,
        MAXIMAL_BATTERY_LEVEL: 4.5,
        REQUIRED_FIRMWARE_VERSION_THERMO: '6.0.14-1-therm',
        REQUIRED_FIRMWARE_VERSION_THERMO_14: '5.0.4-therm',
        REQUIRED_FIRMWARE_VERSION_SENSE_CO2: '6.0.10-sensp',
        REQUIRED_FIRMWARE_VERSION_WIFI: '5.1.5-HW2.1-therm',
        MAXIMAL_CO2_ERROR_GAP: 200,
        DEFAULT_NEW_ROOM_MIN_TEMP: 18,
        DEFAULT_NEW_ROOM_MAX_TEMP: 21,
        REQUIRED_NB_IOT_FIRMWARE_VERSION: 'BC660KGLAAR01A05_01.200.01.200',
        requiredFirmwareVersion: {
            thermo: {
                wnbp: '6.0.14-1-therm',
                wnb: '6.0.14-1-therm',
                wifi: '5.1.5-HW2.1-therm'
            },
            sensor: '6.0.10-sensp'
        }
    },
    adminDashboard: {
        host: 'http://admin.cleveron.ch'
    }
}, localConfig);
